import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import truncate from '../../utils/truncate';

import VoicePreview from './voice.preview';

interface VoiceItemProps {
  voiceUrl: string;
  name: string;
  isSelected: boolean;
  onSelect: () => void;
  onDelete: () => void;
}

const VoiceItem: FC<VoiceItemProps> = ({
  voiceUrl,
  name,
  isSelected,
  onSelect,
  onDelete,
}) => {
  return (
    <Stack
      sx={{
        width: '100%',
        backgroundColor: 'grey.13',
        padding: '6px 8px',
        borderRadius: '6px',
        border: '1px solid',
        borderColor: isSelected ? 'green.2' : 'grey.10',
        cursor: 'pointer',
        height: '58px',
        position: 'relative',
      }}
      onClick={onSelect}
    >
      <Typography
        sx={{
          color: 'grey.1',
          opacity: 0.6,
          fontWeight: 600,
          fontSize: '10px',
        }}
      >
        {truncate(name, 20)}
      </Typography>
      <VoicePreview voiceUrl={voiceUrl} />
      <Button
        className="red"
        sx={{
          width: '24px',
          minWidth: '24px',
          height: '24px',
          borderRadius: '2px!important',
          position: 'absolute',
          top: '5px',
          right: '5px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <DeleteIcon sx={{ width: '16px', height: '16px' }} />
      </Button>
    </Stack>
  );
};

export default VoiceItem;
