import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useContext } from 'react';

import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';

import { BotContext } from './bot.context';
import { FlowTypes, IFlowWithActivatorsAndFolder } from './flows/interfaces';
import BuildPostback from './postback/build-postback';

const PostbackTab = () => {
  const { flows } = useContext(BotContext);

  return (
    <Stack
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        width: '72vw',
        maxWidth: '1000px',
        padding: '24px',
        margin: '0 auto',
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          width: '100%',
          maxWidth: '600px',
          borderRadius: '12px',
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: 'grey.10',
          margin: '0 auto',
          p: '18.5px 24px 23px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: '600',
          }}
        >
          {t('postback.title')}
        </Typography>
        <BuildPostback
          postbackFlows={
            flows
              ? flows.filter((flow: IFlowWithActivatorsAndFolder) =>
                  flow.type.includes(FlowTypes.postback),
                )
              : []
          }
        />
      </Stack>
    </Stack>
  );
};

export default PostbackTab;
