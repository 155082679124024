import { Button, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

import { TELEGRAM_MESSAGE_MAX_LENGTH } from '../../../common/constants/constants';
import ControlButtonsEditor from '../../../common/flow-nodes/control-buttons-editor';
import TelegramButtons from '../../../common/telegram/telegram.buttons';
import {
  IMedia,
  TelegramButton,
  TelegramMessageType,
} from '../../../interfaces/common';
import AddMediaButton from '../../../telegram-messenger/dialogs/add-media.button';
import AttachedMediaPreview from '../../../telegram-messenger/dialogs/attached-media.preview';
import ButtonsModal from '../buttons-modal';

interface TelegramMessageEditorProps {
  open: boolean;
  onClose: () => void;
  clearMessageState: () => void;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  newMessage: string;
  onAddRow: () => void;
  buttons: TelegramButton[][];
  onRemoveRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, buttonIndex: number) => void;
  onAddUrlButton: (buttonIndex: number) => void;
  onAddActionButton: (buttonIndex: number) => void;
  onButtonChange: (
    rowIndex: number,
    buttonIndex: number,
    key: string,
    value: string,
  ) => void;
  onRemoveMedia: (_id: string) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  openMediaModal: () => void;
  openVoiceModal: () => void;
  openVideoNoteModal: () => void;
  media: IMedia[];
  sendButtonDisabled: boolean;
  addNewButtonsRowDisabled: boolean;
  messageType: TelegramMessageType;
  onMessageTypeChange: (newType: TelegramMessageType) => void;
}

const TelegramMessageEditor: FC<TelegramMessageEditorProps> = ({
  open,
  onClose,
  clearMessageState,
  messageType,
  onMessageTypeChange,
  onInput,
  newMessage,
  buttons,
  onAddRow,
  onRemoveRow,
  onRemoveButton,
  onAddUrlButton,
  onAddActionButton,
  onButtonChange,
  onRemoveMedia,
  onSubmit,
  media,
  sendButtonDisabled,
  openMediaModal,
  openVoiceModal,
  openVideoNoteModal,
}) => {
  const [mediaTabIndex, setMediaTabIndex] = useState(0);
  const [buttonsModalOpen, setButtonsModalOpen] = useState(false);
  const [isValidHTML, setIsValidHTML] = useState(false);

  const validateHTML = (inputText: string) => {
    const regex =
      /<\s*\/?\s*\w+(\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)*\s*\/?>/g;
    const matches = inputText.match(regex);
    if (!matches) {
      setIsValidHTML(true);
      return;
    }
    const stack = [];
    for (const tag of matches) {
      if (tag.startsWith('</')) {
        if (stack.length === 0 || stack.pop() !== tag.slice(2, -1)) {
          setIsValidHTML(false);
          return;
        }
      } else {
        const tagNameMatch = tag.match(/\w+/);
        if (tagNameMatch && tagNameMatch.length > 0) {
          stack.push(tagNameMatch[0]);
        }
      }
    }
    setIsValidHTML(stack.length === 0);
  };

  useEffect(() => {
    switch (messageType) {
      case TelegramMessageType.media: {
        setMediaTabIndex(0);
        return;
      }
      case TelegramMessageType.voice: {
        setMediaTabIndex(1);
        return;
      }
      case TelegramMessageType.videoNote: {
        setMediaTabIndex(2);
        return;
      }
    }
  }, [messageType]);

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    clearMessageState();
    switch (newValue) {
      case 0: {
        onMessageTypeChange(TelegramMessageType.media);
        return;
      }
      case 1: {
        onMessageTypeChange(TelegramMessageType.voice);
        return;
      }
      case 2: {
        onMessageTypeChange(TelegramMessageType.videoNote);
        return;
      }
    }
  };

  useEffect(() => {
    validateHTML(newMessage);
  }, [newMessage]);

  if (!open) return null;

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderRight: '2px solid',
        borderLeft: '2px solid',
        borderColor: 'grey.10',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          borderRadius: '4px',
          p: '8px',
          backgroundColor: 'grey.13',
          mt: '16px',
          height: '38px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            opacity: '0.8',
            fontWeight: 500,
            fontSize: '16px',
          }}
        >
          {t('flowNodes.messageLabel')}
        </Typography>
      </Stack>

      <Tabs
        value={mediaTabIndex}
        onChange={onTabChange}
        sx={{
          mt: '14px',
          mx: '-12px',
          borderBottom: '1px solid',
          borderColor: 'grey.10',
          '& .MuiTabs-indicator': {
            backgroundColor: 'green.2',
          },
          '& .MuiTab-root': {},
          '& .MuiTab-root.Mui-selected': {
            color: 'green.2',
          },
        }}
      >
        <Tab
          label="Media"
          sx={{
            width: '33.33%',
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '16px',
          }}
        />
        <Tab
          label="Voice"
          sx={{
            width: '33.33%',
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '16px',
          }}
        />
        <Tab
          label="Video"
          sx={{
            width: '33.33%',
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '16px',
          }}
        />
      </Tabs>

      <Stack flexDirection="row" alignItems="center" sx={{ padding: '12px 0' }}>
        <Stack
          flexDirection="row"
          gap="4px"
          sx={{
            border: '1px solid',
            borderColor: 'grey.13',
            borderRadius: '8px',
            backgroundColor: 'grey.15',
            padding: '8px ',
            width: '100%',
          }}
        >
          <AddMediaButton
            openMediaModal={openMediaModal}
            openVideoNoteModal={openVideoNoteModal}
            openVoiceModal={openVoiceModal}
            messageType={messageType}
          />
          <AttachedMediaPreview
            media={media}
            onRemoveMedia={onRemoveMedia}
            size="small"
            maxWidth="220px"
          />
        </Stack>
      </Stack>
      <Stack>
        {mediaTabIndex === 2 ? (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '140%',
              textAlign: ' center',
              opacity: 0.4,
            }}
          >
            {t('flowNodes.textProhibited')}
          </Typography>
        ) : (
          <Fragment>
            <TextField
              sx={{
                backgroundColor: 'grey.15',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'grey.10',
                overflow: 'hidden',
                '& fieldset': {
                  border: 'none',
                },
              }}
              inputProps={{
                maxLength: TELEGRAM_MESSAGE_MAX_LENGTH,
              }}
              InputProps={{
                sx: {
                  '& .MuiInputBase-root': {
                    fontWeight: 500,
                    fontSize: '14px',
                    p: '15px 15px 15px 40px ',
                  },
                },
              }}
              multiline
              placeholder={
                messageType === TelegramMessageType.videoNote
                  ? t('common.notSupportMessagePlaceholder')
                  : t('common.enterMessagePlaceholder')
              }
              value={newMessage}
              minRows={5}
              maxRows={8}
              onChange={onInput}
              disabled={messageType === TelegramMessageType.videoNote}
            />
            <Stack
              sx={{
                mt: '4px',
                textAlign: 'end',
                fontWeight: 600,
                fontSize: '10px',
                opacity: '0.6',
              }}
            >
              {newMessage.length}/{TELEGRAM_MESSAGE_MAX_LENGTH}
            </Stack>
          </Fragment>
        )}
      </Stack>

      <Stack
        sx={{
          m: '10px 0 20px',
          overflow: 'auto',
        }}
      >
        <TelegramButtons buttons={buttons} />
        <Button
          className="green"
          sx={{ height: '48px', mt: '15px' }}
          disabled={Boolean(media.length > 1)}
          onClick={() => {
            setButtonsModalOpen(true);
          }}
        >
          {t('common.editButtons')}
        </Button>
      </Stack>

      <ButtonsModal
        open={buttonsModalOpen}
        onClose={() => {
          setButtonsModalOpen(false);
        }}
        buttons={buttons}
        onAddUrlButton={onAddUrlButton}
        onAddActionButton={onAddActionButton}
        onButtonChange={onButtonChange}
        onRemoveButton={onRemoveButton}
        onAddButtonsRow={onAddRow}
        onRemoveButtonsRow={onRemoveRow}
      />
      <ControlButtonsEditor
        onClose={onClose}
        onSubmit={onSubmit}
        sendButtonDisabled={sendButtonDisabled || !isValidHTML}
      />
    </Stack>
  );
};

export default TelegramMessageEditor;
