import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ControlButtonsEditorType = {
  onClose: () => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  sendButtonDisabled: boolean;
};
const ControlButtonsEditor: FC<ControlButtonsEditorType> = ({
  onClose,
  onSubmit,
  sendButtonDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap="8px"
      sx={{
        mt: 'auto',
      }}
    >
      <Button
        onClick={onClose}
        className="red"
        sx={{
          width: '50%',
          color: 'red.2',
        }}
      >
        {t('common.cancel')}
      </Button>
      <Button
        className="brightGreen"
        sx={{
          width: '50%',
        }}
        onClick={onSubmit}
        disabled={sendButtonDisabled}
      >
        {t('common.save')}
      </Button>
    </Stack>
  );
};

export default ControlButtonsEditor;
