import React, { FC } from 'react';

import StatisticsContainer from '../../common/statistics/statistics.container';

type BotStatisticProps = {
  onFetchStatistics: (
    params: string[],
    endDate: string,
    startDate: string,
    channelId: string | null,
    // endDate2: string,
    // startDate2: string,
  ) => void;
  statistics: (string | number)[][];
  loadingStatistics: boolean;
};

const BotStatistic: FC<BotStatisticProps> = ({
  onFetchStatistics,
  statistics,
  loadingStatistics,
}) => {
  return (
    <StatisticsContainer
      onFetchStatistics={onFetchStatistics}
      statistics={statistics}
      loadingStatistics={loadingStatistics}
      channelId={null}
      channelCreatedAt={null}
    />
  );
};

export default BotStatistic;
