// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createTheme } from '@mui/material';

import Gilroy from '../../assets/font/gilroy/stylesheet.css';

export const darkThemeColors = {
  grey: {
    1: '#FFFFFF',
    2: '#F4F3F4',
    3: '#DDDDDD',
    4: '#C7C6C7',
    5: '#B1B0B1',
    6: '#9C9B9C',
    7: '#878687',
    8: '#737273',
    9: '#5F5E5F',
    10: '#4C4B4C',
    11: '#434043',
    12: '#353335',
    13: '#292829',
    14: '#1E1D1E',
    15: '#161516',
    16: '#0A0909',
    secondary: '#A1A1A1',
  },
  green: {
    1: '#3C4916',
    2: '#CAFA44',
    3: '#DEFF82',
    4: '#63752D',
    5: '#85A235',
  },
  blue: {
    1: '#122634',
    2: '#0495FF',
    3: '#3DADFF',
    4: '#194C71',
    5: '#0F4873',
    6: '#0C395B',
  },
  red: {
    1: '#451728',
    2: '#FF1F6E',
    3: '#FF639A',
    4: '#FF4E4E',
    5: '#781E3E',
  },
  purple: {
    1: '#461973',
    2: '#631BAB',
    3: '#751DCD',
    4: '#7E1DDE',
  },
  yellow: {
    1: '#453B17',
    2: '#FFCE1F',
  },
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    ...darkThemeColors,
    primary: {
      main: darkThemeColors.blue['2'],
    },
  },

  typography: {
    fontFamily: 'Gilroy, sans-serif',
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          lineHeight: '140%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          cursor: 'pointer',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '140%',
          height: '56px',
          '&:disabled': { opacity: '0.3', cursor: 'not-allowed' },
          '&.brightGreen': {
            backgroundColor: darkThemeColors.green['2'],
            color: darkThemeColors.grey['10'],
            borderRadius: '8px',
            '&:disabled': {
              opacity: 1,
              backgroundColor: ' #979797',
            },
          },
          '&.green': {
            backgroundColor: 'rgba(202, 250, 68, 0.4)',
            boxShadow: '0 4px 10px 0 rgba(202, 250, 68, 0.15)',
            color: darkThemeColors.grey['1'],
            border: '1px solid',
            borderRadius: '8px',
            borderColor: darkThemeColors.green['2'],
            '&:disabled': {
              backgroundColor: '#979797',
              borderColor: '#979797',
            },
          },
          '&.blue': {
            backgroundColor: '#0F4873',
            boxShadow: '0 4px 10px 0 rgba(4, 149, 255, 0.15)',
            color: darkThemeColors.grey['1'],
            border: '1px solid ',
            borderRadius: '8px',
            borderColor: darkThemeColors.blue['2'],
            '&:disabled': {
              opacity: 1,
              backgroundColor: ' #979797',
              borderColor: '#979797',
            },
          },
          '&.blue': {
            backgroundColor: '#0F4873',
            boxShadow: '0 4px 10px 0 rgba(4, 149, 255, 0.15)',
            color: darkThemeColors.grey['1'],
            border: '1px solid',
            borderRadius: '8px',
            borderColor: darkThemeColors.blue['2'],
            '&:disabled': {
              opacity: 1,
              backgroundColor: ' #979797',
              borderColor: '#979797',
            },
          },
          '&.blue': {
            boxShadow: '0 4px 10px 0 rgba(4, 149, 255, 0.15)',
            backgroundColor: '#0F4873',
            color: darkThemeColors.grey['1'],
            border: '1px solid ',
            borderRadius: '8px',
            borderColor: darkThemeColors.blue['2'],
            '&:disabled': {
              backgroundColor: '#979797',
              borderColor: '#979797',
            },
          },
          '&.red': {
            border: ' 1px solid',
            borderColor: darkThemeColors.red['2'],
            borderRadius: '8px',
            backgroundColor: 'rgba(255, 31, 110, 0.4)',
            boxShadow: '0 4px 10px 0 rgba(255, 31, 110, 0.15)',
            color: darkThemeColors.grey['1'],
            '&:disabled': {
              backgroundColor: 'rgba(172, 172, 172, 0.6)',
              borderColor: '#acacac',
              borderRadius: '8px',
            },
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          outline: 'none',
          '&:hover': { outline: 'none' },
          '&:active': { outline: 'none' },
          '&:focus-visible': { outline: 'none' },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 400;
          src: local('Gilroy'), local('Gilroy Regular'), url(${Gilroy}) format('woff2');
        }
      `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            padding: '14.5px 16px',
            color: darkThemeColors.grey['2'],
            backgroundColor: darkThemeColors.grey['15'],
            borderRadius: '6px',
            '&:disabled': { backgroundColor: darkThemeColors.grey['10'] },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: darkThemeColors.grey['10'],
            borderWidth: '1px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: darkThemeColors.grey['10'],
            borderWidth: '1px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: darkThemeColors.blue['2'],
            borderWidth: '1px',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: darkThemeColors.blue['2'],
        },
      },
    },
  },
});

export default darkTheme;
