import CloseIcon from '@mui/icons-material/Close';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import { Button, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { FC, useContext, useState } from 'react';

import changeFlowDefault from '../../api/bots/change-flow-default';
import { deleteFlow } from '../../api/flows/delete-flow';
import { AppContext } from '../../AppContext';
import CopyableText from '../../common/copyableText/copyableText';
import ModalBox from '../../common/modal/modal-box';
import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import { ProjectContext } from '../../projects/project.context';
import { BotContext } from '../bot.context';

import FlowActivatorsModal from './flow-activators.modal';
import { IFlowWithActivatorsAndFolder } from './interfaces';

interface FlowItemProps {
  flow: IFlowWithActivatorsAndFolder;
  openNodesEditor: (flow: IFlowWithActivatorsAndFolder) => void;
  onCopyFlow: (flow: IFlowWithActivatorsAndFolder) => void;
  openEditFlowModal: (flow: IFlowWithActivatorsAndFolder) => void;
}

const FlowItem: FC<FlowItemProps> = ({
  flow,
  openNodesEditor,
  onCopyFlow,
  openEditFlowModal,
}) => {
  const { openConfirmationDialog } = useContext(AppContext);
  const { refetchFlows, bot } = useContext(BotContext);
  const { refetchBots } = useContext(ProjectContext);

  const [activatorsModalOpen, setActivatorsModalOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const openFlowMenu = (e: React.SyntheticEvent) => {
    setMenuAnchor(e.currentTarget);
  };

  const isDefault = flow._id === bot.defaultFlow;

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: 'grey.15',
          border: ' 1px solid',
          borderBottom: ' 1px solid',
          borderLeft: '1px solid',
          borderRight: '1px solid',
          borderColor: 'grey.13',
          height: '45px',
          ':hover': {
            backgroundColor: 'grey.15',
            cursor: 'pointer',
            transition: ' all 0.2s ease-out',
          },
        }}
        onClick={() => openNodesEditor(flow)}
      >
        <TableCell
          sx={{
            textAlign: 'center',
            pr: '42px',
            fontWeight: 600,
            border: 'none',
          }}
        >
          {flow.name}
        </TableCell>
        <TableCell
          sx={{
            border: 'none',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Stack alignItems="center" justifyContent="center">
            <CopyableText
              text={flow._id}
              color="grey.1"
              fontWeight="600"
              fontSize="14px"
            />
          </Stack>
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'center',
            pr: '42px',
            fontWeight: 600,
            fontSize: '14px',
            border: 'none',
          }}
        >
          {flow?.activationsCount}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'center',
            pr: '42px',
            fontWeight: 600,
            border: 'none',
          }}
        >
          {dayjs(flow.updatedAt).fromNow(true)}
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'center',
            pr: '42px',
            fontWeight: 600,
            border: 'none',
          }}
        >
          {flow?.folder?.name ?? '-'}
        </TableCell>
        <TableCell
          sx={{
            pr: '42px',
            justifyContent: 'end',
            border: 'none',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              <Typography
                sx={{
                  mr: '20px',
                  color: 'green.2',
                }}
              >
                {isDefault ? 'Default' : ''}
              </Typography>
            </Stack>
            <Stack
              gap="6px"
              alignItems="center"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: ' space-between',
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button
                className="green"
                sx={{
                  height: '29px',
                  width: '29px',
                  minWidth: '29px',
                }}
                onClick={() => setActivatorsModalOpen(true)}
              >
                <InsertLinkRoundedIcon
                  sx={{
                    fontSize: '17px',
                  }}
                />
              </Button>

              <Button
                onClick={openFlowMenu}
                className="blue"
                sx={{
                  padding: '0',
                  height: '29px',
                  width: '29px',
                  minWidth: '29px',
                }}
              >
                <MoreHorizIcon
                  sx={{
                    fontSize: '17px',
                  }}
                />
              </Button>
              <ThreeDotsMenu
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                anchor={menuAnchor}
                border="1px solid "
                borderRadius="8px"
                marginDivider="0 12px"
                backgroundColor="grey.15"
                hoverBackground="grey.15"
                menuItems={[
                  [
                    {
                      text: t('common.clone'),
                      textSx: {
                        color: 'grey.5',
                        fontWeight: 500,
                        fontSize: '16px',
                      },
                      renderIcon: () => (
                        <ContentCopyIcon
                          sx={{
                            color: 'grey.5',
                            fontSize: '18px',
                          }}
                        />
                      ),
                      onClick: () => {
                        onCopyFlow(flow);
                      },
                    },
                  ],
                  [
                    {
                      text: t('common.edit'),
                      textSx: {
                        color: 'grey.5',
                        fontWeight: 500,
                        fontSize: '16px',
                      },
                      renderIcon: () => (
                        <EditRoundedIcon
                          sx={{
                            fontSize: '18px',
                            color: 'grey.5',
                          }}
                        />
                      ),
                      onClick: () => {
                        openEditFlowModal(flow);
                      },
                    },
                  ],
                  isDefault
                    ? [
                        {
                          text: t('common.removeDefault'),
                          textSx: {
                            color: 'green.2',
                            fontWeight: 500,
                            fontSize: '16px',
                          },
                          renderIcon: () => (
                            <CloseRoundedIcon
                              sx={{
                                fontSize: '18px',
                                color: 'green.2',
                              }}
                            />
                          ),
                          onClick: () => {
                            changeFlowDefault(bot._id, null).then(refetchBots);
                          },
                        },
                      ]
                    : [
                        {
                          text: t('common.makeDefault'),
                          textSx: {
                            color: 'green.2',
                            fontWeight: 500,
                            fontSize: '16px',
                          },
                          renderIcon: () => (
                            <MyLocationRoundedIcon
                              sx={{ color: 'green.2', fontSize: '18px' }}
                            />
                          ),
                          onClick: () => {
                            changeFlowDefault(bot._id, flow._id).then(
                              refetchBots,
                            );
                          },
                        },
                      ],
                  [
                    {
                      text: t('common.delete'),
                      textSx: {
                        color: 'red.2',
                        fontWeight: 500,
                        fontSize: '16px',
                      },
                      renderIcon: () => (
                        <DeleteOutlineIcon
                          sx={{ color: 'red.2', fontSize: '18px' }}
                        />
                      ),
                      onClick: () => {
                        openConfirmationDialog(
                          t('flow.deleteFlow'),
                          t('common.delete'),
                          () => {
                            deleteFlow(flow._id ?? '').then(refetchFlows);
                          },
                        );
                      },
                    },
                  ],
                ]}
              />
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>

      <ModalBox
        open={activatorsModalOpen}
        onClose={() => setActivatorsModalOpen(false)}
        maxWidth="50%"
        sx={{
          position: 'relative',
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
          }}
          onClick={() => setActivatorsModalOpen(false)}
        >
          <CloseIcon />
        </Stack>
        <FlowActivatorsModal flow={flow} />
      </ModalBox>
    </>
  );
};

export default FlowItem;
