import { CreateOrEditProjectInviteAccess } from '../../projects/project.interface';
import axios from '../axios';

const createInviteLink = async (
  projectId: string,
  newInviteLink: CreateOrEditProjectInviteAccess,
) => {
  const { data } = await axios.post(
    `/project-invites/${projectId}`,
    newInviteLink,
  );

  return data;
};

export default createInviteLink;
