import {
  AbstractDocument,
  IMedia,
  Params,
  TelegramButton,
  TelegramMessageType,
} from '../../interfaces/common';

import { IActionsNodeData } from './flow-nodes/actions-node';
import { IChatActionNodeData } from './flow-nodes/chat-action.node';
import { IConditionsNodeData } from './flow-nodes/conditions.node';
import { IDelayNodeData } from './flow-nodes/delay.node';
import { IMenuNodeData } from './flow-nodes/menu.node';
import { IStartNodeData } from './flow-nodes/start.node';
import { ITelegramMessageNodeData } from './flow-nodes/telegram-message-node';

export enum FlowTypes {
  broadcast = 'broadcast',
  start = 'start',
  join = 'join',
  leave = 'leave',
  draft = 'draft',
  postback = 'postback',
  messenger = 'messenger',
}

export interface IFlow extends AbstractDocument {
  name: string;
  activationsCount: number;
  folder: string | null;
  type: FlowTypes[];
  rootNode: string | null;
  startPosition: { x: number; y: number };
  activators: string[];
  owner: string;
  telegramBot: string;
  copyFrom: string | null;
}

export interface IFlowFolder extends AbstractDocument {
  name: string;
  owner: string;
  telegramBot: string;
}

export type CreateFlowFolder = Omit<
  IFlowFolder,
  '_id' | 'createdAt' | 'updatedAt' | 'owner'
>;

export type updateFlowFolder = Omit<
  IFlowFolder,
  '_id' | 'createdAt' | 'updatedAt' | 'owner' | 'telegramBot'
>;

export interface IFlowActivator extends AbstractDocument {
  flow: string;
  name: string;
  params: Params;
}

export type CreateFlowActivator = Omit<
  IFlowActivator,
  '_id' | 'createdAt' | 'updatedAt' | 'flow'
>;

export type UpdateFlowActivator = Partial<
  Omit<IFlowActivator, '_id' | 'createdAt' | 'updatedAt'>
>;

export interface IFlowWithActivatorsAndFolder
  extends Omit<IFlow, 'activators' | 'folder'> {
  activators: IFlowActivator[];
  folder: IFlowFolder | null;
}

export type CreateFlow = Omit<
  IFlow,
  | '_id'
  | 'createdAt'
  | 'updatedAt'
  | 'owner'
  | 'activationsCount'
  | 'startPosition'
  | 'rootNode'
  | 'activators'
>;

export type UpdateFlow = Partial<
  Omit<
    IFlow,
    '_id' | 'createdAt' | 'updatedAt' | 'owner' | 'activations' | 'telegramBot'
  >
>;

export enum FlowNodeTypes {
  telegramMessage = 'telegramMessage',
  actions = 'actions',
  delay = 'delay',
  conditions = 'conditions',
  start = 'start',
  chatAction = 'chatAction',
  menu = 'menu',
}

export interface IBaseFlowNode extends AbstractDocument {
  type: FlowNodeTypes;
  flow: string;
  next: string | null;
  position: { x: number; y: number };
}

export interface ITelegramMessageFlowNodeData {
  text: string;
  buttons: TelegramButton[][];
  media: string[];
  telegramMessageType: TelegramMessageType;
}

export interface ITelegramMessageFlowNodeDataWithMedia
  extends Omit<ITelegramMessageFlowNodeData, 'media'> {
  media: IMedia[];
}

export type ChatActionTypes =
  | 'typing'
  | 'upload_photo'
  | 'record_video'
  | 'record_voice'
  | 'upload_document'
  | 'choose_sticker'
  | 'record_video_note';

export interface IChatActionFlowNodeData {
  actionType: string;
  duration: number;
}

export enum ActionTaskTypes {
  assignParam = 'ASSIGN_PARAM',
  deleteParam = 'DELETE_PARAM',
  acceptToChannel = 'ACCEPT_TO_CHANNEL',
  kickFromChannel = 'KICK_FROM_CHANNEL',
  startFlow = 'START_FLOW',
  unbanInChannel = 'UNBAN_IN_CHANNEL',
}

export enum ConditionTypes {
  checkParam = 'CHECK_PARAM',
  checkChannelMembership = 'CHECK_CHANNEL_MEMBERSHIP',
  checkStatus = 'CHECK_STATUS',
}

export interface IAssignParamFlowNodeData {
  key: string;
  value: string;
  inputValue: string;
}

export interface IDeleteParamFlowNodeData {
  key: string;
  inputValue: string;
}

export interface IAcceptToChannelFlowNodeData {
  channelId: string;
  channelName: string;
}

export interface IKickFromChannelFlowNodeData {
  channelId: string;
  channelName: string;
}

export interface IStartFlowFlowNodeData {
  flowId: string;
  flowName: string;
}

export interface IUnbanFromChannelFlowNodeData {
  channelId: string;
  channelName: string;
}

export type ActionFlowNodeTaskData =
  | (IAssignParamFlowNodeData & { type: ActionTaskTypes.assignParam })
  | (IDeleteParamFlowNodeData & { type: ActionTaskTypes.deleteParam })
  | (IAcceptToChannelFlowNodeData & { type: ActionTaskTypes.acceptToChannel })
  | (IKickFromChannelFlowNodeData & { type: ActionTaskTypes.kickFromChannel })
  | (IStartFlowFlowNodeData & { type: ActionTaskTypes.startFlow })
  | (IUnbanFromChannelFlowNodeData & {
      type: ActionTaskTypes.unbanInChannel;
    });

export interface IActionsFlowNodeData {
  tasks: ActionFlowNodeTaskData[];
}

export interface IDelayFlowNodeData {
  delayForSeconds: number;
  applyTimeRange: boolean;
  days: number[];
  startTime: string;
  endTime: string;
  timezone: string;
}

export interface ICheckParamConditionData {
  key: string;
  value: string;
  truthyNext: string;
  inputValue: string;
}

export interface ICheckChannelMembershipConditionData {
  channelName: string;
  channelId: string;
  truthyNext: string;
}

export interface ICheckStatusConditionData {
  status: string;
  truthyNext: string;
}

export type ConditionsFlowNodeConditionData =
  | (ICheckParamConditionData & { type: ConditionTypes.checkParam })
  | (ICheckChannelMembershipConditionData & {
      type: ConditionTypes.checkChannelMembership;
    })
  | (ICheckStatusConditionData & { type: ConditionTypes.checkStatus });

export interface IConditionsFlowNodeData {
  conditions: ConditionsFlowNodeConditionData[];
}

export interface IMenuFlowNodeData {
  toggleActiveConnectEdge: () => void;
  addTelegramMessageNode: (
    data: ITelegramMessageFlowNodeData,
    position: { x: number; y: number },
    newNodeId?: string,
  ) => void;
  addActionsNode: (
    tasks: ActionFlowNodeTaskData[],
    position: { x: number; y: number },
    newNodeId?: string,
  ) => void;
  addConditionsNode: (
    conditions: ConditionsFlowNodeConditionData[],
    position: { x: number; y: number },
    newNodeId?: string,
  ) => void;
  addDelayNode: (
    {
      delayForSeconds,
      applyTimeRange,
      days,
      startTime,
      endTime,
      timezone,
    }: IDelayFlowNodeData,
    position: { x: number; y: number },
    newNodeId?: string,
  ) => void;
  addChatActionNode: (
    { actionType, duration }: IChatActionFlowNodeData,
    position: { x: number; y: number },
    newNodeId?: string,
  ) => void;
}

export interface ITelegramMessageFlowNode extends IBaseFlowNode {
  data: ITelegramMessageFlowNodeData;
}

export interface ITelegramMessageFlowNodeExtended
  extends Omit<ITelegramMessageFlowNode, 'data'> {
  data: ITelegramMessageFlowNodeDataWithMedia;
}

export interface IChatActionFlowNode extends IBaseFlowNode {
  data: IChatActionFlowNodeData;
}

export interface IActionFlowNode extends IBaseFlowNode {
  data: IActionsFlowNodeData;
}

export interface IDelayFlowNode extends IBaseFlowNode {
  data: IDelayFlowNodeData;
}

export interface IConditionsFlowNode extends IBaseFlowNode {
  data: IConditionsFlowNodeData;
}

export interface IMenuFlowNode extends IBaseFlowNode {
  data: IMenuFlowNodeData;
}

export interface IConditionsFlowNodeExtended
  extends Omit<IConditionsFlowNode, 'data'> {
  data: IConditionsFlowNodeData;
}

export type IFlowNode =
  | (ITelegramMessageFlowNode & {
      type: FlowNodeTypes.telegramMessage;
    })
  | (IActionFlowNode & { type: FlowNodeTypes.actions })
  | (IDelayFlowNode & { type: FlowNodeTypes.delay })
  | (IConditionsFlowNode & { type: FlowNodeTypes.conditions })
  | (IChatActionFlowNode & { type: FlowNodeTypes.chatAction })
  | (IMenuFlowNode & { type: FlowNodeTypes.menu });

export type IFlowNodeExtended =
  | (ITelegramMessageFlowNodeExtended & {
      type: FlowNodeTypes.telegramMessage;
    })
  | (IActionFlowNode & { type: FlowNodeTypes.actions })
  | (IDelayFlowNode & { type: FlowNodeTypes.delay })
  | (IConditionsFlowNodeExtended & { type: FlowNodeTypes.conditions })
  | (IChatActionFlowNode & { type: FlowNodeTypes.chatAction })
  | (IMenuFlowNode & { type: FlowNodeTypes.menu });

export type NodeData =
  | ITelegramMessageNodeData
  | IStartNodeData
  | IActionsNodeData
  | IDelayNodeData
  | IConditionsNodeData
  | IChatActionNodeData
  | IMenuNodeData;
