/* eslint-disable @typescript-eslint/ban-ts-comment */
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer';
import { CSSObject, styled } from '@mui/material/styles';
import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import VolumeOffIcon from '../assets/icons/volumeOffIcon';
import VolumeOnIcon from '../assets/icons/volumeOnIcon';

import { MessengerContext } from './messenger.context';
import { IMessengerTab } from './messenger.page';
import { getModeProps } from './mode.control';

interface DrawerMixinProps {
  theme: Theme;
  open: boolean;
}

const openedMixin = ({ theme }: DrawerMixinProps): CSSObject => ({
  width: '240px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = ({ theme }: DrawerMixinProps): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `72px`,
});

interface CustomDrawerProps extends DrawerProps {
  open: boolean;
}

const SDrawer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'open',
})<CustomDrawerProps>(({ theme, open }) => ({
  width: '240px',
  height: 'calc(100vh)',
  flexShrink: 0,
  zIndex: 9,
  // @ts-ignore
  backgroundColor: theme.palette.grey['14'],
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin({ theme, open }),
    '& .MuiDrawer-paper': openedMixin({ theme, open }),
  }),
  ...(!open && {
    ...closedMixin({ theme, open }),
    '& .MuiDrawer-paper': closedMixin({ theme, open }),
  }),
}));

interface BotDrawerProps {
  tabs: IMessengerTab[];
  currentTabIndex: number;
  onTabChange: (_: React.SyntheticEvent, newIndex: number) => void;
  mode: string;
}

const Drawer: FC<BotDrawerProps> = ({
  tabs,
  currentTabIndex,
  onTabChange,
  mode = '',
}) => {
  const { t } = useTranslation();

  const { isVolume, setIsVolume } = useContext(MessengerContext);

  const [open, setOpen] = useState(
    JSON.parse(sessionStorage.getItem('isDrawerOpened') as string) ?? true,
  );

  useEffect(() => {
    sessionStorage.setItem('isDrawerOpened', JSON.stringify(open));
  }, [open]);

  return (
    <SDrawer open={open}>
      <Tabs
        orientation="vertical"
        value={currentTabIndex}
        onChange={onTabChange}
        TabIndicatorProps={{
          sx: { left: '0', width: '5px' },
        }}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {tabs.map(({ label, icon, disabled, control }, index) => {
          let background;
          if (
            mode === 'online' ||
            mode === 'offline' ||
            mode === 'silentOnline'
          ) {
            background = getModeProps(mode).background;
          }
          return (
            <Tab
              key={'tab-' + label}
              disableRipple={Boolean(control)}
              sx={{
                borderBottom: '1px solid ',
                borderColor: 'grey.13',
                p: '4px',
                minWidth: 'unset',
                alignItems: 'start',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: disabled ? 'transparent' : 'grey.13',
                },
              }}
              label={
                open ? (
                  <Stack
                    direction="row"
                    gap="10px"
                    sx={{
                      width: '100%',
                      borderRadius: '8px',
                      padding: '20px 24px',
                      opacity: disabled ? 0.3 : 1,
                      backgroundColor:
                        currentTabIndex === index ? 'grey.10' : 'transparent',
                      color: currentTabIndex === index ? 'green.2' : 'grey.1',
                      background: control
                        ? background
                        : currentTabIndex === index
                        ? 'grey.10'
                        : 'transparent',
                    }}
                  >
                    {icon}
                    {control ? (
                      control
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '18px',
                          textTransform: 'lowercase',
                          '&:first-letter': {
                            textTransform: 'capitalize',
                          },
                        }}
                      >
                        {label}
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: '64px',
                      height: '64px',
                      borderRadius: '8px',
                      opacity: disabled ? 0.3 : 1,
                      backgroundColor:
                        currentTabIndex === index ? 'grey.10' : 'transparent',
                      color: currentTabIndex === index ? 'green.2' : 'grey.1',
                    }}
                  >
                    {icon}
                  </Stack>
                )
              }
            />
          );
        })}
      </Tabs>

      <Divider sx={{ mt: 'auto' }} />
      <Stack sx={{ p: '4px', height: '72px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="8px"
          sx={{
            borderRadius: '8px',
            background: isVolume
              ? 'linear-gradient(90deg, rgba(29, 145, 233, 0) 0%, rgba(29, 145, 233, 0.25) 100%)'
              : 'linear-gradient(90deg, rgba(255, 31, 110, 0) 0%, rgba(255, 31, 110, 0.25) 100%)',
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={() => setIsVolume(!isVolume)}
        >
          {isVolume ? <VolumeOnIcon /> : <VolumeOffIcon />}
          {open ? (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '18px',
                color: isVolume ? 'blue.2' : 'red.2',
              }}
            >
              {isVolume ? 'On' : 'Off'}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
      <Divider />
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        sx={{ padding: '18px', cursor: 'pointer' }}
        onClick={() => setOpen((prev: boolean) => !prev)}
      >
        <IconButton>
          {!open ? (
            <ArrowForwardIosRoundedIcon />
          ) : (
            <ArrowBackIosNewRoundedIcon />
          )}
        </IconButton>
        {open && (
          <Typography sx={{ mr: '50px', fontWeight: 600, fontSize: '18px' }}>
            {t('drawer.collapse')}
          </Typography>
        )}
      </Stack>
    </SDrawer>
  );
};

export default Drawer;
