import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import newMessageSound from '../assets/audio/income1.mp3';
import {
  ITag,
  ITelegramBotDialogExtended,
  ITelegramMessage,
  Params,
  SendEditTelegramMessage,
  SendTelegramMessage,
  TelegramMessageDirection,
} from '../interfaces/common';
import {
  AdminClientEventNames,
  DialogsClientEventNames,
  MessagesClientEventNames,
} from '../store/context/sockets/constants/client-event.names';
import {
  AdminServerEventNames,
  DialogsServerEventNames,
  MessagesServerEventNames,
} from '../store/context/sockets/constants/server-event.names';
import { SocketContext } from '../store/context/sockets/socket.context';
import { BotContext } from '../telegram-bots/bot.context';
import {
  TelegramMessengerAccess,
  TelegramMessengerMode,
} from '../telegram-bots/telegram-bot.interface';
import { UserAccountContext } from '../telegram-user-accounts/user-account.context';
import getUniqueByIdArray from '../utils/getUniqueArray';

import { ITelegramUserDialogExtended } from './dialogs/interfaces/user-dialog.interface';
import DialogsFilter from './interfaces/dialogs-filter.interface';
import IMessengerContext from './interfaces/messenger-context.interface';
import { LanguageCodes } from './translator.control';

const defaultDialogsFilter: DialogsFilter = {
  search: '',
  tags: [],
  searchMode: 'all',
  status: [],
};

const notificationSound = new Audio(newMessageSound);

type IBotMessengerContext = IMessengerContext<ITelegramBotDialogExtended>;
type IUserAccountMessengerContext =
  IMessengerContext<ITelegramUserDialogExtended>;

export const MessengerContext = createContext<
  IBotMessengerContext | IUserAccountMessengerContext
>({} as IBotMessengerContext | IUserAccountMessengerContext);

export type TelegramDialog =
  | ITelegramUserDialogExtended
  | ITelegramBotDialogExtended;

const MessengerContextProvider: FC<{
  isBot?: boolean;
  children: ReactNode;
}> = ({ children, isBot = false }) => {
  const { access: botAccess, onUpdateMessengerMode: onUpdateBotMessengerMode } =
    useContext(BotContext);
  const { onUpdateMessengerMode: onUpdateUserBotMessengerMode } =
    useContext(UserAccountContext);
  const { socket } = useContext(SocketContext);

  const [dialogsFilter, setDialogsFilter] =
    useState<DialogsFilter>(defaultDialogsFilter);

  const [dialogs, setDialogs] = useState<TelegramDialog[]>([]);
  const [loadingDialogs, setLoadingDialogs] = useState<boolean>(false);
  const [hasMoreDialogs, setHasMoreDialogs] = useState<boolean>(true);

  const [lastDialogUpdatedAtCursor, setLastDialogUpdatedAtCursor] =
    useState('');
  const [isVolume, setIsVolume] = useState(
    JSON.parse(localStorage.getItem('isVolume') as string) ?? true,
  );

  useEffect(() => {
    localStorage.setItem('isVolume', JSON.stringify(isVolume));
  }, [isVolume]);

  const [messages, setMessages] = useState<{
    [key: string]: {
      messages: ITelegramMessage[];
      hasMore: boolean;
    };
  }>({});
  const [loadingMessages, setLoadingMessages] = useState<{
    [key: string]: boolean;
  }>({});

  const [tags, setTags] = useState<ITag[]>([]);

  const [langCode, setLangCode] = useState<LanguageCodes>(
    LanguageCodes.ukrainian,
  );

  const handleChangeLanguage = (newCode: LanguageCodes) => {
    setLangCode(newCode);
  };

  const updateDialogsFilter = (newFilter: Partial<DialogsFilter>) => {
    setDialogsFilter((prev) => ({
      ...prev,
      ...newFilter,
    }));
  };

  const displayedDialogs = useMemo(() => {
    const filteredSearch = dialogs.filter(
      ({ firstName, lastName }) =>
        firstName.toLowerCase().includes(dialogsFilter.search.toLowerCase()) ||
        lastName.toLowerCase().includes(dialogsFilter.search.toLowerCase()),
    );

    const filteredStatus = dialogsFilter.status.length
      ? filteredSearch.filter(({ status }) => {
          return dialogsFilter.status.includes(status);
        })
      : [...filteredSearch];

    const filteredTags = dialogsFilter.tags.length
      ? filteredSearch.filter(({ tags }) =>
          tags.some((tag) => dialogsFilter.tags.includes(tag._id)),
        )
      : [...filteredStatus];

    const result =
      dialogsFilter.searchMode === 'all'
        ? [...filteredTags]
        : dialogsFilter.searchMode === 'notSeen'
        ? filteredTags.filter((dialog) => dialog.notSeenMessagesCount > 0)
        : filteredTags.filter((dialog) => dialog.blockedByUser);

    if (result.length) {
      setLastDialogUpdatedAtCursor(
        result[result.length - 1].updatedAt.toString(),
      );
    }

    return result;
  }, [dialogs, dialogsFilter]);

  const getDialogs = () => {
    if (loadingDialogs && !socket) return;

    setLoadingDialogs(true);
    socket.emit(DialogsClientEventNames.getDialogs, {
      filter: dialogsFilter,
      pagination: {
        limit: 40,
      },
      lastDialogUpdatedAtCursor,
    });
  };

  const getTags = () => {
    socket.emit(DialogsClientEventNames.getTags);
  };

  const createTag = (dialogId: string, text: string) => {
    socket.emit(DialogsClientEventNames.createTag, dialogId, text);
  };

  const assignTags = (dialogId: string, tagIds: string[]) => {
    socket.emit(DialogsClientEventNames.assignTags, dialogId, tagIds);
  };

  const updateTag = (_id: string, text: string, preloadDialogIds: string[]) => {
    socket.emit(DialogsClientEventNames.updateTag, _id, text, preloadDialogIds);
  };

  const deleteTag = (_id: string, preloadDialogIds: string[]) => {
    socket.emit(DialogsClientEventNames.deleteTag, _id, preloadDialogIds);
  };

  const setDialogDescription = (dialogId: string, description: string) => {
    socket.emit(DialogsClientEventNames.setDescription, dialogId, description);
  };

  const updateDialogParams = (dialogId: string, params: Params) => {
    socket.emit(DialogsClientEventNames.updateParams, dialogId, params);
  };

  const deleteDialogParam = (
    dialogId: string,
    telegramId: number,
    project: string,
    paramKey: string,
  ) => {
    socket.emit(
      DialogsClientEventNames.deleteParam,
      dialogId,
      telegramId,
      project,
      paramKey,
    );
  };

  const readDialog = (dialogId: string) => {
    socket.emit(DialogsClientEventNames.readDialog, dialogId);
  };

  const getMessages = (dialogId: string) => {
    if (loadingMessages[dialogId]) return;
    if (!messages[dialogId].hasMore) return;

    setLoadingMessages((prev) => ({
      ...prev,
      [dialogId]: true,
    }));

    socket.emit(MessagesClientEventNames.getMessages, dialogId, {
      limit: 20,
      skip: messages[dialogId]?.messages.length ?? 0,
    });
  };

  const sendMessage = (message: SendTelegramMessage) => {
    socket.emit(MessagesClientEventNames.sendMessage, message);
  };

  const updateDialogNames = (
    dialogId: string,
    names: { firstName: string; lastName: string },
  ) => {
    socket.emit(DialogsClientEventNames.setDialogNames, dialogId, names);
  };

  const acceptJoinRequest = (
    dialogId: string,
    channelMemberId: string,
    isAccept: boolean,
  ) => {
    socket.emit(
      DialogsClientEventNames.acceptJoinRequest,
      dialogId,
      channelMemberId,
      isAccept,
    );
  };

  const translateMessage = (dialogId: string, messageId: string) => {
    socket.emit(
      MessagesClientEventNames.translateMessage,
      dialogId,
      messageId,
      langCode,
    );
  };

  const onReceiveTags = (tags: ITag[]) => {
    setTags(tags);
  };

  const onReceiveDialogs = (
    newDialogs: ITelegramBotDialogExtended[] | ITelegramUserDialogExtended[],
  ) => {
    setDialogs((prev) => {
      return getUniqueByIdArray([...prev, ...newDialogs]).sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );
    });
    setHasMoreDialogs(Boolean(newDialogs.length));
    setLoadingDialogs(false);
  };

  const onReceiveMessages = (
    messages: ITelegramMessage[],
    dialogId: string,
  ) => {
    setLoadingMessages((prev) => ({
      ...prev,
      [dialogId]: false,
    }));
    setMessages((prev) => ({
      ...prev,
      [dialogId]: {
        messages: getUniqueByIdArray(
          [
            ...(prev[dialogId]?.messages ? prev[dialogId].messages : []),
            ...messages,
          ].sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ),
        ),
        hasMore: Boolean(messages.length),
      },
    }));
  };

  const onReceiveNewIncomeMessage = (
    dialog: ITelegramBotDialogExtended,
    message: ITelegramMessage,
  ) => {
    if (isBot) {
      if (
        (message.direction === TelegramMessageDirection.income &&
          JSON.parse(localStorage.getItem('isVolume') as string)) ??
        false
      ) {
        notificationSound.play();
      }
    }
    onReceiveDialogs([dialog]);
    onReceiveMessages([message], dialog._id);
  };

  const setMessengerMode = (newMode: TelegramMessengerMode) => {
    socket.emit(AdminClientEventNames.setMessengerMode, newMode);
  };

  const toggleBlockStatus = (dialogId: string, leadBlockedStatus: boolean) => {
    socket.emit(
      DialogsClientEventNames.updateBlockStatus,
      dialogId,
      leadBlockedStatus,
    );
  };

  const editMessage = (message: SendEditTelegramMessage) => {
    socket.emit(MessagesClientEventNames.editMessage, message);
  };

  const deleteMessage = (_id: string) => {
    socket.emit(MessagesClientEventNames.deleteMessage, _id);
  };

  useEffect(() => {
    for (const dialog of dialogs) {
      if (!messages[dialog._id]) {
        setMessages((prev) => ({
          ...prev,
          [dialog._id]: {
            messages: [],
            hasMore: true,
          },
        }));
      }
    }
  }, [dialogs]);

  useEffect(() => {
    socket.on(DialogsServerEventNames.pushDialogs, onReceiveDialogs);
    socket.on(
      MessagesServerEventNames.pushNewMessage,
      onReceiveNewIncomeMessage,
    );
    socket.on(MessagesServerEventNames.pushMessages, onReceiveMessages);
    socket.on(
      AdminServerEventNames.pushMessengerModeUpdate,
      isBot ? onUpdateBotMessengerMode : onUpdateUserBotMessengerMode,
    );
    socket.on(DialogsServerEventNames.pushTags, onReceiveTags);
  }, [socket]);

  useEffect(() => {
    getDialogs();
    getTags();
  }, [socket]);

  useEffect(() => {
    setHasMoreDialogs(true);
  }, [dialogsFilter]);

  if (isBot && botAccess.messenger === TelegramMessengerAccess.none)
    return null;

  const commonValue = {
    getTags,
    createTag,
    assignTags,
    updateTag,
    deleteTag,
    setDialogDescription,
    readDialog,
    sendMessage,
    setMessengerMode,
    getMessages,
    dialogsFilter,
    updateDialogsFilter,
    hasMoreDialogs,
    loadingDialogs,
    messages,
    loadingMessages,
    tags,
    updateDialogNames,
    updateDialogParams,
    deleteDialogParam,
    toggleBlockStatus,
    langCode,
    handleChangeLanguage,
    translateMessage,
    acceptJoinRequest,
    deleteMessage,
    editMessage,
    getDialogs,
    dialogs: displayedDialogs,
    setIsVolume,
    isVolume,
  };

  const botValue: IBotMessengerContext = {
    ...commonValue,
    messengerType: 'bot',
    dialogs: displayedDialogs as ITelegramBotDialogExtended[],
  };

  const userAccountValue: IUserAccountMessengerContext = {
    ...commonValue,
    messengerType: 'user_account',
    dialogs: displayedDialogs as ITelegramUserDialogExtended[],
  };

  if (isBot) {
    return (
      <MessengerContext.Provider value={botValue}>
        {children}
      </MessengerContext.Provider>
    );
  } else {
    return (
      <MessengerContext.Provider value={userAccountValue}>
        {children}
      </MessengerContext.Provider>
    );
  }
};

export default MessengerContextProvider;
