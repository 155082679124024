import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TELEGRAM_MESSAGE_MAX_LENGTH } from '../../common/constants/constants';
import Input from '../../common/inputs/InputWithIcon';
import { IOSSwitch } from '../../common/iosSwitch';
import ModalBox from '../../common/modal/modal-box';
import { IMedia, TelegramButton } from '../../interfaces/common';
import { getBotIdAndType } from '../../utils/getBotIdAndType';
import AddMediaButton from '../dialogs/add-media.button';
import AttachedMediaPreview from '../dialogs/attached-media.preview';
import ButtonsEditor from '../dialogs/buttons.editor';

import { TemplateMessageType } from './interfaces';

interface TemplateModalProps {
  open: boolean;
  onClose: () => void;
  isEdit: boolean;
  name: string;
  type: TemplateMessageType;
  text: string;
  handleChangeName: (name: ChangeEvent<HTMLInputElement>) => void;
  handleChangeType: (type: TemplateMessageType) => void;
  handleChangeText: (text: ChangeEvent<HTMLInputElement>) => void;
  openModalByType: () => void;
  addNewButtonsRowDisabled: boolean;
  onRemoveMedia: (_id: string) => void;
  privateTemplate: boolean;
  handleChangePrivate: () => void;
  media: IMedia[];
  buttons: TelegramButton[][];
  onAddRow: () => void;
  onRemoveRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, buttonIndex: number) => void;
  onAddButton: (buttonIndex: number) => void;
  onButtonChange: (
    rowIndex: number,
    buttonIndex: number,
    key: string,
    value: string,
  ) => void;
  isValid: boolean;
  onSubmit: () => void;
}

const TemplateModal: FC<TemplateModalProps> = ({
  open,
  onClose,
  isEdit,
  name,
  type,
  text,
  media,
  privateTemplate,
  handleChangePrivate,
  openModalByType,
  addNewButtonsRowDisabled,
  onRemoveMedia,
  handleChangeName,
  handleChangeType,
  handleChangeText,
  buttons,
  onAddRow,
  onRemoveRow,
  onRemoveButton,
  onAddButton,
  onButtonChange,
  isValid,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const botType = getBotIdAndType().botType;
  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="820px"
      sx={{
        p: '32px 24px 24px',
        position: 'relative',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          width: '30px',
          height: '30px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
        sx={{
          mb: '24px',
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
          {isEdit
            ? t('messenger.templates.modal.editingTemplate')
            : t('messenger.templates.modal.creatingTemplate')}
        </Typography>
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={privateTemplate}
              onChange={handleChangePrivate}
            />
          }
          sx={{
            '& .MuiTypography-root': {
              fontWeight: 600,
              fontSize: '18px',
            },
          }}
          label={t('messenger.templates.modal.privateTemplate')}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
      >
        <Input
          value={name}
          handleChangeSearch={handleChangeName}
          placeholder={t('messenger.templates.modal.namePlaceholder')}
          backgroundNarrative="dark"
          withIcon={false}
          fullWidth={true}
        />

        <FormControl fullWidth>
          <Select
            sx={{
              height: '100%',
              backgroundColor: 'grey.15',
            }}
            value={type}
            onChange={(e) =>
              handleChangeType(e.target.value as TemplateMessageType)
            }
          >
            <MenuItem value={TemplateMessageType.media}>
              {t('messenger.templates.modal.type.media')}
            </MenuItem>
            <MenuItem value={TemplateMessageType.videoNote}>
              {t('messenger.templates.modal.type.video')}
            </MenuItem>
            <MenuItem value={TemplateMessageType.voice}>
              {t('messenger.templates.modal.type.voice')}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
        sx={{
          mt: '20px',
        }}
      >
        <Stack
          flexDirection="row"
          gap="4px"
          sx={{
            border: '1px solid',
            borderColor: 'grey.10',
            borderRadius: '8px',
            backgroundColor: 'grey.15',
            padding: '6px ',
            width: '100%',
            height: '100%',
            maxWidth: '49%',
          }}
        >
          <AddMediaButton
            openMediaModal={openModalByType}
            openVideoNoteModal={openModalByType}
            openVoiceModal={openModalByType}
            messageType={type}
          />
          <AttachedMediaPreview
            media={media}
            onRemoveMedia={onRemoveMedia}
            size="small"
            maxWidth="100%"
          />
        </Stack>
        {type !== TemplateMessageType.videoNote && (
          <Stack
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <TextField
              sx={{
                backgroundColor: 'grey.15',
                width: '100%',
                height: '100%',
                border: '1px solid',
                borderRadius: '8px',
                borderColor: 'grey.10',
                overflow: 'hidden',
                p: '8px 12px',
                position: 'relative',
                '& .MuiInputBase-root': {
                  width: '100%',
                  height: '100%',
                  p: '0',
                },
                '& textarea': {
                  height: '100%!important',
                },
                '& fieldset': {
                  border: 'none',
                },
              }}
              multiline
              rows={3}
              placeholder={t('messenger.templates.modal.textPlaceholder')}
              value={text}
              onChange={handleChangeText}
            />
            <Stack
              sx={{
                position: 'absolute',
                bottom: '-16px',
                right: '0',
                opacity: '0.4',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '10px',
                }}
              >
                {TELEGRAM_MESSAGE_MAX_LENGTH}/{text.length}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      {botType === 'BOT' && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
          }}
        >
          <ButtonsEditor
            buttons={buttons}
            addNewButtonsRowDisabled={addNewButtonsRowDisabled}
            onAddButton={onAddButton}
            onButtonChange={onButtonChange}
            onRemoveButton={onRemoveButton}
            onAddRow={onAddRow}
            onRemoveRow={onRemoveRow}
          />
        </Stack>
      )}

      <Stack
        flexDirection="row"
        alignItems="center"
        gap="6px"
        sx={{
          mt: '10px',
        }}
      >
        <Button onClick={onClose} fullWidth className="red">
          {t('common.cancel')}
        </Button>
        <Button
          className="brightGreen"
          onClick={onSubmit}
          disabled={!isValid}
          fullWidth
        >
          {t('common.save')}
        </Button>
      </Stack>
    </ModalBox>
  );
};

export default TemplateModal;
