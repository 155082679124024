import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, ReactNode, useContext } from 'react';

import { APP_CONFIG } from '../../config';
import { IMedia } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import ModalBox from '../modal/modal-box';

interface MediaModalProps {
  open: boolean;
  selectedMedia: IMedia[];
  onSelectMedia: (newMedia: IMedia) => void;
  onClose: () => void;
  onlyOwning: boolean;
  toggleOnlyOwning: () => void;
  children: ReactNode;
}

const MediaUploadModal: FC<MediaModalProps> = ({
  open,
  onClose,
  onlyOwning,
  toggleOnlyOwning,
  children,
}) => {
  const { refetchMedia, project } = useContext(ProjectContext);

  const onUploadClick = () => {
    window.open(
      `https://t.me/${APP_CONFIG.TELEGRAM_AUTH_BOT_USERNAME}?start=up-${project._id}`,
      '_blank',
    );
  };

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="746px"
      sx={{
        height: '540px',
        p: '0',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'grey.10',
        borderRadius: '12px',
        width: '352px',
      }}
    >
      <Stack
        direction="row"
        sx={{
          height: '100%',
          overflow: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Stack
          alignSelf="start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              cursor: 'pointer',
              zIndex: 1,
              backgroundColor: 'grey.14',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={toggleOnlyOwning}
            >
              <Checkbox
                sx={{
                  color: 'green.2',
                  '&.Mui-checked': {
                    color: 'green.2',
                  },
                }}
                checked={onlyOwning}
              />
              <Typography
                sx={{
                  opacity: '0.6',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {t('flowNodes.onlyMyFiles')}
              </Typography>
            </Stack>
          </Stack>
          {children}
          <Stack
            gap="12px"
            alignItems="center"
            sx={{
              mt: 'auto',
              borderRadius: '24px 24px 12px 12px',
              p: '16px',
              backgroundColor: 'grey.13',
            }}
          >
            <Button
              className="brightGreen"
              sx={{
                color: 'grey.10',
                width: '318px',
                height: '56px',
              }}
              onClick={onUploadClick}
            >
              + {t('flowNodes.uploadViaTg')}
            </Button>
            <Button
              sx={{
                color: 'green.2',
                backgroundColor: 'transparent',
                width: '100%',
                '&:hover': { backgroundColor: 'transparent' },
              }}
              startIcon={
                <RefreshIcon
                  sx={{
                    fontSize: '18px',
                  }}
                />
              }
              onClick={refetchMedia}
            >
              {t('flowNodes.updateData')}
            </Button>

            <Button
              className="green"
              sx={{
                width: '318px',
                height: '56px',
              }}
              onClick={onClose}
            >
              {t('common.done')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default MediaUploadModal;
