import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, Button, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../AppContext';

import { UserContext } from './user.context';

interface UserCardProps {
  avatarUrl: string;
  editUser: () => void;
  disabledBtn: boolean;
  firstName: string;
  lastName: string;
  clientLang: string;
  onChangeClientLang: (newLang: string) => void;
  handleChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeLastName: (e: ChangeEvent<HTMLInputElement>) => void;
}

const UserCard: FC<UserCardProps> = ({
  avatarUrl,
  editUser,
  disabledBtn,
  firstName,
  lastName,
  handleChangeName,
  handleChangeLastName,
  clientLang,
  onChangeClientLang,
}) => {
  const { t } = useTranslation();

  const { logout } = useContext(UserContext);
  const { openConfirmationDialog } = useContext(AppContext);
  return (
    <Stack>
      <Stack
        sx={{
          width: '328px',
          backgroundColor: 'grey.14',
          borderRadius: '24px',
          border: '1px solid',
          borderColor: 'grey.10',
          gap: '24px',
          maxHeight: '70vh',
          padding: '22px 20px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '140%',
            color: 'grey.1',
          }}
        >
          {t('user.account')}
        </Typography>
        <Stack alignItems="center">
          <Avatar
            sx={{
              backgroundColor: 'grey.10',
              color: 'grey.1',
              width: '96px',
              height: '96px',
            }}
            src={avatarUrl}
          />
        </Stack>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap="20px"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '150%',
              }}
            >
              {t('user.language')}:
            </Typography>
            <Stack gap="16px" direction="row">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '56px',
                  height: '56px',
                  padding: '10px',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  backgroundColor:
                    clientLang === 'uk' ? 'blue.2' : 'transparent',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onChangeClientLang('uk');
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '18px',
                    lineHeight: '150%',
                  }}
                >
                  UA
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '56px',
                  height: '56px',
                  padding: '10px',
                  border: '1px solid',
                  borderColor: 'grey.10',
                  backgroundColor:
                    clientLang === 'en' ? 'blue.2' : 'transparent',
                  borderRadius: '6px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onChangeClientLang('en');
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '18px',
                    lineHeight: '150%',
                  }}
                >
                  EN
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <TextField
            sx={{
              mt: '20px',
              '& fieldset': {
                borderRadius: '6px',
              },
            }}
            value={firstName}
            onChange={handleChangeName}
            placeholder={'name'}
          />
          <TextField
            sx={{
              mt: '12px',
              '& fieldset': {
                borderRadius: '6px',
              },
            }}
            value={lastName}
            onChange={handleChangeLastName}
            placeholder={'last name'}
          />
        </Stack>
        <Button
          onClick={editUser}
          variant="contained"
          sx={{
            borderRadius: '8px',
            backgroundColor: 'green.2',
            color: 'grey.15',
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '130%',
            height: '56px',
            textTransform: 'none',
            minHeight: '56px',
            '&:hover': { backgroundColor: 'green.2' },
          }}
          disabled={disabledBtn}
        >
          {t('user.saveChanges')}
        </Button>
      </Stack>
      <Button
        sx={{
          mt: '16px',
          boxShadow: ' 0 4px 10px 0 rgba(255, 31, 110, 0.15)',
          backgroundColor: ' rgba(255, 31, 110, 0.4)',
          color: 'grey.1',
          border: '1px solid ',
          borderColor: 'red.2',
          borderRadius: '8px',
          padding: '16px 32px',
          width: '328px',
          height: '56px',
          justifyContent: 'space-around',
          ':hover': { backgroundColor: 'red.2' },
        }}
        onClick={() =>
          openConfirmationDialog(
            t('common.logoutConfirmation'),
            t('common.logout'),
            () => {
              logout();
            },
          )
        }
      >
        <LogoutIcon />
        {t('common.logoutAccount')}
      </Button>
    </Stack>
  );
};

export default UserCard;
