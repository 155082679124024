import { alpha, Divider, Stack, Typography } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import GroupButtons from '../../../common/flow-nodes/control-buttons-node';
import { darkThemeColors } from '../../../common/theme/dark.theme';
import { ChatActionTypes, IChatActionFlowNodeData } from '../interfaces';

import pulsingAnimation from './pulsing-animation';

export interface IChatActionNodeData {
  onDelete: () => void;
  onSelect: (chatActionData: IChatActionFlowNodeData) => void;
  onCopy: (
    chatActionData: IChatActionFlowNodeData,
    position: { x: number; y: number },
  ) => void;
  exitEditor: () => void;
  data: IChatActionFlowNodeData;
  position: { x: number; y: number };
  next: string | null;
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
  _id: string;
}

interface ChatActionNodeProps extends Omit<NodeProps, 'data'> {
  data: IChatActionNodeData;
}

const ChatActionNode: FC<ChatActionNodeProps> = memo(
  ({ data, xPos, yPos, selected }) => {
    useEffect(() => {
      data.onNodePositionChange(data._id, {
        x: xPos,
        y: yPos,
      });
    }, [xPos, yPos]);

    const { t } = useTranslation();

    const nodeRef = useRef<HTMLDivElement>(null);

    const [isNewNode, setIsNewNode] = useState(false);

    useEffect(() => {
      const digitsId = Number(data._id);

      setIsNewNode(!isNaN(digitsId));
    }, []);

    useEffect(() => {
      const node = nodeRef.current;
      const preventZoom = (event: WheelEvent) => event.stopPropagation();

      if (node) {
        node.addEventListener('wheel', preventZoom);
        return () => {
          node.removeEventListener('wheel', preventZoom);
        };
      }
    }, []);

    useEffect(() => {
      if (!selected) {
        data.exitEditor();
      }
    }, [selected]);

    const edit = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      data.onSelect({
        actionType: data.data.actionType,
        duration: data.data.duration,
      });
    };

    const copy = (e: React.SyntheticEvent) => {
      e.stopPropagation();

      data.onCopy(
        {
          actionType: data.data.actionType,
          duration: data.data.duration,
        },
        { x: data.position.x + 400, y: data.position.y },
      );
    };

    return (
      <Stack
        sx={{
          position: 'relative',
        }}
        onDoubleClick={edit}
      >
        {selected && (
          <GroupButtons onEdit={edit} onCopy={copy} onDelete={data.onDelete} />
        )}

        <Stack
          className="dragger"
          sx={{
            width: '340px',
            border: '1px solid',
            borderColor: selected ? 'blue.2' : 'grey.10',
            backgroundColor: alpha(darkThemeColors.grey['14'], 0.8),
            padding: '12px',
            borderRadius: '18px',
            cursor: 'default',
            animation: isNewNode
              ? `${pulsingAnimation} 0.5s linear infinite  alternate`
              : '',
            animationIterationCount: 6,
          }}
          ref={nodeRef}
        >
          <Handle
            type="target"
            position={Position.Left}
            id={'edge-' + data._id}
            style={{
              background: 'transparent',
              borderColor: 'transparent',
              height: '100%',
              width: '100%',
              borderRadius: '0',
              zIndex: -1,
            }}
          />
          <Handle
            type="source"
            id={'src-' + data._id}
            position={Position.Right}
            style={{
              width: '12px',
              height: '12px',
              marginRight: '-2px',
              border: '3px solid',
              borderColor: darkThemeColors.green['2'],
            }}
          />

          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 600,
                  opacity: '0.8',
                  textAlign: 'center',
                }}
              >
                {t('flowNodes.chatAction')}
              </Typography>
              <Divider sx={{ m: '8px 0' }} />
            </Stack>

            <Stack sx={{ width: '100%' }}>
              <Typography>
                {t('flowNodes.actionType')} :{' '}
                {t(
                  `flowNodes.actionTypes.${
                    data.data.actionType as ChatActionTypes
                  }`,
                )}
              </Typography>
              <Typography>
                {t('flowNodes.duration')} : {data.data.duration}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            <Stack gap="6px" flexDirection="column"></Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

ChatActionNode.displayName = 'ChatActionNode';

export default ChatActionNode;
