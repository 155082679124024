import React from 'react';

function IconRobot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1124_8735)">
        <path
          opacity="0.6"
          d="M29.625 4.125C29.5255 4.125 29.4302 4.16451 29.3598 4.23483C29.2895 4.30516 29.25 4.40054 29.25 4.5V12.25C29.25 12.3495 29.2895 12.4448 29.3598 12.5152C29.4302 12.5855 29.5255 12.625 29.625 12.625C29.7245 12.625 29.8198 12.5855 29.8902 12.5152C29.9605 12.4448 30 12.3495 30 12.25V4.5C30 4.40054 29.9605 4.30516 29.8902 4.23483C29.8198 4.16451 29.7245 4.125 29.625 4.125ZM2.375 4.125C2.27554 4.125 2.18016 4.16451 2.10983 4.23483C2.03951 4.30516 2 4.40054 2 4.5V12.25C2 12.3495 2.03951 12.4448 2.10983 12.5152C2.18016 12.5855 2.27554 12.625 2.375 12.625C2.47446 12.625 2.56984 12.5855 2.64017 12.5152C2.71049 12.4448 2.75 12.3495 2.75 12.25V4.5C2.75 4.40054 2.71049 4.30516 2.64017 4.23483C2.56984 4.16451 2.47446 4.125 2.375 4.125Z"
          fill="#FEFEFE"
        />
        <path
          opacity="0.4"
          d="M29.625 4.375C30.7296 4.375 31.625 3.47957 31.625 2.375C31.625 1.27043 30.7296 0.375 29.625 0.375C28.5204 0.375 27.625 1.27043 27.625 2.375C27.625 3.47957 28.5204 4.375 29.625 4.375Z"
          fill="#FEFEFE"
        />
        <path
          opacity="0.4"
          d="M2.375 4.375C3.47957 4.375 4.375 3.47957 4.375 2.375C4.375 1.27043 3.47957 0.375 2.375 0.375C1.27043 0.375 0.375 1.27043 0.375 2.375C0.375 3.47957 1.27043 4.375 2.375 4.375Z"
          fill="#FEFEFE"
        />
        <path
          opacity="0.9"
          d="M2.90473 16.241C2.90958 14.4257 3.40171 12.6449 4.32973 11.0847C3.32315 11.1414 2.36806 11.5482 1.62973 12.2347C0.579735 13.28 0.534735 14.6465 0.468235 16.626C0.399735 18.655 0.354985 19.9917 1.31973 21.0422C2.25923 22.1202 3.55198 22.1867 4.78648 22.1172C3.5695 20.3988 2.91236 18.3467 2.90473 16.241ZM31.533 16.625C31.4665 14.6455 31.4205 13.279 30.3715 12.2337C29.6332 11.5472 28.6781 11.1404 27.6715 11.0837C28.676 12.7678 29.1677 14.7082 29.0866 16.6674C29.0055 18.6266 28.355 20.5197 27.2147 22.115C28.4517 22.1852 29.7262 22.1075 30.6815 21.04C31.6332 19.9795 31.6015 18.6547 31.533 16.625Z"
          fill="#FEFEFE"
        />
        <path
          opacity="0.6"
          d="M29.0948 16.241C29.0948 9.95872 23.2318 4.86597 15.9995 4.86597C8.7673 4.86597 2.9043 9.95872 2.9043 16.241C2.9043 21.219 6.58705 25.4472 11.7138 26.9895C11.3538 27.7145 10.7798 28.376 10.0183 29.2722C9.8291 29.4951 9.71589 29.7726 9.6951 30.0642C9.67431 30.3558 9.74702 30.6465 9.90268 30.894C10.0583 31.1415 10.2889 31.3329 10.5607 31.4405C10.8326 31.5481 11.1317 31.5662 11.4145 31.4922C13.9999 30.809 16.3402 29.4107 18.167 27.458C24.3673 26.5605 29.0948 21.8815 29.0948 16.241Z"
          fill="#FEFEFE"
        />
        <path
          d="M16.0004 6.74097C9.53243 6.74097 4.92993 12.3577 5.61068 18.7297C5.7313 19.8959 6.27895 20.9763 7.14824 21.7629C8.01752 22.5496 9.14703 22.987 10.3194 22.991C13.1599 22.983 13.1599 21.4057 16.0004 21.4057C18.8409 21.4057 18.8409 22.983 21.6814 22.991C22.8538 22.987 23.9833 22.5496 24.8526 21.7629C25.7219 20.9763 26.2695 19.8959 26.3902 18.7297C27.0709 12.3577 22.4684 6.74097 16.0004 6.74097Z"
          fill="#424242"
        />
        <path
          d="M9.92847 16.7854C10.6188 16.7854 11.1785 16.2258 11.1785 15.5354C11.1785 14.845 10.6188 14.2854 9.92847 14.2854C9.23811 14.2854 8.67847 14.845 8.67847 15.5354C8.67847 16.2258 9.23811 16.7854 9.92847 16.7854Z"
          fill="white"
        />
        <path
          d="M16 16.7854C16.6904 16.7854 17.25 16.2258 17.25 15.5354C17.25 14.845 16.6904 14.2854 16 14.2854C15.3096 14.2854 14.75 14.845 14.75 15.5354C14.75 16.2258 15.3096 16.7854 16 16.7854Z"
          fill="white"
        />
        <path
          d="M22.071 16.7854C22.7614 16.7854 23.321 16.2258 23.321 15.5354C23.321 14.845 22.7614 14.2854 22.071 14.2854C21.3807 14.2854 20.821 14.845 20.821 15.5354C20.821 16.2258 21.3807 16.7854 22.071 16.7854Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1124_8735">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconRobot;
