import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import InputChip from '../../common/input-chip/input-chip';
import Input from '../../common/inputs/Input';
import ModalBox from '../../common/modal/modal-box';
import { TelegramLeadStatus } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { BotContext } from '../bot.context';
import { IFlow, IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import { BroadcastParamCondition, BroadcastType } from './interfaces';

interface BroadcastModalProps {
  open: boolean;
  onClose: () => void;
  flows: IFlowWithActivatorsAndFolder[];
  actionBroadcast: string;
  name: string;
  type?: BroadcastType;
  _id?: string;
  flow: IFlow['_id'];
  leadStatuses: string[];
  paramConditions: BroadcastParamCondition[];
  startTime: Date | null;
  onChangeName: (name: ChangeEvent<HTMLInputElement>) => void;
  onChangeFlow: (flow: SelectChangeEvent) => void;
  onChangeParamKey: (index: number, key: string) => void;
  onChangeParamValues: (
    index: number,
    newValue: string[],
    include: boolean,
  ) => void;
  onDeleteCondition: (index: number) => void;
  toggleStatus: (TelegramLeadStatus: string) => void;
  onAddCondition: () => void;
  onChangeStartTime: (date: dayjs.Dayjs | null) => void;
  isValid: boolean;
  onSubmit: (type: BroadcastType) => void;
  receiversNumber: number;
  calculationLoading: boolean;
}

const BroadcastModal: FC<BroadcastModalProps> = ({
  open,
  onClose,
  flows,
  _id,
  actionBroadcast,
  name,
  type,
  flow,
  leadStatuses,
  paramConditions,
  startTime,
  onChangeName,
  onChangeFlow,
  onChangeParamKey,
  onChangeParamValues,
  onDeleteCondition,
  toggleStatus,
  onAddCondition,
  onChangeStartTime,
  isValid,
  onSubmit,
  receiversNumber,
  calculationLoading,
}) => {
  const { bot } = useContext(BotContext);
  const { projectParams } = useContext(ProjectContext);
  const navigate = useNavigate();
  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="50%"
      sx={{ backgroundColor: 'grey.14', position: 'relative' }}
    >
      <Stack
        sx={{
          position: 'absolute',
          cursor: 'pointer',
          top: '12px',
          right: '12px',
        }}
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </Stack>
      <Stack>
        <Typography
          sx={{
            color: 'grey.1',
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '130%',
          }}
        >
          {actionBroadcast === 'new'
            ? t('broadcast.modal.creatingBroadcast')
            : actionBroadcast === 'clone'
            ? `${t('broadcast.cloneBroadcast')}`
            : t('broadcast.modal.broadcastSettings')}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          sx={{
            mt: '29px',
          }}
        >
          <Stack
            sx={{
              width: '50%',
            }}
          >
            <Input
              value={name}
              handleChangeSearch={onChangeName}
              placeholder={t('common.title')}
            />
          </Stack>
          <Box sx={{ width: '50%' }}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                }}
              >
                {t('common.flow')}
              </InputLabel>
              <Select
                value={flow}
                label={t('common.flow')}
                onChange={onChangeFlow}
                sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                  backgroundColor: 'grey.15',
                  height: '56px',
                  borderRadius: '6px',
                  '.MuiSelect-select': {
                    py: '14px',
                  },
                }}
              >
                {Boolean(flows.length) ? (
                  flows.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <Stack sx={{ p: '10px', textAlign: 'center' }}>
                    <Typography
                      sx={{ mt: '10px', fontWeight: 500, fontSize: '16px' }}
                    >
                      {t('common.notFoundFlow')}
                    </Typography>
                    <Button
                      onClick={() =>
                        navigate(
                          `/project/${bot?.project}/bot/${bot?._id}/flows`,
                        )
                      }
                      sx={{ mt: '15px' }}
                    >
                      {t('common.createFlow')}
                    </Button>
                  </Stack>
                )}
              </Select>
            </FormControl>
          </Box>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: '12px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            {t('common.status')}:
          </Typography>

          <Stack sx={{ width: '80%', ml: '16px' }} gap="8px" direction="row">
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: '20px',
                p: '10px 24px',
                height: '40px',
                cursor: 'pointer',
                fontWeight: 600,
                fontSize: '14px',
                border: '2px solid',
                borderColor: 'green.2',
                color: leadStatuses.includes(TelegramLeadStatus.lead)
                  ? 'grey.1'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.lead)
                  ? 'green.5'
                  : 'green.3',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.lead);
              }}
            >
              Lead
            </Stack>

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: '20px',
                p: '10px 24px',
                height: '40px',
                cursor: 'pointer',
                fontWeight: 600,
                fontSize: '14px',
                border: '2px solid',
                borderColor: 'green.2',
                color: leadStatuses.includes(TelegramLeadStatus.reg)
                  ? 'grey.1'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.reg)
                  ? 'green.5'
                  : 'green.3',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.reg);
              }}
            >
              Reg
            </Stack>

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: '20px',
                p: '10px 24px',
                height: '40px',
                cursor: 'pointer',
                fontWeight: 600,
                fontSize: '14px',
                border: '2px solid',
                borderColor: 'green.2',
                color: leadStatuses.includes(TelegramLeadStatus.dep)
                  ? 'grey.1'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.dep)
                  ? 'green.5'
                  : 'green.3',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.dep);
              }}
            >
              Dep
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: '20px',
                p: '10px 24px',
                height: '40px',
                cursor: 'pointer',
                fontWeight: 600,
                fontSize: '14px',
                border: '2px solid',
                borderColor: 'green.2',
                color: leadStatuses.includes(TelegramLeadStatus.qual)
                  ? 'grey.1'
                  : 'grey.10',
                backgroundColor: leadStatuses.includes(TelegramLeadStatus.qual)
                  ? 'green.5'
                  : 'green.3',
              }}
              onClick={() => {
                toggleStatus(TelegramLeadStatus.qual);
              }}
            >
              Qual
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={{
            mt: '10px',
            pt: '6px',
            maxHeight: '250px',
            overflow: 'auto',
          }}
          gap="16px"
        >
          {paramConditions?.map(({ key, values, include }, index) => (
            <Stack
              key={key + index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={{ width: '160px' }}>
                <FormControl fullWidth>
                  <InputLabel sx={{ fontWeight: 500, fontSize: '18px' }}>
                    {t('common.key')}
                  </InputLabel>
                  <Select
                    value={key}
                    label={t('common.key')}
                    onChange={(e) => {
                      const value = e.target.value;
                      onChangeParamKey(index, value);
                    }}
                    sx={{
                      fontWeight: 500,
                      fontSize: '18px',
                      height: '56px',
                      '.MuiSelect-select': {
                        backgroundColor: 'grey.15',
                        py: '14px',
                      },
                    }}
                  >
                    {Boolean(projectParams.length) ? (
                      projectParams.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    ) : (
                      <Stack sx={{ p: '10px', textAlign: 'center' }}>
                        <Typography
                          sx={{ mt: '10px', fontWeight: 500, fontSize: '16px' }}
                        >
                          {t('common.notFoundVariable')}
                        </Typography>
                        <Button
                          onClick={() =>
                            navigate(
                              `/project/${bot?.project}/bot/${bot?._id}/variables`,
                            )
                          }
                          sx={{ mt: '15px' }}
                        >
                          {t('common.createVariable')}
                        </Button>
                      </Stack>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  cursor: 'pointer',
                  px: '2px',
                  border: '1px solid',
                  borderColor: include ? 'green.2' : 'red.2',
                  borderRadius: '28px',
                  m: '0 8px',
                  width: '56px',
                  minWidth: '56px',
                  height: '56px',
                  boxShadow: include
                    ? '0 4px 10px 0 rgba(202, 250, 68, 0.15)'
                    : '0 4px 10px 0 rgba(255, 31, 110, 0.15)',
                  backgroundColor: include
                    ? 'green.4'
                    : 'rgba(255, 31, 110, 0.4)',
                }}
                onClick={() => {
                  onChangeParamValues(index, values, !include);
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '18px',
                  }}
                >
                  {include ? 'in' : 'not'}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  width: '80%',
                }}
              >
                <InputChip
                  chips={values}
                  handleChipsChange={(newValue) => {
                    onChangeParamValues(index, newValue, include);
                  }}
                  onDeleteIconClick={() => {
                    onDeleteCondition(index);
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Button
          fullWidth
          sx={{
            color: 'green.2',
            height: '36px',
            backgroundColor: 'grey.13',
            borderRadius: '4px',
            mt: '20px',
            fontWeight: 600,
            fontSize: '14px',
            '&:hover': {
              backgroundColor: 'grey.13',
            },
          }}
          onClick={onAddCondition}
        >
          {t('broadcast.modal.condition')}
          <AddRoundedIcon
            sx={{
              mr: '5px',
              fontWeight: 600,
              fontSize: '16px',
              color: 'green.2',
            }}
          />
        </Button>

        <Stack
          sx={{
            mt: '24px',
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            {t('broadcast.modal.startDate')}:
          </Typography>
          <Stack
            sx={{
              width: '80%',
            }}
          >
            <Stack
              sx={{
                width: '100%',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={dayjs(startTime)}
                  disablePast
                  ampm={false}
                  onChange={onChangeStartTime}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
        </Stack>
        <Stack flexDirection="row" gap="8px" sx={{ mt: '28px' }}>
          {(actionBroadcast !== 'edit' || type === BroadcastType.draft) && (
            <Button
              className="blue"
              fullWidth
              onClick={() => onSubmit(BroadcastType.draft)}
              disabled={!name || calculationLoading}
            >
              {t('broadcast.modal.drafts')}
            </Button>
          )}

          <Button
            className="brightGreen"
            fullWidth
            onClick={() => onSubmit(BroadcastType.flow)}
            disabled={!isValid || calculationLoading}
          >
            {t('broadcast.modal.plan')} (
            {calculationLoading ? '...' : receiversNumber})
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default BroadcastModal;
