import { Stack } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import BotContextProvider from '../telegram-bots/bot.context';
import BotPage from '../telegram-bots/bot.page';
import BotsList from '../telegram-bots/bots.list';
import UserAccountPage from '../telegram-user-accounts/telegram-account.page';
import UserAccountContextProvider from '../telegram-user-accounts/user-account.context';

import { ProjectContext } from './project.context';
import ChooseOrCreateBot from './screens/choose-or-create-bot';
import NewTelegramBot from './screens/new-telegram-bot';
import NewUserAccount from './screens/new-user-account';
import ProjectMain from './screens/project-main';
import ProjectScreens from './screens/project-screens.enum';
import ProjectSettings from './screens/settings/settings';
import Statistics from './screens/statistics';

const ProjectPage = () => {
  const { project } = useContext(ProjectContext);

  const { screen } = useParams();

  const screens = {
    [ProjectScreens.newTelegramBot]: <NewTelegramBot />,
    [ProjectScreens.newTelegramUserAccount]: <NewUserAccount />,
    [ProjectScreens.statistics]: <Statistics project={project} />,
    [ProjectScreens.main]: <ProjectMain />,
    [ProjectScreens.bot]: (
      <BotContextProvider>
        <BotPage />
      </BotContextProvider>
    ),
    [ProjectScreens.userAccount]: (
      <UserAccountContextProvider>
        <UserAccountPage />
      </UserAccountContextProvider>
    ),
    [ProjectScreens.settings]: <ProjectSettings />,
  };

  return (
    <Stack
      flexDirection="row"
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
      }}
    >
      <BotsList />
      <Stack
        sx={{
          width: '100%',
        }}
      >
        {screens[screen as ProjectScreens] ?? <ChooseOrCreateBot />}
      </Stack>
    </Stack>
  );
};

export default ProjectPage;
