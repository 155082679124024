import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useState } from 'react';

import ArrowLeft from '../../assets/icons/ArrowLeft';

interface FlowControlsProps {
  saveFlow: () => void;
  onBack: () => void;
  onAddClick: (e: React.SyntheticEvent) => void;
  nodesChanged: boolean;
  isFlowValid: boolean;
  setDefaultNodesAndEdges: () => void;
}

const FlowControls: FC<FlowControlsProps> = ({
  onBack,
  saveFlow,
  isFlowValid,
  setDefaultNodesAndEdges,
  nodesChanged,
  onAddClick,
}) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [confirmationDialogText, setConfirmationDialogText] =
    useState<string>('');
  const [isFlowSaving, setIsFlowSaving] = useState(false);

  const openConfirmationDialogOpen = (text: string) => {
    setConfirmationDialogOpen(true);
    setConfirmationDialogText(text);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    setIsFlowSaving(false);
    setConfirmationDialogText('');
  };

  const onConfirm = () => {
    if (isFlowSaving) {
      saveFlow();
      setIsFlowSaving(false);
    } else {
      setDefaultNodesAndEdges();
    }
    setConfirmationDialogOpen(false);
    setConfirmationDialogText('');
  };
  return (
    <Stack
      flexDirection="row"
      gap="16px"
      sx={{
        position: 'absolute',
        top: 20,
        right: 20,
      }}
    >
      <Button
        onClick={onBack}
        disabled={nodesChanged}
        className="green"
        sx={{
          borderRadius: '8px',
          width: '48px',
          height: '48px',
          minWidth: '48px',
          zIndex: '1',
        }}
      >
        <ArrowLeft />
      </Button>
      <Button
        onClick={onAddClick}
        className="brightGreen"
        sx={{
          borderRadius: '8px',
          width: '48px',
          height: '48px',
          minWidth: '48px',
          zIndex: '1',
        }}
      >
        <AddRoundedIcon sx={{ color: 'grey.10' }} />
      </Button>
      <Button
        className="brightGreen"
        sx={{
          borderRadius: '8px',
          width: '48px',
          height: '48px',
          minWidth: '48px',
          zIndex: '1',
        }}
        disabled={Boolean(!isFlowValid) || Boolean(!nodesChanged)} //no/yes
        onClick={() => {
          openConfirmationDialogOpen(`${t('flowNodes.saveChangesFlow')} ?`);
          setIsFlowSaving(true);
        }}
      >
        <SaveRoundedIcon />
      </Button>
      <Button
        className="red"
        sx={{
          borderRadius: '8px',
          width: '48px',
          minWidth: '48px',
          height: '48px',
          zIndex: '1',
        }}
        onClick={() =>
          openConfirmationDialogOpen(`${t('flowNodes.cancelChangesFlow')} ?`)
        }
        disabled={!nodesChanged}
      >
        <ClearRoundedIcon
          sx={{
            color: 'grey.1',
          }}
        />
      </Button>
      <Dialog
        open={confirmationDialogOpen}
        onClose={closeConfirmationDialog}
        sx={{
          '& .MuiPaper-root': {
            border: '1px solid',
            borderColor: 'grey.10',
            borderRadius: '12px',
          },
        }}
      >
        <DialogContent
          sx={{
            width: '420px',
            backgroundColor: 'grey.14',
            p: '32px 56px 24px',
          }}
        >
          <DialogContentText
            sx={{
              wordWrap: 'break-word',
              textAlign: 'center',
              color: 'grey.1',
            }}
          >
            {confirmationDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ width: '420px', backgroundColor: 'grey.14', p: '0 56px 32px' }}
        >
          <Button
            className={'red'}
            sx={{
              width: '154px',
            }}
            fullWidth
            onClick={closeConfirmationDialog}
            autoFocus
          >
            {t('common.cancel')}
          </Button>
          <Button
            className={'blue'}
            sx={{
              width: '154px',
            }}
            fullWidth
            onClick={onConfirm}
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default FlowControls;
