import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Container, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import WithNav from '../common/layout/with-nav';
import ModalBox from '../common/modal/modal-box';
import { GlobalContext } from '../GlobalContext';

import CreateProject from './create-project';
import ProjectCard from './project.card';

const ProjectsListPage = () => {
  const { projects, refetchProjects } = useContext(GlobalContext);

  const { t } = useTranslation();

  const [isCreateProjectModal, setIsCreateProjectModal] = useState(false);

  const openCreateProjectModal = () => setIsCreateProjectModal(true);
  const closeCreateProjectModal = () => setIsCreateProjectModal(false);

  useEffect(() => {
    refetchProjects();
  }, []);

  const hasProjects = Boolean(projects.length);

  if (!hasProjects) {
    return (
      <WithNav>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
          }}
        >
          <Stack
            alignItems="center"
            sx={{
              backgroundColor: 'grey.14',
              border: '1px solid',
              borderColor: 'grey.10',
              padding: '32px 56px',
              maxWidth: '704px',
            }}
          >
            <CreateProject />
          </Stack>
        </Stack>
      </WithNav>
    );
  }

  return (
    <WithNav>
      <Stack
        sx={{
          height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        }}
      >
        <Container
          sx={{
            mt: '50px',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '30px',
              lineHeight: '130%',
              color: 'grey.5',
              m: '30px auto 36px',
            }}
          >
            {t('projects.chooseProjectOrCreate')}
          </Typography>
          <Stack
            direction="row"
            gap="15px"
            sx={{
              flexWrap: 'wrap',
            }}
          >
            <Button
              className={'green'}
              sx={{
                width: '272px',
                height: '80px',
                color: 'grey.1',
                fontWeight: '600',
                fontSize: '18px',
                lineHeight: '140%',
              }}
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={openCreateProjectModal}
            >
              {t('common.newProject')}
            </Button>

            <ModalBox
              open={isCreateProjectModal}
              onClose={closeCreateProjectModal}
              sx={{
                padding: '32px 56px',
                borderRadius: '12px',
                position: 'relative',
              }}
              maxWidth={'700px'}
            >
              <Stack
                sx={{
                  position: 'absolute',
                  top: '15px',
                  right: '15px',
                  cursor: 'pointer',
                }}
                onClick={closeCreateProjectModal}
              >
                <CloseIcon />
              </Stack>
              <CreateProject />
            </ModalBox>
            {projects.map((project) => (
              <ProjectCard key={project._id} project={project} />
            ))}
          </Stack>
        </Container>
      </Stack>
    </WithNav>
  );
};

export default ProjectsListPage;
