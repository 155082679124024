import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';

import PhotoPreview from '../../common/media/photo.preview';
import VideoNotePreview from '../../common/media/video-note.preview';
import VideoPreview from '../../common/media/video.preview';
import VoicePreview from '../../common/media/voice.preview';
import { IMedia, MediaType } from '../../interfaces/common';

type Size = 'large' | 'small';

interface AttachedMediaPreviewProps {
  media: IMedia[];
  onRemoveMedia: (_id: string) => void;
  size?: Size;
  maxWidth?: string;
}

const getPreviewSize = (size: Size) => {
  switch (size) {
    case 'large': {
      return {
        wrapperSide: '132px',
        videoHeight: 124,
        videoNoteSide: 132,
      };
    }
    case 'small': {
      return {
        wrapperSide: '96px',
        videoHeight: 100,
        videoNoteSide: 100,
      };
    }
    default: {
      return {
        wrapperSide: '132px',
        videoHeight: 124,
        videoNoteSide: 132,
      };
    }
  }
};

const AttachedMediaPreview: FC<AttachedMediaPreviewProps> = ({
  media,
  onRemoveMedia,
  size = 'large',
  maxWidth = '420px',
}) => {
  const { videoHeight, videoNoteSide, wrapperSide } = getPreviewSize(size);

  return (
    <Stack
      flexDirection="row"
      gap="10px"
      sx={{ width: '100%', maxWidth, overflow: 'auto' }}
    >
      {media.map(({ url, _id, type }) => (
        <Stack
          key={_id}
          sx={{
            minWidth: wrapperSide,
            height: wrapperSide,
            position: 'relative',
          }}
        >
          {type === MediaType.photo && (
            <PhotoPreview
              imageSrc={url}
              imgStyle={{
                maxWidth: wrapperSide,
                maxHeight: wrapperSide,
                width: wrapperSide,
                height: wrapperSide,
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: '4px',
              }}
            />
          )}

          {type === MediaType.video && (
            <VideoPreview videoSrc={url} playerHeight={videoHeight} />
          )}

          {type === MediaType.voice && (
            <Stack
              justifyContent="center"
              sx={{
                height: '100%',
                backgroundColor: 'grey.13',
                borderRadius: '4px',
              }}
            >
              <VoicePreview voiceUrl={url} attachedPreview={true} />
            </Stack>
          )}

          {type === MediaType.videoNote && (
            <Stack
              justifyContent="center"
              sx={{
                height: '100%',
              }}
            >
              <VideoNotePreview
                videoSrc={url}
                playerHeight={videoNoteSide}
                playerWidth={videoNoteSide}
              />
            </Stack>
          )}
          <Button
            sx={{
              width: '20px',
              height: '20px',
              minWidth: '20px',
              position: 'absolute',
              top: '3px',
              right: '3px',
              backgroundColor: '#ff1f6e4d',
              borderRadius: '50%',
              '&:hover': { backgroundColor: '#ff1f6e96' },
            }}
            onClick={() => onRemoveMedia(_id)}
          >
            <CloseIcon
              sx={{
                fontSize: '12px',
                fontWeight: 600,
                fill: '#FF1F6E',
              }}
            />
          </Button>
        </Stack>
      ))}
    </Stack>
  );
};

export default AttachedMediaPreview;
