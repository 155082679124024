import React from 'react';
import { Route, Routes } from 'react-router-dom';

import WithNav from '../common/layout/with-nav';
import DocumentationPage from '../documentation/documentation.page';
import LoginPage from '../login/login.page';
import ProjectContextProvider from '../projects/project.context';
import ProjectPage from '../projects/project.page';
import ProjectsListPage from '../projects/projects-list.page';
import SocketContextProvider from '../store/context/sockets/socket.context';
import BotContextProvider from '../telegram-bots/bot.context';
import MessengerContextProvider from '../telegram-messenger/messenger.context';
import MessengerPage from '../telegram-messenger/messenger.page';
import UserAccountContextProvider from '../telegram-user-accounts/user-account.context';
import UserPage from '../user/user.page';

import LoggedInRoute from './LoggedInRoute';
import NavigateToDefault from './NavigateToDefault';

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/user"
        element={
          <LoggedInRoute>
            <UserPage />
          </LoggedInRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <LoggedInRoute>
            <ProjectsListPage />
          </LoggedInRoute>
        }
      />

      <Route
        path="/project/:projectId/:screen?/:botId?/:botTab?"
        element={
          <LoggedInRoute>
            <ProjectContextProvider>
              <WithNav>
                <ProjectPage />
              </WithNav>
            </ProjectContextProvider>
          </LoggedInRoute>
        }
      />

      <Route
        path="/project/:projectId/user-account-messenger/:botId?/:messengerTab?"
        element={
          <LoggedInRoute>
            <ProjectContextProvider>
              <UserAccountContextProvider>
                <SocketContextProvider isUserAccount>
                  <MessengerContextProvider>
                    <MessengerPage />
                  </MessengerContextProvider>
                </SocketContextProvider>
              </UserAccountContextProvider>
            </ProjectContextProvider>
          </LoggedInRoute>
        }
      />

      <Route
        path="/project/:projectId/messenger/:botId?/:messengerTab?"
        element={
          <LoggedInRoute>
            <ProjectContextProvider>
              <BotContextProvider>
                <SocketContextProvider isUserAccount={false}>
                  <MessengerContextProvider isBot>
                    <MessengerPage isBot />
                  </MessengerContextProvider>
                </SocketContextProvider>
              </BotContextProvider>
            </ProjectContextProvider>
          </LoggedInRoute>
        }
      />
      <Route path="/documentation" element={<DocumentationPage />} />

      <Route path="/*" element={<NavigateToDefault />} />
    </Routes>
  );
};

export default Router;
