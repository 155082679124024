import uploadFile from '../api/user/upload-file';

const UploadCroppedImage = async (croppedImage: string) => {
  const base64ToBlob = (base64: string) => {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const blob = base64ToBlob(croppedImage);

  const formData = new FormData();
  formData.append('file', blob, 'cropped_image.jpg');

  try {
    const { url } = await uploadFile(formData);

    return url;
  } catch (error) {}
};

export default UploadCroppedImage;
