import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import { alpha, Stack, Tooltip } from '@mui/material';
import React, { FC } from 'react';

import {
  ITelegramActionButton,
  ITelegramUrlButton,
  TelegramButton,
  TelegramButtonType,
} from '../../interfaces/common';
import useThemeColors from '../../utils/hooks/useThemeColors';
import truncate from '../../utils/truncate';

export const TelegramUrlButton: FC<{
  button: ITelegramUrlButton;
  fontSize?: string;
  isValid: boolean;
}> = ({ button, fontSize = '12px', isValid }) => {
  const themeColors = useThemeColors();

  return (
    <Tooltip title={button.url}>
      <Stack
        component="a"
        href={button.url}
        target="_blank"
        rel="noopener noreferrer"
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: '15px',
          width: '100%',
          backgroundColor: isValid
            ? `${alpha(themeColors.green['3'], 0.1)}`
            : 'red.1',
          fontSize,
          padding: '5px',
          borderRadius: '6px',
          color: 'white',
          textDecoration: 'none',
          position: 'relative',
        }}
      >
        {truncate(button.label, 13)}
        <Stack
          sx={{
            top: 3,
            right: 3,
            position: 'absolute',
          }}
        >
          <CallMadeRoundedIcon
            sx={{
              fontSize: '10px',
            }}
          />
        </Stack>
      </Stack>
    </Tooltip>
  );
};

const TelegramActionButton: FC<{
  button: ITelegramActionButton;
  fontSize?: string;
  isValid: boolean;
}> = ({ button, fontSize = '12px', isValid }) => {
  const themeColors = useThemeColors();
  return (
    <Tooltip title="Perform Action">
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          minHeight: '15px',
          backgroundColor: isValid
            ? `${alpha(themeColors.blue['3'], 0.2)}`
            : 'red.1',
          fontSize,
          padding: '5px',
          borderRadius: '6px',
          color: 'white',
          textDecoration: 'none',
          position: 'relative',
        }}
      >
        {truncate(button.label, 13)}
        <Stack
          sx={{
            top: 3,
            right: 3,
            position: 'absolute',
          }}
        >
          <FlashOnRoundedIcon
            sx={{
              fontSize: '10px',
            }}
          />
        </Stack>
      </Stack>
    </Tooltip>
  );
};

interface TelegramButtonsProps {
  buttons: TelegramButton[][];
  fontSize?: string;
}

const TelegramButtons: FC<TelegramButtonsProps> = ({ buttons, fontSize }) => {
  return (
    <Stack spacing={1} width="100%">
      {buttons.map((buttonGroup, index) => (
        <Stack key={index} direction="row" spacing={1} width="100%">
          {buttonGroup.map((button, index) => {
            if (button.type === TelegramButtonType.url) {
              const isValid = Boolean(button?.url) && Boolean(button?.label);
              return (
                <TelegramUrlButton
                  key={index}
                  button={button}
                  fontSize={fontSize}
                  isValid={isValid}
                />
              );
            }

            if (button.type === TelegramButtonType.action) {
              const isValid = Boolean(button?.label);
              return (
                <TelegramActionButton
                  key={index}
                  button={button}
                  fontSize={fontSize}
                  isValid={isValid}
                />
              );
            }
          })}
        </Stack>
      ))}
    </Stack>
  );
};

export default TelegramButtons;
