import CallSplitIcon from '@mui/icons-material/CallSplit';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import MailIcon from '@mui/icons-material/Mail';
import StarIcon from '@mui/icons-material/Star';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import TooltipInfo from '../../common/tooltip/tooltip';
import { IUserSafe } from '../../user/user.interface';
import {
  ITelegramBotAccess,
  TelegramMessengerAccess,
} from '../telegram-bot.interface';

interface AccessTableProps {
  owner: string;
  members: IUserSafe[];
  accesses: ITelegramBotAccess[];
  setAccesses: (accesses: ITelegramBotAccess[]) => void;
}

function AccessTable({
  members,
  accesses,
  setAccesses,
  owner,
}: AccessTableProps) {
  const handleCheckboxChange = (
    memberId: string,
    key: keyof ITelegramBotAccess,
    value: boolean | TelegramMessengerAccess,
  ) => {
    setAccesses(
      accesses.map((access) =>
        access.user === memberId ? { ...access, [key]: value } : access,
      ),
    );
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', flex: '1 1 auto' }}>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          maxHeight: 440,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              backgroundColor: 'grey.10',
              borderBottom: '1px solid rgba(81, 81, 81, 1)',
            }}
          >
            <TableRow
              sx={{
                backgroundColor: 'grey.12',
                border: '1px solid',
                borderColor: 'grey.10',
                borderRadius: '6px',
                position: 'sticky',
                top: '0',
                zIndex: '12',
              }}
            >
              <TableCell
                sx={{ backgroundColor: 'transparent', borderBottom: 'none' }}
              >
                {t('common.name')}
              </TableCell>

              <TableCell
                sx={{ backgroundColor: 'transparent', borderBottom: 'none' }}
              >
                {t('common.lastName')}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <MailIcon sx={{ color: 'grey.5', fontSize: '15px' }} />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessBroadcast',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.broadcasts')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <CampaignRoundedIcon
                        sx={{ color: 'grey.5', fontSize: '15px' }}
                      />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessChannels',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.channels')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <CallSplitIcon
                        sx={{ color: 'grey.5', fontSize: '15px' }}
                      />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessFlow',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.flows')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <TrendingUpRoundedIcon
                        sx={{ color: 'grey.5', fontSize: '15px' }}
                      />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessStatistics',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.statistics')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <ChatRoundedIcon
                        sx={{ color: 'grey.5', fontSize: '15px' }}
                      />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessMessenger',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.messenger')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <SupportAgentRoundedIcon
                        sx={{ color: 'grey.5', fontSize: '15px' }}
                      />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessManager',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.manager')}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'transparent',
                  borderBottom: 'none',
                  padding: '16px 6px 1px',
                }}
                align="center"
              >
                <Stack flexDirection="column" alignItems="center">
                  <TooltipInfo
                    icon={
                      <StarIcon sx={{ color: 'grey.5', fontSize: '15px' }} />
                    }
                    title={t(
                      'projects.screen-new-telegram-bot.participantsTable.tooltips.accessAdmin',
                    )}
                    placement="top"
                  />
                  <Typography sx={{ fontSize: '10px' }}>
                    {t('common.admin')}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member, index) => {
              const access =
                accesses.find((access) => access.user === member._id) ||
                ({} as ITelegramBotAccess);
              const isEven = index % 2 === 0;
              return (
                <TableRow
                  sx={{
                    backgroundColor: isEven ? 'grey.15' : 'grey.13',
                    borderBottom: '1px solid rgba(81, 81, 81, 1)',
                  }}
                  key={member._id}
                >
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    component="th"
                    scope="row"
                  >
                    {member?.firstName ?? ''}
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    component="th"
                    scope="row"
                  >
                    {member.lastName ?? ''}
                  </TableCell>

                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Checkbox
                      disabled={member._id === owner}
                      checked={
                        access.broadcasts || member._id === owner || false
                      }
                      style={{
                        color:
                          access.broadcasts || member._id
                            ? '#DEFF82'
                            : '#4b4a4a',
                        opacity: member._id === owner ? '0.5' : '1',
                      }}
                      onChange={(event) =>
                        handleCheckboxChange(
                          member._id,
                          'broadcasts',
                          event.target.checked,
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Checkbox
                      disabled={member._id === owner}
                      checked={access.channels || member._id === owner || false}
                      style={{
                        color:
                          access.channels || member._id ? '#DEFF82' : '#4b4a4a',
                        opacity: member._id === owner ? '0.5' : '1',
                      }}
                      onChange={(event) =>
                        handleCheckboxChange(
                          member._id,
                          'channels',
                          event.target.checked,
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Checkbox
                      disabled={member._id === owner}
                      checked={access.flows || member._id === owner || false}
                      style={{
                        color:
                          access.flows || member._id ? '#DEFF82' : '#4b4a4a',
                        opacity: member._id === owner ? '0.5' : '1',
                      }}
                      onChange={(event) =>
                        handleCheckboxChange(
                          member._id,
                          'flows',
                          event.target.checked,
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Checkbox
                      disabled={member._id === owner}
                      checked={
                        access.statistics || member._id === owner || false
                      }
                      style={{
                        color:
                          access.statistics || member._id
                            ? '#DEFF82'
                            : '#4b4a4a',
                        opacity: member._id === owner ? '0.5' : '1',
                      }}
                      onChange={(event) =>
                        handleCheckboxChange(
                          member._id,
                          'statistics',
                          event.target.checked,
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: 'none',
                      padding: '6px 16px',
                    }}
                  >
                    <FormControlLabel
                      sx={{ margin: '0' }}
                      control={
                        <Checkbox
                          disabled={member._id === owner}
                          checked={
                            access.messenger !== TelegramMessengerAccess.none ||
                            member._id === owner
                          }
                          style={{
                            color:
                              access.messenger || member._id
                                ? '#DEFF82'
                                : '#4b4a4a',
                            opacity: member._id === owner ? '0.5' : '1',
                          }}
                          onChange={(event) =>
                            handleCheckboxChange(
                              member._id,
                              'messenger',
                              event.target.checked
                                ? TelegramMessengerAccess.manager
                                : TelegramMessengerAccess.none,
                            )
                          }
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Radio
                      checked={
                        access.messenger === TelegramMessengerAccess.manager
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          member._id,
                          'messenger',
                          TelegramMessengerAccess.manager,
                        )
                      }
                      style={{
                        color:
                          access.messenger === TelegramMessengerAccess.manager
                            ? '#DEFF82'
                            : '#4b4a4a',
                        opacity: member._id === owner ? '0.5' : '1',
                      }}
                      disabled={
                        access.messenger === TelegramMessengerAccess.none ||
                        member._id === owner
                      }
                    />
                  </TableCell>

                  <TableCell
                    sx={{ border: 'none', padding: '6px 16px' }}
                    align="center"
                  >
                    <Radio
                      checked={
                        access.messenger === TelegramMessengerAccess.admin ||
                        member._id === owner
                      }
                      style={{
                        color:
                          access.messenger === TelegramMessengerAccess.admin
                            ? '#DEFF82'
                            : '#4b4a4a',
                        opacity: member._id === owner ? '0.5' : '1',
                      }}
                      onChange={() =>
                        handleCheckboxChange(
                          member._id,
                          'messenger',
                          TelegramMessengerAccess.admin,
                        )
                      }
                      disabled={
                        access.messenger === TelegramMessengerAccess.none ||
                        member._id === owner
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default AccessTable;
