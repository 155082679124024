import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TelegramLeadStatus } from '../../../../interfaces/common';
import { ProjectContext } from '../../../../projects/project.context';
import { ITelegramChannelWithActivators } from '../../../channels/interfaces';
import {
  ConditionsFlowNodeConditionData,
  ConditionTypes,
  IConditionsFlowNodeData,
} from '../../interfaces';

type ConditionsItemProps = {
  conditions: IConditionsFlowNodeData['conditions'];
  onEditConditions: (
    index: number,
    data: ConditionsFlowNodeConditionData,
  ) => void;
  onDeleteCondition: (index: number) => void;
  channels: ITelegramChannelWithActivators[];
  onSelectChannel: (event: SelectChangeEvent, index: number) => void;
  onSelectStatus: (event: SelectChangeEvent, index: number) => void;
};

const ConditionsItem: FC<ConditionsItemProps> = ({
  conditions,
  onEditConditions,
  onDeleteCondition,
  channels,
  onSelectChannel,
  onSelectStatus,
}) => {
  const { t } = useTranslation();

  const { projectParams } = useContext(ProjectContext);

  return (
    <Stack
      sx={{
        mt: '10px',
        mb: '20px',
        overflow: 'auto',
      }}
      gap="5px"
    >
      {conditions.map((condition, index) => {
        switch (condition.type) {
          case ConditionTypes.checkParam: {
            return (
              <Stack
                key={'check-param' + index}
                className="conditionsContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.checkParam')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Stack
                    direction="row"
                    gap="10px"
                    sx={{ maxWidth: '251px', position: 'relative' }}
                  >
                    <Autocomplete
                      sx={{
                        width: '120px',
                        '& .MuiInputBase-root': {
                          backgroundColor: 'grey.15',
                          height: '40px',
                          p: '0',
                          pl: '12px',
                        },
                      }}
                      freeSolo
                      options={projectParams}
                      value={condition.key}
                      onInputChange={(_, newValue) => {
                        onEditConditions(index, {
                          ...condition,
                          key: newValue ?? '',
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Key"
                          sx={{
                            height: '40px',
                            '& .MuiInputBase-input': {
                              fontWeight: 500,
                              fontSize: '18px',
                            },
                          }}
                        />
                      )}
                    />
                    <TextField
                      sx={{
                        width: '50%',
                        height: '40px',
                        '& .MuiInputBase-root': {
                          height: '40px',
                          fontWeight: 500,
                          fontSize: '18px',
                          '& input': { p: '6px 16px' },
                        },
                      }}
                      placeholder="Value"
                      value={condition.value}
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        if (value.length > 20) return;

                        onEditConditions(index, {
                          ...condition,
                          value,
                        });
                      }}
                    />
                  </Stack>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteCondition(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }

          case ConditionTypes.checkChannelMembership: {
            return (
              <Stack
                key={'assignParam' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.checkIfInChanel')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Box sx={{ width: '80%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.channel')}</InputLabel>
                      <Select
                        value={condition?.channelId ?? ''}
                        label="Channel"
                        onChange={(event) => {
                          onSelectChannel(event, index);
                        }}
                        sx={{
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            py: '14px',
                          },
                        }}
                      >
                        {channels.map(({ title, _id }) => (
                          <MenuItem
                            key={_id}
                            value={_id}
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteCondition(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }

          case ConditionTypes.checkStatus: {
            return (
              <Stack
                key={'checkStatus' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.checkStatus')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Box sx={{ width: '80%' }}>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={condition?.status ?? ''}
                        label="Status"
                        onChange={(event) => {
                          onSelectStatus(event, index);
                        }}
                        sx={{
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            py: '14px',
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            fontWeight: 500,
                          }}
                          key={'status_lead'}
                          value={TelegramLeadStatus.lead}
                        >
                          {TelegramLeadStatus.lead}
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontWeight: 500,
                          }}
                          key={'status_reg'}
                          value={TelegramLeadStatus.reg}
                        >
                          {TelegramLeadStatus.reg}
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontWeight: 500,
                          }}
                          key={'status_dep'}
                          value={TelegramLeadStatus.dep}
                        >
                          {TelegramLeadStatus.dep}
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontWeight: 500,
                          }}
                          key={'status_qual'}
                          value={TelegramLeadStatus.qual}
                        >
                          {TelegramLeadStatus.qual}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteCondition(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }
        }
      })}
    </Stack>
  );
};

export default ConditionsItem;
