import ImageIcon from '@mui/icons-material/Image';
import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../user/user.context';
import truncate from '../utils/truncate';

import IProject from './project.interface';

interface ProjectCardProps {
  project: IProject;
}

const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const onSelectProject = () => {
    navigate(`/project/${project._id}`);
  };
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap="12px"
      sx={{
        width: '272px',
        height: '80px',
        backgroundColor: 'grey.14',
        borderRadius: '8px',
        padding: '12px',
        border: '1px solid',
        borderColor: 'grey.10',
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        zIndex: '1',
      }}
      onClick={onSelectProject}
    >
      <Stack
        sx={{
          width: '56px',
          height: '56px',
          overflow: 'hidden',
          borderRadius: '50%',
        }}
      >
        {project.imageUrl ? (
          <img
            src={project.imageUrl}
            style={{
              width: '100%',
              height: '100%',
            }}
            alt="#"
          />
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'grey.10',
            }}
          >
            <ImageIcon />
          </Stack>
        )}
      </Stack>

      <Stack gap="9px">
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '140%',
            width: '75%',
          }}
        >
          {truncate(project.name, 15)}
        </Typography>
        <Typography
          sx={{
            opacity: '0.4',
            fontSize: '12px',
            fontWeight: 500,
          }}
        >
          {project.description}
        </Typography>
      </Stack>
      {project?.owner === user?._id && (
        <Stack
          justifyContent="center"
          sx={{
            position: 'absolute',
            top: '5px',
            right: '15px',
            zIndex: '12',
            borderRadius: '2px',
            padding: ' 2px 4px',
            width: '54px',
            height: '18px',
            backgroundColor: 'green.2',
          }}
        >
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: '9px',
              lineHeight: '100%',
              textAlign: 'center',
              color: 'grey.15',
              textTransform: 'uppercase',
            }}
          >
            {t('common.owner')}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ProjectCard;
