import { IUserSafe } from '../user/user.interface';

interface botStatistics {
  activeUsersCount: number;
  bannedUsersCount: number;
  depsCount: number;
  qualsCount: number;
  regsCount: number;
  statisticsUpdatedAt: Date;
  totalUsersCount: number;
}

export interface ITelegramBot {
  _id: string;
  owner: string;
  token: string;
  name: string;
  authorized: boolean;
  username: string;
  avatarUrl: string;
  firstName: string;
  telegramId: number;
  defaultFlow?: string | null;
  project: string;
  statistics: botStatistics;
  createdAt: Date;
  updatedAt: Date;
}

export type ITelegramBotSafe = Omit<
  ITelegramBot,
  'token' | 'owner' | 'authorized'
>;

export type CreateTelegramBot = Omit<
  ITelegramBot,
  | '_id'
  | 'owner'
  | 'createdAt'
  | 'updatedAt'
  | 'username'
  | 'avatarUrl'
  | 'telegramId'
  | 'firstName'
  | 'authorized'
  | 'statistics'
>;

export type UpdateTelegramBot = Partial<
  Omit<
    ITelegramBot,
    | '_id'
    | 'owner'
    | 'createdAt'
    | 'updatedAt'
    | 'creator'
    | 'project'
    | 'telegramId'
    | 'firstName'
    | 'avatarUrl'
    | 'username'
    | 'token'
  >
> & { token: string | null };

export enum TelegramMessengerAccess {
  none = 'none',
  manager = 'manager',
  admin = 'admin',
}

export enum TelegramMessengerMode {
  online = 'online',
  silentOnline = 'silentOnline',
  offline = 'offline',
}

export interface ITelegramBotAccess {
  _id: string;
  user: string;
  broadcasts: boolean;
  channels: boolean;
  flows: boolean;
  statistics: boolean;
  messenger: TelegramMessengerAccess;
  messengerMode: TelegramMessengerMode;
}

export interface ITelegramBotAccessWithUser {
  _id: string;
  telegramBot: string;
  user: IUserSafe;
  broadcasts: boolean;
  channels: boolean;
  flows: boolean;
  statistics: boolean;
  messenger: TelegramMessengerAccess;
  messengerMode: TelegramMessengerMode;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITelegramBotWithAccess {
  bot: ITelegramBot;
  access: ITelegramBotAccess;
}
