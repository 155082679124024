import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, Fragment, SyntheticEvent, useContext } from 'react';

import { ProjectContext } from '../../../../projects/project.context';
import { ITelegramChannelWithActivators } from '../../../channels/interfaces';
import {
  ActionFlowNodeTaskData,
  ActionTaskTypes,
  IActionsFlowNodeData,
  IFlowWithActivatorsAndFolder,
} from '../../interfaces';

type ActionsItemProps = {
  tasks: IActionsFlowNodeData['tasks'];
  onEditTask: (index: number, data: ActionFlowNodeTaskData) => void;
  onDeleteTask: (index: number) => void;
  onSelectChannel: (
    event: SelectChangeEvent,
    index: number,
    actionType: ActionTaskTypes,
  ) => void;
  onSelectFlow: (event: SelectChangeEvent, index: number) => void;
  channels: ITelegramChannelWithActivators[];
  flows: IFlowWithActivatorsAndFolder[];
};
const ActionsItem: FC<ActionsItemProps> = ({
  tasks,
  onEditTask,
  onDeleteTask,
  onSelectChannel,
  onSelectFlow,
  channels,
  flows,
}) => {
  const { projectParams } = useContext(ProjectContext);
  return (
    <Fragment>
      {tasks.map((task, index) => {
        switch (task.type) {
          case ActionTaskTypes.assignParam: {
            return (
              <Stack
                key={'assignParam' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.assignParam')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Stack
                    direction="row"
                    gap="10px"
                    sx={{ maxWidth: '251px', position: 'relative' }}
                  >
                    <Autocomplete
                      sx={{
                        width: '120px',
                        '& .MuiInputBase-root': {
                          backgroundColor: 'grey.15',
                          height: '40px',
                          p: '0',
                          pl: '12px',
                        },
                      }}
                      options={projectParams}
                      value={task.key}
                      onInputChange={(event, newInputValue) => {
                        onEditTask(index, {
                          ...task,
                          inputValue: newInputValue ?? ' ',
                        });
                      }}
                      onChange={(
                        e: SyntheticEvent,
                        newValue: string | null,
                      ) => {
                        onEditTask(index, {
                          ...task,
                          inputValue: newValue ?? '',
                          key: newValue ?? '',
                        });
                      }}
                      inputValue={task.inputValue || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Key"
                          sx={{
                            height: '40px',
                            '& .MuiInputBase-input': {
                              fontWeight: 500,
                              fontSize: '18px',
                            },
                          }}
                        />
                      )}
                    />
                    <TextField
                      sx={{
                        width: '50%',
                        height: '40px',
                        '& .MuiInputBase-root': {
                          height: '40px',
                          fontWeight: 500,
                          fontSize: '18px',
                          '& input': { p: '6px 16px' },
                        },
                      }}
                      placeholder="Value"
                      value={task.value}
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        onEditTask(index, {
                          ...task,
                          value,
                        });
                      }}
                    />
                  </Stack>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }
          case ActionTaskTypes.deleteParam: {
            return (
              <Stack
                key={'deleteParam' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.deleteParam')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Stack
                    direction="row"
                    gap="10px"
                    sx={{
                      maxWidth: '251px',
                      width: '80%',
                      position: 'relative',
                    }}
                  >
                    <Autocomplete
                      sx={{
                        width: '251px',
                        '& .MuiInputBase-root': {
                          backgroundColor: 'grey.15',
                          height: '40px',
                          p: '0',
                          '& input': { p: '6px 16px' },
                        },
                      }}
                      freeSolo
                      options={projectParams}
                      value={task.key}
                      onInputChange={(event, newInputValue) => {
                        onEditTask(index, {
                          ...task,
                          inputValue: newInputValue ?? ' ',
                        });
                      }}
                      onChange={(
                        e: SyntheticEvent,
                        newValue: string | null,
                      ) => {
                        onEditTask(index, {
                          ...task,
                          inputValue: newValue ?? '',
                          key: newValue ?? '',
                        });
                      }}
                      inputValue={task.inputValue || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Key"
                          sx={{
                            p: '0',
                            height: '40px',
                            '& .MuiInputBase-input': {
                              p: '0',
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }
          case ActionTaskTypes.acceptToChannel: {
            return (
              <Stack
                key={'assignParam' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.acceptToChannel')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Box sx={{ width: '80%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.channel')}</InputLabel>
                      <Select
                        value={task?.channelId ?? ''}
                        label="Channel"
                        onChange={(event) => {
                          onSelectChannel(
                            event,
                            index,
                            ActionTaskTypes.acceptToChannel,
                          );
                        }}
                        sx={{
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            p: '6px 12px',
                            '& input': { height: '40px' },
                          },
                        }}
                      >
                        {channels.map(({ title, _id }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }
          case ActionTaskTypes.startFlow: {
            return (
              <Stack
                key={'assignParam' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.startFlow')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Box sx={{ width: '80%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.flow')}</InputLabel>
                      <Select
                        value={task?.flowId ?? ''}
                        label="Flow"
                        onChange={(event) => onSelectFlow(event, index)}
                        sx={{
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            p: '6px 12px',
                            '& input': { height: '40px' },
                          },
                        }}
                      >
                        {flows.map(({ _id, name }) => (
                          <MenuItem key={_id} value={_id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }
          case ActionTaskTypes.kickFromChannel: {
            return (
              <Stack
                key={'assignParam' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.kickFromChannel')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Box sx={{ width: '80%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.channel')}</InputLabel>
                      <Select
                        value={task?.channelId ?? ''}
                        label="Channel"
                        onChange={(event) => {
                          onSelectChannel(
                            event,
                            index,
                            ActionTaskTypes.unbanInChannel,
                          );
                        }}
                        sx={{
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            p: '6px 12px',
                            '& input': { height: '40px' },
                          },
                        }}
                      >
                        {channels.map(({ title, _id }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }
          case ActionTaskTypes.unbanInChannel: {
            return (
              <Stack
                key={'assignParam' + index}
                className="taskContainer"
                sx={{
                  m: '7px 0',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderRadius: '6px',
                  borderColor: 'grey.10',
                  padding: '6px 10px',
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                  {t('flowNodes.unbanInChannel')}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justify-content="space-between"
                  gap="15px"
                  sx={{
                    mt: '10px',
                  }}
                >
                  <Box sx={{ width: '80%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{t('common.channel')}</InputLabel>
                      <Select
                        value={task?.channelId ?? ''}
                        label="Channel"
                        onChange={(event) => {
                          onSelectChannel(
                            event,
                            index,
                            ActionTaskTypes.unbanInChannel,
                          );
                        }}
                        sx={{
                          '.MuiSelect-select': {
                            backgroundColor: 'grey.15',
                            p: '6px 12px',
                            '& input': { height: '40px' },
                          },
                        }}
                      >
                        {channels.map(({ title, _id }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack>
                    <Button
                      className="red"
                      sx={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                      }}
                      onClick={() => {
                        onDeleteTask(index);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'grey.1', fontSize: '20px' }} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }
        }
      })}
    </Fragment>
  );
};

export default ActionsItem;
