import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Button, Menu, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';

import { ITag, TelegramLeadStatus } from '../../interfaces/common';
import DialogsFilter from '../interfaces/dialogs-filter.interface';

import TagsFilter from './tags.filter';

type FilterControlProps = {
  openMenu: (event: React.SyntheticEvent) => void;
  menuOpen: boolean;
  closeMenu: () => void;
  anchorEl: Element | null;
  dialogStatus: string[];
  toggleStatus: (status: TelegramLeadStatus) => void;
  tags: ITag[];
  selectedTagIds: ITag['_id'][];
  handleSelectTag: (newTag: ITag['_id']) => void;
  handleDeleteTag: (tagToDelete: ITag['_id']) => void;
  searchMode: DialogsFilter['searchMode'];
  updateSearchMode: (value: DialogsFilter['searchMode']) => void;
  resetAllFilters: () => void;
};

const FilterPopup: FC<FilterControlProps> = ({
  openMenu,
  menuOpen,
  closeMenu,
  anchorEl,
  dialogStatus,
  toggleStatus,
  tags,
  selectedTagIds,
  handleDeleteTag,
  handleSelectTag,
  searchMode,
  updateSearchMode,
  resetAllFilters,
}) => {
  const filterApplied =
    selectedTagIds.length || searchMode !== 'all' || dialogStatus.length;

  return (
    <Stack>
      <Button
        className="green"
        sx={{
          minWidth: '56px',
          width: '56px',
          height: '56px',
        }}
        onClick={openMenu}
      >
        {filterApplied ? (
          <FilterAltOffIcon sx={{ color: 'grey.1', fontSize: '24px' }} />
        ) : (
          <FilterAltIcon sx={{ color: 'grey.1', fontSize: '24px' }} />
        )}
      </Button>

      <Menu
        open={menuOpen}
        onClose={closeMenu}
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            top: '78px!important',
            left: '246px!important',
            width: '250px',
            backgroundColor: 'grey.13',
            p: '12px',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.10',
            backgroundImage: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack gap="12px">
          <Typography
            sx={{
              color: 'grey.secondary',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {t('messenger.filter.filterLabel')}
          </Typography>
          <Stack>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                mb: '6px',
              }}
            >
              {t('common.status')}
            </Typography>

            <Stack flexWrap="wrap" gap="12px" direction="row">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '66px',
                  padding: '3px 16.5px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: dialogStatus.includes(TelegramLeadStatus.lead)
                    ? 'grey.10'
                    : 'grey.secondary',
                  borderColor: dialogStatus.includes(TelegramLeadStatus.lead)
                    ? 'green.3'
                    : 'grey.secondary',
                  backgroundColor: dialogStatus.includes(
                    TelegramLeadStatus.lead,
                  )
                    ? 'green.2'
                    : 'grey.10',
                }}
                onClick={() => toggleStatus(TelegramLeadStatus.lead)}
              >
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                  }}
                >
                  Lead
                </Typography>
              </Stack>

              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '66px',
                  padding: '3px 16.5px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: dialogStatus.includes(TelegramLeadStatus.reg)
                    ? 'grey.10'
                    : 'grey.secondary',
                  borderColor: dialogStatus.includes(TelegramLeadStatus.reg)
                    ? 'green.3'
                    : 'grey.secondary',
                  backgroundColor: dialogStatus.includes(TelegramLeadStatus.reg)
                    ? 'green.2'
                    : 'grey.10',
                }}
                onClick={() => toggleStatus(TelegramLeadStatus.reg)}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                >
                  Reg
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '66px',
                  padding: '3px 16.5px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: dialogStatus.includes(TelegramLeadStatus.dep)
                    ? 'grey.10'
                    : 'grey.secondary',
                  borderColor: dialogStatus.includes(TelegramLeadStatus.dep)
                    ? 'green.3'
                    : 'grey.secondary',
                  backgroundColor: dialogStatus.includes(TelegramLeadStatus.dep)
                    ? 'green.2'
                    : 'grey.10',
                }}
                onClick={() => toggleStatus(TelegramLeadStatus.dep)}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                >
                  Dep
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {t('messenger.tagLabel')}
            </Typography>
            <TagsFilter
              allTags={tags}
              selectedTagIds={selectedTagIds}
              handleSelectTag={handleSelectTag}
              handleDeleteTag={handleDeleteTag}
            />
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                mb: '6px',
              }}
            >
              {t('messenger.filter.dialogsLabel')}
            </Typography>
            <Stack flexWrap="wrap" gap="12px" direction="row">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '3px 12px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: searchMode.includes('all')
                    ? 'grey.10'
                    : 'grey.secondary',
                  borderColor: searchMode.includes('all')
                    ? 'green.3'
                    : 'grey.secondary',
                  backgroundColor: searchMode.includes('all')
                    ? 'green.2'
                    : 'grey.10',
                }}
                onClick={() => updateSearchMode('all')}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {t('messenger.filter.all')}
                </Typography>
              </Stack>

              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '3px 12px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: searchMode.includes('notSeen')
                    ? 'grey.10'
                    : 'grey.secondary',
                  borderColor: searchMode.includes('notSeen')
                    ? 'green.3'
                    : 'grey.secondary',
                  backgroundColor: searchMode.includes('notSeen')
                    ? 'green.2'
                    : 'grey.10',
                }}
                onClick={() => updateSearchMode('notSeen')}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {t('messenger.filter.notSeen')}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: '3px 12px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  border: '1px solid',
                  color: searchMode.includes('blockedByUser')
                    ? 'grey.10'
                    : 'grey.secondary',
                  borderColor: searchMode.includes('blockedByUser')
                    ? 'green.3'
                    : 'grey.secondary',
                  backgroundColor: searchMode.includes('blockedByUser')
                    ? 'green.2'
                    : 'grey.10',
                }}
                onClick={() => updateSearchMode('blockedByUser')}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {t('messenger.filter.blocked')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap="3px"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              resetAllFilters();
              closeMenu();
            }}
          >
            <CloseIcon
              sx={{
                fontSize: '18px',
              }}
            />
            <Typography
              sx={{
                color: 'grey.5',
                fontWeight: 500,
                fontSize: ' 14px',
              }}
            >
              {t('messenger.filter.resetFilters')}
            </Typography>
          </Stack>
        </Stack>
      </Menu>
    </Stack>
  );
};

export default FilterPopup;
