import React from 'react';

const ArrowLeft = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 19H13V11.5C13 9.57 11.43 8 9.5 8H7V12L0 6L7 0V4H9.5C13.64 4 17 7.36 17 11.5V19Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default ArrowLeft;
