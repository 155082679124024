import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Stack } from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';

import BroadcastCard from './broadcast-card';
import { IBroadcast } from './interfaces';

interface DraftsTabProps {
  openModal: () => void;
  broadcasts: IBroadcast[];
  onCopy: (broadcast: IBroadcast, action: string) => void;
  onEdit: (broadcast: IBroadcast, action: string) => void;
  onDelete: (broadcastId: IBroadcast['_id']) => void;
}

const DraftsTab: FC<DraftsTabProps> = ({
  openModal,
  broadcasts,
  onCopy,
  onEdit,
  onDelete,
}) => {
  return (
    <Stack
      direction="row"
      gap="18px"
      flexWrap="wrap"
      alignItems="start"
      sx={{
        margin: '0',
        height: '100%',
        maxHeight: 'calc(100vh - 160px)',
        overflow: 'auto',
      }}
    >
      <Stack
        direction="row"
        gap="18px"
        flexWrap="wrap"
        alignItems="start"
        sx={{
          height: 'max-content',
          maxHeight: ' 87%',
          overflow: 'auto',
          padding: '0 3%',
        }}
      >
        {broadcasts.map((broadcast) => (
          <BroadcastCard
            key={broadcast._id}
            name={broadcast.name}
            type={broadcast.type}
            status={broadcast.status}
            recipientsCount={broadcast.recipientsCount}
            startDate={broadcast.startTime}
            endDate={broadcast.endTime}
            onCopy={() => onCopy({ ...broadcast, _id: '' }, 'clone')}
            onEdit={() => onEdit(broadcast, 'edit')}
            onDelete={() => onDelete(broadcast._id)}
          />
        ))}
      </Stack>
      <Button
        className="brightGreen"
        sx={{ width: '100%', height: '56px', mt: 'auto' }}
        onClick={openModal}
      >
        <AddRoundedIcon
          sx={{
            fontSize: '20px',
          }}
        />
        {t('broadcast.createBroadcast')}
      </Button>
    </Stack>
  );
};

export default DraftsTab;
