import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import truncate from '../../utils/truncate';

import { IFlowFolder } from './interfaces';

interface FolderCardProps {
  folder: IFlowFolder;
  selectedFolder: IFlowFolder | null;
  onSelectFolder: (folderId: IFlowFolder | null) => void;
  onEdit: (folder: IFlowFolder) => void;
  onDelete: (folder: IFlowFolder) => void;
}

const FolderCard: FC<FolderCardProps> = ({
  folder,
  onSelectFolder,
  selectedFolder,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const openProjectMenu = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };
  return (
    <Stack
      onClick={() => onSelectFolder(folder)}
      sx={{
        width: '236px',
        height: '56px',
        backgroundColor:
          selectedFolder?._id === folder._id ? 'green.2' : 'grey.14',
        color: selectedFolder?._id === folder._id ? 'grey.15' : 'grey.1',
        border: '1px solid',
        borderColor: 'grey.10',
        padding: '10px',
        borderRadius: '6px',
        cursor: 'pointer',
      }}
      direction="row"
      alignItems="center"
    >
      <FolderRoundedIcon
        sx={{
          fontSize: '20px',
          mr: '10px',
          color: selectedFolder?._id === folder._id ? 'grey.15' : 'grey.5',
        }}
      />
      <Tooltip title={folder.name}>
        <Typography sx={{ fontWeight: 500 }}>
          {truncate(folder.name, 16)}
        </Typography>
      </Tooltip>
      <Stack sx={{ ml: 'auto' }} onClick={(e) => e.stopPropagation()}>
        <IconButton
          onClick={openProjectMenu}
          sx={{
            alignItems: 'start',
            height: 'fit-content',
            padding: '0',
            color: selectedFolder?._id === folder._id ? 'grey.15' : 'grey.1',
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <ThreeDotsMenu
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
          anchor={menuAnchor}
          border="1px solid "
          borderRadius="8px"
          marginDivider="0 12px"
          backgroundColor="grey.15"
          hoverBackground="grey.15"
          menuItems={[
            [
              {
                text: t('common.edit'),
                textSx: {
                  color: 'grey.5',
                  fontWeight: 500,
                  fontSize: '16px',
                },
                renderIcon: () => (
                  <EditIcon sx={{ fontSize: '18px', opacity: '0.8' }} />
                ),
                onClick: () => {
                  onEdit(folder);
                },
              },
            ],
            [
              {
                text: t('common.delete'),
                textSx: {
                  color: 'red.2',
                  fontWeight: 500,
                  fontSize: '16px',
                },
                renderIcon: () => (
                  <DeleteOutlineIcon
                    sx={{ color: 'red.2', fontSize: '18px', opacity: '0.8' }}
                  />
                ),
                onClick: () => {
                  onDelete(folder);
                },
              },
            ],
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default FolderCard;
