import { useMemo, useState } from 'react';

import { TelegramButton, TelegramButtonType } from '../../interfaces/common';

const useTelegramButtonsEditor = (initialButtons: TelegramButton[][] = []) => {
  const [buttons, setButtons] = useState<TelegramButton[][]>(initialButtons);

  const onAddButtonsRow = () => {
    setButtons((prev) => [...prev, []]);
  };

  const onRemoveButtonsRow = (rowIndex: number) => {
    setButtons((prev) => prev.filter((_, index) => index !== rowIndex));
  };

  const onRemoveButton = (rowIndex: number, btnIndex: number) => {
    setButtons((prev) => {
      return prev.map((row, index) => {
        if (index === rowIndex) {
          return row.filter((_, i) => i !== btnIndex);
        }
        return row;
      });
    });
  };

  const onButtonChange = (
    rowIndex: number,
    btnIndex: number,
    key: string,
    value: string,
  ) => {
    setButtons((prev) => {
      const result: TelegramButton[][] = prev.map((row, index) => {
        if (index === rowIndex) {
          const updatedRow: TelegramButton[] = row.map(
            (button, buttonIndex) => {
              if (buttonIndex === btnIndex) {
                return {
                  ...button,
                  [key]: value,
                };
              }
              return button;
            },
          );
          return updatedRow;
        }
        return row;
      });

      return result;
    });
  };

  const onAddUrlButton = (rowIndex: number) => {
    setButtons((prev) => {
      const result: TelegramButton[][] = prev.map((row, index) => {
        if (index === rowIndex) {
          return [
            ...row,
            {
              type: TelegramButtonType.url,
              url: '',
              label: '',
            },
          ];
        }
        return row;
      });

      return result;
    });
  };

  const onAddActionButton = (rowIndex: number) => {
    setButtons((prev) => {
      const result: TelegramButton[][] = prev.map((row, index) => {
        if (index === rowIndex) {
          return [
            ...row,
            {
              type: TelegramButtonType.action,
              action: '',
              label: '',
            },
          ];
        }
        return row;
      });

      return result;
    });
  };

  const updateButtons = (buttons: TelegramButton[][]) => {
    setButtons(buttons);
  };

  const isTelegramButtonsValid = useMemo(
    () =>
      buttons.every((arr) => arr.length) &&
      buttons.every((arr) =>
        arr.every((button) =>
          button.type === 'url' ? button.url && button.label : button.label,
        ),
      ),
    [buttons],
  );

  const isEmptyButtons = useMemo(
    () =>
      buttons.every((arr) => arr.length) &&
      buttons.every((arr) =>
        arr.every((button) =>
          button.type === 'url' ? button.url && button.label : button.label,
        ),
      ),
    [buttons],
  );
  return {
    buttons,
    updateButtons,
    onAddUrlButton,
    onAddActionButton,
    onButtonChange,
    onRemoveButton,
    onRemoveButtonsRow,
    onAddButtonsRow,
    isTelegramButtonsValid,
    isEmptyButtons,
  };
};
export default useTelegramButtonsEditor;
