import { ITelegramUserAccount } from '../../telegram-user-accounts/telegram-user.interface';
import axios from '../axios';

const setTelegramAccountPassword = async (
  accountId: ITelegramUserAccount['_id'],
  password: string,
) => {
  const { data } = await axios.post(`/user-accounts/${accountId}/password`, {
    password,
  });

  return data;
};

export default setTelegramAccountPassword;
