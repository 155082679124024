import React from 'react';

const VolumeOffIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8551 0.860993C18.8551 0.535333 18.6666 0.226813 18.3752 0.0896936C18.0667 -0.0645664 17.7239 -0.0131464 17.4668 0.192533L9.87376 6.24295L18.8551 15.2243V0.860993ZM23.7469 22.5362L1.46488 0.254237C1.13065 -0.0799923 0.587313 -0.0799923 0.253083 0.254237C-0.0811466 0.588467 -0.0811466 1.1318 0.253083 1.46603L5.64532 6.85999H5.14312C4.61178 6.85999 4.149 7.09995 3.84048 7.47703C3.58338 7.76841 3.42912 8.16263 3.42912 8.57399V15.43C3.42912 16.3727 4.20042 17.144 5.14312 17.144H9.1196L17.4668 23.8114C17.621 23.9314 17.8096 24 17.9981 24C18.1181 24 18.2552 23.9657 18.3752 23.9143C18.6666 23.7772 18.8551 23.4686 18.8551 23.143V20.0698L22.5334 23.748C22.7013 23.916 22.9207 24 23.1401 24C23.3595 24 23.5789 23.916 23.7469 23.7497C24.0811 23.4138 24.0811 22.8722 23.7469 22.5362Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default VolumeOffIcon;
