import { TextField } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

type InputProps = {
  value: string;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string | undefined;
  fullWidth?: boolean;
};

const Input: FC<InputProps> = ({
  value,
  handleChangeSearch,
  placeholder,
  fullWidth = false,
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      fullWidth={fullWidth}
      sx={{
        p: '0',
        backgroundColor: 'grey.15',
        borderRadius: '6px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '6px',
          //   '& .MuiOutlinedInput-notchedOutline': {
          //     border: '1px solid',
          //     borderColor: 'grey.10',
          //   },
          //   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          //     border: '2px solid',
          //     borderColor: 'blue.2',
          //   },
          //   '&:hover .MuiOutlinedInput-notchedOutline': {
          //     border: '2px solid',
          //     borderColor: 'blue.2',
          //   },
        },
      }}
      placeholder={placeholder ? placeholder : t('common.filterPlaceholder')}
      value={value}
      onChange={handleChangeSearch}
      InputProps={{
        sx: {
          position: 'relative',
          fontWeight: 500,
          fontSize: '18px',
          '& input': {
            borderRadius: '6px',
            pt: '15px',
            pb: '15px',
            pr: '15px',
            pl: '15px',
          },
        },
      }}
    />
  );
};

export default Input;
