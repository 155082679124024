import {
  Divider,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import calculateBroadcastReceivers from '../api/broadcasts/calculate-receivers';
import createBroadcast from '../api/broadcasts/create-broadcast';
import deleteBroadcast from '../api/broadcasts/delete-broadcast';
import editBroadcast from '../api/broadcasts/edit-broadcast';
import fetchBroadcasts from '../api/broadcasts/fetch-broadcasts';
import { AppContext } from '../AppContext';

import { BotContext } from './bot.context';
import BroadcastList from './broadcasts/broadcast-list';
import BroadcastModal from './broadcasts/broadcast.modal';
import DraftsTab from './broadcasts/drafts.tab';
import {
  BroadcastParamCondition,
  BroadcastType,
  CreateBroadcast,
  IBroadcast,
} from './broadcasts/interfaces';
import { FlowTypes, IFlow } from './flows/interfaces';

interface BroadcastTab {
  label: string;
}

const BroadcastsTab: FC = () => {
  const BROADCAST_TABS: BroadcastTab[] = [
    { label: t('bot-page.broadcastTabs.drafts') },
    { label: t('bot-page.broadcastTabs.planned') },
    { label: t('bot-page.broadcastTabs.started') },
  ];

  const { bot, flows } = useContext(BotContext);
  const { triggerSnackbar } = useContext(AppContext);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [broadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [actionBroadcast, setActionBroadcast] = useState<string>('');
  const [broadcasts, setBroadcasts] = useState<IBroadcast[]>([]);
  const [name, setName] = useState('');
  const [flow, setFlow] = useState<IFlow['_id']>('');
  const [leadStatuses, setLeadStatuses] = useState<string[]>([]);
  const [paramConditions, setParamConditions] = useState<
    BroadcastParamCondition[]
  >([]);
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [calculationLoading, setCalculationLoading] = useState(false);

  const [receiversNumber, setReceiversNumber] = useState(0);

  const [currentBroadcast, setCurrentBroadcast] = useState<IBroadcast | null>(
    null,
  );
  const currentTime = new Date();

  const paramsValid = Boolean(
    paramConditions.every(({ key, values }) => Boolean(key) && values.length),
  );

  useEffect(() => {
    if (!leadStatuses.length || !bot || !paramsValid) return;

    setCalculationLoading(true);

    calculateBroadcastReceivers(bot._id, {
      statusConditions: {
        values: leadStatuses,
      },
      paramConditions,
    })
      .then(setReceiversNumber)
      .finally(() => setCalculationLoading(false));
  }, [leadStatuses, paramConditions, paramsValid]);

  useEffect(() => {
    if (!broadcastModalOpen) {
      setCurrentBroadcast(null);
    }
  }, [broadcastModalOpen]);

  const getBroadcasts = () => {
    fetchBroadcasts(bot._id).then(setBroadcasts);
  };

  const onCreateBroadcast = (data: CreateBroadcast) => {
    createBroadcast(bot._id, data)
      .then(getBroadcasts)
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorCreatingBroadcast'), 'error');
      });

    setBroadcastModalOpen(false);
  };

  const onEditBroadcast = (type: BroadcastType) => {
    if (!currentBroadcast) return;

    if (currentBroadcast.type !== BroadcastType.draft && !Boolean(startTime))
      return;

    editBroadcast(bot._id, currentBroadcast._id, {
      name,
      flow,
      statusConditions: {
        values: leadStatuses,
      },
      paramConditions,
      startTime: (startTime as Date).toISOString(),
      type,
    })
      .then(getBroadcasts)
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorEditingBroadcast'), 'error');
      });
    setBroadcastModalOpen(false);
  };

  const handleBroadcastOpenModal = () => {
    setName('');
    setFlow('');
    setLeadStatuses([]);
    setParamConditions([]);
    setStartTime(new Date());
    setActionBroadcast('new');
    setBroadcastModalOpen(true);
  };

  const onCopyBroadcastData = (broadcast: IBroadcast, action = '') => {
    setName(broadcast.name ?? '');
    setFlow(broadcast.flow ?? '');
    setLeadStatuses(broadcast.statusConditions?.values ?? []);
    setParamConditions(broadcast.paramConditions ?? []);
    setStartTime(
      broadcast.startTime ? new Date(broadcast.startTime) : new Date(),
    );
    setCurrentBroadcast({ ...broadcast });
    setBroadcastModalOpen(true);
    setActionBroadcast(action);
  };

  const onDeleteBroadcast = (broadcastId: string) => {
    deleteBroadcast(bot._id, broadcastId).then(getBroadcasts);
  };

  const onChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeFlow = (event: SelectChangeEvent) => {
    setFlow(event.target.value);
  };

  const onChangeParamKey = (index: number, key: string) => {
    setParamConditions((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          key,
        };
      });
    });
  };

  const onChangeParamValues = (
    index: number,
    values: string[],
    include: boolean,
  ) => {
    setParamConditions((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          values,
          include,
        };
      });
    });
  };

  const onDeleteCondition = (index: number) => {
    setParamConditions((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  const toggleStatus = (status: string) => {
    setLeadStatuses((prev) =>
      prev.includes(status)
        ? prev.filter((st) => st !== status)
        : [...prev, status],
    );
  };

  const onAddParamCondition = () => {
    setParamConditions((prev) => [
      ...prev,
      { key: '', values: [], include: true },
    ]);
  };

  const onChangeStartTime = (date: dayjs.Dayjs | null) => {
    setStartTime(date?.toDate() ?? new Date());
  };

  const isValid = useMemo(() => {
    if (currentBroadcast) {
      return (
        Boolean(name) &&
        Boolean(flow) &&
        paramsValid &&
        Boolean(leadStatuses.length) &&
        Boolean(startTime && dayjs(startTime) > dayjs(new Date()))
      );
    } else {
      return Boolean(name);
    }
  }, [name, flow, paramsValid, leadStatuses, startTime, currentBroadcast]);

  const onCreateClick = (type: BroadcastType) => {
    if (currentBroadcast?._id) {
      onEditBroadcast(type);
      return;
    }

    if (!isValid || !startTime) return;

    if (type === BroadcastType.draft) {
      onCreateBroadcast({
        name,
        type,
        paramConditions,
        startTime: startTime.toISOString(),
        statusConditions: {
          values: leadStatuses,
        },
      });
    } else {
      onCreateBroadcast({
        name,
        flow,
        type,
        paramConditions,
        startTime: startTime.toISOString(),
        statusConditions: {
          values: leadStatuses,
        },
      });
    }
  };

  useEffect(() => {
    getBroadcasts();
  }, [bot]);

  return (
    <Stack sx={{ height: '100%' }}>
      <Tabs
        variant="fullWidth"
        value={currentTabIndex}
        onChange={onChangeTab}
        TabIndicatorProps={{
          sx: {
            left: '0',
            width: '5px',
          },
        }}
        sx={{
          backgroundColor: 'grey.15',
          '& .MuiTabs-indicator': {
            backgroundColor: 'blue.2',
          },
          '& .MuiTab-root': {
            color: 'grey.1',
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'blue.2',
          },
        }}
      >
        {BROADCAST_TABS.map(({ label }) => (
          <Tab
            sx={{
              height: '100%',
              width: '50%',
              maxWidth: 'none',
              borderRight: '1px solid ',
              borderColor: 'grey.10',
              '&:last-child': {
                borderRight: 'none',
              },
            }}
            key={'tab-' + label}
            label={
              <Stack
                sx={{
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    fontSize: '16px',
                  }}
                >
                  {label}
                </Typography>
              </Stack>
            }
          />
        ))}
      </Tabs>

      <Divider />

      <Stack
        sx={{
          padding: '20px 20px 10px',
          height: ' 100%',
        }}
      >
        {currentTabIndex === 0 && (
          <DraftsTab
            openModal={handleBroadcastOpenModal}
            onCopy={onCopyBroadcastData}
            onEdit={onCopyBroadcastData}
            onDelete={onDeleteBroadcast}
            broadcasts={broadcasts.filter(
              (broadcast: IBroadcast) => broadcast.type === BroadcastType.draft,
            )}
          />
        )}

        {currentTabIndex === 1 && (
          <BroadcastList
            onCopy={onCopyBroadcastData}
            onEdit={onCopyBroadcastData}
            onDelete={onDeleteBroadcast}
            broadcasts={broadcasts.filter((broadcast: IBroadcast) => {
              const startTime = broadcast?.startTime
                ? new Date(broadcast?.startTime)
                : currentTime;
              return (
                broadcast.type !== BroadcastType.draft &&
                startTime > currentTime
              );
            })}
          />
        )}

        {currentTabIndex === 2 && (
          <BroadcastList
            onDelete={onDeleteBroadcast}
            onCopy={onCopyBroadcastData}
            onEdit={onCopyBroadcastData}
            broadcasts={broadcasts.filter((broadcast: IBroadcast) => {
              const startTime = broadcast?.startTime
                ? new Date(broadcast?.startTime)
                : currentTime;
              return (
                broadcast.type !== BroadcastType.draft &&
                startTime < currentTime
              );
            })}
          />
        )}

        <BroadcastModal
          _id={currentBroadcast?._id}
          type={currentBroadcast?.type}
          receiversNumber={receiversNumber}
          open={broadcastModalOpen}
          onClose={() => {
            setBroadcastModalOpen(false);
            setActionBroadcast('');
          }}
          flows={flows.filter((flow) =>
            flow.type.includes(FlowTypes.broadcast),
          )}
          name={name}
          flow={flow}
          leadStatuses={leadStatuses}
          paramConditions={paramConditions}
          startTime={startTime}
          actionBroadcast={actionBroadcast}
          onChangeName={onChangeName}
          onChangeFlow={onChangeFlow}
          onChangeParamKey={onChangeParamKey}
          onChangeParamValues={onChangeParamValues}
          onDeleteCondition={onDeleteCondition}
          toggleStatus={toggleStatus}
          onAddCondition={onAddParamCondition}
          onChangeStartTime={onChangeStartTime}
          isValid={isValid}
          onSubmit={onCreateClick}
          calculationLoading={calculationLoading}
        />
      </Stack>
    </Stack>
  );
};

export default BroadcastsTab;
