import ImageIcon from '@mui/icons-material/Image';
import { Button, Divider, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import deleteProject from '../api/projects/delete-project';
import editProject from '../api/projects/edit-project';
import leaveProject from '../api/projects/leave-project';
import { AppContext } from '../AppContext';
import { GlobalContext } from '../GlobalContext';
import { UserContext } from '../user/user.context';
import UploadCroppedImage from '../utils/uploadCroppedImage';

import CropImage from './crop-image.modal';
import IProject from './project.interface';

const MAX_NAME_LENGTH = 30;
const MAX_DESCRIPTION_LENGTH = 30;

type EditProject = {
  project: IProject | null;
};

const ProjectEdit: FC<EditProject> = ({ project }) => {
  const { triggerSnackbar } = useContext(AppContext);
  const { refetchProjects } = useContext(GlobalContext);
  const { openConfirmationDialog } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const isProjectOwner = project && user?._id === project.owner;
  const navigate = useNavigate();

  const [name, setName] = useState(project?.name);
  const [description, setDescription] = useState(project?.description);
  const [webhookUrl, setWebhookUrl] = useState(project?.webhookUrl);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [isEditImage, setIsEditImage] = useState<boolean>(false);

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onWebhookInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setWebhookUrl(value);
  };

  const onDescriptionInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(value);
    }
  };

  const editButtonDisabled =
    Boolean(!name) ||
    (Boolean(name === project?.name) &&
      Boolean(!isEditImage) &&
      Boolean(description === project?.description) &&
      webhookUrl === project?.webhookUrl);

  const onSubmitClick = async () => {
    if (!project) return;

    if (croppedImage) {
      const url = await UploadCroppedImage(croppedImage);
      editProject(project?._id, {
        name,
        description,
        webhookUrl,
        imageUrl: url,
      }).then(() => {
        triggerSnackbar(t('common.projectEdit'), 'success');
        refetchProjects();
      });
      setIsEditImage(false);
    } else {
      editProject(project?._id, {
        name,
        description,
        webhookUrl,
        imageUrl: project?.imageUrl,
      }).then(() => {
        triggerSnackbar(t('common.projectEdit'), 'success');
        refetchProjects();
      });
    }
  };

  const onDeleteClick = () => {
    openConfirmationDialog(
      `${t('projects.settings.deleteProjectText')}`,
      `${t('projects.settings.deleteProjectAgree')}`,
      () => {
        deleteProject(project?._id as string)
          .then(refetchProjects)
          .then(() => {
            navigate(`/projects`);
          });
      },
    );
  };

  const onLeaveProject = () => {
    openConfirmationDialog(
      `${t('common.logoutProject')} ${project?.name}?`,
      t('common.logout'),
      () => {
        leaveProject(project?._id as string).then(() => {
          navigate(`/projects`);
        });
      },
    );
  };

  return (
    <Stack
      sx={{
        height: '100%',
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          width: 'maxContent',
          position: ' relative',
          top: '0%',
          margin: ' -50px auto 0',
        }}
      >
        <CropImage
          setIsEditImage={setIsEditImage}
          setCroppedImage={setCroppedImage}
          isProjectOwner={isProjectOwner ?? false}
        />
        {!isProjectOwner ? (
          !!project?.imageUrl ? (
            <img
              style={{
                width: '102px',
                height: '102px',
                borderRadius: '50%',
              }}
              src={project?.imageUrl}
              alt="#"
            />
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '102px',
                height: '102px',
                borderRadius: '50%',
                backgroundColor: 'grey.10',
              }}
            >
              <ImageIcon
                sx={{
                  width: '36px',
                  height: '36px',
                }}
              />
            </Stack>
          )
        ) : !project?.imageUrl && !croppedImage ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '102px',
              height: '102px',
              borderRadius: '50%',
              backgroundColor: 'grey.10',
            }}
          >
            <ImageIcon
              sx={{
                width: '36px',
                height: '36px',
              }}
            />
          </Stack>
        ) : (
          <img
            style={{
              width: '102px',
              height: '102px',
              borderRadius: '50%',
            }}
            src={croppedImage ? croppedImage : project?.imageUrl}
            alt="#"
          />
        )}

        <Stack
          sx={{
            position: 'absolute',
            right: '-5px',
            bottom: '5px',
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#DEFF82" />
            <path
              d="M6.75 15.185V16.9583C6.75 17.1217 6.87833 17.25 7.04167 17.25H8.815C8.89083 17.25 8.96667 17.2208 9.01917 17.1625L15.3892 10.7983L13.2017 8.61083L6.8375 14.975C6.77917 15.0333 6.75 15.1033 6.75 15.185ZM17.0808 9.10666C17.3083 8.87916 17.3083 8.51166 17.0808 8.28416L15.7158 6.91916C15.4883 6.69166 15.1208 6.69166 14.8933 6.91916L13.8258 7.98666L16.0133 10.1742L17.0808 9.10666Z"
              fill="#292829"
            />
          </svg>
        </Stack>
      </Stack>
      <TextField
        value={name}
        onChange={onNameInput}
        placeholder={t('common.nameLabel')}
        sx={{
          mt: '32px',
          mb: '16px',
        }}
        disabled={!isProjectOwner}
      />
      <TextField
        value={description}
        onChange={onDescriptionInput}
        placeholder={t('common.descriptionLabel')}
        sx={{
          mb: '16px',
        }}
        disabled={!isProjectOwner}
      />
      <TextField
        value={webhookUrl}
        onChange={onWebhookInput}
        placeholder="Webhook URL"
        sx={{
          mb: '16px',
        }}
        disabled={!isProjectOwner}
      />
      <Divider sx={{ margin: 'auto 0 24px' }} />
      {isProjectOwner && (
        <Button
          variant="contained"
          sx={{
            width: '100%',
            height: '56px',
            mb: '20px',
          }}
          disabled={editButtonDisabled}
          className={'brightGreen'}
          onClick={onSubmitClick}
        >
          {t('common.save')}
        </Button>
      )}
      <Button
        className={'red'}
        sx={{
          gap: '3px',
          width: '100%',
          height: '56px',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '140%',
        }}
        onClick={isProjectOwner ? onDeleteClick : onLeaveProject}
      >
        {isProjectOwner ? t('common.delete') : t('common.logout')}
      </Button>
    </Stack>
  );
};

export default ProjectEdit;
