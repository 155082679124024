import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PhotoPreview from './photo.preview';
import VideoPreview from './video.preview';

interface MediaPreviewProps {
  file: Blob;
  fileSrc?: string;
  onCancel: () => void;
  onUpload: () => void;
}

const IMAGE_TYPES = ['image/png', 'image/jpg', 'image/webp', 'image/jpeg'];
const VIDEO_TYPES = ['video/mp4'];

const MediaPreview: FC<MediaPreviewProps> = ({
  file,
  fileSrc,
  onCancel,
  onUpload,
}) => {
  const { t } = useTranslation();

  const isImage = IMAGE_TYPES.includes(file.type) && Boolean(fileSrc);
  const isVideo = VIDEO_TYPES.includes(file.type) && Boolean(fileSrc);

  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      {isImage && (
        <PhotoPreview
          imageSrc={fileSrc as string}
          imgStyle={{
            height: '150px',
            width: 'max-content',
            objectFit: 'contain',
            margin: '0 auto',
          }}
        />
      )}
      {isVideo && <VideoPreview videoSrc={fileSrc as string} />}

      <Stack
        direction="row"
        sx={{
          mt: '35px',
          width: '100%',
          gap: '10px',
        }}
      >
        <Button
          className="green"
          sx={{
            width: '100%',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onUpload();
          }}
        >
          {t('uploadMedia.upload')}
        </Button>
        <Button
          className="red"
          sx={{
            width: '100%',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          {t('common.cancel')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default MediaPreview;
