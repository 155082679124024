import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import fetchProjects from './api/projects/fetch-projects';
import IProject from './projects/project.interface';

interface IGlobalContext {
  projects: IProject[];
  refetchProjects: () => void;
  selectProject: (_id: string) => void;
  activeFlowName: string;
  onSelectFlowName: (flowName: string, flowId: string) => void;
  resetFlowName: () => void;
  activeStatisticSection: string;
  activeChannelNameStatistic: string;
  toggleActiveStatisticSection: (activeSection: string) => void;
  toggleActiveChannelNameStatistic: (name: string) => void;
}

export const GlobalContext = createContext<IGlobalContext>(
  {} as IGlobalContext,
);

const GlobalContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [activeFlowName, setActiveFlowName] = useState<string>('');
  const [activeStatisticSection, setActiveStatisticSection] =
    useState<string>('Bot');
  const [activeChannelNameStatistic, setActiveChannelNameStatistic] =
    useState<string>('');

  const navigate = useNavigate();

  const getProjects = () =>
    new Promise((resolve) => {
      fetchProjects().then((res: IProject[]) => {
        setProjects(res);
        resolve(0);
      });
    });

  const selectProject = (_id: string) => {
    navigate(`/project/${_id}}`);
  };

  const onSelectFlowName = (flowName: string, flowId: string) => {
    setActiveFlowName(flowName);
    sessionStorage.setItem('flowId', flowId);
  };

  const resetFlowName = () => {
    setActiveFlowName('');
    sessionStorage.removeItem('flowId');
  };

  const toggleActiveStatisticSection = (activeSection: string) => {
    setActiveStatisticSection(activeSection);
  };

  const toggleActiveChannelNameStatistic = (name: string) => {
    setActiveChannelNameStatistic(name);
  };

  useEffect(() => {
    if (activeStatisticSection === 'Bot') {
      setActiveChannelNameStatistic('');
    }
  }, [activeStatisticSection]);

  useEffect(() => {
    getProjects().then(() => setLoading(false));
  }, []);

  if (loading) return <div>Global loading</div>;

  return (
    <GlobalContext.Provider
      value={{
        projects,
        refetchProjects: getProjects,
        selectProject,
        activeFlowName,
        onSelectFlowName,
        resetFlowName,
        activeStatisticSection,
        toggleActiveStatisticSection,
        toggleActiveChannelNameStatistic,
        activeChannelNameStatistic,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
