/* eslint-disable @typescript-eslint/ban-ts-comment */
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer';
import { CSSObject, styled } from '@mui/material/styles';
import { t } from 'i18next';
import React, { FC, useContext, useEffect } from 'react';

import { drawerWidth, NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import { GlobalContext } from '../GlobalContext';

import { IBotTab } from './bot.page';
import StatisticAccordion from './statistics/statistic.accordion';

interface DrawerMixinProps {
  theme: Theme;
  open: boolean;
}

const openedMixin = ({ theme }: DrawerMixinProps): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = ({ theme }: DrawerMixinProps): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `80px`,
});

interface CustomDrawerProps extends DrawerProps {
  open: boolean;
}

const SDrawer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'open',
})<CustomDrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
  flexShrink: 0,
  zIndex: 9,
  // @ts-ignore
  backgroundColor: theme.palette.grey['14'],
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin({ theme, open }),
    '& .MuiDrawer-paper': openedMixin({ theme, open }),
  }),
  ...(!open && {
    ...closedMixin({ theme, open }),
    '& .MuiDrawer-paper': closedMixin({ theme, open }),
  }),
}));

interface BotDrawerProps {
  tabs: IBotTab[];
  open: boolean;
  onToggle: () => void;
  currentTabIndex: number;
  onTabChange: (_: React.SyntheticEvent, newIndex: number) => void;
}

const Drawer: FC<BotDrawerProps> = ({
  tabs,
  currentTabIndex,
  onTabChange,
  open,
  onToggle,
}) => {
  const { activeFlowName } = useContext(GlobalContext);

  useEffect(() => {
    if (activeFlowName && open) {
      onToggle();
    }
  }, [activeFlowName]);
  return (
    <SDrawer open={open}>
      <Tabs
        orientation="vertical"
        value={currentTabIndex}
        onChange={onTabChange}
        TabIndicatorProps={{
          sx: { left: '0', width: '5px' },
        }}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
          '& .MuiTabs-scroller': {
            overflow: 'auto!important',
          },
        }}
      >
        {tabs.map(({ label, icon, disabled }, index) => {
          const isStatistic = Boolean(currentTabIndex === 4);
          const isActive = Boolean(currentTabIndex === index);
          return (
            <Tab
              key={'tab-' + label}
              disableRipple
              disableFocusRipple
              disabled={disabled}
              sx={{
                borderBottom: '1px solid ',
                borderColor: 'grey.13',
                p: '4px',
                minWidth: 'unset',
                alignItems: 'start',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: disabled
                    ? 'transparent'
                    : isStatistic
                    ? 'transparent'
                    : 'grey.13',
                },
              }}
              label={
                open ? (
                  <Stack
                    direction="row"
                    gap="8px"
                    sx={{
                      width: '100%',
                      borderRadius: '8px',
                      padding:
                        label === t('bot-page.tabs.statistics')
                          ? '0'
                          : '20px 24px',
                      opacity: disabled ? 0.3 : 1,
                      minHeight: '66px',
                      transition: 'all 0.2s',
                      backgroundColor: !isActive
                        ? 'transparent'
                        : isStatistic
                        ? 'transparent'
                        : 'grey.10',
                      color: isActive ? 'green.2' : 'grey.1',
                    }}
                  >
                    {label !== t('bot-page.tabs.statistics') ? icon : null}
                    {label === t('bot-page.tabs.statistics') ? (
                      <StatisticAccordion
                        isStatistic={currentTabIndex === 4}
                        icon={icon}
                        currentTabIndex={currentTabIndex}
                        index={index}
                      />
                    ) : (
                      <Typography
                        sx={{
                          textTransform: 'capitalize',
                          fontWeight: 600,
                          fontSize: '18px',
                          lineHeight: '140%',
                        }}
                      >
                        {label}
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <Stack
                    justifyContent="center"
                    direction="row"
                    sx={{
                      width: '100%',
                      padding: '20px',
                      borderRadius: '8px',
                      opacity: disabled ? 0.3 : 1,
                      transition: 'all 0.2s',
                      backgroundColor: isActive ? 'grey.10' : 'transparent',
                      color: isActive ? 'green.2' : 'grey.1',
                    }}
                    onClick={() => {
                      if (label === t('bot-page.tabs.statistics')) {
                        onToggle();
                      }
                    }}
                  >
                    {icon}
                  </Stack>
                )
              }
            />
          );
        })}
      </Tabs>
      <Divider sx={{ mt: 'auto', backgroundColor: 'grey.13' }} />
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        sx={{ padding: '18px', cursor: 'pointer' }}
        onClick={onToggle}
      >
        <IconButton>
          {!open ? (
            <ArrowForwardIosRoundedIcon />
          ) : (
            <ArrowBackIosNewRoundedIcon />
          )}
        </IconButton>
        {open && (
          <Typography sx={{ mr: '50px', fontWeight: 600 }}>
            {t('drawer.collapse')}
          </Typography>
        )}
      </Stack>
    </SDrawer>
  );
};

export default Drawer;
