import { Stack } from '@mui/material';
import React, { FC } from 'react';

import axiomaLogo from '../../assets/images/axioma.png';

interface LogoProps {
  width?: number;
  onClick: () => void;
}

const Logo: FC<LogoProps> = ({ width = 110, onClick }) => {
  return (
    <Stack
      sx={{
        width,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <img
        style={{
          width: '100%',
          height: 'auto',
        }}
        src={axiomaLogo}
        alt="logo"
      />
    </Stack>
  );
};

export default Logo;
