import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';

import deleteProjectMedia from '../../api/projects/delete-media';
import { AppContext } from '../../AppContext';
import { IMedia, MediaType } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { UserContext } from '../../user/user.context';

import MediaUploadModal from './media-upload.modal';
import VideoNoteItem from './video-note.item';

interface VideoNoteModalProps {
  open: boolean;
  selectedMedia: IMedia[];
  onSelectMedia: (newMedia: IMedia) => void;
  onClose: () => void;
  onRemoveMedia: (_id: string) => void;
}

const VideoNoteModal: FC<VideoNoteModalProps> = ({
  open,
  onClose,
  selectedMedia,
  onSelectMedia,
  onRemoveMedia,
}) => {
  const { projectMedia, project, refetchMedia } = useContext(ProjectContext);
  const { triggerSnackbar, openConfirmationDialog } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const [onlyOwning, setOnlyOwning] = useState(true);
  const [displayedMedia, setDisplayedMedia] = useState<IMedia[]>([]);

  const onDelete = (mediaId: string) => {
    openConfirmationDialog(
      `${t('common.deleteMediaVideoNote')} ?`,
      t('common.delete'),
      () => {
        deleteProjectMedia(project._id, mediaId)
          .then(() => {
            refetchMedia();
            onRemoveMedia(mediaId);
          })
          .catch(() => triggerSnackbar(t('triggerSnackbar.error'), 'error'));
      },
    );
  };

  const toggleOnlyOwning = () => {
    setOnlyOwning((prev) => !prev);
  };

  useEffect(() => {
    if (!user) return;

    if (onlyOwning) {
      setDisplayedMedia(projectMedia.filter(({ owner }) => owner === user._id));
    } else {
      setDisplayedMedia(projectMedia);
    }
  }, [onlyOwning, projectMedia]);

  return (
    <MediaUploadModal
      open={open}
      onClose={onClose}
      selectedMedia={selectedMedia}
      onSelectMedia={onSelectMedia}
      onlyOwning={onlyOwning}
      toggleOnlyOwning={toggleOnlyOwning}
    >
      <Stack
        direction="row"
        gap="10px"
        sx={{
          flexWrap: 'wrap',
          p: '6px',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {displayedMedia
          .filter((media) => media.type === MediaType.videoNote)
          .map((media) => {
            const isSelected = selectedMedia[0]?._id === media._id;

            return (
              <Fragment key={media._id}>
                {Boolean(!media._id) ? (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '14px',
                      opacity: 0.4,
                    }}
                  >
                    {t('flowNodes.addVideoFile')}
                  </Typography>
                ) : (
                  <VideoNoteItem
                    videoUrl={media.url}
                    name={media.name ?? ''}
                    isSelected={isSelected}
                    onSelect={() => {
                      onSelectMedia(media);
                    }}
                    onDelete={() => onDelete(media._id)}
                  />
                )}
              </Fragment>
            );
          })}
      </Stack>
    </MediaUploadModal>
  );
};

export default VideoNoteModal;
