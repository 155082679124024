import { Stack } from '@mui/material';
import React, { FC, useContext, useState } from 'react';

import fetchTelegramBotStatistics from '../api/bots/fetch-bot-statistics';
import fetchChannelStatistics from '../api/channels/fetch-channel-statistics';
import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import { GlobalContext } from '../GlobalContext';

import BotStatistic from './statistics/bot.statistic';
import ChannelsStatistics from './statistics/channels.statistics';
import { ITelegramBot } from './telegram-bot.interface';

interface StatisticsTabProps {
  botId: ITelegramBot['_id'];
}

const StatisticsTab: FC<StatisticsTabProps> = ({ botId }) => {
  const { activeStatisticSection } = useContext(GlobalContext);

  const [statistics, setStatistics] = useState<(string | number)[][]>([]);
  const [loadingStatistics, setLoadingStatistics] = useState<boolean>(false);

  const onFetchStatistics = (
    params: string[],
    endDate: string,
    startDate: string,
    channelId: string | null,
    // endDate2: string,
    // startDate2: string,
  ) => {
    if (!endDate) return;

    setLoadingStatistics(true);

    if (channelId) {
      fetchChannelStatistics(botId, channelId, {
        params,
        endDate,
        startDate,
        // endDate2,
        // startDate2,
      })
        .then(setStatistics)
        .finally(() => setLoadingStatistics(false));
    } else {
      fetchTelegramBotStatistics(botId, {
        params,
        endDate,
        startDate,
        // endDate2,
        // startDate2,
      })
        .then(setStatistics)
        .finally(() => setLoadingStatistics(false));
    }
  };

  // const onFetchChannelStatistics = (
  //   channelId: string,
  //   params: string[],
  //   endDate: string,
  //   startDate: string,
  //   endDate2: string,
  //   startDate2: string,
  // ) => {
  //   if (!endDate) return;
  //
  //   fetchChannelStatistics(botId, channelId, {
  //     params,
  //     endDate,
  //     startDate,
  //     endDate2,
  //     startDate2,
  //   })
  //     .then(setStatistics)
  //     .finally(() => setLoadingStatistics(false));
  // };

  return (
    <Stack
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        margin: '40px 0',
        width: '100%',
        maxWidth: '1200px',
      }}
    >
      {activeStatisticSection === 'Bot' ? (
        <BotStatistic
          onFetchStatistics={onFetchStatistics}
          statistics={statistics}
          loadingStatistics={loadingStatistics}
        />
      ) : (
        <ChannelsStatistics
          botId={botId}
          onFetchStatistics={onFetchStatistics}
          statistics={statistics}
          loadingStatistics={loadingStatistics}
        />
      )}
    </Stack>
  );
};

export default StatisticsTab;
