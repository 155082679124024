import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC, SyntheticEvent, useState } from 'react';
import ReactPlayer from 'react-player';

import { IMedia } from '../../interfaces/common';

interface VideoItemProps {
  media: IMedia;
  isSelected: boolean;
  onSelectMedia: (media: IMedia) => void;
  number: number;
  onDelete: () => void;
}

const VideoItem: FC<VideoItemProps> = ({
  media,
  onSelectMedia,
  isSelected,
  number,
  onDelete,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = (e: SyntheticEvent) => {
    e.stopPropagation();

    setIsPlaying((prev) => !prev);
  };

  const onVideoEnd = () => {
    setIsPlaying(false);
  };

  return (
    <Stack
      key={media._id}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '6px',
        border: '2px solid',
        borderColor: isSelected ? 'green.2' : 'grey.10',
      }}
      onClick={() => {
        onSelectMedia(media);
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          ':hover': {
            '.pauseButton': {
              display: 'block',
            },
          },
        }}
      >
        <ReactPlayer
          url={media.url}
          width={96}
          height={96}
          playing={isPlaying}
          onEnded={onVideoEnd}
        />
        <Button
          className="red"
          sx={{
            width: '24px',
            minWidth: '24px',
            height: '24px',
            borderRadius: '2px!important',
            position: 'absolute',
            bottom: '0',
            right: '0',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <DeleteIcon sx={{ width: '16px', height: '16px' }} />
        </Button>
        {!isPlaying && (
          <PlayCircleRoundedIcon
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '48px',
              cursor: 'pointer',
            }}
            onClick={togglePlay}
          />
        )}

        {isPlaying && (
          <PauseCircleFilledRoundedIcon
            className="pauseButton"
            sx={{
              display: 'none',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '48px',
              cursor: 'pointer',
            }}
            onClick={togglePlay}
          />
        )}
      </Stack>

      {isSelected && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            backgroundColor: 'green.2',
            top: 0,
            right: 5,
            width: '20px',
            height: '20px',
            borderBottomLeftRadius: '3px',
            borderBottomRightRadius: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'grey.14',
            }}
          >
            {number}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default VideoItem;
