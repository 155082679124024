import { Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import isSubmitting from '../../utils/isSubmitting';
import { MessengerContext, TelegramDialog } from '../messenger.context';

type DialogDescriptionProps = {
  dialog: TelegramDialog;
};
const DialogDescription: FC<DialogDescriptionProps> = ({ dialog }) => {
  const { setDialogDescription } = useContext(MessengerContext);
  const ref = useRef<HTMLInputElement>(null);

  const [description, setDescription] = useState<string>(dialog.description);

  const onChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDescription(value);
  };

  const sendNewDescription = () => {
    setDialogDescription(dialog._id, description);
  };

  useEffect(() => {
    setDescription(dialog.description);
  }, [dialog]);
  return (
    <Stack
      direction="row"
      sx={{
        margin: '10px 0',
        minHeight: '94px',
        height: 'minContent',
      }}
    >
      <TextField
        fullWidth
        multiline
        minRows={2}
        maxRows={7}
        sx={{ height: '100%', border: 'none' }}
        InputProps={{
          sx: {
            '*.MuiOutlinedInput-notchedOutline': {},
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'green.2',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'green.2',
            },
            height: '100%',
            p: '4px 8px',
            maxHeight: '100%',
            borderRadius: '6px',
            backgroundColor: 'grey.15',
          },
        }}
        inputProps={{
          maxLength: '400',
          sx: {
            border: 'none',
            height: '100%',
            padding: '4px',
            width: '100%',
          },
        }}
        inputRef={ref}
        value={description}
        placeholder={t('messenger.currentDialog.note.placeholderInput')}
        onKeyDown={(e) => {
          if (!isSubmitting(e)) return;
          ref?.current?.blur();
        }}
        onChange={onChangeDescription}
        onBlur={sendNewDescription}
      />
    </Stack>
  );
};

export default DialogDescription;
