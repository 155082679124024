import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import truncate from '../../utils/truncate';

import VideoNotePreview from './video-note.preview';

interface VoiceItemProps {
  videoUrl: string;
  name: string;
  isSelected: boolean;
  onSelect: () => void;
  onDelete: () => void;
}

const VideoNoteItem: FC<VoiceItemProps> = ({
  videoUrl,
  name,
  isSelected,
  onSelect,
  onDelete,
}) => {
  return (
    <Stack>
      <Stack
        sx={{
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          border: '2px solid',
          borderColor: isSelected ? 'green.2' : 'grey.10',
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={onSelect}
      >
        <VideoNotePreview
          videoSrc={videoUrl}
          playerHeight="96px"
          playerWidth="96px"
        />
        <Button
          className="red"
          sx={{
            width: '24px',
            minWidth: '24px',
            height: '24px',
            borderRadius: '2px!important',
            position: 'absolute',
            bottom: '0',
            left: 'calc(50% - 12px)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <DeleteIcon sx={{ width: '16px', height: '16px' }} />
        </Button>
      </Stack>
      <Typography
        sx={{
          color: 'grey.4',
          textAlign: 'center',
          fontSize: '12px',
          fontWeight: 500,
          mt: '5px',
        }}
      >
        {truncate(name, 20)}
      </Typography>
    </Stack>
  );
};

export default VideoNoteItem;
