import { Avatar, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import buetifyMilliseconds from '../../utils/buetifyMilliseconds';
import truncate from '../../utils/truncate';
import { TelegramDialog } from '../messenger.context';

interface DialogItemProps {
  dialog: TelegramDialog;
  onSelect: () => void;
  isActive: boolean;
  lastMessageDate: string | null;
}

const NotSeenSince = ({ date }: { date: Date | null }) => {
  const [notSeenSince, setNotSeenSince] = useState('');

  useEffect(() => {
    if (!date) return;
    const setInfo = () => {
      const now = new Date();
      const notSeenDate = new Date(date);

      setNotSeenSince(
        buetifyMilliseconds(now.getTime() - notSeenDate.getTime()),
      );
    };

    setInfo();
    const timerId = setInterval(setInfo, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [date]);

  if (!date)
    return (
      <Stack
        sx={{
          width: '35px',
          borderRadius: '8px',
          px: '2px',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          sx={{
            fontSize: '11px',
            color: 'grey.15',
          }}
        ></Typography>
      </Stack>
    );

  return (
    <Stack
      sx={{
        width: '35px',
        height: '20px',
        borderRadius: '8px',
        backgroundColor: 'orange',
        px: '2px',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        sx={{
          fontSize: '11px',
          color: 'grey.15',
        }}
      >
        {notSeenSince}
      </Typography>
    </Stack>
  );
};

const DialogItem: FC<DialogItemProps> = ({
  dialog,
  onSelect,
  isActive,
  lastMessageDate,
}) => {
  return (
    <Stack
      sx={{
        cursor: 'pointer',
        backgroundColor: 'grey.13',
        border: '1px solid',
        borderColor: dialog.banned ? 'red.1' : 'grey.10',
        padding: '1px',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
      }}
      onClick={onSelect}
    >
      <Stack
        gap="9px"
        alignItems="center"
        direction="row"
        sx={{
          backgroundColor: isActive
            ? dialog.banned
              ? 'red.1'
              : 'grey.10'
            : 'grey.13',
          padding: '10px',
          borderRadius: '8px',
        }}
      >
        <Stack>
          <Avatar src={dialog.avatarUrl} />
        </Stack>
        <Stack>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              color: isActive ? 'green.2' : 'white',
            }}
          >
            {truncate(dialog.firstName, 13)}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: 'grey.secondary',
            }}
          >
            {lastMessageDate} ago
          </Typography>
        </Stack>
        <Stack
          gap="3px"
          alignItems="center"
          sx={{
            ml: 'auto',
            mr: '3px',
          }}
        >
          {Boolean(dialog.notSeenMessagesCount) && (
            <Stack
              sx={{
                minWidth: '20px',
                height: '20px',
                borderRadius: '12px',
                backgroundColor: 'blue.2',
                pt: '3px',
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{
                  lineHeight: 0.8,
                  mx: '5px',
                }}
              >
                {dialog.notSeenMessagesCount}
              </Typography>
            </Stack>
          )}
          <NotSeenSince date={dialog.notSeenSince} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DialogItem;
