import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReactFlow, {
  Background,
  BackgroundVariant,
  MiniMap,
  NodeToolbar,
  ReactFlowProvider,
} from 'reactflow';

import { darkThemeColors } from '../common/theme/dark.theme';

import { FlowNodeTypes } from './flows/interfaces';

const FlowNodeTab = () => {
  return (
    <ReactFlowProvider>
      <ReactFlow
        defaultNodes={[]}
        defaultEdges={[]}
        defaultEdgeOptions={undefined}
        nodes={[]}
        edges={[]}
        style={{ background: darkThemeColors.grey['15'] }}
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: 0.5,
        }}
        minZoom={0.1}
        maxZoom={1}
      >
        <MiniMap
          style={{
            backgroundColor: darkThemeColors.grey['14'],
          }}
          pannable
          nodeBorderRadius={30}
          nodeColor={(node) => {
            if (node.type === FlowNodeTypes.start)
              return darkThemeColors.grey['2'];

            return darkThemeColors.green['1'];
          }}
        />
        <NodeToolbar />
        <Background
          variant={BackgroundVariant.Dots}
          gap={40}
          size={2}
          color={darkThemeColors.grey['10']}
        />
      </ReactFlow>
    </ReactFlowProvider>
  );
};

export default FlowNodeTab;
