import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import { ITelegramUserAccess } from '../../telegram-user-accounts/telegram-user.interface';
import axios from '../axios';

const updateTelegramUserAccesses = async (
  userAccountId: string,
  accesses: ITelegramUserAccess[],
) => {
  const { data } = await axios.patch(
    `/user-accounts/${userAccountId}/accesses`,
    {
      accesses,
    },
  );

  return data as ITelegramBot;
};

export default updateTelegramUserAccesses;
