import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import MediationRoundedIcon from '@mui/icons-material/MediationRounded';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import React, {
  FC,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReactFlow, {
  Background,
  BackgroundVariant,
  MiniMap,
  Node,
  NodeToolbar,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
  useUpdateNodeInternals,
} from 'reactflow';

import 'reactflow/dist/style.css';
import fetchActiveChannels from '../../api/channels/fetch-active-channels';
import { NAVBAR_HEIGHT_PX } from '../../common/constants/constants';
import MediaModal from '../../common/media/media.modal';
import VideoNoteModal from '../../common/media/video-notes.modal';
import VoiceModal from '../../common/media/voice.modal';
import { darkThemeColors } from '../../common/theme/dark.theme';
import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';
import {
  IMedia,
  TelegramButton,
  TelegramMessageType,
} from '../../interfaces/common';
import useMenu from '../../utils/hooks/useMenu';
import useNewTelegramMessage from '../../utils/hooks/useNewTelegramMessage';
import useThemeColors from '../../utils/hooks/useThemeColors';
import { BotContext } from '../bot.context';
import { ITelegramChannelWithActivators } from '../channels/interfaces';

import ConnectionEdge from './connection-edge';
import FlowControls from './flow-controls';
import ActionsNode from './flow-nodes/actions-node';
import ChatActionNode from './flow-nodes/chat-action.node';
import ConditionsNode from './flow-nodes/conditions.node';
import DelayNode from './flow-nodes/delay.node';
import MenuNode from './flow-nodes/menu.node';
import StartNode, { IStartNodeData } from './flow-nodes/start.node';
import TelegramMessageNode, {
  ITelegramMessageNodeData,
} from './flow-nodes/telegram-message-node';
import {
  FlowNodeTypes,
  IActionsFlowNodeData,
  IChatActionFlowNodeData,
  IConditionsFlowNodeData,
  IDelayFlowNodeData,
  IFlow,
  IFlowNodeExtended,
  NodeData,
} from './interfaces';
import ActionsEditor from './nodes-editors/actions/actions.editor';
import ChatActionEditor from './nodes-editors/chat-action.editor';
import ConditionsEditor from './nodes-editors/conditions/conditions.editor';
import DelayEditor from './nodes-editors/delay.editor';
import TelegramMessageEditor from './nodes-editors/telegram-message.editor';
import resetActionButtonsTargets from './utils/resetActionButtonsTargets';
import useDeleteEdge from './utils/useDeleteEdge';
import useOnNodesConnect from './utils/useOnNodesConnect';
import useReactFlowEditor from './utils/useReactFlowEditor';
import useUpdateEdges from './utils/useUpdateEdges';
import UseUpdateNodes from './utils/useUpdateNodes';

export const getConnectionLineStyle = (colors: typeof darkThemeColors) => ({
  stroke: colors.green['2'],
  strokeWidth: '3px',
  strokeDasharray: '5',
});

const snapGrid: [number, number] = [10, 10];

const nodeTypes = {
  [FlowNodeTypes.telegramMessage]: TelegramMessageNode,
  [FlowNodeTypes.start]: StartNode,
  [FlowNodeTypes.actions]: ActionsNode,
  [FlowNodeTypes.delay]: DelayNode,
  [FlowNodeTypes.conditions]: ConditionsNode,
  [FlowNodeTypes.chatAction]: ChatActionNode,
  [FlowNodeTypes.menu]: MenuNode,
};

const edgeTypes = {
  connection: ConnectionEdge,
};

interface FlowNodesEditorProps {
  flowNodes: IFlowNodeExtended[];
  flow: IFlow;
  refetchFlow: () => void;
  onBack: () => void;
}

type PositionType = {
  x: number;
  y: number;
};

const Editor: FC<FlowNodesEditorProps> = ({
  flowNodes,
  flow,
  refetchFlow,
  onBack,
}) => {
  const themeColors = useThemeColors();
  const { anchorEl, menuOpen, openMenu, closeMenu } = useMenu();
  const { bot, flows } = useContext(BotContext);

  const [channels, setChannels] = useState<ITelegramChannelWithActivators[]>(
    [],
  );

  const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [initialNodes, setInitialNodes] = useNodesState<
    ITelegramMessageNodeData | IStartNodeData
  >([]);

  const [centerPosition, setCenterPosition] = useState<PositionType>({
    x: 0,
    y: 0,
  });

  const updateNodeInternals = useUpdateNodeInternals();

  const width = useStore((state) => state.width);
  const height = useStore((state) => state.height);
  const transform = useStore((state) => state.transform);

  useEffect(() => {
    fetchActiveChannels(bot._id).then(setChannels);
  }, []);

  useEffect(() => {
    const [x, y, zoom] = transform;

    const centerX = (-x + width / 2) / zoom;
    const centerY = (-y + height / 2) / zoom;
    setCenterPosition({
      x: centerX,
      y: centerY,
    });
  }, [width, height, transform]);

  const {
    newMessageText,
    onChangeNewMessageText,
    media,
    onSelectMedia,
    onRemoveMedia,
    onButtonChange,
    onRemoveButton,
    onRemoveButtonsRow,
    onAddButtonsRow,
    onAddActionButton,
    onAddUrlButton,
    buttons,
    newMessageValid,
    clearMessageState,
    onChangeInitialMessage,
    addNewButtonsRowDisabled,
    messageType,
    onMessageTypeChange,
  } = useNewTelegramMessage();

  const [mediaModalOpen, setMediaModalOpen] = useState<boolean>(false);
  const [voiceModalOpen, setVoiceModalOpen] = useState<boolean>(false);
  const [videoNotesModalOpen, setVideoNotesModalOpen] =
    useState<boolean>(false);

  const [initialTasks, setInitialTasks] = useState<
    IActionsFlowNodeData['tasks']
  >([]);
  const [initialDelay, setInitialDelay] = useState<IDelayFlowNodeData>({
    delayForSeconds: 0,
    applyTimeRange: false,
    days: [],
    startTime: '',
    endTime: '',
    timezone: '',
  });
  const [initialConditions, setInitialConditions] = useState<
    IConditionsFlowNodeData['conditions']
  >([]);
  const [initialChatAction, setInitialChatAction] =
    useState<IChatActionFlowNodeData>({
      actionType: 'typing',
      duration: 1,
    });

  const [editingTelegramMessageNodeId, setEditingTelegramMessageNodeId] =
    useState('');
  const [editingActionsNodeId, setEditingActionsNodeId] = useState('');
  const [editingDelayNodeId, setEditingDelayNodeId] = useState('');
  const [editingConditionsNodeId, setEditingConditionsNodeId] = useState('');
  const [editingChatActionNodeId, setEditingChatActionNodeId] = useState('');

  const [currentNewNodeId, setCurrentNewNodeId] = useState<string | null>(null);
  const [activeConnectEdge, setActiveConnectEdge] = useState<boolean>(false);
  const [isPasteBuffer, setIsPasteBuffer] = useState<boolean>(false);

  const openMediaModalBuffer = () => {
    setIsPasteBuffer(true);
  };

  useEffect(() => {
    if (editingTelegramMessageNodeId && isPasteBuffer) {
      setMediaModalOpen(true);
    }
  }, [editingTelegramMessageNodeId, isPasteBuffer]);

  const onDeleteNode = (nodeId: string) => {
    setNodes((prev) => [...prev.filter(({ id }) => nodeId !== id)]);
  };

  const unselectAllNodes = () => {
    setNodes((prev) => [
      ...prev.map((node) => ({
        ...node,
        selected: false,
        data: {
          ...node.data,
        },
      })),
    ]);
  };

  const exitEditor = () => {
    clearMessageState();
    setEditingTelegramMessageNodeId('');
    setInitialTasks([]);
    setEditingActionsNodeId('');
    setInitialDelay({
      delayForSeconds: 0,
      applyTimeRange: false,
      days: [],
      startTime: '',
      endTime: '',
      timezone: '',
    });
    setInitialChatAction({ actionType: 'typing', duration: 1 });

    setEditingDelayNodeId('');
    setInitialConditions([]);
    setEditingConditionsNodeId('');
    setEditingChatActionNodeId('');
  };

  const openNodeEditor = (type: FlowNodeTypes, nodeId: string) => {
    setEditingTelegramMessageNodeId('');
    setEditingActionsNodeId('');
    setEditingDelayNodeId('');
    setEditingConditionsNodeId('');
    setEditingChatActionNodeId('');

    switch (type) {
      case FlowNodeTypes.telegramMessage: {
        setEditingTelegramMessageNodeId(nodeId);
        break;
      }

      case FlowNodeTypes.actions: {
        setEditingActionsNodeId(nodeId);
        break;
      }

      case FlowNodeTypes.delay: {
        setEditingDelayNodeId(nodeId);
        break;
      }

      case FlowNodeTypes.conditions: {
        setEditingConditionsNodeId(nodeId);
        break;
      }

      case FlowNodeTypes.chatAction: {
        setEditingChatActionNodeId(nodeId);
      }
    }
  };

  const onNodePositionChange = (nodeId: string, newPosition: PositionType) => {
    setNodes((prev) => {
      const result = [];

      for (const node of prev) {
        if (node.id !== nodeId) {
          result.push(node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              position: newPosition,
            },
          });
        }
      }

      return result;
    });
  };

  const deleteEdge = useDeleteEdge(setNodes);
  const updateEdges = useUpdateEdges(setEdges, deleteEdge);

  useEffect(() => {
    updateEdges(nodes);
  }, [nodes]);

  const onAddActionsNode = (
    initialTasks: IActionsFlowNodeData['tasks'] = [],
    position: PositionType = { x: 0, y: 0 },
    newNodeId?: string,
  ) => {
    const newId = newNodeId ? newNodeId : Date.now().toString();

    unselectAllNodes();
    setEditingActionsNodeId(newId);
    setNodes((prev) => [
      ...prev,
      {
        id: newId,
        type: FlowNodeTypes.actions,
        selected: true,
        position: {
          x: position.x === 0 ? centerPosition.x : position.x,
          y: position.y === 0 ? centerPosition.y : position.y,
        },
        dragHandle: '.dragger',
        data: {
          _id: newId,
          data: {
            tasks: initialTasks,
          },
          position,
          next: null,
          onDelete: () => {
            onDeleteNode(newId);
            setEditingActionsNodeId('');
          },
          exitEditor,
          onNodePositionChange,
          onSelect: (tasks: IActionsFlowNodeData['tasks']) => {
            setInitialTasks(tasks);
            openNodeEditor(FlowNodeTypes.actions, newId);
          },
          onCopy: (
            tasks: IActionsFlowNodeData['tasks'],
            position: PositionType,
          ) => {
            onAddActionsNode(tasks, position);
          },
        },
      },
    ]);
  };

  const onAddConditionsNode = (
    conditions: IConditionsFlowNodeData['conditions'] = [],
    position: PositionType = { x: 0, y: 0 },
    newNodeId?: string,
  ) => {
    const newId = newNodeId ? newNodeId : Date.now().toString();

    unselectAllNodes();
    setEditingConditionsNodeId(newId);
    setNodes((prev) => [
      ...prev,
      {
        id: newId,
        type: FlowNodeTypes.conditions,
        selected: true,
        position: {
          x: position.x === 0 ? centerPosition.x : position.x,
          y: position.y === 0 ? centerPosition.y : position.y,
        },
        dragHandle: '.dragger',
        data: {
          _id: newId,
          data: {
            conditions: conditions.map((cond) => ({ ...cond, truthyNext: '' })),
          },
          position,
          next: null,
          onDelete: () => {
            onDeleteNode(newId);
            setEditingConditionsNodeId('');
          },
          exitEditor,
          onNodePositionChange,
          onSelect: (conditions: IConditionsFlowNodeData['conditions']) => {
            setInitialConditions(conditions);
            openNodeEditor(FlowNodeTypes.conditions, newId);
          },
          onCopy: (
            conditions: IConditionsFlowNodeData['conditions'],
            position: PositionType,
          ) => {
            onAddConditionsNode(conditions, position);
          },
        },
      },
    ]);
  };

  const onAddDelayNode = (
    delayNodeData: IDelayFlowNodeData = {
      delayForSeconds: 0,
      applyTimeRange: false,
      days: [],
      startTime: '',
      endTime: '',
      timezone: '',
    },
    position: PositionType = { x: 0, y: 0 },
    newNodeId?: string,
  ) => {
    const newId = newNodeId ? newNodeId : Date.now().toString();

    unselectAllNodes();
    setEditingDelayNodeId(newId);
    setNodes((prev) => [
      ...prev,
      {
        id: newId,
        selected: true,
        type: FlowNodeTypes.delay,
        position: {
          x: position.x === 0 ? centerPosition.x : position.x,
          y: position.y === 0 ? centerPosition.y : position.y,
        },
        dragHandle: '.dragger',
        data: {
          _id: newId,
          data: {
            delayForSeconds: delayNodeData.delayForSeconds,
            applyTimeRange: delayNodeData.applyTimeRange,
            days: delayNodeData.days,
            startTime: delayNodeData.startTime,
            endTime: delayNodeData.endTime,
            timezone: delayNodeData.timezone,
          },
          next: null,
          position,
          onDelete: () => {
            onDeleteNode(newId);
            setEditingDelayNodeId('');
          },
          exitEditor,
          onNodePositionChange,
          onSelect: (delayNodeData: IDelayFlowNodeData) => {
            setInitialDelay(delayNodeData);
            openNodeEditor(FlowNodeTypes.delay, newId);
          },
          onCopy: (
            delayNodeData: IDelayFlowNodeData,
            position: PositionType,
          ) => {
            onAddDelayNode(delayNodeData, position);
          },
        },
      },
    ]);
  };

  const onAddTelegramMessageNode = (
    buttons: TelegramButton[][] = [],
    media: IMedia[] = [],
    text = '',
    messageType = TelegramMessageType.media,
    position: PositionType = { x: 0, y: 0 },
    newNodeId = '',
  ) => {
    const newId = newNodeId ? newNodeId : Date.now().toString();

    const newNodeButtons = resetActionButtonsTargets(buttons);

    unselectAllNodes();

    setEditingTelegramMessageNodeId(newId);
    setNodes((prev) => [
      ...prev,
      {
        id: newId,
        selected: true,
        type: FlowNodeTypes.telegramMessage,
        position: {
          x: position.x === 0 ? centerPosition.x : position.x,
          y: position.y === 0 ? centerPosition.y : position.y,
        },
        dragHandle: '.dragger',
        data: {
          _id: newId,
          data: {
            buttons: newNodeButtons,
            media,
            text,
            telegramMessageType: messageType,
          },
          position,
          next: null,
          onDelete: () => {
            onDeleteNode(newId);
            setEditingTelegramMessageNodeId('');
          },
          exitEditor,
          onNodePositionChange,
          onSelect: (
            newText: string,
            newButtons: TelegramButton[][],
            newMedia: IMedia[],
            newType: TelegramMessageType,
          ) => {
            openNodeEditor(FlowNodeTypes.telegramMessage, newId);
            onChangeInitialMessage(newText, newButtons, newMedia, newType);
          },
          onCopy: (
            buttons: TelegramButton[][],
            media: IMedia[],
            text: string,
            messageType: TelegramMessageType,
            position: PositionType,
          ) => {
            onAddTelegramMessageNode(
              buttons,
              media,
              text,
              messageType,
              position,
            );
          },
        },
      },
    ]);
  };

  const onAddChatActionNode = (
    actionType = 'typing',
    duration = 1,
    position: PositionType = { x: 0, y: 0 },
    newNodeId?: string,
  ) => {
    const newId = newNodeId ? newNodeId : Date.now().toString();

    unselectAllNodes();
    setEditingChatActionNodeId(newId);
    setNodes((prev) => [
      ...prev,
      {
        id: newId,
        selected: true,
        type: FlowNodeTypes.chatAction,
        position: {
          x: position.x === 0 ? centerPosition.x : position.x,
          y: position.y === 0 ? centerPosition.y : position.y,
        },
        dragHandle: '.dragger',
        data: {
          _id: newId,
          data: {
            actionType,
            duration,
          },
          position,
          next: null,
          onDelete: () => {
            onDeleteNode(newId);
            setEditingChatActionNodeId('');
          },
          exitEditor,
          onNodePositionChange,
          onSelect: ({ actionType, duration }: IChatActionFlowNodeData) => {
            openNodeEditor(FlowNodeTypes.chatAction, newId);
            setInitialChatAction({
              actionType,
              duration,
            });
          },
          onCopy: (
            { actionType, duration }: IChatActionFlowNodeData,
            position: PositionType,
          ) => {
            onAddChatActionNode(actionType, duration, position);
          },
        },
      },
    ]);
  };

  const onAddMenuNode = (position: PositionType = { x: 0, y: 0 }) => {
    const newId = Date.now().toString();

    unselectAllNodes();
    setNodes((prev) => [
      ...prev,
      {
        id: newId,
        selected: true,
        type: FlowNodeTypes.menu,
        position: {
          x: position.x,
          y: position.y,
        },
        dragHandle: '.dragger',
        data: {
          _id: newId,
          data: {
            addTelegramMessageNode: () =>
              onAddTelegramMessageNode(
                [],
                [],
                '',
                TelegramMessageType.media,
                position,
                newId,
              ),
            addActionsNode: () => onAddActionsNode([], position, newId),
            addConditionsNode: () => onAddConditionsNode([], position, newId),
            addDelayNode: () =>
              onAddDelayNode(
                {
                  delayForSeconds: 0,
                  applyTimeRange: false,
                  days: [],
                  startTime: '',
                  endTime: '',
                  timezone: '',
                },
                position,
                newId,
              ),
            addChatActionNode: () =>
              onAddChatActionNode('typing', 1, position, newId),
          },
          position,
          next: null,
          onDelete: () => {
            onDeleteNode(newId);
          },
          onNodePositionChange,
        },
      },
    ]);
  };

  const setDefaultNodes = () => {
    const nds: Node[] = flowNodes.map(({ _id, type, position, data, next }) => {
      switch (type) {
        case FlowNodeTypes.telegramMessage: {
          return {
            id: _id,
            type,
            position,
            dragHandle: '.dragger',
            data: {
              _id,
              data: data,
              next,
              position,
              onDelete: () => {
                onDeleteNode(_id);
                setEditingTelegramMessageNodeId('');
              },
              exitEditor,
              onNodePositionChange,
              onSelect: (
                newText: string,
                newButtons: TelegramButton[][],
                newMedia: IMedia[],
                newType: TelegramMessageType,
              ) => {
                openNodeEditor(FlowNodeTypes.telegramMessage, _id);
                onChangeInitialMessage(newText, newButtons, newMedia, newType);
              },
              onCopy: (
                buttons: TelegramButton[][],
                media: IMedia[],
                text: string,
                messageType: TelegramMessageType,
                position: PositionType,
              ) => {
                onAddTelegramMessageNode(
                  buttons,
                  media,
                  text,
                  messageType,
                  position,
                );
              },
            },
          };
        }

        case FlowNodeTypes.actions: {
          return {
            id: _id,
            type,
            position,
            dragHandle: '.dragger',
            data: {
              _id,
              data: data,
              next,
              position,
              onDelete: () => {
                onDeleteNode(_id);
                setEditingActionsNodeId('');
              },
              exitEditor,
              onNodePositionChange,
              onSelect: (tasks: IActionsFlowNodeData['tasks']) => {
                openNodeEditor(FlowNodeTypes.actions, _id);
                setInitialTasks(tasks);
              },
              onCopy: (
                tasks: IActionsFlowNodeData['tasks'] = [],
                position: PositionType = { x: 0, y: 0 },
              ) => {
                onAddActionsNode(tasks, position);
              },
            },
          };
        }

        case FlowNodeTypes.delay: {
          return {
            id: _id,
            type,
            position,
            dragHandle: '.dragger',
            data: {
              _id,
              data: data,
              position,
              next,
              onDelete: () => {
                onDeleteNode(_id);
                setEditingDelayNodeId('');
              },
              exitEditor,
              onNodePositionChange,
              onSelect: (delayNodeData: IDelayFlowNodeData) => {
                setInitialDelay(delayNodeData);
                openNodeEditor(FlowNodeTypes.delay, _id);
              },
              onCopy: (
                delayNodeData: IDelayFlowNodeData,
                position: PositionType = { x: 0, y: 0 },
              ) => {
                onAddDelayNode(delayNodeData, position);
              },
            },
          };
        }

        case FlowNodeTypes.conditions: {
          return {
            id: _id,
            type,
            position,
            dragHandle: '.dragger',
            data: {
              _id,
              data: data,
              next,
              position,
              onDelete: () => {
                setEditingConditionsNodeId('');
                onDeleteNode(_id);
              },
              exitEditor,
              onNodePositionChange,
              onSelect: (conditions: IConditionsFlowNodeData['conditions']) => {
                setInitialConditions(conditions);
                openNodeEditor(FlowNodeTypes.conditions, _id);
              },
              onCopy: (
                conditions: IConditionsFlowNodeData['conditions'],
                position: PositionType,
              ) => {
                onAddConditionsNode(conditions, position);
              },
            },
          };
        }

        case FlowNodeTypes.chatAction: {
          return {
            id: _id,
            type,
            position,
            dragHandle: '.dragger',
            data: {
              _id,
              data: data,
              next,
              position,
              onDelete: () => {
                onDeleteNode(_id);
                setEditingChatActionNodeId('');
              },
              exitEditor,
              onNodePositionChange,
              onSelect: ({ actionType, duration }: IChatActionFlowNodeData) => {
                setInitialChatAction({
                  actionType,
                  duration,
                });
                openNodeEditor(FlowNodeTypes.chatAction, _id);
              },
              onCopy: (
                { actionType, duration }: IChatActionFlowNodeData,
                position: PositionType,
              ) => {
                onAddChatActionNode(actionType, duration, position);
              },
            },
          };
        }

        case FlowNodeTypes.menu: {
          return {
            id: _id,
            type,
            position,
            dragHandle: '.dragger',
            data: {
              _id,
              data: data,
              next,
              position,
              onDelete: () => {
                onDeleteNode(_id);
              },
              exitEditor,
              onNodePositionChange,
            },
          };
        }

        default: {
          return {
            _id: 'unknown',
            data: {},
            type: 'unknown',
            position: {
              x: 0,
              y: 0,
            },
            id: '',
            dragHandle: '',
          };
        }
      }
    });

    nds.push({
      type: FlowNodeTypes.start,
      position: flow.startPosition ?? {
        x: 600,
        y: 40,
      },
      data: {
        onNodePositionChange,
        deleteEdge,
        _id: 'start_node',
        next: flow.rootNode,
      },
      id: 'start_node',
      dragHandle: '.dragger',
    });

    setNodes(nds);
    setInitialNodes(nds);

    // updateEdges(nds); TODO: check if needed
  };

  useEffect(() => {
    setDefaultNodes();
  }, [flow, flowNodes]);

  const onAddClick = (e: React.SyntheticEvent) => {
    openMenu(e);
    unselectAllNodes();
  };

  const onConnect = useOnNodesConnect(setNodes);

  const onCloseTelegramMessageEditing = () => {
    setEditingTelegramMessageNodeId('');
  };

  const { nodesChanged, isFlowValid, saveFlow } = useReactFlowEditor(
    flow,
    initialNodes,
    nodes,
  );

  const onSubmitTelegramMessage = (e: React.SyntheticEvent) => {
    e.preventDefault();

    clearMessageState();

    setNodes((prev) => {
      const result = [];

      for (const node of prev) {
        if (node.id !== editingTelegramMessageNodeId) {
          result.push(node as Node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              data: {
                text: newMessageText,
                telegramMessageType: messageType,
                media: media,
                buttons: buttons,
              },
            },
          });
        }
      }

      return result;
    });

    setTimeout(() => {
      updateNodeInternals(editingTelegramMessageNodeId);
    }, 30);

    setEditingTelegramMessageNodeId('');
  };

  const onSubmitActionsNode = (
    e: React.SyntheticEvent,
    tasks: IActionsFlowNodeData['tasks'],
  ) => {
    e.preventDefault();

    setNodes((prev: Node[]) => {
      const result = [];

      for (const node of prev) {
        if (node.id !== editingActionsNodeId) {
          result.push(node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              data: {
                tasks,
              },
            },
          });
        }
      }

      return result;
    });

    setTimeout(() => {
      updateNodeInternals(editingActionsNodeId);
    }, 30);

    setEditingActionsNodeId('');
    setInitialTasks([]);
  };

  const onSubmitDelayNode = (
    e: React.SyntheticEvent,
    delayNode: IDelayFlowNodeData,
  ) => {
    e.preventDefault();

    setNodes((prev: Node[]) => {
      const result = [];

      for (const node of prev) {
        if (node.id !== editingDelayNodeId) {
          result.push(node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              data: {
                ...delayNode,
              },
            },
          });
        }
      }

      return result;
    });

    setTimeout(() => {
      updateNodeInternals(editingDelayNodeId);
    }, 30);

    setEditingDelayNodeId('');
    setInitialDelay((prev) => ({
      ...prev,
      delayForSeconds: 0,
      applyTimeRange: false,
      days: [],
      startTime: '',
      endTime: '',
      timezone: '',
    }));
  };

  const onSubmitConditionsNode = (
    e: React.SyntheticEvent,
    resultConditions: IConditionsFlowNodeData['conditions'],
  ) => {
    e.preventDefault();

    setNodes((prev: Node[]) => {
      const result = [];

      for (const node of prev) {
        if (node.id !== editingConditionsNodeId) {
          result.push(node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              next: resultConditions.length ? node.data.next : null,
              data: {
                ...node.data.data,
                conditions: resultConditions,
              },
            },
          });
        }
      }

      return result;
    });

    setTimeout(() => {
      updateNodeInternals(editingConditionsNodeId);
    }, 30);

    setEditingConditionsNodeId('');
    setInitialConditions([]);
  };

  const onSubmitChatActionNode = (
    e: React.SyntheticEvent,
    resultChatAction: IChatActionFlowNodeData,
  ) => {
    e.preventDefault();

    setNodes((prev: Node[]) => {
      const result = [];

      for (const node of prev) {
        if (node.id !== editingChatActionNodeId) {
          result.push(node);
        } else {
          result.push({
            ...node,
            data: {
              ...node.data,
              data: {
                ...resultChatAction,
              },
            },
          });
        }
      }

      return result;
    });

    setTimeout(() => {
      updateNodeInternals(editingChatActionNodeId);
    }, 30);
    setInitialChatAction({
      actionType: 'typing',
      duration: 1,
    });
    setEditingChatActionNodeId('');
  };

  const { screenToFlowPosition } = useReactFlow();

  const connectingNodeId = useRef<string | null>(null);
  const connectingHandleId = useRef<string | null>(null);

  const onConnectStart = useCallback(
    (
      event: ReactMouseEvent | ReactTouchEvent,
      { nodeId, handleId }: { nodeId: string | null; handleId: string | null },
    ) => {
      connectingNodeId.current = nodeId;
      connectingHandleId.current = handleId;
    },
    [],
  );

  const onConnectEnd = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!connectingNodeId.current) return;

      const targetIsPane =
        (event.target as HTMLElement)?.classList?.contains(
          'react-flow__pane',
        ) ?? false;

      if (targetIsPane && nodes[nodes.length - 1].type !== 'menu') {
        setActiveConnectEdge(true);
        if ('clientY' in event) {
          const newPos = screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          });
          onAddMenuNode(newPos);
        }
      }
    },
    [screenToFlowPosition, nodes],
  );

  useEffect(() => {
    if (
      activeConnectEdge &&
      connectingNodeId.current !== nodes[nodes.length - 1].id
    ) {
      setCurrentNewNodeId(nodes[nodes.length - 1].id);
    }
  }, [nodes]);

  useEffect(() => {
    if (currentNewNodeId && activeConnectEdge) {
      UseUpdateNodes(setNodes, connectingHandleId.current, currentNewNodeId);

      setCurrentNewNodeId(null);
      setActiveConnectEdge(false);
    }
  }, [currentNewNodeId]);

  return (
    <Stack
      sx={{
        width: '100%',
        position: 'relative',
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        '& .react-flow__attribution': {
          display: 'none',
        },
      }}
    >
      <FlowControls
        saveFlow={() => {
          saveFlow().then(refetchFlow);
        }}
        isFlowValid={isFlowValid}
        nodesChanged={nodesChanged}
        onAddClick={onAddClick}
        onBack={onBack}
        setDefaultNodesAndEdges={setDefaultNodes}
      />

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={(params) => {
          const deleting = params.filter(({ type }) => type === 'remove');
          const rest = params.filter(({ type }) => type !== 'remove');

          for (const edge of deleting) {
            if ('id' in edge) {
              deleteEdge(edge?.id);
            }
          }
          onEdgesChange(rest);
        }}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onConnect={onConnect}
        style={{ background: darkThemeColors.grey['15'] }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineStyle={getConnectionLineStyle(themeColors)}
        snapToGrid={true}
        snapGrid={snapGrid}
        defaultViewport={{
          x: (-flow.startPosition.x + 150) / 2 ?? 0,
          y: -flow.startPosition.y / 2 + 20 ?? 0,
          zoom: 0.5,
        }}
        minZoom={0.1}
        maxZoom={1}
      >
        <ThreeDotsMenu
          open={menuOpen}
          onClose={closeMenu}
          anchor={anchorEl}
          borderColor="green.2"
          backgroundColor="grey.15"
          hoverBackground={'green.2'}
          borderRadius="8px"
          color="grey.15"
          menuAnchorProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }}
          menuItems={[
            [
              {
                textSx: {
                  fontWeight: 600,
                  '&:hover ': {
                    color: 'grey.15',
                  },
                },
                text: t('flowNodes.messageLabel'),
                renderIcon: () => <ChatBubbleRoundedIcon />,
                onClick: onAddTelegramMessageNode,
              },
              {
                textSx: {
                  fontWeight: 600,
                  '&:hover ': {
                    color: 'grey.15',
                  },
                },
                text: t('flowNodes.actionsLabel'),
                renderIcon: () => <BoltRoundedIcon />,
                onClick: onAddActionsNode,
              },
              {
                textSx: {
                  fontWeight: 600,
                  '&:hover ': {
                    color: 'grey.15',
                  },
                },
                text: t('flowNodes.conditionsLabel'),
                renderIcon: () => <MediationRoundedIcon />,
                onClick: onAddConditionsNode,
              },
              {
                textSx: {
                  fontWeight: 600,
                  '&:hover ': {
                    color: 'grey.15',
                  },
                },
                text: t('flowNodes.delayLabel'),
                renderIcon: () => <TimerRoundedIcon />,
                onClick: onAddDelayNode,
              },
              {
                textSx: {
                  fontWeight: 600,
                  '&:hover ': {
                    color: 'grey.15',
                  },
                },
                text: t('flowNodes.chatAction'),
                renderIcon: () => <FmdBadIcon />,
                onClick: onAddChatActionNode,
              },
            ],
          ]}
        />
        <MiniMap
          style={{
            backgroundColor: darkThemeColors.grey['14'],
          }}
          pannable
          nodeBorderRadius={30}
          nodeColor={(node) => {
            if (node.type === FlowNodeTypes.start)
              return darkThemeColors.grey['2'];

            return darkThemeColors.green['1'];
          }}
        />
        <NodeToolbar />
        <Background
          variant={BackgroundVariant.Dots}
          gap={40}
          size={2}
          color={darkThemeColors.grey['10']}
        />
      </ReactFlow>

      <TelegramMessageEditor
        open={Boolean(editingTelegramMessageNodeId)}
        onClose={onCloseTelegramMessageEditing}
        onInput={onChangeNewMessageText}
        newMessage={newMessageText}
        clearMessageState={clearMessageState}
        buttons={buttons}
        onAddRow={onAddButtonsRow}
        onRemoveRow={onRemoveButtonsRow}
        onRemoveButton={onRemoveButton}
        onAddUrlButton={onAddUrlButton}
        onAddActionButton={onAddActionButton}
        onButtonChange={onButtonChange}
        addNewButtonsRowDisabled={addNewButtonsRowDisabled}
        onRemoveMedia={onRemoveMedia}
        onSubmit={onSubmitTelegramMessage}
        openMediaModal={() => {
          setMediaModalOpen(true);
        }}
        openVoiceModal={() => {
          setVoiceModalOpen(true);
        }}
        openVideoNoteModal={() => {
          setVideoNotesModalOpen(true);
        }}
        media={media}
        sendButtonDisabled={!newMessageValid}
        messageType={messageType}
        onMessageTypeChange={onMessageTypeChange}
      />

      <ActionsEditor
        open={Boolean(editingActionsNodeId)}
        onClose={() => {
          setEditingActionsNodeId('');
          setInitialTasks([]);
        }}
        onSubmit={onSubmitActionsNode}
        initialTasks={initialTasks}
        flows={flows}
        channels={channels}
      />

      <DelayEditor
        delayNode={initialDelay}
        open={Boolean(editingDelayNodeId)}
        onClose={() => {
          setInitialDelay((prev) => ({
            ...prev,
            delayForSeconds: 0,
            applyTimeRange: false,
            days: [],
            startTime: '',
            endTime: '',
            timezone: '',
          }));
          setEditingDelayNodeId('');
        }}
        onSubmit={onSubmitDelayNode}
      />

      <ConditionsEditor
        open={Boolean(editingConditionsNodeId)}
        onClose={() => {
          setInitialConditions([]);
          setEditingConditionsNodeId('');
        }}
        initialConditions={initialConditions}
        onSubmit={onSubmitConditionsNode}
        channels={channels}
      />

      <ChatActionEditor
        open={Boolean(editingChatActionNodeId)}
        onClose={() => {
          setInitialChatAction({
            actionType: 'typing',
            duration: 1,
          });
          setEditingChatActionNodeId('');
        }}
        initialChatAction={initialChatAction}
        onSubmit={onSubmitChatActionNode}
      />

      <MediaModal
        open={mediaModalOpen}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onClose={() => {
          setMediaModalOpen(false);
          setIsPasteBuffer(false);
        }}
        openMediaModalBuffer={openMediaModalBuffer}
        onRemoveMedia={onRemoveMedia}
      />

      <VoiceModal
        open={voiceModalOpen}
        onClose={() => {
          setVoiceModalOpen(false);
        }}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onRemoveMedia={onRemoveMedia}
      />

      <VideoNoteModal
        open={videoNotesModalOpen}
        onClose={() => {
          setVideoNotesModalOpen(false);
        }}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onRemoveMedia={onRemoveMedia}
      />
    </Stack>
  );
};

const FlowNodesEditor: FC<FlowNodesEditorProps> = (props) => {
  return (
    <ReactFlowProvider>
      <Editor {...props} />
    </ReactFlowProvider>
  );
};

export default FlowNodesEditor;
