import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { t } from 'i18next';
import React, { useContext } from 'react';

import { AppContext } from '../../AppContext';

const DialogConfirmation = () => {
  const {
    confirmationDialogOpen,
    closeConfirmationDialog,
    confirmationDialogText,
    confirmationDialogButtonLabel,
    onConfirm,
  } = useContext(AppContext);
  return (
    <Dialog
      open={confirmationDialogOpen}
      onClose={closeConfirmationDialog}
      sx={{
        '& .MuiPaper-root': {
          border: '1px solid',
          borderColor: 'grey.10',
          borderRadius: '12px',
        },
      }}
    >
      <DialogContent
        sx={{
          width: '420px',
          backgroundColor: 'grey.14',
          p: '32px 56px 24px',
        }}
      >
        <DialogContentText
          sx={{
            wordWrap: 'break-word',
            textAlign: 'center',
            color: 'grey.1',
            fontWeight: 500,
            fontSize: '18px',
          }}
        >
          {confirmationDialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ width: '420px', backgroundColor: 'grey.14', p: '0 56px 32px' }}
      >
        <Button
          className={'red'}
          sx={{
            width: '154px',
          }}
          fullWidth
          onClick={onConfirm}
          autoFocus
        >
          {confirmationDialogButtonLabel}
        </Button>
        <Button
          className={'blue'}
          sx={{
            width: '154px',
          }}
          fullWidth
          onClick={closeConfirmationDialog}
        >
          {t('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
