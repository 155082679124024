import { Button, List, ListItem, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useEffect, useState } from 'react';

import fetchActiveChannels from '../../api/channels/fetch-active-channels';
import StatisticsContainer from '../../common/statistics/statistics.container';
import { GlobalContext } from '../../GlobalContext';
import ChannelCard from '../channels/channel.card';
import { ITelegramChannelWithActivators } from '../channels/interfaces';
import { ITelegramBot } from '../telegram-bot.interface';

type ChannelsStatisticsProps = {
  onFetchStatistics: (
    params: string[],
    endDate: string,
    startDate: string,
    channelId: string | null,
    // endDate2: string,
    // startDate2: string,
  ) => void;
  statistics: (string | number)[][];
  loadingStatistics: boolean;
  botId: ITelegramBot['_id'];
};

const ChannelsStatistics: FC<ChannelsStatisticsProps> = ({
  onFetchStatistics,
  statistics,
  loadingStatistics,
  botId,
}) => {
  const { toggleActiveChannelNameStatistic } = useContext(GlobalContext);

  const [channels, setChannels] = useState<ITelegramChannelWithActivators[]>(
    [],
  );
  const [selectedChannel, setSelectedChannel] =
    useState<ITelegramChannelWithActivators | null>(null);

  const getChannels = () => {
    fetchActiveChannels(botId).then(setChannels);
  };

  const onSelectedChannel = (channel: ITelegramChannelWithActivators) => {
    setSelectedChannel(channel);
  };

  const onBackClick = () => {
    setSelectedChannel(null);
  };

  useEffect(() => {
    getChannels();
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        p: '8px 48px 48px',
        gap: '8px',
      }}
    >
      {!selectedChannel ? (
        Boolean(channels.length) ? (
          channels.map((channel) => (
            <ChannelCard
              key={channel._id}
              channel={channel}
              buttonText={t('statistics.checkStatistic')}
              onClick={() => {
                onSelectedChannel(channel);
                toggleActiveChannelNameStatistic(channel.title);
              }}
            />
          ))
        ) : (
          <Stack
            alignItems="center"
            sx={{
              width: '100%',
            }}
          >
            <Stack
              sx={{
                backgroundColor: 'grey.14',
                border: '1px solid',
                borderColor: 'grey.10',
                borderRadius: '12px',
                padding: ' 32px 56px',
                width: '420px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                }}
              >
                {t('channels.noChannels')}
              </Typography>
              <List sx={{ listStyle: 'decimal' }}>
                <ListItem
                  sx={{
                    display: 'list-item',
                    p: '0',
                    mt: '20px',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {t('channels.empty.firstStep')}
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item',
                    p: '0',
                    mt: '12px',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {t('channels.empty.secondStep')}
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item',
                    p: '0',
                    mt: '12px',
                    fontSize: '16px',
                    fontWeight: 500,
                  }}
                >
                  {t('channels.empty.thirdStep')}
                </ListItem>
              </List>
              <Button
                className="brightGreen"
                sx={{
                  mt: '24px',
                }}
                disabled
              >
                {t('channels.empty.viewDocumentation')}
              </Button>
            </Stack>
          </Stack>
        )
      ) : (
        <StatisticsContainer
          onFetchStatistics={onFetchStatistics}
          statistics={statistics}
          loadingStatistics={loadingStatistics}
          channelId={selectedChannel._id}
          channelCreatedAt={selectedChannel.createdAt}
          onBackClick={onBackClick}
        />
      )}
    </Stack>
  );
};

export default ChannelsStatistics;
