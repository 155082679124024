import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useState } from 'react';

import deleteInviteLink from '../../../api/projects/delete-invite-link';
import editInviteLink from '../../../api/projects/edit-invite-link';
import { AppContext } from '../../../AppContext';
import ModalBox from '../../../common/modal/modal-box';
import ThreeDotsMenu from '../../../common/threeDotsMenu/three-dots-menu.component';
import { APP_CONFIG } from '../../../config';
import { GlobalContext } from '../../../GlobalContext';
import { TelegramMessengerAccess } from '../../../telegram-bots/telegram-bot.interface';
import { ProjectInvite, ProjectInviteAccess } from '../../project.interface';

import EditInviteLinkModal from './edit-invite-link.modal';

type LibraryInviteLinksModalProps = {
  isOpen: boolean;
  projectInvites: ProjectInvite[];
  onClose: () => void;
  onCreateNewLink: () => void;
  projectId: string;
};

const LibraryInviteLinksModal: FC<LibraryInviteLinksModalProps> = ({
  isOpen,
  projectInvites,
  onClose,
  onCreateNewLink,
  projectId,
}) => {
  const { refetchProjects } = useContext(GlobalContext);
  const { triggerSnackbar, openConfirmationDialog } = useContext(AppContext);

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [currentInviteLink, setCurrentInviteLink] =
    useState<ProjectInvite | null>(null);
  const [isEditInviteLink, setIsEditInviteLink] = useState<boolean>(false);
  const [nameInviteLink, setNameInviteLink] = useState<string>('');

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setNameInviteLink(e.target.value);
  };

  const handleCheckboxChange = (
    telegramBotId: string,
    key: keyof ProjectInviteAccess,
    value: boolean | TelegramMessengerAccess,
  ) => {
    setCurrentInviteLink((prev) => {
      if (prev === null) return null;
      const updatedAccesses = prev.accesses.map((acc) =>
        acc.telegramBot === telegramBotId ? { ...acc, [key]: value } : acc,
      );
      return { ...prev, accesses: updatedAccesses };
    });
  };

  const checkAllCheckboxChange = (telegramBotId: string, value: boolean) => {
    setCurrentInviteLink((prev) => {
      if (prev === null) return null;
      const updatedAccesses = prev.accesses.map((acc) =>
        acc.telegramBot === telegramBotId
          ? {
              ...acc,
              broadcasts: value,
              channels: value,
              flows: value,
              statistics: value,
              messenger: value
                ? TelegramMessengerAccess.manager
                : TelegramMessengerAccess.none,
            }
          : acc,
      );
      return { ...prev, accesses: updatedAccesses };
    });
  };
  const onCopyInviteLink = (code: string) => {
    navigator.clipboard.writeText(
      `https://t.me/${APP_CONFIG.TELEGRAM_AUTH_BOT_USERNAME}?start=jp-${code}` as string,
    );
    triggerSnackbar(t('projects.inviteLinks.inviteLinkCopied'), 'success');
    onClose();
  };

  const openProjectInvite = (
    e: React.SyntheticEvent,
    inviteLink: ProjectInvite,
  ) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    setCurrentInviteLink(inviteLink);
    setNameInviteLink(inviteLink.name);
  };

  const openEditProjectInviteLink = () => {
    setIsEditInviteLink(true);
  };

  const onEditProjectInviteLink = () => {
    editInviteLink(projectId, currentInviteLink?._id ?? '', {
      name: nameInviteLink ?? '',
      accesses: currentInviteLink?.accesses ?? [],
    }).then(() => {
      refetchProjects();
      setCurrentInviteLink(null);
      setIsEditInviteLink(false);
      setNameInviteLink('');
      triggerSnackbar(t('projects.inviteLinks.inviteLinkEdited'), 'success');
    });
  };

  const onDeleteProjectInviteLink = () => {
    if (!currentInviteLink) return;
    openConfirmationDialog(
      `${t('projects.inviteLinks.deleteInviteLink')} ${
        currentInviteLink.name
      }?`,
      `${t('common.delete')} `,
      () => {
        deleteInviteLink(projectId, currentInviteLink._id).then(() => {
          refetchProjects();
          triggerSnackbar(
            t('projects.inviteLinks.inviteLinkDeleted'),
            'success',
          );
        });
      },
    );
  };

  return (
    <Stack>
      <ModalBox
        open={isOpen}
        onClose={onClose}
        maxWidth="490px"
        sx={{
          height: '472px',
          p: '24px 24px 16px',
          borderRadius: '12px',
          backgroundColor: 'grey.14',
          position: 'relative',
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </Stack>
        <Stack sx={{ height: '100%' }}>
          <Typography
            sx={{ fontWeight: 600, fontSize: '24px', textAlign: 'center' }}
          >
            {t('projects.inviteLinks.referenceLibrary')}
          </Typography>
          <Stack
            gap="8px"
            sx={{
              m: '24px 0',
              overflow: 'auto',
            }}
          >
            {projectInvites?.map((projectInvite) => (
              <Stack
                key={projectInvite._id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  backgroundColor: 'grey.10',
                  borderRadius: '8px',
                  p: '12px 16px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '18px',
                  }}
                >
                  {projectInvite.name}
                </Typography>
                <Stack direction="row" alignItems="center" gap="8px">
                  <IconButton
                    onClick={() => onCopyInviteLink(projectInvite.code)}
                    sx={{
                      padding: '0',
                      backgroundColor: 'green.4',
                      border: '1px solid',
                      borderColor: 'green.2',
                      borderRadius: '4px',
                      width: '29px',
                      minWidth: '29px',
                      height: '29px',
                      boxShadow: ' 0 4px 10px 0 rgba(202, 250, 68, 0.15)',
                      '&:hover': {
                        backgroundColor: 'green.4',
                      },
                    }}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7117 15C12.7279 15.6492 12.2086 16.1685 11.5594 16.1523C10.9264 16.1523 10.4071 15.633 10.4071 15C10.4071 11.8353 12.9794 9.26293 16.1442 9.26293L21.8894 9.26293C25.0541 9.26293 27.6264 11.8353 27.6264 15C27.6264 18.1648 25.0541 20.7371 21.8894 20.7371H19.4712C20.1447 20.0798 20.7046 19.3089 21.1104 18.4488L21.8812 18.4407C23.7882 18.4488 25.33 16.907 25.3219 15C25.33 13.0931 23.7882 11.5513 21.8812 11.5594L16.1523 11.5594C14.2453 11.5513 12.7035 13.0931 12.7117 15ZM18.4406 13.8477C19.0736 13.8477 19.5929 14.3671 19.5929 15C19.5929 18.1648 17.0206 20.7371 13.8558 20.7371L8.11064 20.7371C4.94592 20.7371 2.37356 18.1648 2.37356 15C2.37356 11.8353 4.94592 9.26293 8.11064 9.26293L10.5288 9.26293C9.8553 9.92022 9.29538 10.6911 8.88154 11.5594L8.11876 11.5594C6.21181 11.5513 4.67002 13.0931 4.67813 15C4.67002 16.907 6.21181 18.4488 8.11875 18.4407L13.8477 18.4407C15.7547 18.4488 17.2965 16.907 17.2883 15C17.2721 14.3508 17.7914 13.8315 18.4406 13.8477Z"
                        fill={'#FFF'}
                      />
                    </svg>
                  </IconButton>

                  <IconButton
                    onClick={(e) => {
                      openProjectInvite(e, projectInvite);
                    }}
                    sx={{
                      padding: '0',
                      backgroundColor: 'blue.5',
                      border: '1px solid',
                      borderRadius: '4px',
                      borderColor: 'blue.2',
                      width: '29px',
                      minWidth: '29px',
                      height: '29px',
                      boxShadow: ' 0 4px 10px 0 rgba(202, 250, 68, 0.15)',
                      '&:hover': {
                        backgroundColor: 'blue.4',
                      },
                    }}
                  >
                    <MoreHorizIcon />
                  </IconButton>

                  <ThreeDotsMenu
                    open={Boolean(menuAnchor)}
                    onClose={() => setMenuAnchor(null)}
                    anchor={menuAnchor}
                    borderColor={'grey.12'}
                    backgroundColor={'grey.15'}
                    paddingMenu={'0px'}
                    paddingMenuItem={'10px 20px'}
                    borderRadius={'8px'}
                    border={'1px solid'}
                    marginDivider={'0 12px'}
                    menuItems={[
                      [
                        {
                          text: t('common.edit'),
                          textSx: {
                            color: 'grey.5',
                            fontWeight: 500,
                            fontSize: '16px',
                          },
                          renderIcon: () => (
                            <EditIcon sx={{ color: 'grey.secondary' }} />
                          ),
                          onClick: () => {
                            openEditProjectInviteLink();
                          },
                        },
                      ],
                      [
                        {
                          text: t('common.delete'),
                          textSx: {
                            color: 'red.2',
                            fontWeight: 500,
                            fontSize: '16px',
                          },
                          renderIcon: () => (
                            <DeleteOutlineIcon sx={{ color: 'red.2' }} />
                          ),
                          onClick: () => {
                            onDeleteProjectInviteLink();
                          },
                        },
                      ],
                    ]}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Button
            className="brightGreen"
            sx={{
              mt: 'auto',
              minHeight: '56px',
            }}
            onClick={onCreateNewLink}
          >
            {t('projects.inviteLinks.generateLink')}
          </Button>
        </Stack>
      </ModalBox>
      <EditInviteLinkModal
        isOpen={isEditInviteLink}
        onClose={() => {
          setCurrentInviteLink(null);
          setIsEditInviteLink(false);
        }}
        onEditProjectInviteLink={onEditProjectInviteLink}
        accesses={currentInviteLink?.accesses ?? []}
        handleCheckboxChange={handleCheckboxChange}
        checkAllCheckboxChange={checkAllCheckboxChange}
        nameInviteLink={nameInviteLink}
        onChangeName={onChangeName}
      />
    </Stack>
  );
};
export default LibraryInviteLinksModal;
