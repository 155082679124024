import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext } from 'react';

import createChannelActivator from '../../api/channels/create-channel-activator';
import { AppContext } from '../../AppContext';
import ArrowLeft from '../../assets/icons/ArrowLeft';
import truncate from '../../utils/truncate';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import ChannelActivators from './channel-activators';
import {
  IChannelActivatorWithParams,
  ITelegramChannelWithActivators,
} from './interfaces';

interface SelectedChannelProps {
  channel: ITelegramChannelWithActivators;
  onBackClick: () => void;
  getChannels: () => void;
  flows: IFlowWithActivatorsAndFolder[];
}

const SelectedChannel: FC<SelectedChannelProps> = ({
  channel,
  onBackClick,
  getChannels,
  flows,
}) => {
  const { triggerSnackbar } = useContext(AppContext);

  const onCreateActivator = (
    newChannelActivator: Omit<
      IChannelActivatorWithParams,
      | 'createdAt'
      | 'updatedAt'
      | '_id'
      | 'channel'
      | 'hidden'
      | 'inviteLink'
      | 'joinRequestsCount'
    >,
  ) => {
    createChannelActivator(
      channel.telegramBot,
      channel._id,
      newChannelActivator,
    )
      .then(() => {
        triggerSnackbar(t('triggerSnackbar.created'), 'success');
        getChannels();
      })
      .catch(() => triggerSnackbar(t('triggerSnackbar.error'), 'error'));
  };
  return (
    <Stack
      sx={{
        width: '100%',
        margin: '0 auto',
      }}
      gap="14px"
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          maxWidth: '787px',
          margin: '0 auto',
          width: '100%',
          padding: '12px',
          borderRadius: '12px',
          backgroundColor: 'grey.14',
          border: '1px solid',
          borderColor: 'grey.10',
        }}
      >
        <Button
          className="green"
          sx={{
            width: '48px',
            minWidth: '48px',
            height: '48px',
            borderRadius: '8px',
          }}
          onClick={onBackClick}
        >
          <ArrowLeft />
        </Button>
        <Stack
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{ ml: '40px' }}
        >
          {Boolean(channel.avatarUrl) ? (
            <img
              src={channel.avatarUrl}
              alt=""
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
              }}
            />
          ) : (
            <CampaignRoundedIcon
              sx={{
                fontSize: '40px',
                color: 'blue.3',
              }}
            />
          )}
          <Stack>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {truncate(channel.title, 18)}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: 'grey.secondary',
              }}
            >
              {channel.subscribersCount} {t('channels.channelCard.subscribers')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <ChannelActivators
        flows={flows}
        channel={channel}
        getChannels={getChannels}
        onCreateActivator={onCreateActivator}
      />
    </Stack>
  );
};

export default SelectedChannel;
