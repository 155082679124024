import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import createProject from '../api/projects/create-project';
import ModalBox from '../common/modal/modal-box';
import { GlobalContext } from '../GlobalContext';
import UploadCroppedImage from '../utils/uploadCroppedImage';

import CropImage from './crop-image.modal';
import JoinProjectModal from './join-project.modal';

const MAX_NAME_LENGTH = 25;
const MAX_DESCRIPTION_LENGTH = 30;

type CreateProjectType = {
  firstProject?: boolean;
};

const CreateProject: FC<CreateProjectType> = ({ firstProject }) => {
  const { refetchProjects } = useContext(GlobalContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [joinProjectModalOpen, setJoinProjectModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const navigate = useNavigate();

  const isCreateButtonDisabled = Boolean(!name);

  const openJoinProjectModal = () => setJoinProjectModalOpen(true);
  const closeJoinProjectModal = () => setJoinProjectModalOpen(false);

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onDescriptionInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(value);
    }
  };

  const onCreateClick = async () => {
    if (croppedImage) {
      const url = await UploadCroppedImage(croppedImage);
      createProject({
        name,
        description,
        webhookUrl: '',
        imageUrl: url,
        params: [],
      }).then((newProject) => {
        refetchProjects();
        navigate(`/project/${newProject._id}`);
      });
    } else {
      createProject({
        name,
        description,
        webhookUrl: '',
        imageUrl: '',
        params: [],
      }).then((newProject) => {
        refetchProjects();
        navigate(`/project/${newProject._id}`);
      });
    }

    setCroppedImage(null);
    setName('');
    setDescription('');
  };

  return (
    <Stack>
      <Stack>
        <Typography
          sx={{
            color: 'grey.1',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '140%',
          }}
        >
          {firstProject
            ? t('projects.yourFirstProjectTitle')
            : t('common.newProject')}
        </Typography>
        <Typography
          sx={{
            mt: '8px',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '140%',
            color: 'grey.1',
          }}
        >
          {t('projects.yourFirstProjectDescription')}
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        sx={{
          width: '100%',
          mt: '24px',
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: 'grey.10',
            minWidth: '128px',
            height: '128px',
            borderRadius: '50%',
            position: 'relative',
          }}
        >
          <CropImage setCroppedImage={setCroppedImage} isProjectOwner={true} />
          {croppedImage ? (
            <img
              src={croppedImage as string}
              alt="Cropped"
              style={{
                minWidth: '128px',
                height: '128px',
                borderRadius: '50%',
              }}
            />
          ) : (
            <AddPhotoAlternateIcon
              sx={{
                width: '48px',
                height: '48px',
              }}
            />
          )}
        </Stack>
        <Stack
          gap="16px"
          sx={{
            ml: '40px',
            width: '100%',
          }}
        >
          <TextField
            onChange={onNameInput}
            placeholder={`${t('common.nameLabel')} *`}
          />
          <TextField
            placeholder={t('common.descriptionLabel')}
            onChange={onDescriptionInput}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap="32px"
        sx={{
          mt: '36px',
        }}
      >
        <Button
          className={'brightGreen'}
          sx={{
            width: '280px',
            height: '56px',
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '140%',
            '&:disabled': {
              opacity: '0.3',
            },
          }}
          onClick={onCreateClick}
          disabled={isCreateButtonDisabled}
        >
          {t('projects.createProjectButton')}
        </Button>
        <Button
          className="blue"
          sx={{
            width: '280px',
            height: '56px',
          }}
          onClick={openJoinProjectModal}
        >
          {t('projects.joinToProject')}
        </Button>
      </Stack>
      <ModalBox
        open={joinProjectModalOpen}
        onClose={closeJoinProjectModal}
        sx={{
          padding: '32px 56px',
        }}
        maxWidth={'700px'}
      >
        <JoinProjectModal closeJoinProjectModal={closeJoinProjectModal} />
      </ModalBox>
    </Stack>
  );
};

export default CreateProject;
