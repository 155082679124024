import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useState } from 'react';

import deleteProjectMember from '../../../api/projects/delete-project-member';
import { AppContext } from '../../../AppContext';
import { GlobalContext } from '../../../GlobalContext';
import { UserContext } from '../../../user/user.context';
import IProject from '../../project.interface';

import CreateInviteLinkModal from './create-invite-link.modal';
import LibraryInviteLinksModal from './library-invite-links.modal';
import MembersTable from './members-table';

interface ProjectMembersProps {
  project: IProject;
}

const ProjectMembers: FC<ProjectMembersProps> = ({ project }) => {
  const { openConfirmationDialog } = useContext(AppContext);
  const { refetchProjects } = useContext(GlobalContext);
  const { user } = useContext(UserContext);

  const isProjectOwner = user?._id === project.owner;

  const [isCreateLink, setIsCreateLink] = useState<boolean>(false);
  const [isEditLink, setIsEditLink] = useState<boolean>(false);

  return (
    <Stack
      sx={{
        backgroundColor: 'grey.14',
        border: '1px solid ',
        borderColor: 'grey.10',
        borderRadius: '24px',
        minWidth: '70%',
        padding: '17px',
        height: 'auto',
        maxHeight: '560px',
        overflow: 'auto',
      }}
    >
      <Stack>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '18px',
            color: 'grey.1',
            lineHeight: '130%',
            textAlign: 'center',
            mb: '17px',
          }}
        >
          {t('projects.settings.title')}
        </Typography>
      </Stack>
      <Stack sx={{ gap: '17px', overflow: 'hidden', height: '100%' }}>
        <MembersTable
          members={project.members}
          isProjectOwner={isProjectOwner}
          user={user}
          onDeleteMember={(currentMember) => {
            openConfirmationDialog(
              `${t('projects.settings.deleteMember1Part')} ${
                currentMember.firstName
              } ?`,
              t('common.delete'),
              () => {
                deleteProjectMember(project._id, currentMember._id).then(
                  refetchProjects,
                );
              },
            );
          }}
        />
        {isProjectOwner && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              mt: 'auto',
              padding: '12px 18px',
              backgroundColor: 'grey.15',
              border: '1px solid',
              borderColor: 'grey.10',
              borderRadius: '6px',
            }}
          >
            <Stack>
              <Typography>{t('projects.settings.inviteText')}</Typography>
            </Stack>
            <Stack
              direction="row"
              gap="8px"
              sx={{
                mt: 'auto',
              }}
            >
              <Button
                className="blue"
                sx={{
                  ml: 'auto',
                  width: '269px',
                  height: '56px',
                }}
                onClick={() => setIsCreateLink(true)}
              >
                {t('projects.inviteLinks.generateLink')}
              </Button>
              <Button
                className="green"
                sx={{
                  width: '56px',
                  height: '56px',
                  minWidth: '56px',
                }}
                onClick={() => setIsEditLink(true)}
              >
                <FormatListBulletedIcon />
              </Button>
            </Stack>
            <CreateInviteLinkModal
              isOpen={isCreateLink}
              onClose={() => setIsCreateLink(false)}
              projectId={project._id}
            />
            <LibraryInviteLinksModal
              isOpen={isEditLink}
              projectInvites={project.invites}
              onClose={() => setIsEditLink(false)}
              onCreateNewLink={() => setIsCreateLink(true)}
              projectId={project._id}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ProjectMembers;
