import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, SyntheticEvent, useState } from 'react';
import ReactPlayer from 'react-player';

import VoicePreview from '../../common/media/voice.preview';
import TelegramButtons from '../../common/telegram/telegram.buttons';
import { IMedia, MediaType, TelegramButton } from '../../interfaces/common';
import truncate from '../../utils/truncate';

interface TemplateCardProps {
  title: string;
  media?: IMedia[];
  text: string | null;
  buttons: TelegramButton[][];
  onEdit: () => void;
  isDisabledDelete: boolean;
  onDelete: () => void;
}

const TemplateCard: FC<TemplateCardProps> = ({
  title,
  media,
  text,
  buttons,
  onEdit,
  isDisabledDelete,
  onDelete,
}) => {
  return (
    <Stack
      gap="12px"
      sx={{
        maxWidth: '340px',
        width: '100%',
        height: '312px',
        p: '16px 20px',
        borderRadius: '12px',
        border: '1px solid',
        borderColor: 'grey.10',
        backgroundColor: 'grey.14',
      }}
    >
      <Typography sx={{ textAlign: 'center' }}>
        {truncate(title, 25)}
      </Typography>
      <Divider
        sx={{
          borderColor: 'grey.13',
        }}
      />
      {Boolean(media?.length) && (
        <Stack
          flexDirection="row"
          gap="18px"
          sx={{ overflow: 'auto', height: '100px', minHeight: '100px' }}
        >
          {media?.map((mediaItem) => {
            switch (mediaItem.type) {
              case MediaType.photo: {
                return (
                  <Stack
                    key={mediaItem._id}
                    sx={{
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: '6px',
                    }}
                  >
                    <img
                      src={mediaItem.url}
                      style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        borderRadius: '4px',
                      }}
                      alt=""
                    />
                  </Stack>
                );
              }

              case MediaType.videoNote: {
                const [isPlaying, setIsPlaying] = useState(false);

                const togglePlay = (e: SyntheticEvent) => {
                  e.stopPropagation();

                  setIsPlaying((prev) => !prev);
                };

                const onVideoEnd = () => {
                  setIsPlaying(false);
                };
                return (
                  <Stack
                    sx={{
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      border: '2px solid',
                      borderColor: 'grey.10',
                    }}
                  >
                    <Stack
                      sx={{
                        position: 'relative',
                        ':hover': {
                          '.pauseButton': {
                            display: 'block',
                          },
                        },
                      }}
                    >
                      <ReactPlayer
                        url={mediaItem.url}
                        width={100}
                        height={100}
                        playing={isPlaying}
                        onEnded={onVideoEnd}
                      />

                      {!isPlaying && (
                        <PlayCircleRoundedIcon
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '48px',
                            cursor: 'pointer',
                          }}
                          onClick={togglePlay}
                        />
                      )}

                      {isPlaying && (
                        <PauseCircleFilledRoundedIcon
                          className="pauseButton"
                          sx={{
                            display: 'none',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '48px',
                            cursor: 'pointer',
                          }}
                          onClick={togglePlay}
                        />
                      )}
                    </Stack>
                  </Stack>
                );
              }

              case MediaType.voice: {
                return (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: '100px',
                      height: '100px',
                      backgroundColor: 'grey.13',
                      padding: '5px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                  >
                    <VoicePreview
                      voiceUrl={mediaItem?.url}
                      attachedPreview={true}
                    />
                  </Stack>
                );
              }
            }
          })}
        </Stack>
      )}
      {Boolean(text) && (
        <Stack sx={{ maxHeight: '200px', overflow: 'auto' }}>
          <Typography>{text}</Typography>
        </Stack>
      )}
      {Boolean(buttons.length) && (
        <Stack>
          <TelegramButtons buttons={buttons} />
        </Stack>
      )}
      <Divider sx={{ mt: 'auto', borderColor: 'grey.13' }} />
      <Stack
        flexDirection="row"
        gap="8px"
        sx={{
          mt: '4px',
        }}
      >
        <Button
          className="red"
          fullWidth
          disabled={isDisabledDelete}
          onClick={onDelete}
        >
          {t('common.delete')}
        </Button>
        <Button className="green" fullWidth onClick={onEdit}>
          {t('common.edit')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default TemplateCard;
