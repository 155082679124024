import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import Input from '../../common/inputs/InputWithIcon';
import ModalBox from '../../common/modal/modal-box';
import { Params } from '../../interfaces/common';
import { MessengerContext } from '../messenger.context';

interface ParamsProps {
  param: Params | null;
  telegramId: number;
  project: string;
  isEditParamsModalOpen: boolean;
  onClose: () => void;
  dialogId: string;
}

const EditParamsModal: FC<ParamsProps> = ({
  param,
  isEditParamsModalOpen,
  onClose,
  dialogId,
}) => {
  const { updateDialogParams } = useContext(MessengerContext);

  const [paramToUpdate, setParamToUpdate] = useState<Params | null>(null);

  const onChangeParam = (key: string, e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setParamToUpdate((prev) => {
      if (prev) {
        return { ...prev, value };
      } else {
        return null;
      }
    });
  };

  const onUpdateParams = () => {
    if (!paramToUpdate) return;

    updateDialogParams(dialogId, { [paramToUpdate.key]: paramToUpdate.value });
    onClose();
  };

  const disabledSubmit = param?.value.trim() === '';

  useEffect(() => {
    setParamToUpdate(param);
  }, [param]);

  return (
    <ModalBox
      open={isEditParamsModalOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="378px"
      sx={{ p: '12px', position: 'relative' }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </Stack>
      <Stack gap="10px">
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          {t('flowNodes.editParam')}
        </Typography>
        <Input
          value={paramToUpdate?.value ?? ''}
          handleChangeSearch={(e) => {
            const key = paramToUpdate?.key;
            if (key) {
              onChangeParam(key, e as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          withIcon={false}
          placeholder={'Param'}
          backgroundNarrative="dark"
        />
        <Stack direction="row" gap="12px">
          <Button className="red" fullWidth onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button
            className="green"
            fullWidth
            disabled={disabledSubmit}
            onClick={onUpdateParams}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default EditParamsModal;
