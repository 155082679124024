import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext } from 'react';

import { AppContext } from '../../AppContext';

interface CopyableTextProps {
  text: string;
  textToCopy?: string;
  fontSize?: string;
  color?: string;
  fontWeight?: string;
}

const CopyableText: FC<CopyableTextProps> = ({
  text,
  textToCopy = text,
  fontSize = '1rem',
  color = 'green.2',
  fontWeight = '400',
}) => {
  const { triggerSnackbar } = useContext(AppContext);

  const copyText = () => {
    navigator.clipboard.writeText(textToCopy as string);
    triggerSnackbar(t('copyableText.text'));
  };
  return (
    <Stack
      onClick={copyText}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        ':hover': {
          '.copyIcon': {
            opacity: 1,
          },
        },
      }}
    >
      <Typography sx={{ color, mr: '10px', fontSize, fontWeight }}>
        {text}
      </Typography>
      <ContentCopyIcon
        className="copyIcon"
        sx={{
          opacity: 0,
          color,
          fontSize,
        }}
      />
    </Stack>
  );
};

export default CopyableText;
