import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReactCrop, {
  type Crop,
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import ModalBox from '../common/modal/modal-box';

type CropImageProps = {
  setCroppedImage: (image: string) => void;
  setIsEditImage?: (isEdit: boolean) => void;
  isProjectOwner?: boolean;
};

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const CropImage: FC<CropImageProps> = ({
  setIsEditImage,
  setCroppedImage,
  isProjectOwner = false,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [imgSrc, setImgSrc] = useState<string | undefined>('');
  const [error, setError] = useState('');
  const [cropImageModalOpen, setCropImageModalOpen] = useState(false);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 100,
    y: 100,
    width: 100,
    height: 100,
  });

  const setCanvasPreview = (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
  ) => {
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    ctx.restore();
  };

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height,
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const onSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || '';
      imageElement.src = imageUrl;

      imageElement.addEventListener('load', (e) => {
        if (error) setError('');
        const { naturalWidth, naturalHeight } =
          e.currentTarget as HTMLImageElement;

        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError(t('uploadMedia.errorCrop'));
          return setImgSrc('');
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
    e.target.value = '';
  };

  const openCropImageModal = () => {
    setCropImageModalOpen(true);
    if (setIsEditImage) {
      setIsEditImage(true);
    }
  };

  const closeCropImageModal = () => {
    const imgElement = imgRef.current;
    const canvasElement = previewCanvasRef.current;
    if (!imgElement || !canvasElement) return;

    setCanvasPreview(
      imgElement,
      canvasElement,
      convertToPixelCrop(crop, imgElement.width, imgElement.height),
    );

    const dataUrl = previewCanvasRef.current?.toDataURL();
    setCroppedImage(dataUrl || '');
    setCropImageModalOpen(false);
  };

  useEffect(() => {
    if (imgSrc) {
      openCropImageModal();
    }
  }, [imgSrc]);
  return (
    <Stack>
      <input
        type="file"
        accept="image/*"
        onChange={onSelectImage}
        disabled={!isProjectOwner}
        style={{
          position: 'absolute',
          opacity: '0',
          width: '100%',
          height: '100%',
          cursor: isProjectOwner ? 'pointer' : 'default',
          top: '0',
          right: '0',
        }}
      />
      <ModalBox
        open={cropImageModalOpen}
        onClose={() => {
          closeCropImageModal();
          if (setIsEditImage) {
            setIsEditImage(false);
          }
        }}
        sx={{
          padding: '32px 24px',
        }}
        maxWidth={'380px'}
      >
        <Stack sx={{ position: 'relative' }}>
          <Stack
            sx={{
              position: 'absolute',
              top: '-5%',
              right: '-5%',
            }}
          >
            <IconButton
              onClick={() => {
                setCroppedImage('');
                setError('');
                setCropImageModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack>
            <Typography
              sx={{
                textAlign: 'center',
                mb: '16px',
                fontWeight: '600',
                fontSize: '24px',
                lineHeight: '140%',
              }}
            >
              {t('projects.chooseImg')}
            </Typography>
            {!error && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '140%',
                  textAlign: 'center',
                }}
              >
                {t('projects.selectPlace')}
              </Typography>
            )}
          </Stack>
          <Stack sx={{ m: '24px 0' }}>
            {error && (
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: '16px',
                }}
              >
                {error}
              </Typography>
            )}
            {imgSrc && (
              <Stack>
                <ReactCrop
                  crop={crop}
                  onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                  circularCrop
                  keepSelection
                  aspect={ASPECT_RATIO}
                  minWidth={MIN_DIMENSION}
                >
                  <img
                    ref={imgRef}
                    src={imgSrc}
                    alt="Upload"
                    style={{
                      maxHeight: '40vh',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </Stack>
            )}
            {crop && (
              <canvas
                ref={previewCanvasRef}
                className="mt-4"
                style={{
                  display: 'none',
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: 150,
                  height: 150,
                }}
              />
            )}
            {!error && (
              <Typography
                sx={{
                  mt: '8px',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '140%',
                  opacity: 0.6,
                  textAlign: 'center',
                }}
              >
                {t('projects.imgRules')}
              </Typography>
            )}
          </Stack>

          <Button
            sx={{
              m: '0 auto ',
              fontWeight: '600',
              fontSize: '18px',
              color: 'grey.15',
              borderRadius: '8px',
              padding: '16px 32px',
              width: '100%',
              height: '56px',
              boxShadow: ' 0 4px 10px 0 rgba(202, 250, 68, 0.15)',
              backgroundColor: 'green.2',
              '&:hover': { backgroundColor: 'green.2' },
            }}
            onClick={closeCropImageModal}
          >
            {t('common.continue')}
          </Button>
          <Stack
            sx={{
              position: 'relative',
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={onSelectImage}
              style={{
                position: 'absolute',
                opacity: '0',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                zIndex: '10',
              }}
            />
            <Button
              sx={{
                mt: '12px',
                color: 'green.2',
                backgroundColor: 'transparent',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              {t('cropImage.chooseAnother')}
            </Button>
          </Stack>
        </Stack>
      </ModalBox>
    </Stack>
  );
};

export default CropImage;
