import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const refreshBotStatistic = async (botId: ITelegramBot['_id']) => {
  const { data } = await axios.get(
    `/telegram-bots/${botId}/refresh-statistics`,
  );

  return data;
};

export default refreshBotStatistic;
