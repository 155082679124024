import React from 'react';

const ImagePlusIcon = ({ width = '31', height = '31' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 18.5V23H18.5V26H23V30.5H26V26H30.5V23H26V18.5H23ZM15.95 27.5H3.5C1.85 27.5 0.5 26.15 0.5 24.5V3.5C0.5 1.85 1.85 0.5 3.5 0.5H24.5C26.15 0.5 27.5 1.85 27.5 3.5V15.95C26.6 15.65 25.55 15.5 24.5 15.5C22.85 15.5 21.2 15.95 19.85 16.85L17.75 14L12.5 20.75L8.75 16.25L3.5 23H15.65C15.5 23.45 15.5 24.05 15.5 24.5C15.5 25.55 15.65 26.6 15.95 27.5Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default ImagePlusIcon;
