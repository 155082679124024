import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../AppContext';
import Input from '../../common/inputs/InputWithIcon';
import ModalBox from '../../common/modal/modal-box';
import { MessengerContext, TelegramDialog } from '../messenger.context';

interface NamesModalProps {
  namesModalOpen: boolean;
  onClose: () => void;
  dialog: TelegramDialog;
}

const NamesModal: FC<NamesModalProps> = ({
  namesModalOpen,
  dialog,
  onClose,
}) => {
  const { updateDialogNames } = useContext(MessengerContext);
  const { triggerSnackbar } = useContext(AppContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const saveButtonDisabled =
    !Boolean(firstName) ||
    dialog.firstName + dialog.lastName === firstName + lastName;

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };
  const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  useEffect(() => {
    setFirstName(dialog.firstName);
    setLastName(dialog.lastName);
  }, [dialog]);

  const onSave = () => {
    onClose();
    updateDialogNames(dialog._id, { firstName, lastName });
    triggerSnackbar(t('messenger.currentDialog.namesModal.notification'));
  };

  return (
    <ModalBox
      open={namesModalOpen}
      onClose={onClose}
      maxWidth="492px"
      sx={{
        backgroundColor: 'grey.14',
        borderColor: 'grey.10',
        p: '24px 32px 32px',
      }}
    >
      <Stack sx={{ gap: '16px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          {t('messenger.currentDialog.namesModal.title')}
        </Typography>
        <Stack gap="8px">
          <Input
            value={firstName}
            handleChangeSearch={onNameChange}
            placeholder={t(
              'messenger.currentDialog.namesModal.firstNamePlaceholder',
            )}
            withIcon={false}
            backgroundNarrative="dark"
          />
          <Input
            value={lastName}
            handleChangeSearch={onLastNameChange}
            placeholder={t(
              'messenger.currentDialog.namesModal.lastNamePlaceholder',
            )}
            withIcon={false}
            backgroundNarrative="dark"
          />
        </Stack>
        <Stack
          direction="row"
          gap="8px"
          sx={{
            width: '100%',
          }}
        >
          <Button fullWidth className="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button
            fullWidth
            className="blue"
            disabled={saveButtonDisabled}
            onClick={onSave}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default NamesModal;
