import { IMedia, TelegramButton } from '../../interfaces/common';

export enum TemplateMessageType {
  media = 'media',
  voice = 'voice',
  videoNote = 'videoNote',
}

export interface ITemplate {
  _id: string;
  name: string;
  text: string | null;
  buttons: TelegramButton[][];
  media?: IMedia[];
  type: TemplateMessageType;
  owner: string;
  bot: string;
  isPrivate: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateTemplate
  extends Omit<
    ITemplate,
    '_id' | 'createdAt' | 'updatedAt' | 'bot' | 'owner' | 'media'
  > {
  media?: string[];
}

export type UpdateTemplate = Partial<
  Omit<ITemplate, 'createdAt' | 'updatedAt' | 'owner' | 'bot' | 'media'>
> & {
  media?: string[];
};
