import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { t } from 'i18next';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import ControlButtonsEditor from '../../../common/flow-nodes/control-buttons-editor';
import { ChatActionTypes, IChatActionFlowNodeData } from '../interfaces';

interface ChatActionEditorProps {
  open: boolean;
  initialChatAction: IChatActionFlowNodeData;
  onClose: () => void;
  onSubmit: (
    e: React.SyntheticEvent,
    chatAction: IChatActionFlowNodeData,
  ) => void;
}

const ChatActionEditor: FC<ChatActionEditorProps> = ({
  open,
  onClose,
  onSubmit,
  initialChatAction,
}) => {
  const ACTION_TYPES: ChatActionTypes[] = [
    'typing',
    'upload_photo',
    'record_video',
    'record_voice',
    'upload_document',
    'choose_sticker',
    'record_video_note',
  ];

  const [chatAction, setChatAction] = useState<IChatActionFlowNodeData>({
    actionType: 'typing',
    duration: 1,
  });

  const changeAnimationDuration = (_: Event, duration: number | number[]) => {
    setChatAction((prev) => ({ ...prev, duration: duration as number }));
  };

  const changeAnimationType = (
    _: SyntheticEvent<Element, Event>,
    value: string | null,
  ) => {
    setChatAction((prev) => ({
      ...prev,
      actionType: value ?? '',
    }));
  };

  const onSubmitButton = (e: React.SyntheticEvent) => {
    onSubmit(e, chatAction);
  };

  useEffect(() => {
    setChatAction(initialChatAction);
  }, [initialChatAction]);

  if (!open) return null;

  const buttonDisabled = Boolean(
    !chatAction.actionType || chatAction.duration < 0,
  );

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderRight: '2px solid',
        borderLeft: '2px solid',
        borderColor: 'grey.10',
      }}
    >
      <Stack
        sx={{
          mt: '10px',
        }}
        gap="5px"
      >
        <Stack
          sx={{
            width: '100%',
            borderRadius: '4px',
            backgroundColor: 'grey.13',
            padding: '8px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: 'grey.1',
              fontWeight: 500,
              fontSize: '16px',
              opacity: 0.8,
            }}
          >
            {t('flowNodes.chatAction')}
          </Typography>
        </Stack>
        <Stack>
          <Stack>
            <Autocomplete
              options={ACTION_TYPES}
              getOptionLabel={(option) => {
                if (!option) return '';
                return t(`flowNodes.actionTypes.${option as ChatActionTypes}`);
              }}
              value={chatAction.actionType}
              onChange={changeAnimationType}
              sx={{
                mt: '16px',
                width: '100%',
                p: '0 10px',
                '& .MuiInputBase-root': { backgroundColor: 'grey.15' },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('flowNodes.actionLabel')}
                  sx={{
                    p: '0',
                    '& .MuiInputBase-input': { p: '0' },
                  }}
                />
              )}
            />
          </Stack>
          <Stack
            sx={{
              width: '100%',
              p: '10px 20px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                m: '16px 0 14px',
              }}
            >
              {t('flowNodes.durationLabel')}
            </Typography>
            <Slider
              value={chatAction.duration}
              onChange={changeAnimationDuration}
              getAriaValueText={(value: number) => `${value}°C`}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={5}
              sx={{
                color: 'green.2',
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <ControlButtonsEditor
        onClose={onClose}
        onSubmit={onSubmitButton}
        sendButtonDisabled={buttonDisabled}
      />
    </Stack>
  );
};

export default ChatActionEditor;
