import { CreateOrEditProjectInviteAccess } from '../../projects/project.interface';
import axios from '../axios';

const editInviteLink = async (
  projectId: string,
  projectInviteAccessId: string,
  editedInviteLink: CreateOrEditProjectInviteAccess,
) => {
  const { data } = await axios.patch(
    `/project-invites/${projectId}/${projectInviteAccessId}`,
    editedInviteLink,
  );

  return data;
};

export default editInviteLink;
