import React from 'react';

const ChainIcon = ({ fill = '#FFF' }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7117 15C12.7279 15.6492 12.2086 16.1685 11.5594 16.1523C10.9264 16.1523 10.4071 15.633 10.4071 15C10.4071 11.8353 12.9794 9.26293 16.1442 9.26293L21.8894 9.26293C25.0541 9.26293 27.6264 11.8353 27.6264 15C27.6264 18.1648 25.0541 20.7371 21.8894 20.7371H19.4712C20.1447 20.0798 20.7046 19.3089 21.1104 18.4488L21.8812 18.4407C23.7882 18.4488 25.33 16.907 25.3219 15C25.33 13.0931 23.7882 11.5513 21.8812 11.5594L16.1523 11.5594C14.2453 11.5513 12.7035 13.0931 12.7117 15ZM18.4406 13.8477C19.0736 13.8477 19.5929 14.3671 19.5929 15C19.5929 18.1648 17.0206 20.7371 13.8558 20.7371L8.11064 20.7371C4.94592 20.7371 2.37356 18.1648 2.37356 15C2.37356 11.8353 4.94592 9.26293 8.11064 9.26293L10.5288 9.26293C9.8553 9.92022 9.29538 10.6911 8.88154 11.5594L8.11876 11.5594C6.21181 11.5513 4.67002 13.0931 4.67813 15C4.67002 16.907 6.21181 18.4488 8.11875 18.4407L13.8477 18.4407C15.7547 18.4488 17.2965 16.907 17.2883 15C17.2721 14.3508 17.7914 13.8315 18.4406 13.8477Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChainIcon;
