import { Stack } from '@mui/material';
import React, { useContext, useState } from 'react';

import Logo from '../common/logo';
import NavigateToDefault from '../router/NavigateToDefault';
import { UserContext } from '../user/user.context';

import LoginCodeForm from './login-code.form';
import TelegramLoginButton from './telegram-login.button';

const LoginPage = () => {
  const { user } = useContext(UserContext);

  const [clickedLoginButton, setClickedLoginButton] = useState<boolean>(false);

  const clickLoginCallback = () => setClickedLoginButton(true);

  if (user) return <NavigateToDefault />;

  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100vh',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          border: '1px solid',
          borderColor: 'grey.10',
          borderRadius: '12px',
          backgroundColor: 'grey.15',
          p: '32px 55px',
        }}
      >
        <Logo
          width={280}
          onClick={() => {
            //
          }}
        />

        {!clickedLoginButton && (
          <Stack
            sx={{
              mt: '36px',
            }}
          >
            <TelegramLoginButton onClickCallback={clickLoginCallback} />
          </Stack>
        )}

        {clickedLoginButton && <LoginCodeForm />}

        <Stack
          sx={{
            position: 'absolute',
            width: '150px',
            height: '290px',
            bottom: '-150px',
            left: '0',
            backgroundColor: 'green.2',
            borderRadius: '50%',
            filter: 'blur(246px)',
          }}
        />
        <Stack
          sx={{
            position: 'absolute',
            width: '150px',
            height: '290px',
            top: '-150px',
            right: '0',
            backgroundColor: 'green.2',
            borderRadius: '50%',
            filter: 'blur(246px)',
          }}
        />
      </Stack>
    </Stack>
  );
};

export default LoginPage;
