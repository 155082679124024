import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ConditionsWrapperModalProps = {
  children: ReactNode;
  title: string;
  onClose: () => void;
  addToConditions: () => void;
  disabledButton: boolean;
};

const ConditionsWrapperModal: FC<ConditionsWrapperModalProps> = ({
  children,
  title,
  onClose,
  addToConditions,
  disabledButton,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column">
      <Typography sx={{ fontWeight: 500, fontSize: '18px' }}>
        {title}
      </Typography>
      <Stack
        direction="column"
        alignItems="center"
        justify-content="space-between"
      >
        <Stack
          direction="row"
          gap="12px"
          sx={{
            position: 'relative',
            width: '100%',
            m: '12px 0 20px',
          }}
        >
          {children}
        </Stack>
        <Stack
          direction="row"
          gap="12px"
          sx={{
            width: '100%',
          }}
          onClick={onClose}
        >
          <Button
            className="red"
            sx={{
              width: '56px',
              height: '56px',
            }}
          >
            <DeleteIcon sx={{ color: 'grey.1' }} />
          </Button>

          <Button
            onClick={addToConditions}
            className="green"
            disabled={disabledButton}
            sx={{ width: '100%' }}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ConditionsWrapperModal;
