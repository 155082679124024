import CloseIcon from '@mui/icons-material/Close';
import { Button, List, ListItem, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useState } from 'react';

import ModalBox from '../../common/modal/modal-box';

import ChannelCard from './channel.card';
import { ITelegramChannelWithActivators } from './interfaces';

interface ChannelsListProps {
  channels: ITelegramChannelWithActivators[];
  onSelect: (channel: ITelegramChannelWithActivators) => void;
}

const ChannelsList: FC<ChannelsListProps> = ({ channels, onSelect }) => {
  const [isModalInstruction, setIsModalInstruction] = useState(false);

  if (!channels.length) {
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
        }}
        justifyContent="center"
        alignItems="center"
        gap="18px"
      >
        <Stack
          sx={{
            backgroundColor: 'grey.14',
            border: '1px solid',
            borderColor: 'grey.10',
            borderRadius: '12px',
            padding: ' 32px 56px',
            width: '420px',
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
            }}
          >
            {t('channels.noChannels')}
          </Typography>
          <List sx={{ listStyle: 'decimal' }}>
            <ListItem
              sx={{
                display: 'list-item',
                p: '0',
                mt: '20px',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('channels.empty.firstStep')}
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                p: '0',
                mt: '12px',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('channels.empty.secondStep')}
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                p: '0',
                mt: '12px',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('channels.empty.thirdStep')}
            </ListItem>
          </List>
          <Button
            className="brightGreen"
            sx={{
              mt: '24px',
            }}
            disabled
          >
            {t('channels.empty.viewDocumentation')}
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="column">
      <ModalBox
        open={isModalInstruction}
        onClose={() => setIsModalInstruction(false)}
        maxWidth="420px"
        sx={{ p: '28px 24px 24px', position: 'relative' }}
      >
        <Stack
          onClick={() => setIsModalInstruction(false)}
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: '13px',
            right: '13px',
          }}
        >
          <CloseIcon />
        </Stack>
        <Stack>
          <Typography
            sx={{ fontWeight: 600, fontSize: '24px', textAlign: 'center' }}
          >
            {t('channels.howToAdd')}
          </Typography>
        </Stack>
        <Stack
          sx={{
            m: '20px 0 32px',
          }}
        >
          <List sx={{ listStyle: 'decimal' }}>
            <ListItem
              sx={{
                display: 'list-item',
                p: '0',
                mt: '20px',
                ml: '20px',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('channels.empty.firstStep')}
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                p: '0',
                mt: '12px',
                ml: '20px',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('channels.empty.secondStep')}
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                p: '0',
                mt: '12px',
                ml: '20px',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {t('channels.empty.thirdStep')}
            </ListItem>
          </List>
        </Stack>
        <Button className="green" onClick={() => setIsModalInstruction(false)}>
          {t('common.understood')}
        </Button>
      </ModalBox>
      <Stack
        sx={{
          margin: '0 auto',
          maxWidth: '91%',
          width: '100%',
          mb: '16px',
        }}
      >
        <Stack
          direction="row"
          sx={{
            borderRadius: '12px',
            padding: '2px 8px',
            width: 'max-content',
            backgroundColor: 'grey.13',
          }}
          onClick={() => setIsModalInstruction(true)}
        >
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              mr: '20px',
            }}
          >
            {t('channels.howToAdd')}
          </Typography>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer' }}
          >
            <path
              d="M10.3025 7.4375L9.6275 8.1275C9.0875 8.6675 8.75 9.125 8.75 10.25H7.25V9.875C7.25 9.0425 7.5875 8.2925 8.1275 7.7525L9.0575 6.8075C9.335 6.5375 9.5 6.1625 9.5 5.75C9.5 4.9175 8.825 4.25 8 4.25C7.60218 4.25 7.22064 4.40804 6.93934 4.68934C6.65804 4.97064 6.5 5.35218 6.5 5.75H5C5 4.95435 5.31607 4.19129 5.87868 3.62868C6.44129 3.06607 7.20435 2.75 8 2.75C8.79565 2.75 9.55871 3.06607 10.1213 3.62868C10.6839 4.19129 11 4.95435 11 5.75C11 6.41 10.73 7.0025 10.3025 7.4375ZM8.75 13.25H7.25V11.75H8.75M8 0.5C7.01509 0.5 6.03982 0.693993 5.12987 1.0709C4.21993 1.44781 3.39314 2.00026 2.6967 2.6967C1.29018 4.10322 0.5 6.01088 0.5 8C0.5 9.98912 1.29018 11.8968 2.6967 13.3033C3.39314 13.9997 4.21993 14.5522 5.12987 14.9291C6.03982 15.306 7.01509 15.5 8 15.5C9.98912 15.5 11.8968 14.7098 13.3033 13.3033C14.7098 11.8968 15.5 9.98912 15.5 8C15.5 3.8525 12.125 0.5 8 0.5Z"
              fill="#CAFA44"
            />
          </svg>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        gap="12px"
        flexWrap="wrap"
        sx={{
          margin: '0 auto',
          maxWidth: '91%',
          width: '100%',
        }}
      >
        {channels.map((channel) => (
          <ChannelCard
            key={channel._id}
            channel={channel}
            buttonText={t('channels.channelCard.settings')}
            onClick={() => {
              onSelect(channel);
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ChannelsList;
