import { Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { UserContext } from '../user/user.context';

const ProjectNotFound = () => {
  const { user } = useContext(UserContext);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldNavigate(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (shouldNavigate) {
    return <Navigate to={user ? '/projects' : '/login'} />;
  }
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100vh',
      }}
    >
      Project not found. Redirecting...
    </Stack>
  );
};

export default ProjectNotFound;
