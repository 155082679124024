import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { t } from 'i18next';
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';

import { GlobalContext } from '../../GlobalContext';

type StatisticAccordionProps = {
  icon: ReactNode;
  currentTabIndex: number;
  index: number;
  isStatistic: boolean;
};

const StatisticAccordion: FC<StatisticAccordionProps> = ({
  icon,
  currentTabIndex,
  index,
  isStatistic,
}) => {
  const { activeStatisticSection, toggleActiveStatisticSection } =
    useContext(GlobalContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (!isStatistic) {
      setExpanded(false);
    }
  }, [isStatistic]);
  return (
    <Accordion
      sx={{
        width: '100%',
        '&:hover': {
          backgroundColor: expanded ? 'transparent' : 'grey.14',
        },
      }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: expanded ? 'green.2' : 'grey.1',
            }}
          />
        }
        sx={{
          p: '0',
          marginTop: '10px',
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            pl: '24px',
            color: currentTabIndex === index ? 'green.2' : 'grey.1',
            '& .MuiPaper-root': {
              width: '100%',
            },
          }}
        >
          <Stack>{icon}</Stack>
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '140%',
            }}
          >
            {t('bot-page.tabs.statistics')}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          borderTop: ' 1px solid',
          borderColor: 'grey.13',
        }}
      >
        <Stack
          sx={{
            marginBottom: '10px',
          }}
        >
          <svg
            width="15"
            height="54"
            viewBox="0 0 15 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 14H13L10.7889 12.8944C9.96699 12.4835 9 13.0811 9 14Z"
              fill="#FEFEFE"
            />
            <path
              d="M9 14C9 14.9189 9.96699 15.5165 10.7889 15.1056L13 14H9Z"
              fill="#FEFEFE"
            />
            <path
              d="M9 52H13L10.7889 50.8944C9.96699 50.4835 9 51.0811 9 52Z"
              fill="#FEFEFE"
            />
            <path
              d="M9 52C9 52.9189 9.96699 53.5165 10.7889 53.1056L13 52H9Z"
              fill="#FEFEFE"
            />
            <path
              d="M1 0V14M1 14V50C1 51.1046 1.89543 52 3 52H9M1 14H9M13 14L10.7889 12.8944C9.96699 12.4835 9 13.0811 9 14V14M13 14L10.7889 15.1056C9.96699 15.5165 9 14.9189 9 14V14M13 14H9M13 52L10.7889 50.8944C9.96699 50.4835 9 51.0811 9 52V52M13 52L10.7889 53.1056C9.96699 53.5165 9 52.9189 9 52V52M13 52H9"
              stroke="#FEFEFE"
            />
          </svg>
        </Stack>
        <Stack
          sx={{
            alignSelf: 'end',
            gap: '10px',
          }}
        >
          <Typography
            onClick={() => toggleActiveStatisticSection('Bot')}
            sx={{
              textAlign: 'start',
              fontWeight: 500,
              fontSize: '18px',
              color: activeStatisticSection === 'Bot' ? 'green.2' : 'grey.1',
            }}
          >
            {t('common.bot')}
          </Typography>
          <Typography
            onClick={() => toggleActiveStatisticSection('Channels')}
            sx={{
              textAlign: 'start',
              fontWeight: 500,
              fontSize: '18px',
              color:
                activeStatisticSection === 'Channels' ? 'green.2' : 'grey.1',
            }}
          >
            {t('common.channels')}
          </Typography>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default StatisticAccordion;
