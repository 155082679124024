import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import CopyableText from '../../common/copyableText/copyableText';
import ModalBox from '../../common/modal/modal-box';
import { IFlowWithActivatorsAndFolder } from '../flows/interfaces';

import getChannelScriptText from './get-channel-script-text';
import getFlowScriptText from './get-flow-script-text';

interface ChannelScriptProps {
  type: 'channel';
  botId: string;
  channelId: string;
  flows: IFlowWithActivatorsAndFolder[];
}

interface FlowScriptProps {
  type: 'flow';
  botId: string;
  flowId: string;
  botUsername: string;
}

interface CommonScriptInfoProps {
  scriptModalOpen: boolean;
  handleScriptModalClose: () => void;
}

type ModalScriptInfoProps = CommonScriptInfoProps &
  (FlowScriptProps | ChannelScriptProps);

const ModalScriptInfo: FC<ModalScriptInfoProps> = (props) => {
  const { t } = useTranslation();
  const { triggerSnackbar } = useContext(AppContext);

  const { scriptModalOpen, handleScriptModalClose, type, botId } = props;
  const [availableFlows, setAvailableFlows] = useState<
    IFlowWithActivatorsAndFolder[]
  >([]);

  useEffect(() => {
    if (type === 'channel') {
      const { flows } = props as ChannelScriptProps;
      setAvailableFlows([...flows]);
    }
  }, []);

  const buttonId = useMemo(
    () => (type === 'channel' ? props.channelId : props.flowId),
    [],
  );

  const [joinFlow, setJoinFlow] = useState<string | undefined>(undefined);
  const [leaveFlow, setLeaveFlow] = useState<string | undefined>(undefined);

  const handleChangeJoinFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setJoinFlow(value);
  };

  const handleChangeLeaveFlow = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setLeaveFlow(value);
  };

  const onClearJoinFlow = () => {
    setJoinFlow(undefined);
  };

  const onClearLeaveFlow = () => {
    setLeaveFlow(undefined);
  };

  let scriptText = '';

  if (type === 'channel') {
    scriptText = getChannelScriptText(
      botId,
      props.channelId,
      buttonId,
      joinFlow,
      leaveFlow,
    );
  }
  if (type === 'flow') {
    scriptText = getFlowScriptText(
      botId,
      props.botUsername,
      props.flowId,
      buttonId,
    );
  }

  const copyScript = () => {
    navigator.clipboard.writeText(scriptText as string);
    triggerSnackbar(t('copyableText.text'));
  };

  return (
    <ModalBox
      open={scriptModalOpen}
      onClose={handleScriptModalClose}
      maxWidth={'630px'}
      sx={{
        position: 'relative',
        p: '12px 24px 24px',
      }}
    >
      <Fragment>
        <Stack>
          <Typography
            sx={{
              textAlign: 'center',
              mb: '12px',
              fontWeight: 500,
              fontSize: '18px',
            }}
          >
            {t('common.script')}
          </Typography>
          <Divider />
          <Typography
            sx={{ m: '16px auto', fontWeight: 500, fontSize: '16px' }}
          >
            {`${t('script.instruction.firstPart')} </body>, ${t(
              'script.instruction.secondPart',
            )} <button id="${t(
              'script.instruction.copyIdHere',
            )}">go to telegram</button>`}
          </Typography>
          {type === 'channel' && (
            <Stack
              sx={{
                mb: '12px',
              }}
            >
              <Stack flexDirection="row" gap="10px">
                <FormControl fullWidth>
                  <InputLabel>{t('common.subscriptionFlow')}</InputLabel>
                  <Select
                    sx={{
                      backgroundColor: 'grey.15',
                    }}
                    label={t('common.subscriptionFlow')}
                    value={joinFlow ?? ''}
                    onChange={handleChangeJoinFlow}
                  >
                    {availableFlows.map(({ _id, name }) => (
                      <MenuItem key={_id} value={_id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  className="red"
                  sx={{
                    minWidth: '40px',
                    width: '40px',
                    height: '56px',
                    cursor: 'pointer',
                  }}
                  onClick={onClearJoinFlow}
                >
                  <DeleteIcon sx={{ color: 'grey.1' }} />
                </Button>
              </Stack>
              <Stack flexDirection="row" gap="10px" sx={{ mt: '10px' }}>
                <FormControl fullWidth>
                  <InputLabel>{t('common.unsubscribeFlow')}</InputLabel>
                  <Select
                    sx={{
                      backgroundColor: 'grey.15',
                    }}
                    label={t('common.unsubscribeFlow')}
                    value={leaveFlow ?? ''}
                    onChange={handleChangeLeaveFlow}
                  >
                    {availableFlows.map(({ _id, name }) => (
                      <MenuItem key={_id} value={_id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  className="red"
                  sx={{
                    minWidth: '40px',
                    width: '40px',
                    height: '56px',
                    cursor: 'pointer',
                  }}
                  onClick={onClearLeaveFlow}
                >
                  <DeleteIcon sx={{ color: 'grey.1' }} />
                </Button>
              </Stack>
            </Stack>
          )}
          <TextField
            sx={{
              backgroundColor: 'grey.15',
            }}
            multiline
            rows={4}
            disabled
            value={scriptText}
          />

          <Button
            startIcon={<ContentCopyIcon />}
            className="green"
            sx={{
              height: '56px',
              mt: '16px',
            }}
            onClick={copyScript}
          >
            {t('common.copy')}
          </Button>
        </Stack>
        <Stack
          sx={{
            position: 'absolute',
            width: '630px',
            bottom: '-72px',
            left: '0px',
            backgroundColor: 'grey.14',
            border: '1px solid',
            borderColor: 'grey.10',
            borderRadius: '12px',
            p: '16px 10px',
          }}
          direction="row"
          alignItems="center"
          gap="5px"
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              mr: '8px',
            }}
          >
            Button id:
          </Typography>
          <CopyableText text={buttonId} fontSize={'18px'} fontWeight={'500'} />
        </Stack>
      </Fragment>
    </ModalBox>
  );
};

export default ModalScriptInfo;
