import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ThreeDotsMenu from '../common/threeDotsMenu/three-dots-menu.component';
import TooltipInfo from '../common/tooltip/tooltip';
import { TelegramMessengerMode } from '../telegram-bots/telegram-bot.interface';
import { TelegramUserMessengerMode } from '../telegram-user-accounts/telegram-user.interface';

interface StatusControlProps {
  mode: TelegramMessengerMode | TelegramUserMessengerMode;
  handleChangeMode: (newMode: TelegramMessengerMode) => void;
}

export const getModeProps = (mode: 'online' | 'offline' | 'silentOnline') => {
  switch (mode) {
    case TelegramMessengerMode.online: {
      return {
        color: 'green.2',
        background:
          'linear-gradient(90deg, rgba(202, 250, 68, 0) 0%, rgba(202, 250, 68, 0.25) 100%)',
      };
    }
    case TelegramMessengerMode.offline: {
      return {
        color: 'red.2',
        background:
          'linear-gradient(90deg, rgba(255, 31, 110, 0) 0%, rgba(255, 31, 110, 0.25) 100%)',
      };
    }
    case TelegramMessengerMode.silentOnline: {
      return {
        color: 'grey.secondary',
        background:
          'linear-gradient(90deg, rgba(161, 161, 161, 0) 0%, rgba(161, 161, 161, 0.25) 100%)',
      };
    }

    default: {
      return {
        color: 'grey.7',
        background:
          'linear-gradient(270deg, rgba(177, 176, 177, 0.30) 0%, rgba(177, 176, 177, 0.00) 100%)',
      };
    }
  }
};

const ModeControl: FC<StatusControlProps> = ({ mode, handleChangeMode }) => {
  const { t } = useTranslation();
  const { color } = getModeProps(mode);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ml: '10px',
      }}
      gap="15px"
    >
      <Typography
        sx={{
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '18px',
        }}
      >
        {t('common.status')}
      </Typography>
      <Stack
        onClick={(e) => openMenu(e)}
        sx={{
          width: '150px',
          position: 'relative',
        }}
        alignItems="start"
        justifyContent="start"
      >
        <Stack
          sx={{
            position: 'absolute',
            width: '100%',
            height: '60px',
            top: '-75%',
            zIndex: -1,
          }}
        />
        <Typography
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '14px',
            color,
          }}
        >
          {t(`messengerMode.${mode}`)}
        </Typography>
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          right: '6%',
          top: 'calc(50% - 7px)',
        }}
      >
        <TooltipInfo
          icon={<InfoOutlinedIcon />}
          title={
            <Stack gap="10px">
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {t('messenger.statusTooltips.online')}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {t('messenger.statusTooltips.pause')}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                {t('messenger.statusTooltips.offline')}
              </Typography>
            </Stack>
          }
        />
      </Stack>

      <ThreeDotsMenu
        open={menuOpen}
        onClose={closeMenu}
        anchor={anchorEl}
        border={'1px solid'}
        borderRadius={'8px'}
        borderColor={'grey.10'}
        backgroundColor={'grey.15'}
        marginDivider={'1px'}
        paddingMenu={'0 12px'}
        paddingMenuItem={'12px 12px 12px 2px'}
        menuItems={[
          [
            {
              text: t(`messengerMode.${TelegramMessengerMode.offline}`),
              textSx: { color: 'red.2', fontWeight: 500, fontSize: '16px' },
              onClick: () => {
                handleChangeMode(TelegramMessengerMode.offline);
              },
            },
          ],
          [
            {
              text: t(`messengerMode.${TelegramMessengerMode.silentOnline}`),
              textSx: { color: 'grey.4', fontWeight: 500, fontSize: '16px' },
              onClick: () => {
                handleChangeMode(TelegramMessengerMode.silentOnline);
              },
            },
          ],
          [
            {
              text: t(`messengerMode.${TelegramMessengerMode.online}`),
              textSx: { color: 'green.2', fontWeight: 500, fontSize: '16px' },
              onClick: () => {
                handleChangeMode(TelegramMessengerMode.online);
              },
            },
          ],
        ]}
      />
    </Stack>
  );
};

export default ModeControl;
