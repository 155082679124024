import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { TELEGRAM_MESSAGE_MAX_LENGTH } from '../../common/constants/constants';
import ModalBox from '../../common/modal/modal-box';
import {
  IMedia,
  TelegramButton,
  ITelegramMessage,
  TelegramMessageType,
} from '../../interfaces/common';
import { getBotIdAndType } from '../../utils/getBotIdAndType';

import AddMediaButton from './add-media.button';
import AttachedMediaPreview from './attached-media.preview';
import ButtonsEditor from './buttons.editor';

interface ModalPreviewProps {
  open: boolean;
  onClose: () => void;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  newMessage: string;
  onAddRow: () => void;
  buttons: TelegramButton[][];
  onRemoveRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, buttonIndex: number) => void;
  onAddButton: (buttonIndex: number) => void;
  onButtonChange: (
    rowIndex: number,
    buttonIndex: number,
    key: string,
    value: string,
  ) => void;
  onRemoveMedia: (_id: string) => void;
  onSubmit: (e: React.SyntheticEvent) => void;
  openMediaModal: () => void;
  openVoiceModal: () => void;
  openVideoNoteModal: () => void;
  media: IMedia[];
  sendButtonDisabled: boolean;
  addNewButtonsRowDisabled: boolean;
  messageType: TelegramMessageType;
  onMessageTypeChange: (newType: TelegramMessageType) => void;
  clearMessageState: () => void;
  editingMessageId: ITelegramMessage['_id'] | null;
}

const MessagePreviewModal: FC<ModalPreviewProps> = ({
  open,
  onClose,
  onInput,
  newMessage,
  buttons,
  onAddRow,
  onRemoveRow,
  onRemoveButton,
  onAddButton,
  onButtonChange,
  onRemoveMedia,
  onSubmit,
  media,
  sendButtonDisabled,
  openMediaModal,
  openVoiceModal,
  openVideoNoteModal,
  addNewButtonsRowDisabled,
  messageType,
  onMessageTypeChange,
  clearMessageState,
  editingMessageId,
}) => {
  const botType = getBotIdAndType().botType;

  const [mediaTabIndex, setMediaTabIndex] = useState(0);

  useEffect(() => {
    switch (messageType) {
      case TelegramMessageType.media: {
        setMediaTabIndex(0);
        return;
      }
      case TelegramMessageType.videoNote: {
        setMediaTabIndex(1);
        return;
      }
      case TelegramMessageType.voice: {
        setMediaTabIndex(2);
        return;
      }
    }
  }, [messageType]);

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0: {
        onMessageTypeChange(TelegramMessageType.media);
        clearMessageState();
        return;
      }
      case 1: {
        onMessageTypeChange(TelegramMessageType.videoNote);
        clearMessageState();
        return;
      }
      case 2: {
        onMessageTypeChange(TelegramMessageType.voice);
        clearMessageState();
        return;
      }
    }
  };

  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth={'704px'}
      sx={{ p: '0 0 24px', position: 'relative', borderRadius: '12px' }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '-30px',
          right: '0',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={mediaTabIndex}
          onChange={onTabChange}
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.10',
            '& .MuiTabs-indicator': {
              backgroundColor: 'green.2',
            },
            '& .MuiTab-root.Mui-selected': {
              color: 'green.2',
            },
          }}
        >
          <Tab
            disabled={
              Boolean(editingMessageId) &&
              messageType !== TelegramMessageType.media
            }
            label="Media"
            sx={{
              width: '33.33%',
              textTransform: 'none',
              borderRight: '1px solid ',
              borderColor: 'grey.10',
            }}
          />
          <Tab
            disabled={
              Boolean(editingMessageId) &&
              messageType !== TelegramMessageType.videoNote
            }
            label="Video Note"
            sx={{
              width: '33.33%',
              textTransform: 'none',
              borderRight: '1px solid ',
              borderColor: 'grey.10',
            }}
          />
          <Tab
            disabled={
              Boolean(editingMessageId) &&
              messageType !== TelegramMessageType.voice
            }
            label="Voice"
            sx={{ width: '33.33%', textTransform: 'none' }}
          />
        </Tabs>
      </Box>
      <Stack sx={{ padding: '14px 24px 0' }}>
        <Stack direction="row" gap="16px">
          <Stack
            sx={{
              width: '50%',
            }}
          >
            <Stack
              flexDirection="row"
              gap="10px"
              sx={{
                width: '100%',
                maxWidth: '320px',
                height: '112px',
                border: '1px solid',
                borderColor: 'grey.10',
                borderRadius: '8px',
                padding: '6px',
                backgroundColor: 'grey.15',
              }}
            >
              <AddMediaButton
                openMediaModal={openMediaModal}
                openVideoNoteModal={openVideoNoteModal}
                openVoiceModal={openVoiceModal}
                messageType={messageType}
              />
              <AttachedMediaPreview
                media={media}
                onRemoveMedia={onRemoveMedia}
                size="small"
              />
            </Stack>
            {messageType === TelegramMessageType.videoNote && (
              <Typography
                sx={{
                  mt: '6px',
                  fontWeight: 500,
                  fontSize: '14px',
                  color: 'grey.secondary',
                  textAlign: 'center',
                }}
              >
                {t('common.notSupportMessagePlaceholder')}
              </Typography>
            )}
          </Stack>
          {messageType !== TelegramMessageType.videoNote && (
            <Stack
              sx={{
                position: 'relative',
                width: '50%',
              }}
            >
              <TextField
                sx={{
                  borderRadius: '8px',
                  height: '112px',
                  backgroundColor: 'grey.15',
                  width: '100%',
                  maxWidth: '320px',
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'green.2',
                      overflow: 'hidden',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'green.2',
                    },
                  },
                }}
                InputProps={{
                  sx: {
                    height: '100%',
                    width: '100%',
                    p: '8px 12px',
                    '& .MuiInputBase-input': {
                      height: '100%!important',
                      width: '100%',
                    },
                  },
                }}
                multiline
                placeholder={t('common.enterMessagePlaceholder')}
                value={newMessage}
                minRows={5}
                maxRows={10}
                onChange={onInput}
              />
              <Stack
                sx={{
                  position: 'absolute',
                  bottom: '-16px',
                  right: '0',
                  opacity: '0.4',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '10px',
                  }}
                >
                  {TELEGRAM_MESSAGE_MAX_LENGTH}/{newMessage.length}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        {botType === 'BOT' && (
          <ButtonsEditor
            buttons={buttons}
            addNewButtonsRowDisabled={addNewButtonsRowDisabled}
            onAddButton={onAddButton}
            onButtonChange={onButtonChange}
            onRemoveButton={onRemoveButton}
            onAddRow={onAddRow}
            onRemoveRow={onRemoveRow}
          />
        )}

        <Stack>
          <Button
            className="brightGreen"
            fullWidth
            sx={{
              mt: '10px',
              borderRadius: '8px',
            }}
            onClick={onSubmit}
            disabled={sendButtonDisabled}
          >
            {t('common.send')}
          </Button>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default MessagePreviewModal;
