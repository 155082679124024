import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import { IMedia } from '../../interfaces/common';

interface PhotoItemProps {
  media: IMedia;
  isSelected: boolean;
  onSelectMedia: (media: IMedia) => void;
  number: number;
  onDelete: () => void;
}

const PhotoItem: FC<PhotoItemProps> = ({
  media,
  isSelected,
  onSelectMedia,
  number,
  onDelete,
}) => {
  return (
    <Stack
      key={media._id}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '6px',
        border: '2px solid',
        borderColor: isSelected ? 'green.2' : 'grey.10',
      }}
      onClick={() => {
        onSelectMedia(media);
      }}
    >
      <img
        src={media.url}
        style={{
          width: '96px',
          height: '96px',
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: '4px',
        }}
        alt=""
      />
      <Button
        className="red"
        sx={{
          width: '24px',
          minWidth: '24px',
          height: '24px',
          borderRadius: '2px!important',
          position: 'absolute',
          bottom: '0',
          right: '0',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <DeleteIcon sx={{ width: '16px', height: '16px' }} />
      </Button>
      {isSelected && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            backgroundColor: 'green.2',
            top: 0,
            right: 5,
            width: '20px',
            height: '20px',
            borderBottomLeftRadius: '3px',
            borderBottomRightRadius: '3px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              color: 'grey.14',
            }}
          >
            {number}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default PhotoItem;
