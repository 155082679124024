import { Stack } from '@mui/material';
import { t } from 'i18next';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import createTemplate from '../api/templates/create-template';
import deleteTemplate from '../api/templates/delete-template';
import editTemplate from '../api/templates/edit-template';
import fetchTemplates from '../api/templates/fetch-templates';
import { AppContext } from '../AppContext';
import MediaModal from '../common/media/media.modal';
import VideoNoteModal from '../common/media/video-notes.modal';
import VoiceModal from '../common/media/voice.modal';
import { IMedia, TelegramMessageType } from '../interfaces/common';
import { getBotIdAndType } from '../utils/getBotIdAndType';
import useNewTelegramMessage from '../utils/hooks/useNewTelegramMessage';

import {
  CreateTemplate,
  ITemplate,
  TemplateMessageType,
} from './templates/interfaces';
import TemplateList from './templates/template-list';
import TemplateModal from './templates/template-modal';

const TemplatesTab = () => {
  const { triggerSnackbar, openConfirmationDialog } = useContext(AppContext);

  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [templateOpenModal, setTemplateOpenModal] = useState<boolean>(false);
  const [mediaModalOpen, setMediaModalOpen] = useState<boolean>(false);
  const [voiceModalOpen, setVoiceModalOpen] = useState<boolean>(false);
  const [videoNotesModalOpen, setVideoNotesModalOpen] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentTemplateId, setCurrentTemplateId] = useState<
    ITemplate['_id'] | null
  >(null);
  const [name, setName] = useState('');
  const [type, setType] = useState<TemplateMessageType>(
    TemplateMessageType.media,
  );
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const botId = getBotIdAndType().botId;
  const botType = getBotIdAndType().botType;

  const {
    newMessageText,
    setNewMessageText,
    onChangeNewMessageText,
    media,
    updateMedia,
    onSelectMedia,
    onRemoveMedia,
    updateButtons,
    onButtonChange,
    onRemoveButton,
    onRemoveButtonsRow,
    onAddButtonsRow,
    clearMessageState,
    onAddUrlButton,
    onMessageTypeChange,
    addNewButtonsRowDisabled,
    buttons,
    isEmptyButtons,
  } = useNewTelegramMessage();

  const openModalByType = () => {
    switch (type) {
      case TemplateMessageType.media: {
        setMediaModalOpen(true);
        onMessageTypeChange(TelegramMessageType.media);
        return;
      }
      case TemplateMessageType.videoNote: {
        setVideoNotesModalOpen(true);
        onMessageTypeChange(TelegramMessageType.videoNote);
        return;
      }
      case TemplateMessageType.voice: {
        setVoiceModalOpen(true);
        onMessageTypeChange(TelegramMessageType.voice);
        return;
      }
      default: {
        return setMediaModalOpen(true);
      }
    }
  };

  const handleOpenModal = () => {
    setTemplateOpenModal(true);
  };

  const handleCloseModal = () => {
    setTemplateOpenModal(false);
    setName('');
    setType(TemplateMessageType.media);
    updateMedia([]);
    setNewMessageText('');
    updateButtons([]);
    setIsEdit(false);
  };

  const getTemplates = () => {
    if (!botId) return;

    fetchTemplates(botId, botType).then(setTemplates);
  };

  const onCreateTemplate = (data: CreateTemplate) => {
    if (!botId) return;

    createTemplate(botId, botType, data)
      .then(getTemplates)
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorCreatingTemplate'), 'error');
      });
    handleCloseModal();
  };

  const onEditSubmit = (data: CreateTemplate) => {
    if (!botId || !currentTemplateId) return;

    editTemplate(botId, botType, currentTemplateId, data)
      .then(getTemplates)
      .catch(() => {
        triggerSnackbar(t('triggerSnackbar.errorCreatingTemplate'), 'error');
      });
    handleCloseModal();
    setIsEdit(false);
  };

  const onOpenEditTemplate = (template: Partial<ITemplate>) => {
    setName(template?.name ?? '');
    setType(template?.type ?? TemplateMessageType.media);
    updateMedia(template?.media ?? []);
    setNewMessageText(template?.text ?? '');
    setIsPrivate(template?.isPrivate ?? false);
    updateButtons(template?.buttons ?? []);
    setCurrentTemplateId(template?._id ?? null);
    setIsEdit(true);
    handleOpenModal();
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeType = (newType: TemplateMessageType) => {
    if (newType !== type) {
      clearMessageState();
    }
    setType(newType);
  };

  const handleChangePrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const isValid = useMemo(
    () => Boolean(name) && Boolean(type) && isEmptyButtons,
    [name, type, newMessageText, buttons],
  );

  const onCreateClick = () => {
    if (!isValid) return;

    const mediaIds =
      media?.map((med) => {
        if (typeof med === 'object' && med !== null && '_id' in med) {
          return (med as IMedia)._id;
        }
        return med as string;
      }) ?? [];

    onCreateTemplate({
      name,
      text: newMessageText,
      media: mediaIds,
      isPrivate,
      buttons,
      type,
    });
  };

  const onEditClick = () => {
    if (!isValid) return;

    const mediaIds =
      media?.map((med) => {
        if (typeof med === 'object' && med !== null && '_id' in med) {
          return (med as IMedia)._id;
        }
        return med as string;
      }) ?? [];
    onEditSubmit({
      name,
      text: newMessageText,
      media: mediaIds,
      isPrivate,
      buttons,
      type,
    });
  };

  const onDeleteClick = (templateId: ITemplate['_id']) => {
    openConfirmationDialog(
      `${t('messenger.templates.deleteText')}`,
      `${t('common.delete')}`,
      () => {
        if (!botId || !templateId) return;

        deleteTemplate(botId, botType, templateId)
          .then(getTemplates)
          .catch(() => {
            triggerSnackbar(
              t('triggerSnackbar.errorDeletingTemplate'),
              'error',
            );
          });
      },
    );
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <Stack
      sx={{
        height: '100%',
      }}
    >
      <TemplateList
        onEdit={onOpenEditTemplate}
        onDelete={onDeleteClick}
        templates={templates}
        openModal={handleOpenModal}
      />
      <TemplateModal
        open={templateOpenModal}
        onClose={handleCloseModal}
        isEdit={isEdit}
        name={name}
        type={type}
        text={newMessageText}
        media={media}
        privateTemplate={isPrivate}
        handleChangePrivate={handleChangePrivate}
        handleChangeName={handleChangeName}
        handleChangeType={handleChangeType}
        handleChangeText={onChangeNewMessageText}
        buttons={buttons}
        onAddRow={onAddButtonsRow}
        onRemoveRow={onRemoveButtonsRow}
        onRemoveButton={onRemoveButton}
        onAddButton={onAddUrlButton}
        onButtonChange={onButtonChange}
        isValid={isValid}
        onSubmit={isEdit ? onEditClick : onCreateClick}
        openModalByType={openModalByType}
        onRemoveMedia={onRemoveMedia}
        addNewButtonsRowDisabled={addNewButtonsRowDisabled}
      />
      <MediaModal
        open={mediaModalOpen}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onClose={() => {
          setMediaModalOpen(false);
        }}
        onRemoveMedia={onRemoveMedia}
      />
      <VoiceModal
        open={voiceModalOpen}
        onClose={() => {
          setVoiceModalOpen(false);
        }}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onRemoveMedia={onRemoveMedia}
      />

      <VideoNoteModal
        open={videoNotesModalOpen}
        onClose={() => {
          setVideoNotesModalOpen(false);
        }}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onRemoveMedia={onRemoveMedia}
      />
    </Stack>
  );
};

export default TemplatesTab;
