import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { t } from 'i18next';
import React, { FC, useContext, useState } from 'react';

import { AppContext } from '../../AppContext';
import ThreeDotsMenu from '../../common/threeDotsMenu/three-dots-menu.component';

interface VariableItemProps {
  variable: string;
  index: number;
  openEditVariableModal: (index: number, variable: string) => void;
  onDeleteVariable: (index: number) => void;
}

const VariableItem: FC<VariableItemProps> = ({
  variable,
  index,
  openEditVariableModal,
  onDeleteVariable,
}) => {
  const { openConfirmationDialog } = useContext(AppContext);

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const openVariableMenu = (e: React.SyntheticEvent) => {
    setMenuAnchor(e.currentTarget);
  };
  return (
    <>
      <TableRow
        sx={{
          backgroundColor: 'grey.15',
          height: '45px',
          ':hover': {
            backgroundColor: 'grey.15',
            cursor: 'pointer',
            transition: ' all 0.2s ease-out',
          },
          '&:last-child': {
            '& td': {
              borderBottom: 'none',
            },
          },
        }}
      >
        <TableCell
          sx={{
            textAlign: 'left',
            pr: '42px',
            pl: '23px',
            fontWeight: 600,
            borderBottom: '1px solid ',
            borderColor: 'grey.10',
            '&::last-child': {
              borderBottom: 'none',
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              opacity: 0.6,
            }}
          >
            {variable}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            pr: '42px',
            justifyContent: 'end',
            borderBottom: '1px solid',
            borderColor: 'grey.10',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              gap="6px"
              alignItems="center"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: ' space-between',
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button
                onClick={openVariableMenu}
                className="blue"
                sx={{
                  padding: '0',
                  height: '29px',
                  width: '29px',
                  minWidth: '29px',
                }}
              >
                <MoreHorizIcon
                  sx={{
                    fontSize: '17px',
                  }}
                />
              </Button>
              <ThreeDotsMenu
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                anchor={menuAnchor}
                border="1px solid "
                borderRadius="8px"
                marginDivider="0 12px"
                backgroundColor="grey.15"
                hoverBackground="grey.15"
                menuItems={[
                  [
                    {
                      text: t('common.edit'),
                      textSx: {
                        color: 'grey.5',
                        fontWeight: 500,
                        fontSize: '16px',
                      },
                      renderIcon: () => (
                        <EditRoundedIcon
                          sx={{
                            fontSize: '18px',
                            color: 'grey.5',
                          }}
                        />
                      ),
                      onClick: () => {
                        openEditVariableModal(index, variable);
                      },
                    },
                  ],
                  [
                    {
                      text: t('common.delete'),
                      textSx: {
                        color: 'red.2',
                        fontWeight: 500,
                        fontSize: '16px',
                      },
                      renderIcon: () => (
                        <DeleteOutlineIcon
                          sx={{ color: 'red.2', fontSize: '18px' }}
                        />
                      ),
                      onClick: () => {
                        openConfirmationDialog(
                          t('variables.deleteVariable'),
                          t('common.delete'),
                          () => {
                            onDeleteVariable(index);
                          },
                        );
                      },
                    },
                  ],
                ]}
              />
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default VariableItem;
