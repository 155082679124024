import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import editUser from '../api/user/edit-user';
import fetchUser from '../api/user/fetch-user';
import login from '../api/user/login';

import { IUser, UpdateUser } from './user.interface';

export type ClientTheme = 'dark' | 'light' | 'lol';

interface IUserContext {
  user: IUser | null;
  handleLogin: (authCode: string) => void;
  logout: () => void;
  currentTheme: ClientTheme;
  loginError: boolean;
  userLoading: boolean;
  handleEditUser: (newUserData: UpdateUser) => void;
}

export const UserContext = createContext<IUserContext>({
  user: null,
  currentTheme: 'dark',
  handleLogin: () => {
    //
  },
  logout: () => {
    //
  },
  loginError: false,
  userLoading: true,
  handleEditUser: () => {
    //
  },
});

const UserContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loginError, setLoginError] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [currentTheme, setCurrentTheme] = useState<ClientTheme>('dark');

  const handleFetchUser = () => {
    fetchUser()
      .then((res: IUser) => setUser(res))
      .catch(() => setUser(null))
      .finally(() => setUserLoading(false));
  };

  useLayoutEffect(() => {
    handleFetchUser();
  }, []);

  useEffect(() => {
    if (!user) return;

    switch (user.clientTheme) {
      case 'light': {
        setCurrentTheme('light');
        break;
      }
      case 'lol': {
        setCurrentTheme('light');
        break;
      }
      default: {
        setCurrentTheme('dark');
      }
    }
  }, [user]);

  const handleLogin = (authCode: string) => {
    login(authCode)
      .then(({ user, token }) => {
        setUser(user);
        localStorage.setItem('token', token);
      })
      .catch(() => setLoginError(true));
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
  };

  const handleEditUser = (newUserData: UpdateUser) => {
    editUser(newUserData).then(handleFetchUser);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        loginError,
        userLoading,
        handleLogin,
        logout,
        handleEditUser,
        currentTheme,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
