import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import { alpha, Divider, Stack, Typography } from '@mui/material';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position } from 'reactflow';

import GroupButtons from '../../../common/flow-nodes/control-buttons-node';
import { darkThemeColors } from '../../../common/theme/dark.theme';
import { convertNumberToTime } from '../../../utils/convertNumberToTime';
import { IDelayFlowNodeData } from '../interfaces';

import pulsingAnimation from './pulsing-animation';

export interface IDelayNodeData {
  onDelete: () => void;
  onSelect: (delayNodeData: IDelayFlowNodeData) => void;
  onCopy: (
    delayNodeData: IDelayFlowNodeData,
    position: { x: number; y: number },
  ) => void;
  position: { x: number; y: number };
  exitEditor: () => void;
  data: IDelayFlowNodeData;
  next: string | null;
  onNodePositionChange: (
    nodeId: string,
    newPosition: { x: number; y: number },
  ) => void;
  _id: string;
}

interface DelayNodeProps extends Omit<NodeProps, 'data'> {
  data: IDelayNodeData;
}

type DaysEnum = {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  [key: number]: string;
};

const DelayNode: FC<DelayNodeProps> = memo(({ data, xPos, yPos, selected }) => {
  useEffect(() => {
    data.onNodePositionChange(data._id, {
      x: xPos,
      y: yPos,
    });
  }, [xPos, yPos]);

  const { t } = useTranslation();

  const daysEnum: DaysEnum = {
    1: t('common.weekDays.monday'),
    2: t('common.weekDays.tuesday'),
    3: t('common.weekDays.wednesday'),
    4: t('common.weekDays.thursday'),
    5: t('common.weekDays.friday'),
    6: t('common.weekDays.saturday'),
    7: t('common.weekDays.sunday'),
  };

  const [isNewNode, setIsNewNode] = useState(false);

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    const { days, hours, minutes, seconds } = convertNumberToTime(
      data.data.delayForSeconds,
    );

    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }, [data.data.delayForSeconds]);

  useEffect(() => {
    const digitsId = Number(data._id);

    setIsNewNode(!isNaN(digitsId));
  }, []);

  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = nodeRef.current;
    const preventZoom = (event: WheelEvent) => event.stopPropagation();

    if (node) {
      node.addEventListener('wheel', preventZoom);
      return () => {
        node.removeEventListener('wheel', preventZoom);
      };
    }
  }, []);

  useEffect(() => {
    if (!selected) {
      data.exitEditor();
    }
  }, [selected]);

  const initialSeconds = data.data.delayForSeconds;
  const applyTimeRange = data.data.applyTimeRange;
  const daysRange = data.data.days;
  const startTime = data.data.startTime;
  const endTime = data.data.endTime;
  const timezone = data.data.timezone;

  const daysString = daysRange
    ?.map((dayNumber) => (daysEnum[dayNumber] ? daysEnum[dayNumber] : ''))
    .join(', ');

  const edit = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    data.onSelect({
      delayForSeconds: initialSeconds,
      applyTimeRange,
      days: daysRange,
      startTime,
      endTime,
      timezone,
    });
  };

  const copy = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    data.onCopy(
      {
        delayForSeconds: initialSeconds,
        applyTimeRange,
        days: daysRange,
        startTime,
        endTime,
        timezone,
      },
      { x: data.position.x + 400, y: data.position.y },
    );
  };

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
      onDoubleClick={edit}
    >
      {selected && (
        <GroupButtons
          onEdit={edit}
          onCopy={copy}
          onDelete={data.onDelete}
          isEmptyNode={Boolean(!data.data.delayForSeconds)}
        />
      )}
      <Stack
        className="dragger"
        sx={{
          width: '340px',
          border: '1px solid',
          borderColor: selected ? 'red.2' : 'grey.10',
          backgroundColor: alpha(darkThemeColors.grey['14'], 0.8),
          padding: '12px',
          borderRadius: '18px',
          cursor: 'default',
          animation: isNewNode
            ? `${pulsingAnimation} 0.5s linear infinite  alternate`
            : '',
          animationIterationCount: 6,
        }}
        ref={nodeRef}
      >
        <Handle
          type="target"
          position={Position.Left}
          id={'edge-' + data._id}
          style={{
            background: 'transparent',
            borderColor: 'transparent',
            height: '100%',
            width: '100%',
            borderRadius: '0',
            zIndex: -1,
          }}
        />
        <Handle
          type="source"
          id={'src-' + data._id}
          position={Position.Right}
          style={{
            width: '12px',
            height: '12px',
            marginRight: '-2px',
            border: '3px solid',
            borderColor: darkThemeColors.green['2'],
          }}
        />

        <Stack direction="row" justifyContent="center" alignItems="center">
          <Stack
            sx={{
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                opacity: '0.8',
                textAlign: 'center',
              }}
            >
              {t('flowNodes.delayLabel')}
            </Typography>
            <Divider sx={{ m: '8px 0' }} />
            {initialSeconds === 0 && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap="6px"
                sx={{
                  width: '100%',
                  height: '42px',
                  backgroundColor: 'grey.13',
                  border: '1px solid',
                  borderColor: 'grey.10',
                  p: '6px 10px',
                  borderRadius: '6px',
                  cursor: 'pointer',
                }}
                onClick={edit}
              >
                <TimerRoundedIcon
                  sx={{
                    fontSize: '20px',
                    mr: '5px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  {t('flowNodes.clickToAdd')}
                </Typography>
              </Stack>
            )}
            {initialSeconds !== 0 && (
              <Stack
                direction="row"
                alignItems="center"
                gap="8px"
                flexWrap="wrap"
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    maxWidth: '48%',
                    width: '100%',
                    color: '#F4F3F4',
                    backgroundColor: ' #161516',
                    border: '2px solid',
                    borderRadius: '4px',
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  }}
                >
                  <Typography sx={{ pl: '5px' }}>{days ? days : ''}</Typography>
                  <Stack
                    sx={{
                      backgroundColor: '#1c1b1c',
                      padding: ' 8px 5px',
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    <Typography sx={{ color: 'grey.4' }}>
                      {t('common.days')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    maxWidth: '48%',
                    width: '100%',
                    color: '#F4F3F4',
                    backgroundColor: ' #161516',
                    border: '2px solid',
                    borderRadius: '4px',
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  }}
                >
                  <Typography sx={{ pl: '5px' }}>
                    {hours ? hours : ''}
                  </Typography>
                  <Stack
                    sx={{
                      backgroundColor: '#1c1b1c',
                      padding: ' 8px 5px',
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    <Typography sx={{ color: 'grey.4' }}>
                      {t('common.hours')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    maxWidth: '48%',
                    width: '100%',
                    color: '#F4F3F4',
                    backgroundColor: ' #161516',
                    border: '2px solid',
                    borderRadius: '4px',
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  }}
                >
                  <Typography sx={{ pl: '5px' }}>
                    {minutes ? minutes : ''}
                  </Typography>
                  <Stack
                    sx={{
                      backgroundColor: '#1c1b1c',
                      padding: ' 8px 5px',
                      width: '50%',
                      textAlign: 'center',
                    }}
                  >
                    <Typography sx={{ color: 'grey.4' }}>
                      {t('common.minutes')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    maxWidth: '48%',
                    width: '100%',
                    color: '#F4F3F4',
                    backgroundColor: ' #161516',
                    border: '2px solid',
                    borderRadius: '4px',
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                    textAlign: 'center',
                  }}
                >
                  <Typography sx={{ pl: '5px' }}>
                    {seconds ? seconds : ''}
                  </Typography>
                  <Stack
                    sx={{
                      backgroundColor: '#1c1b1c',
                      padding: ' 8px 5px',
                      width: '50%',
                    }}
                  >
                    <Typography sx={{ color: 'grey.4' }}>
                      {t('common.seconds')}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {applyTimeRange && (
              <Stack
                sx={{
                  mt: '12px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {`${t(
                    'flowNodes.range',
                  )} ${startTime} - ${endTime}, ${daysString}, timezone ${timezone}`}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});

DelayNode.displayName = 'DelayNode';

export default DelayNode;
