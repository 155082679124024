import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import Lottie from 'lottie-react';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import deleteProjectMedia from '../../api/projects/delete-media';
import uploadFile from '../../api/user/upload-file';
import { AppContext } from '../../AppContext';
import uploadingAnimation from '../../assets/lottie/uploading.json';
import { IMedia, MediaType } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { UserContext } from '../../user/user.context';
import ModalBox from '../modal/modal-box';

import MediaContent from './media.content';
import MediaPreview from './media.preview';

interface MediaModalProps {
  open: boolean;
  selectedMedia: IMedia[];
  onSelectMedia: (newMedia: IMedia) => void;
  onClose: () => void;
  openMediaModalBuffer?: () => void;
  onRemoveMedia: (mediaId: string) => void;
}

const MAX_FILE_SIZE = 10000000; //10 Mb

const MediaModal: FC<MediaModalProps> = ({
  open,
  onClose,
  onSelectMedia,
  selectedMedia,
  openMediaModalBuffer,
  onRemoveMedia,
}) => {
  const { projectMedia, refetchMedia, project } = useContext(ProjectContext);
  const { triggerSnackbar, openConfirmationDialog } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const [onlyOwning, setOnlyOwning] = useState(true);
  const [displayedMedia, setDisplayedMedia] = useState<IMedia[]>([]);
  const [isPasteBuffer, setIsPasteBuffer] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;

    if (onlyOwning) {
      setDisplayedMedia(projectMedia.filter(({ owner }) => owner === user._id));
    } else {
      setDisplayedMedia(projectMedia);
    }
  }, [onlyOwning, projectMedia]);

  const [fileSrc, setFileSrc] = useState<string | undefined>();
  const [file, setFile] = useState<Blob | null>(null);

  const [uploading, setUploading] = useState(false);

  const onDelete = (mediaId: string) => {
    openConfirmationDialog(
      `${t('common.deleteMedia')} ?`,
      t('common.delete'),
      () => {
        deleteProjectMedia(project._id, mediaId)
          .then(() => {
            refetchMedia();
            onRemoveMedia(mediaId);
          })
          .catch(() => triggerSnackbar(t('triggerSnackbar.error'), 'error'));
      },
    );
  };

  const resetState = () => {
    setFileSrc(undefined);
    setFile(null);
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    const file = acceptedFiles[0];

    if (file.size > MAX_FILE_SIZE) {
      triggerSnackbar(t('uploadMedia.fileExceeds'), 'error');
      return;
    }

    setFile(file);
    const imageUrl = URL.createObjectURL(file);
    setFileSrc(imageUrl);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/webp': ['.webp'],
      'video/mp4': ['.mp4'],
    },
  });

  const uploadMedia = async () => {
    if (!file) {
      throw new Error();
    }

    const formData = new FormData();
    formData.append('file', file);
    return uploadFile(formData, project._id);
  };

  const onUpload = async () => {
    setUploading(true);
    try {
      await uploadMedia();

      triggerSnackbar(t('uploadMedia.mediaUploaded'), 'success');
      refetchMedia();
    } catch (e) {
      triggerSnackbar(t('uploadMedia.ErrorUploadingMedia'), 'error');
    }

    setUploading(false);
    resetState();
  };

  const onCancel = () => {
    resetState();
  };

  const handlePaste = (event: ClipboardEvent) => {
    const items = event.clipboardData?.items;
    const readFile = (file: File) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target?.result;
        if (result && typeof result === 'string') {
          if (openMediaModalBuffer) {
            openMediaModalBuffer();
            setIsPasteBuffer(true);
          }

          if (file.size > MAX_FILE_SIZE) {
            triggerSnackbar(t('uploadMedia.fileExceeds'), 'error');
            return;
          }

          setFile(file);
          const imageUrl = URL.createObjectURL(file);
          setFileSrc(imageUrl);
        }
      };

      reader.readAsDataURL(file);
    };
    if (items) {
      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();
          if (file) {
            readFile(file);
          }
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (openMediaModalBuffer) {
      document.addEventListener('paste', handlePaste);

      return () => {
        document.removeEventListener('paste', handlePaste);
      };
    }
  }, []);

  useEffect(() => {
    if (isPasteBuffer) {
      onSelectMedia(displayedMedia[0]);
      onClose();
    }
  }, [displayedMedia]);
  return (
    <ModalBox
      open={open}
      onClose={onClose}
      maxWidth="746px"
      sx={{
        height: '540px',
        p: '0',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'grey.10',
        borderRadius: '12px',
        width: '352px',
      }}
    >
      <Stack
        direction="row"
        sx={{
          height: '100%',
          overflow: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Stack
          alignSelf="start"
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              cursor: 'pointer',
              zIndex: 1,
              backgroundColor: 'grey.14',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => setOnlyOwning((prev) => !prev)}
            >
              <Checkbox
                sx={{
                  color: 'green.2',
                  '&.Mui-checked': {
                    color: 'green.2',
                  },
                }}
                checked={onlyOwning}
              />
              <Typography
                sx={{
                  opacity: '0.6',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {t('flowNodes.onlyMyFiles')}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              opacity: file ? '0.2' : '1 ',
            }}
          >
            <MediaContent
              key="media-content"
              media={displayedMedia.filter(
                ({ type }) =>
                  type === MediaType.photo || type === MediaType.video,
              )}
              selectedMedia={selectedMedia}
              onSelectMedia={onSelectMedia}
              onDelete={onDelete}
            />
          </Stack>
          <Stack
            gap="12px"
            alignItems="center"
            sx={{
              mt: 'auto',
              borderRadius: '24px 24px 12px 12px',
              p: '16px',
              backgroundColor: 'grey.13',
              position: 'relative',
              transition: 'all 0.2s easy',
              bottom: '0',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Button
              className="brightGreen"
              sx={{
                display: file ? 'none ' : 'flex',
                color: 'grey.10',
                width: '318px',
                height: '56px',
              }}
            >
              + {t('flowNodes.uploadMedia')}
            </Button>
            {uploading && (
              <Stack
                sx={{
                  width: '135px',
                  borderRadius: '4px',
                  overflow: 'hidden',
                }}
              >
                <Lottie animationData={uploadingAnimation} />
              </Stack>
            )}

            {!uploading && (
              <Stack
                sx={{
                  width: ' 100%',
                }}
                alignItems="center"
              >
                {file && (
                  <MediaPreview
                    file={file}
                    onCancel={onCancel}
                    fileSrc={fileSrc}
                    onUpload={onUpload}
                  />
                )}
                {!file && (
                  <Stack
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        mt: '6px',
                        width: '100%',
                        fontSize: '10px',
                        textAlign: 'center',
                        fontWeight: 600,
                        lineHeight: '130%',
                        color: 'grey.5',
                      }}
                    >
                      {t('uploadMedia.warning')}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
            <Button
              className="blue"
              sx={{
                width: '318px',
                height: '56px',
                display: file ? 'none ' : 'flex',
              }}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              {t('common.done')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </ModalBox>
  );
};

export default MediaModal;
