import { Stack } from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { AppContext } from '../AppContext';
import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import WithNav from '../common/layout/with-nav';

import UserCard from './user.card';
import { UserContext } from './user.context';

const UserPage = () => {
  const { triggerSnackbar } = useContext(AppContext);
  const { user, handleEditUser } = useContext(UserContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [clientLanguage, setClientLanguage] = useState('');

  if (!user) return null;

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLastName(value);
  };

  const handleChangeClientLang = (lang: string) => {
    setClientLanguage(lang);
  };

  const editUser = () => {
    handleEditUser({ firstName, lastName, clientLanguage });
    triggerSnackbar(t('common.changes_saved'));
  };

  useEffect(() => {
    setFirstName(user.firstName ?? '');
    setLastName(user.lastName ?? '');
    setClientLanguage(user.clientLanguage ?? '');
  }, [user]);

  const saveButtonDisabled =
    !Boolean(firstName) ||
    user.firstName + user.lastName + user.clientLanguage ===
      firstName + lastName + clientLanguage;

  return (
    <WithNav>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        }}
      >
        <UserCard
          disabledBtn={saveButtonDisabled}
          editUser={editUser}
          avatarUrl={user?.avatarUrl}
          firstName={firstName}
          lastName={lastName}
          handleChangeName={handleChangeName}
          handleChangeLastName={handleChangeLastName}
          clientLang={clientLanguage}
          onChangeClientLang={handleChangeClientLang}
        />
      </Stack>
    </WithNav>
  );
};

export default UserPage;
