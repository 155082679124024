import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  Button,
  Divider,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useEffect, useState } from 'react';

import ControlButtonsEditor from '../../../../common/flow-nodes/control-buttons-editor';
import { TelegramLeadStatus } from '../../../../interfaces/common';
import { ITelegramChannelWithActivators } from '../../../channels/interfaces';
import {
  ConditionsFlowNodeConditionData,
  ConditionTypes,
  IConditionsFlowNodeData,
} from '../../interfaces';

import ConditionsItem from './conditions-item';
import ConditionsModal from './conditions-modal';

interface ConditionsEditorProps {
  open: boolean;
  initialConditions: IConditionsFlowNodeData['conditions'];
  onClose: () => void;
  onSubmit: (
    e: React.SyntheticEvent,
    conditions: IConditionsFlowNodeData['conditions'],
  ) => void;
  channels: ITelegramChannelWithActivators[];
}

const ConditionsEditor: FC<ConditionsEditorProps> = ({
  open,
  onClose,
  onSubmit,
  initialConditions,
  channels,
}) => {
  const [conditions, setConditions] = useState<
    IConditionsFlowNodeData['conditions']
  >([]);
  const [creatingCondition, setCreatingCondition] =
    useState<ConditionsFlowNodeConditionData | null>(null);

  useEffect(() => {
    setConditions(initialConditions);
  }, [initialConditions]);

  const resetCreatingCondition = () => {
    setCreatingCondition(null);
  };

  const addToConditions = () => {
    if (!creatingCondition) return;

    setConditions((prev) => [...prev, creatingCondition]);
    resetCreatingCondition();
  };

  const onChangeCreatingCondition = (data: ConditionsFlowNodeConditionData) => {
    setCreatingCondition(data);
  };

  const onEditConditions = (
    index: number,
    data: ConditionsFlowNodeConditionData,
  ) => {
    setConditions((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          ...data,
        });
      }

      return result;
    });
  };

  const onDeleteCondition = (index: number) => {
    setConditions((prev) => {
      const result = [...prev];

      return result.filter((_, i) => index !== i);
    });
  };

  const onSelectChannel = (event: SelectChangeEvent, index: number) => {
    const selected = channels?.find((channel) =>
      channel?._id?.includes(event.target.value),
    );

    if (!selected) return;

    onEditConditions(index, {
      type: ConditionTypes.checkChannelMembership,
      channelId: selected._id,
      channelName: selected.title,
      truthyNext: '',
    });
  };

  const onSelectChannelCreatingCondition = (event: SelectChangeEvent) => {
    const selected = channels?.find((channel) =>
      channel?._id?.includes(event.target.value),
    );

    if (!selected) return;

    setCreatingCondition({
      type: ConditionTypes.checkChannelMembership,
      channelId: selected._id,
      channelName: selected.title,
      truthyNext: '',
    });
  };

  const onSelectStatus = (event: SelectChangeEvent, index: number) => {
    onEditConditions(index, {
      type: ConditionTypes.checkStatus,
      status: event.target.value,
      truthyNext: '',
    });
  };

  const onSelectStatusCreatingCondition = (event: SelectChangeEvent) => {
    setCreatingCondition({
      type: ConditionTypes.checkStatus,
      status: event.target.value,
      truthyNext: '',
    });
  };

  const onAddCheckParam = () => {
    const newCondition: ConditionsFlowNodeConditionData = {
      type: ConditionTypes.checkParam,
      key: '',
      value: '',
      truthyNext: '',
      inputValue: '',
    };

    setCreatingCondition(newCondition);
  };

  const onAddCheckChannelMembership = () => {
    const newCondition: ConditionsFlowNodeConditionData = {
      type: ConditionTypes.checkChannelMembership,
      channelName: '',
      channelId: '',
      truthyNext: '',
    };

    setCreatingCondition(newCondition);
  };

  const onAddCheckStatus = () => {
    const newCondition: ConditionsFlowNodeConditionData = {
      type: ConditionTypes.checkStatus,
      status: TelegramLeadStatus.lead,
      truthyNext: '',
    };

    setCreatingCondition(newCondition);
  };

  const onSubmitButton = (e: React.SyntheticEvent) => {
    onSubmit(e, conditions);
  };

  if (!open) return null;

  const allConditionsMatch = () => {
    let allConditionsMatch = true;

    for (let index = 0; index < conditions.length; index++) {
      const condition = conditions[index];
      const initialCondition = initialConditions[index];

      if (condition?.type !== initialCondition?.type) {
        allConditionsMatch = false;
        break;
      }

      switch (condition.type) {
        case ConditionTypes.checkParam:
          if (
            initialCondition.type !== ConditionTypes.checkParam ||
            condition.key !== initialCondition.key ||
            condition.value !== initialCondition.value ||
            condition.truthyNext !== initialCondition.truthyNext
          ) {
            allConditionsMatch = false;
          }
          break;
        case ConditionTypes.checkChannelMembership:
          if (
            initialCondition.type !== ConditionTypes.checkChannelMembership ||
            condition.channelName !== initialCondition.channelName ||
            condition.channelId !== initialCondition.channelId ||
            condition.truthyNext !== initialCondition.truthyNext
          ) {
            allConditionsMatch = false;
          }
          break;
        case ConditionTypes.checkStatus:
          if (
            initialCondition.type !== ConditionTypes.checkStatus ||
            condition.status !== initialCondition.status ||
            condition.truthyNext !== initialCondition.truthyNext
          ) {
            allConditionsMatch = false;
          }
          break;

        default:
          allConditionsMatch = false;
      }
    }
    return allConditionsMatch;
  };

  const buttonDisabled =
    conditions.length === 0 ||
    allConditionsMatch() ||
    conditions.some((condition) => {
      if (condition.type === ConditionTypes.checkChannelMembership) {
        return (
          condition.channelName.trim() === '' ||
          condition.channelId.trim() === ''
        );
      } else if (condition.type === ConditionTypes.checkParam) {
        return condition.key.trim() === '' || condition.value.trim() === '';
      }
      return false;
    });

  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderRight: '2px solid',
        borderLeft: '2px solid',
        borderColor: 'grey.10',
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          borderRadius: '4px',
          p: '8px',
          backgroundColor: 'grey.13',
          height: '38px',
          mt: '16px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            color: 'grey.1',
            fontWeight: 500,
            fontSize: '16px',
            opacity: '0.8',
          }}
        >
          {t('flowNodes.conditionsLabel')}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        gap="6px"
        sx={{
          mt: '10px',
        }}
      >
        <Button
          startIcon={<PlaylistAddIcon sx={{ fontSize: '24px' }} />}
          sx={{
            width: '100%',
            fontWeight: '500',
            fontSize: '16px',
            border: '1px solid ',
            borderColor: 'grey.13',
            backgroundColor: 'grey.15',
            borderRadius: '6px',
            height: '56px',
          }}
          color="inherit"
          onClick={onAddCheckParam}
        >
          {t('flowNodes.checkParam')}
        </Button>
        <Button
          color="inherit"
          startIcon={<PersonAddIcon sx={{ fontSize: '24px' }} />}
          sx={{
            width: '100%',
            fontWeight: '500',
            fontSize: '16px',
            border: '1px solid ',
            borderColor: 'grey.13',
            backgroundColor: 'grey.15',
            borderRadius: '6px',
            height: '56px',
          }}
          onClick={onAddCheckChannelMembership}
          disabled={Boolean(!channels.length)}
        >
          {t('flowNodes.checkChanel')}
        </Button>
        <Button
          color="inherit"
          startIcon={<HowToRegRoundedIcon sx={{ fontSize: '24px' }} />}
          sx={{
            width: '100%',
            fontWeight: '500',
            fontSize: '16px',
            border: '1px solid ',
            borderColor: 'grey.13',
            backgroundColor: 'grey.15',
            borderRadius: '6px',
            height: '56px',
          }}
          onClick={onAddCheckStatus}
        >
          {t('flowNodes.checkStatus')}
        </Button>
      </Stack>
      <Divider sx={{ m: '16px 0' }} />
      <ConditionsItem
        conditions={conditions}
        onEditConditions={onEditConditions}
        onDeleteCondition={onDeleteCondition}
        channels={channels}
        onSelectChannel={onSelectChannel}
        onSelectStatus={onSelectStatus}
      />

      <ControlButtonsEditor
        onClose={onClose}
        onSubmit={onSubmitButton}
        sendButtonDisabled={buttonDisabled}
      />
      <ConditionsModal
        open={Boolean(!!creatingCondition)}
        onClose={resetCreatingCondition}
        addToConditions={addToConditions}
        channels={channels}
        creatingCondition={creatingCondition}
        onChangeCreatingCondition={onChangeCreatingCondition}
        onSelectChannel={onSelectChannelCreatingCondition}
        onSelectStatus={onSelectStatusCreatingCondition}
      />
    </Stack>
  );
};

export default ConditionsEditor;
