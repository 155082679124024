import { IFlow } from '../../telegram-bots/flows/interfaces';
import { ITelegramBot } from '../../telegram-bots/telegram-bot.interface';
import axios from '../axios';

const changeFlowDefault = async (
  botId: ITelegramBot['_id'],
  flowId: IFlow['_id'] | null,
) => {
  const { data } = await axios.patch(
    `/telegram-bots/${botId}/change-default-flow`,
    {
      flowId,
    },
  );

  return data;
};

export default changeFlowDefault;
