import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Stack } from '@mui/material';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NAVBAR_HEIGHT_PX } from '../common/constants/constants';
import Drawer from '../telegram-bots/drawer';
import { UserContext } from '../user/user.context';

import UserAccountSettings from './settings/user-account.settings';
import { TelegramUserMessengerAccess } from './telegram-user.interface';
import { UserAccountContext } from './user-account.context';

export interface IBotTab {
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  component?: ReactNode;
  tabName: string;
}

const MESSENGER_INDEX = 0;

const UserAccountPage = () => {
  const { user } = useContext(UserContext);
  const { userAccount, access } = useContext(UserAccountContext);

  const navigate = useNavigate();
  const { botTab } = useParams();
  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(true);

  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const TABS: IBotTab[] = [
    {
      disabled: access?.messenger === TelegramUserMessengerAccess.none,
      label: t('bot-page.tabs.messages'),
      icon: <ChatRoundedIcon />,
      tabName: 'messenger',
    },
    {
      disabled: user?._id !== userAccount?.owner,
      label: t('bot-page.tabs.settings'),
      icon: <SettingsRoundedIcon />,
      component: <UserAccountSettings userAccount={userAccount} />,
      tabName: 'settings',
    },
  ];

  const onChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    if (newValue === MESSENGER_INDEX) {
      navigate(
        `/project/${userAccount.project}/user-account-messenger/${userAccount._id}`,
      );
      return;
    }

    if (TABS[newValue].disabled) {
      return;
    }

    navigate(
      `/project/${userAccount.project}/user-account/${userAccount._id}/${TABS[newValue].tabName}`,
    );
  };

  useEffect(() => {
    if (!botTab) {
      setCurrentTabIndex(-1);
    }

    const foundIndex = TABS.findIndex((tab) => tab.tabName === botTab);
    if (foundIndex === -1) return;

    if (TABS[foundIndex]?.disabled) return;

    setCurrentTabIndex(foundIndex);
  }, [TABS]);

  return (
    <Stack direction="row">
      <Stack>
        <Drawer
          open={drawerOpen}
          onToggle={toggleDrawer}
          tabs={TABS}
          currentTabIndex={currentTabIndex}
          onTabChange={onChangeTab}
        />
      </Stack>
      <Stack
        sx={{
          width: '100%',
          maxHeight: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
          overflowY: 'scroll',
        }}
      >
        {TABS[currentTabIndex]?.component}
      </Stack>
    </Stack>
  );
};

export default UserAccountPage;
