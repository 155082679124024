import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';

import Input from '../../common/inputs/InputWithIcon';

interface CreateEditVariableModalProps {
  onClose: () => void;
  isCreateVariable: boolean;
  variable: string | null;
  onSubmit: () => void;
  onChangeVariableItem: (value: string) => void;
}

const CreateEditVariableModal: FC<CreateEditVariableModalProps> = ({
  onClose,
  isCreateVariable,
  variable,
  onSubmit,
  onChangeVariableItem,
}) => {
  const createButtonDisabled = Boolean(!variable);
  return (
    <Stack>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          opacity: '0.6',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          textAlign: 'center',
          mb: '12px',
        }}
      >
        {isCreateVariable
          ? t('variables.creatingVariable')
          : t('variables.editingVariable')}
      </Typography>
      <Input
        value={variable ?? ''}
        handleChangeSearch={(event) => onChangeVariableItem(event.target.value)}
        placeholder={t('common.nameLabel')}
        backgroundNarrative="dark"
        withIcon={false}
      />

      <Button
        disabled={createButtonDisabled}
        className="brightGreen"
        sx={{
          mt: '24px',
          height: '56px',
        }}
        onClick={() => {
          onSubmit();
          onClose();
        }}
      >
        {t('common.save')}
      </Button>
    </Stack>
  );
};

export default CreateEditVariableModal;
