import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../common/inputs/InputWithIcon';

import { IFlowFolder } from './interfaces';

const MAX_NAME_LENGTH = 40;

interface FolderModalProps {
  onClose: () => void;
  createFolder: boolean;
  folder?: IFlowFolder | null;
  onSubmit: (name: string) => void;
}

const CreateEditFolderModal: FC<FolderModalProps> = ({
  onSubmit,
  onClose,
  createFolder,
  folder,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');

  const createButtonDisabled = Boolean(!name);

  const onCreateClick = () => {
    onSubmit(name);
    setName('');
    onClose();
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  useEffect(() => {
    if (!createFolder) {
      setName(folder?.name as IFlowFolder['name']);
    } else {
      setName('');
    }
  }, [open]);

  return (
    <>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          opacity: '0.6',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          textAlign: 'center',
          mb: '12px',
        }}
      >
        {createFolder ? t('flow.createFolder') : t('flow.folders.editFolder')}
      </Typography>
      <Input
        value={name}
        handleChangeSearch={onNameInput}
        placeholder={t('common.nameLabel')}
        backgroundNarrative="dark"
        withIcon={false}
      />

      <Button
        disabled={createButtonDisabled}
        className="brightGreen"
        sx={{
          mt: '24px',
          height: '56px',
        }}
        onClick={onCreateClick}
      >
        {createFolder ? t('common.create') : t('common.edit')}
      </Button>
    </>
  );
};

export default CreateEditFolderModal;
