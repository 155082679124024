import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';

import fetchTemplates from '../../api/templates/fetch-templates';
import MediaModal from '../../common/media/media.modal';
import VideoNoteModal from '../../common/media/video-notes.modal';
import VoiceModal from '../../common/media/voice.modal';
import StatusBadge from '../../common/status.badge';
import {
  EditTelegramMessage,
  IMedia,
  ITelegramMessage,
  TelegramButton,
  TelegramMessageDirection,
  TelegramMessageReason,
  TelegramMessageType,
} from '../../interfaces/common';
import { BotContext } from '../../telegram-bots/bot.context';
import { IChannelMember } from '../../telegram-bots/channels/interfaces';
import { getBotIdAndType } from '../../utils/getBotIdAndType';
import getFormattedDate from '../../utils/getFormattedDate';
import useDisplayedMessages from '../../utils/hooks/useDisplayedMessages';
import useNewTelegramMessage from '../../utils/hooks/useNewTelegramMessage';
import MessageContainer from '../messages/message.container';
import NewMessageInput from '../messages/new-message.input';
import { MessengerContext, TelegramDialog } from '../messenger.context';
import { ITemplate } from '../templates/interfaces';

import CurrentDialogInfo from './current-dialog.info';
import MessagePreviewModal from './message-preview.modal';
import NamesModal from './names.modal';

interface CurrentDialogProps {
  dialog: TelegramDialog;
  readDialogs: boolean;
}

const CurrentDialog: FC<CurrentDialogProps> = ({ dialog, readDialogs }) => {
  const { bot } = useContext(BotContext);
  const {
    sendMessage,
    messages,
    loadingMessages,
    readDialog,
    getMessages,
    acceptJoinRequest,
    editMessage,
    deleteMessage,
  } = useContext(MessengerContext);

  const {
    newMessageText,
    onChangeNewMessageText,
    media,
    updateMedia,
    onSelectMedia,
    onRemoveMedia,
    updateButtons,
    onButtonChange,
    onRemoveButton,
    onRemoveButtonsRow,
    onAddButtonsRow,
    onAddUrlButton,
    buttons,
    newMessageValid,
    clearMessageState,
    onChangeInitialMessage,
    addNewButtonsRowDisabled,
    messageType,
    onMessageTypeChange,
    onEmojiPick,
  } = useNewTelegramMessage();

  const [templates, setTemplates] = useState<ITemplate[]>([]);

  const [messagePreviewModalOpen, setMessagePreviewModalOpen] =
    useState<boolean>(false);
  const [mediaModalOpen, setMediaModalOpen] = useState<boolean>(false);
  const [voiceModalOpen, setVoiceModalOpen] = useState<boolean>(false);
  const [videoNotesModalOpen, setVideoNotesModalOpen] =
    useState<boolean>(false);
  const [namesModalOpen, setNamesModalOpen] = useState<boolean>(false);
  const [joinRequestChannels, setJoinRequestChannels] = useState<
    IChannelMember[]
  >([]);

  const [replyMessage, setReplyMessage] = useState<{
    telegramId: number;
    text?: string;
    media?: string;
  } | null>(null);
  const [editingMessageId, setEditingMessageId] = useState<
    ITelegramMessage['_id'] | null
  >(null);
  const [currentMessageMedia, setCurrentMessageMedia] = useState<IMedia[]>([]);

  const botId = getBotIdAndType().botId;
  const botType = getBotIdAndType().botType;

  const stackRef = useRef<HTMLDivElement | null>(null);

  const onReply = (telegramMessageToReply: {
    telegramId: number;
    text?: string;
    media?: string;
  }) => {
    setReplyMessage(telegramMessageToReply);
  };

  const onEditMessage = (message: EditTelegramMessage) => {
    setEditingMessageId(message._id);
    setCurrentMessageMedia(message.media as IMedia[]);
    setMessagePreviewModalOpen(true);
    onChangeInitialMessage(
      message.text ?? '',
      message.buttons,
      message.media as IMedia[],
      message.type,
    );
  };

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.3,
  });

  useLayoutEffect(() => {
    if (!inView) return;

    getMessages(dialog._id);
  }, [inView, dialog._id]);

  const getTemplates = () => {
    if (!botId) return;

    fetchTemplates(botId, botType).then(setTemplates);
  };

  const handleOpenModalPreview = () => {
    setMessagePreviewModalOpen(true);
  };

  const handleCloseModalPreview = () => {
    setMessagePreviewModalOpen(false);
    updateMedia([]);
    updateButtons([]);
  };

  const handleTemplateClick = (
    text: string,
    buttons: TelegramButton[][],
    media: IMedia[],
    type: TelegramMessageType,
  ) => {
    onChangeInitialMessage(text, buttons, media, type);
    setMessagePreviewModalOpen(true);
  };

  const clearReplyEdit = () => {
    setReplyMessage(null);
    setEditingMessageId(null);
  };

  const onSubmitMessage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // if user doesn't read dialogs by default
    // we need to read it manually before sending
    if (!readDialogs) {
      readDialog(dialog._id);
    }

    if (editingMessageId) {
      editMessage({
        _id: editingMessageId,
        buttons: buttons,
        media: [...media.map((mediaFile) => mediaFile._id)],
        text: newMessageText,
      });
    } else {
      sendMessage({
        dialog: dialog._id,
        buttons: buttons,
        chatId: dialog.telegramId,
        direction: TelegramMessageDirection.outcome,
        media: [...media.map((mediaFile) => mediaFile._id)],
        reason: TelegramMessageReason.messenger,
        replyMessageId: replyMessage?.telegramId,
        seen: true,
        text: newMessageText,
        type: messageType,
      });
    }

    clearMessageState();
    clearReplyEdit();
  };

  const onSubmitModalPreview = (e: React.SyntheticEvent) => {
    onSubmitMessage(e);
    setMessagePreviewModalOpen(false);
  };

  const onAcceptJoinRequest = (
    channelMember: IChannelMember,
    isAccept: boolean,
  ) => {
    if (!channelMember) return;

    acceptJoinRequest(dialog?._id, channelMember?._id, isAccept);
  };

  const sendButtonDisabled =
    Boolean(!newMessageValid) ||
    Boolean(editingMessageId && currentMessageMedia.length !== media.length);

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    setJoinRequestChannels(
      dialog?.channelMemberships?.filter((member) =>
        member.status.includes('joinRequest'),
      ),
    );
  }, [dialog]);

  const displayedMessages = useDisplayedMessages(messages, dialog._id);

  useEffect(() => {
    if (stackRef.current) {
      stackRef.current.scrollTop = stackRef.current.scrollHeight;
    }

    clearMessageState();
    clearReplyEdit();
  }, [dialog._id]);

  const openMediaModalBuffer = () => {
    setMessagePreviewModalOpen(true);
    setMediaModalOpen(true);
  };
  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        minHeight: '100vh',
        height: '100vh',
      }}
    >
      <Stack sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <Stack
          sx={{ backgroundColor: 'grey.14', py: '8px', px: '10px' }}
          direction="row"
          alignItems="center"
          gap="12px"
        >
          <StatusBadge status={dialog.status} />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              textTransform: 'capitalize',
              userSelect: 'text',
            }}
          >
            {`${dialog.firstName} ${dialog.lastName}`}
          </Typography>
          <Stack
            gap="10px"
            alignItems="center"
            flexDirection="row"
            sx={{
              ml: 'auto',
            }}
          >
            <Stack>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  color: 'grey.secondary',
                  textAlign: 'right',
                }}
              >
                {t('messenger.startDialog')}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  textTransform: 'capitalize',
                }}
              >
                {getFormattedDate(dialog.createdAt)}
              </Typography>
            </Stack>
            <Button
              className="blue"
              sx={{
                width: '30px',
                minWidth: '30px',
                height: '30px',
              }}
              onClick={() => {
                setNamesModalOpen(true);
              }}
            >
              <MoreHorizIcon sx={{ fontSize: '16px' }} />
            </Button>
          </Stack>
        </Stack>
        <Divider sx={{ backgroundColor: 'grey.10' }} />
        {joinRequestChannels?.map((member) => (
          <Stack
            key={member._id}
            direction="row"
            gap="35px"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              border: '1px solid',
              borderColor: '#CBFB45',
              backgroundColor: 'grey.14',
              p: '9px 12px ',
            }}
          >
            <Typography>
              {t('messenger.joinRequest')} {member.telegramChannel.title}
            </Typography>
            <Stack direction="row" gap="7px">
              <Button
                sx={{
                  borderRadius: '6px',
                  backgroundColor: 'green.2',
                  color: 'grey.13',
                  '&:hover': {
                    backgroundColor: 'green.1',
                    color: 'grey.1',
                  },
                }}
                onClick={() => onAcceptJoinRequest(member, true)}
              >
                {t('common.accept')}
              </Button>
              <Button
                sx={{
                  borderRadius: '6px',
                  border: ' 1px solid ',
                  borderColor: 'red.2',
                  backgroundColor: 'red.1',
                  color: 'grey.1',
                  '&:hover': {
                    backgroundColor: 'red.2',
                    color: 'grey.15',
                  },
                }}
                onClick={() => onAcceptJoinRequest(member, false)}
              >
                {t('common.decline')}
              </Button>
            </Stack>
          </Stack>
        ))}

        <Stack
          ref={stackRef}
          sx={{
            padding: '12px 12px 75px',
            height: '100%',
            overflow: 'scroll',
            flexDirection: 'column-reverse',
            backgroundSize: 'contain',
          }}
          gap="20px"
        >
          {loadingMessages[dialog._id] && (
            <Stack
              sx={{
                color: 'green.2',
                position: 'absolute',
                top: '10%',
                left: '50%',
                transform: 'translate(-50%)',
                zIndex: '10',
              }}
            >
              <CircularProgress color="inherit" />
            </Stack>
          )}
          {displayedMessages.map((message, i) => (
            <MessageContainer
              key={'msg-container' + dialog._id + i}
              message={message}
              onDeleteMessage={deleteMessage}
              onEditMessage={onEditMessage}
              onReplyMessage={onReply}
            />
          ))}
          <Stack
            sx={{
              minHeight: '1px',
            }}
            ref={inViewRef}
          />
        </Stack>
        <NewMessageInput
          disabled={dialog.banned}
          messagePreviewModalOpen={messagePreviewModalOpen}
          openPreviewModal={handleOpenModalPreview}
          onSubmit={onSubmitMessage}
          newMessageText={newMessageText}
          onChangeNewMessageText={onChangeNewMessageText}
          mediaAdded={false}
          dialogId={dialog._id}
          onEmojiPick={onEmojiPick}
          editingMessage={Boolean(editingMessageId)}
          isReplyMessage={Boolean(replyMessage)}
          onClearReplayEdit={clearReplyEdit}
          replyMessageText={replyMessage?.text ?? null}
          replyMessageMedia={replyMessage?.media ?? null}
        />
      </Stack>
      <Stack
        sx={{
          backgroundColor: 'grey.14',
          borderLeft: '1px solid',
          borderColor: 'grey.13',
          maxWidth: '306px',
          width: '100%',
          height: '100vh',
          overflowY: 'scroll',
        }}
      >
        <CurrentDialogInfo
          dialog={dialog}
          templates={templates}
          onTemplateClick={handleTemplateClick}
          isBot={Boolean(bot)}
        />
      </Stack>

      <MessagePreviewModal
        open={messagePreviewModalOpen}
        onClose={handleCloseModalPreview}
        onInput={onChangeNewMessageText}
        newMessage={newMessageText}
        buttons={buttons}
        onAddRow={onAddButtonsRow}
        onRemoveRow={onRemoveButtonsRow}
        onRemoveButton={onRemoveButton}
        onAddButton={onAddUrlButton}
        onButtonChange={onButtonChange}
        addNewButtonsRowDisabled={addNewButtonsRowDisabled}
        onRemoveMedia={onRemoveMedia}
        onSubmit={onSubmitModalPreview}
        openMediaModal={() => {
          setMediaModalOpen(true);
        }}
        openVoiceModal={() => {
          setVoiceModalOpen(true);
        }}
        openVideoNoteModal={() => {
          setVideoNotesModalOpen(true);
        }}
        media={media}
        sendButtonDisabled={sendButtonDisabled}
        messageType={messageType}
        onMessageTypeChange={onMessageTypeChange}
        clearMessageState={clearMessageState}
        editingMessageId={editingMessageId}
      />

      <MediaModal
        open={mediaModalOpen}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onClose={() => {
          setMediaModalOpen(false);
        }}
        openMediaModalBuffer={openMediaModalBuffer}
        onRemoveMedia={onRemoveMedia}
      />

      <VoiceModal
        open={voiceModalOpen}
        onClose={() => {
          setVoiceModalOpen(false);
        }}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onRemoveMedia={onRemoveMedia}
      />

      <VideoNoteModal
        open={videoNotesModalOpen}
        onClose={() => {
          setVideoNotesModalOpen(false);
        }}
        selectedMedia={media}
        onSelectMedia={onSelectMedia}
        onRemoveMedia={onRemoveMedia}
      />

      <NamesModal
        namesModalOpen={namesModalOpen}
        dialog={dialog}
        onClose={() => {
          setNamesModalOpen(false);
        }}
      />
    </Stack>
  );
};

export default CurrentDialog;
