import { Stack } from '@mui/material';
import React, { FC } from 'react';
import Slider from 'react-slick';

import VideoPreview from '../../common/media/video.preview';
import { ITelegramMediaMessage } from '../../interfaces/common';

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function PrevArrowBtn(props: ArrowProps) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M30.819 33.16L21.659 24L30.819 14.82L27.999 12L15.999 24L27.999 36L30.819 33.16Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

function NextArrowBtn(props: ArrowProps) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M17.1816 33.16L26.3416 24L17.1816 14.82L20.0016 12L32.0016 24L20.0016 36L17.1816 33.16Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

interface MediaSliderProps {
  media: ITelegramMediaMessage['media'];
  selectedMediaIndex: number;
  handleSlideChange: (i: number) => void;
}

const MediaSlider: FC<MediaSliderProps> = ({
  media,
  selectedMediaIndex,
  handleSlideChange,
}) => {
  const sliderSettings = {
    customPaging: function (i: number) {
      return (
        <div
          style={{
            opacity: i === selectedMediaIndex ? 1 : 0.5,
            transition: 'opacity 0.1s ease-in-out',
          }}
        >
          <img
            src={media[i].url}
            style={{ width: '50px', height: '50px', borderRadius: '6px' }}
            alt="alt"
          />
        </div>
      );
    },
    dots: true,
    infinite: true,
    speed: 100,
    focusOnSelect: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: handleSlideChange,
    nextArrow: <NextArrowBtn />,
    prevArrow: <PrevArrowBtn />,
  };

  return (
    <Slider {...sliderSettings} initialSlide={selectedMediaIndex}>
      {media.map(({ url, _id, type }, index) => (
        <Stack
          key={_id}
          className={index === selectedMediaIndex ? 'slick-active' : ''}
        >
          {type === 'video' ? (
            <VideoPreview
              videoSrc={url}
              playerHeight="80vh"
              playerWidth="80vw"
            />
          ) : (
            <img
              style={{
                width: '100%',
                height: '80vh',
                objectFit: 'contain',
                opacity: index === selectedMediaIndex ? 1 : 0.5,
              }}
              src={url}
              alt="#"
            />
          )}
        </Stack>
      ))}
    </Slider>
  );
};

export default MediaSlider;
