import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ChainIcon from '../../assets/icons/ChainIcon';
import TouchIcon from '../../assets/icons/TouchIcon';
import { TelegramButton, TelegramButtonType } from '../../interfaces/common';

interface TelegramButtonsRowProps {
  buttons: TelegramButton[];
  onButtonChange: (index: number, key: string, value: string) => void;
  onRemoveButton: (index: number) => void;
  onAddNewUrlButton: () => void;
  onAddNewActionButton?: () => void;
  showAddActionButton?: boolean;
  onRemoveRow: () => void;
  urlExtended?: boolean;
  rowIndex: number;
}

const TelegramButtonsRow: FC<TelegramButtonsRowProps> = ({
  buttons,
  onButtonChange,
  onAddNewUrlButton,
  onAddNewActionButton,
  onRemoveButton,
  onRemoveRow,
  urlExtended = false,
  showAddActionButton = false,
  rowIndex,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        backgroundColor: 'grey.13',
        padding: '8px 0',
        borderRadius: '8px',
        mb: '12px',
      }}
    >
      <Typography sx={{ ml: '12px', fontWeight: 500, fontSize: '18px' }}>
        {t('telegramButtons.row')} {rowIndex + 1}
      </Typography>
      {buttons.map((button, btnIndex) => {
        const urlPattern = /(http[s]?:\/\/[^\s]+)|^https:\/\/t\.me\/\+\w+$/;

        if (button.type === TelegramButtonType.url) {
          return (
            <Stack
              key={'row-key-' + btnIndex}
              gap="5px"
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ padding: '8px' }}
            >
              <Stack alignItems="center" sx={{ m: ' 0 17px', width: '36px' }}>
                <ChainIcon fill="#CAFA44" />
              </Stack>
              <TextField
                fullWidth
                sx={{
                  width: urlExtended ? '35%' : '100%',
                }}
                value={button.label}
                onChange={(e) =>
                  onButtonChange(btnIndex, 'label', e.target.value)
                }
                placeholder="Label"
              />
              <TextField
                fullWidth
                sx={{
                  width: urlExtended ? '65%' : '100%',
                }}
                InputProps={{
                  sx: {
                    '*.MuiOutlinedInput-notchedOutline': {
                      border: '1px solid',
                      borderColor: urlPattern.test(button.url)
                        ? 'grey.10'
                        : 'red.2',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid',
                      borderColor: urlPattern.test(button.url)
                        ? 'grey.10'
                        : 'red.2',
                    },
                  },
                }}
                value={button.url}
                onChange={(e) =>
                  onButtonChange(btnIndex, 'url', e.target.value)
                }
                placeholder="Url"
              />
              <Button
                className="red"
                sx={{
                  minWidth: '40px',
                  width: '40px',
                  height: '100%',
                }}
                onClick={() => {
                  onRemoveButton(btnIndex);
                }}
              >
                <DeleteIcon
                  sx={{
                    color: 'grey.1',
                  }}
                />
              </Button>
            </Stack>
          );
        }

        return (
          <Stack
            key={'row-key-' + btnIndex}
            gap="5px"
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ padding: '8px' }}
          >
            <Stack alignItems="center" sx={{ m: ' 0 17px', width: '36px' }}>
              <TouchIcon fill={'#CAFA44'} />
            </Stack>
            <TextField
              fullWidth
              sx={{
                width: '100%',
              }}
              inputProps={{
                sx: {},
              }}
              value={button.label}
              onChange={(e) =>
                onButtonChange(btnIndex, 'label', e.target.value)
              }
              placeholder="Label"
            />
            <Button
              className="red"
              sx={{
                minWidth: '40px',
                width: '40px',
                height: '100%',
                color: 'red.2',
              }}
              onClick={() => {
                onRemoveButton(btnIndex);
              }}
            >
              <DeleteIcon sx={{ color: 'grey.1' }} />
            </Button>
          </Stack>
        );
      })}
      <Divider sx={{ m: '12px 0 8px' }} />
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{ padding: '0 8px' }}
      >
        <Button
          className="blue"
          sx={{
            height: '40px',
            fontWeight: 500,
            fontSize: '16px',
            width: '100%',
          }}
          disabled={buttons?.length > 4}
          onClick={onAddNewUrlButton}
        >
          {t('common.addButtonURL')}
        </Button>
        {showAddActionButton && (
          <Button
            className="blue"
            sx={{
              height: '40px',
              fontWeight: 500,
              fontSize: '16px',
              width: '100%',
            }}
            disabled={buttons?.length > 4}
            onClick={
              onAddNewActionButton
                ? onAddNewActionButton
                : () => {
                    //
                  }
            }
          >
            {t('common.addButtonAction')}
          </Button>
        )}
        <Button
          className="red"
          sx={{
            width: '40px',
            minWidth: '40px',
            height: '40px',
          }}
          onClick={onRemoveRow}
        >
          <DeleteIcon />
        </Button>
      </Stack>
    </Stack>
  );
};

export default TelegramButtonsRow;
