import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import * as React from 'react';
import { FC, useState } from 'react';

interface StatisticsLeadsProps {
  statistics: (string | number)[][];
  loadingStatistics: boolean;
}

const StatisticsLeads: FC<StatisticsLeadsProps> = ({
  statistics,
  loadingStatistics,
}) => {
  const [order, setOrder] = useState<string>('asc');
  const [orderBy, setOrderBy] = useState<string>('LEAD');
  const header = statistics[0];

  const isStatisticsEmpty = Boolean(
    Array.isArray(statistics) && statistics?.length > 1,
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (loadingStatistics) {
    return (
      <Stack
        sx={{
          width: '100%',
          height: '200px',
          color: 'green.2',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" />
      </Stack>
    );
  }

  const sortedStatistics = statistics?.slice(1).sort((a, b) => {
    const aIndex = header.indexOf(orderBy);
    const bIndex = header.indexOf(orderBy);

    const aValue = a[aIndex];
    const bValue = b[bIndex];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      if (order === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    } else {
      if (order === 'asc') {
        return Number(aValue) - Number(bValue);
      } else {
        return Number(bValue) - Number(aValue);
      }
    }
  });

  const totalValue = Array(header?.length).fill(0);

  if (statistics?.length > 2) {
    sortedStatistics?.forEach((array) => {
      array?.forEach((value, index) => {
        if (typeof value === 'string') {
          totalValue[index] = ' ';
        }
        if (!isNaN(parseFloat(value as string)) && isFinite(value as number)) {
          totalValue[index] += Number(value);
        }
      });
    });
  }

  return (
    <Paper sx={{ width: '100%', borderRadius: '8px 8px 0 0 ' }}>
      <Box
        sx={{
          mt: '20px',
          height: 'max-content',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <TableContainer sx={{ minHeight: '200px', maxHeight: '100%' }}>
          <Table
            sx={{
              minWidth: 650,
              backgroundColor: 'grey.15',
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: 'grey.10',
                border: '1px solid',
                borderColor: 'grey.10',
              }}
            >
              <TableRow>
                {header?.map((title) => {
                  return (
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        border: 'none',
                      }}
                      key={title}
                      sortDirection={
                        orderBy === title ? (order as 'asc' | 'desc') : false
                      }
                    >
                      <TableSortLabel
                        active={orderBy === title}
                        onClick={() => handleRequestSort(title.toString())}
                        direction={
                          orderBy === title
                            ? (order as 'asc' | 'desc')
                            : undefined
                        }
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '14px',
                            opacity: 0.6,
                          }}
                        >
                          {title}
                        </Typography>
                        {orderBy === title ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                        <Typography
                          sx={{
                            position: 'relative',
                            bottom: '24px',
                            left: '25px',
                          }}
                        ></Typography>
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                borderLeft: '1px solid #4C4B4C',
                borderBottom: '1px solid #4C4B4C',
                borderRight: '1px solid #4C4B4C',
              }}
            >
              {sortedStatistics?.map((array, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: 'grey.15',
                      height: '45px',
                      ':hover': {
                        backgroundColor: 'grey.15',
                        cursor: 'pointer',
                        transition: ' all 0.2s ease-out',
                      },
                      '&:last-child': {
                        '& td': {
                          borderBottom: 'none',
                        },
                      },
                    }}
                  >
                    {array.map((value, index) => {
                      return (
                        <TableCell sx={{ textAlign: 'center' }} key={index}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: '14px',
                              opacity: 0.6,
                            }}
                          >
                            {value}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {Boolean(statistics?.length > 2) && (
                <TableRow sx={{ backgroundColor: 'grey.10' }}>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                        opacity: 0.6,
                      }}
                    >
                      total
                    </Typography>
                  </TableCell>
                  {totalValue?.slice(1)?.map((value, index) => (
                    <TableCell sx={{ textAlign: 'center' }} key={index}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '14px',
                          opacity: 0.6,
                        }}
                      >
                        {value}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!isStatisticsEmpty && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ mt: '15px', padding: '30px 0' }}
            >
              {t('statistics.emptyRequest')}
            </Stack>
          )}
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default StatisticsLeads;
