import { APP_CONFIG } from '../../config';

const getFlowScriptText = (
  botId: string,
  botUsername: string,
  flowId: string,
  buttonId: string,
) => {
  return `<script>
const urlParams = new URLSearchParams(window.location.search);

const params = Object.fromEntries(urlParams.entries());

const str = [];
  for (const p in params){
      if (params.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
  }
    
const link = \`${APP_CONFIG.API_URL}/ext/${botId}/${flowId}/start?\${str.join("&")}\`;


const fallbackLink = ''; // put you default link here
let linkToOpen;

fetch(link).then(r => r.json()).then(({_id}) => {
    linkToOpen = \`https://t.me/${botUsername}?start=f-\${_id}\`
}).catch(() => {
    linkToOpen = fallbackLink;
})

const addLinkToButton = () => {
    const button = document.getElementById("${buttonId}");
    if(!button) return;
    
    button.onclick = () => {
       window.location.href = linkToOpen;
    }
}

addLinkToButton()
</script>`;
};

export default getFlowScriptText;
