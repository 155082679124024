import { Button, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NAVBAR_HEIGHT_PX } from '../../common/constants/constants';
import { UserContext } from '../../user/user.context';
import { ProjectContext } from '../project.context';

import ProjectScreens from './project-screens.enum';

const ChooseOrCreateBot = () => {
  const { project } = useContext(ProjectContext);
  const { user } = useContext(UserContext);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { projectId } = useParams();

  const onCreateNewBot = () => {
    navigate(`/project/${projectId}/${ProjectScreens.newTelegramBot}`);
  };

  const onCreateNewUserAccount = () => {
    navigate(`/project/${projectId}/${ProjectScreens.newTelegramUserAccount}`);
  };
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
      }}
    >
      <Stack
        sx={{
          border: '1px solid',
          borderColor: 'grey.10',
          borderRadius: '12px',
          padding: '32px 56px',
          backgroundColor: 'grey.14',
          maxWidth: '550px',
        }}
      >
        <Typography
          sx={{
            color: 'grey.1',
            textAlign: 'center',
            fontSize: '24px',
            fontFamily: 'Gilroy',
            fontWeight: '600',
            lineHeight: '140%',
            mb: '12px',
          }}
        >
          {t('projects.empty.title')} {project.name}
        </Typography>
        <Typography
          sx={{
            color: 'grey.3',
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Gilroy',
            fontWeight: '500',
            lineHeight: '140%',
          }}
        >
          {project.owner === user?._id
            ? t('projects.empty.paragraphOwner')
            : t('projects.empty.paragraph')}
        </Typography>
        <Stack
          flexDirection="row"
          gap="16px"
          sx={{
            mt: '32px',
          }}
        >
          <Button
            sx={{
              backgroundColor: 'green.2',
              color: 'grey.10',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '140%',
              textAlign: 'center',
              width: '212px',
              height: '56px',
              '&:hover': { backgroundColor: 'green.2' },
            }}
            onClick={onCreateNewBot}
          >
            + {t('common.bot')}
          </Button>
          <Button
            sx={{
              backgroundColor: 'green.2',
              color: 'grey.10',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '140%',
              textAlign: 'center',
              width: '212px',
              height: '56px',
              '&:hover': { backgroundColor: 'green.2' },
              '&:disabled': { color: 'grey.10', opacity: '0.4' },
            }}
            // disabled={!telegramBots.length}
            disabled={true}
            onClick={onCreateNewUserAccount}
          >
            + {t('common.userbot')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ChooseOrCreateBot;
