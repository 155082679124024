import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';

import truncate from '../../utils/truncate';

import { ITelegramChannelWithActivators } from './interfaces';

interface ChannelCardProps {
  channel: ITelegramChannelWithActivators;
  buttonText: string;
  onClick: () => void;
}

const ChannelCard: FC<ChannelCardProps> = ({
  channel,
  buttonText,
  onClick,
}) => {
  return (
    <Stack
      sx={{
        padding: '12px 16px',
        borderRadius: '12px',
        width: '322px',
        backgroundColor: 'grey.13',
      }}
    >
      <Stack direction="row" alignItems="center" gap="16px">
        {Boolean(channel.avatarUrl) ? (
          <img
            src={channel.avatarUrl}
            alt=""
            style={{
              width: '56px',
              height: '56px',
              borderRadius: '50%',
            }}
          />
        ) : (
          <CampaignRoundedIcon
            sx={{
              fontSize: '40px',
              color: 'blue.3',
            }}
          />
        )}

        <Stack>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 600,
            }}
          >
            {truncate(channel.title, 16)}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              opacity: '0.6',
            }}
          >
            {channel.subscribersCount} {t('channels.channelCard.subscribers')}
          </Typography>
        </Stack>
      </Stack>
      <Button
        className="blue"
        sx={{
          mt: '16px',
        }}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

export default ChannelCard;
