import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type JoinProjectModalProps = {
  closeJoinProjectModal: () => void;
};

const JoinProjectModal: FC<JoinProjectModalProps> = ({
  closeJoinProjectModal,
}) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack
        sx={{
          position: 'absolute',
          top: '-8%',
          right: '-8%',
        }}
      >
        <IconButton onClick={closeJoinProjectModal}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack>
        <Typography
          sx={{
            textAlign: 'center',
            mb: '16px',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '140%',
          }}
        >
          {t('projects.joinToProject')}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '140%',
          }}
        >
          1. {t('projects.instructionJoin1')}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '140%',
          }}
        >
          2. {t('projects.instructionJoin2')}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '140%',
          }}
        >
          3. {t('projects.instructionJoin3')}
        </Typography>
      </Stack>
      <Button
        sx={{
          m: '32px auto 0',
          fontWeight: '600',
          fontSize: '18px',
          color: 'grey.15',
          borderRadius: '8px',
          padding: '16px 32px',
          width: '188px',
          height: '56px',
          boxShadow: ' 0 4px 10px 0 rgba(202, 250, 68, 0.15)',
          backgroundColor: 'green.2',
          '&:hover': { backgroundColor: 'green.2' },
        }}
        onClick={closeJoinProjectModal}
      >
        {t('common.understood')}
      </Button>
    </Stack>
  );
};

export default JoinProjectModal;
