import { Stack } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useEffect, useState } from 'react';

import deleteProjectMedia from '../../api/projects/delete-media';
import { AppContext } from '../../AppContext';
import { IMedia, MediaType } from '../../interfaces/common';
import { ProjectContext } from '../../projects/project.context';
import { UserContext } from '../../user/user.context';

import MediaUploadModal from './media-upload.modal';
import VoiceItem from './voice.item';

interface VoiceModalProps {
  open: boolean;
  selectedMedia: IMedia[];
  onSelectMedia: (newMedia: IMedia) => void;
  onClose: () => void;
  onRemoveMedia: (mediaId: string) => void;
}

const VoiceModal: FC<VoiceModalProps> = ({
  open,
  onClose,
  selectedMedia,
  onSelectMedia,
  onRemoveMedia,
}) => {
  const { projectMedia, project, refetchMedia } = useContext(ProjectContext);
  const { user } = useContext(UserContext);
  const { triggerSnackbar, openConfirmationDialog } = useContext(AppContext);

  const [onlyOwning, setOnlyOwning] = useState(true);
  const [displayedMedia, setDisplayedMedia] = useState<IMedia[]>([]);

  const toggleOnlyOwning = () => {
    setOnlyOwning((prev) => !prev);
  };

  const onDelete = (mediaId: string) => {
    openConfirmationDialog(
      `${t('common.deleteMediaAudio')} ?`,
      t('common.delete'),
      () => {
        deleteProjectMedia(project._id, mediaId)
          .then(() => {
            refetchMedia();
            onRemoveMedia(mediaId);
          })
          .catch(() => triggerSnackbar(t('triggerSnackbar.error'), 'error'));
      },
    );
  };

  useEffect(() => {
    if (!user) return;

    if (onlyOwning) {
      setDisplayedMedia(projectMedia.filter(({ owner }) => owner === user._id));
    } else {
      setDisplayedMedia(projectMedia);
    }
  }, [onlyOwning, projectMedia]);

  return (
    <MediaUploadModal
      open={open}
      onClose={onClose}
      selectedMedia={selectedMedia}
      onSelectMedia={onSelectMedia}
      onlyOwning={onlyOwning}
      toggleOnlyOwning={toggleOnlyOwning}
    >
      <Stack
        direction="column"
        gap="10px"
        sx={{
          flexWrap: 'wrap',
          p: '6px',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {displayedMedia
          .filter((media) => media.type === MediaType.voice)
          .map((media) => {
            const isSelected = selectedMedia[0]?._id === media._id;

            return (
              <VoiceItem
                key={media._id}
                voiceUrl={media.url}
                name={media.name ?? ''}
                isSelected={isSelected}
                onSelect={() => {
                  onSelectMedia(media);
                }}
                onDelete={() => onDelete(media._id)}
              />
            );
          })}
      </Stack>
    </MediaUploadModal>
  );
};

export default VoiceModal;
