import { Divider, Menu, MenuItem } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { FC } from 'react';

interface IMenuItem {
  onClick?: () => void;
  renderIcon?: () => JSX.Element;
  text: string;
  textSx?: SxProps<Theme>;
  disabled?: boolean;
}

interface PositionSettings {
  vertical: 'top' | 'center' | 'bottom' | number;
  horizontal: 'left' | 'center' | 'right' | number;
}

interface IAnchorProps {
  anchorOrigin: PositionSettings;
  transformOrigin: PositionSettings;
}

interface ThreeDotsMenuProps {
  open: boolean;
  onClose: () => void;
  anchor: Element | null;
  menuItems: IMenuItem[][];
  borderColor?: string;
  backgroundColor?: string;
  menuAnchorProps?: IAnchorProps;
  hoverBackground?: string;
  paddingMenu?: string;
  paddingMenuItem?: string;
  borderRadius?: string;
  border?: string;
  marginDivider?: string;
  color?: string;
}

const ThreeDotsMenu: FC<ThreeDotsMenuProps> = ({
  open,
  onClose,
  anchor,
  menuItems,
  borderColor = 'grey.7',
  backgroundColor = 'grey.13',
  menuAnchorProps,
  hoverBackground = 'grey.14',
  paddingMenu = '0px',
  paddingMenuItem = '10px 20px',
  borderRadius = '12px',
  border = '2px solid',
  marginDivider = '0',
}) => {
  if (!menuItems.length) return null;

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      PaperProps={{
        sx: {
          backgroundColor,
          border,
          borderColor,
          backgroundImage: 'none',
          borderRadius,
          padding: paddingMenu,
          top: '50p`x',
        },
      }}
      {...(menuAnchorProps ?? {})}
    >
      {menuItems.map((subMenuItems, index) => (
        <div key={`subMenu-${index}`}>
          {subMenuItems.map((menuItem, itemIndex) => (
            <React.Fragment key={`menuItem-${index}-${itemIndex}`}>
              <MenuItem
                onClick={(e: React.SyntheticEvent) => {
                  e.stopPropagation();
                  if (menuItem.onClick) {
                    menuItem.onClick();
                  }

                  onClose();
                }}
                sx={{
                  padding: paddingMenuItem,
                  gap: '10px',

                  backgroundColor: menuItem?.disabled
                    ? 'grey.15'
                    : 'transparent',
                  alignItems: 'center',
                  ...(menuItem.textSx ?? {}),
                  '&:hover': {
                    backgroundColor: hoverBackground,
                  },
                }}
                disabled={menuItem.disabled && menuItem.disabled}
              >
                <>
                  {menuItem.renderIcon && menuItem.renderIcon()}
                  {menuItem.text}
                </>
              </MenuItem>
            </React.Fragment>
          ))}
          {index !== menuItems.length - 1 && (
            <Divider
              style={{
                margin: marginDivider,
              }}
              sx={{
                backgroundColor: 'grey.10',
              }}
            />
          )}
        </div>
      ))}
    </Menu>
  );
};

export default ThreeDotsMenu;
