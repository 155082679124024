import { Stack, Typography } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import IconRobot from '../assets/icons/RobotIcon';
import { ProjectContext } from '../projects/project.context';
import ProjectScreens from '../projects/screens/project-screens.enum';
import { ITelegramBotWithAccess } from '../telegram-bots/telegram-bot.interface';
import truncate from '../utils/truncate';

import { ITelegramUserAccountWithAccess } from './telegram-user.interface';

interface TelegramBotAndAccountThumbProps {
  bot: ITelegramBotWithAccess | ITelegramUserAccountWithAccess;
  botType: string;
  selected: boolean;
}

const TelegramBotAndAccountThumb: FC<TelegramBotAndAccountThumbProps> = ({
  bot,
  botType,
  selected,
}) => {
  const { project } = useContext(ProjectContext);
  const navigate = useNavigate();

  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [nameSplit, setNameSplit] = useState<string[]>(['', '']);

  const onSelect = () => {
    if ('userAccount' in bot) {
      navigate(
        `/project/${project._id}/${ProjectScreens.userAccount}/${bot.userAccount._id}`,
      );
    } else if ('bot' in bot) {
      navigate(`/project/${project._id}/${ProjectScreens.bot}/${bot.bot._id}`);
    }
  };

  useEffect(() => {
    if ('userAccount' in bot) {
      setAvatarUrl(bot.userAccount.avatarUrl);
      setNameSplit(bot.userAccount.name.split(' '));
    } else if ('bot' in bot) {
      setAvatarUrl(bot.bot.avatarUrl);
      setNameSplit(bot.bot.name.split(' '));
    }
  }, [bot]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'relative',
        padding: '4px',
        cursor: 'pointer',
        backgroundColor: selected ? 'green.1' : 'transparent',
      }}
      onClick={onSelect}
    >
      {avatarUrl ? (
        <img
          src={avatarUrl}
          alt=""
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
          }}
        />
      ) : (
        <Stack
          sx={{
            backgroundColor: 'grey.10',
            border: '1.5px solid',
            borderColor: 'green.2',
            borderRadius: '50%',
            p: '8px',
            marginBottom: '2px',
          }}
        >
          <IconRobot />
        </Stack>
      )}
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '140%',
          color: 'grey.1',
        }}
      >
        {truncate(nameSplit[0], 10)}
      </Typography>

      <Typography
        sx={{
          fontSize: '12px',
          color: selected ? 'grey.1' : 'grey.6',
        }}
      >
        {truncate(nameSplit[1] ?? '', 10)}
      </Typography>
      <Stack
        sx={{
          position: 'absolute',
          top: '2px',
          right: '0',
          width: '17px',
          height: '17px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: botType === 'BOT' ? 'blue.2' : 'purple.4',
        }}
      >
        {botType === 'BOT' ? (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11"
              cy="11"
              r="10"
              fill="#FFC803"
              stroke="#1E1D1E"
              strokeWidth="2"
            />
            <path
              d="M15.9703 9.77832C15.8928 9.4944 15.6216 9.25103 15.3503 9.21047L12.8317 8.84543L11.708 6.45235C11.5142 6.04675 11.0492 5.88451 10.6617 6.08731C10.5067 6.16843 10.3905 6.29011 10.313 6.45235L9.1893 8.84543L6.67064 9.21047C6.2444 9.29159 5.93441 9.6972 6.01191 10.1434C6.05066 10.3056 6.12816 10.4679 6.2444 10.5895L8.06559 12.4553L7.63935 15.0512C7.56186 15.4974 7.8331 15.903 8.25933 15.9841C8.41432 16.0246 8.60807 15.9841 8.76306 15.903L11.0105 14.6861L13.2579 15.903C13.6454 16.1058 14.1104 15.9435 14.3041 15.5785C14.3816 15.4162 14.4204 15.254 14.3816 15.0512L13.9554 12.4553L15.7766 10.5895C15.9703 10.3867 16.0478 10.1028 15.9703 9.77832Z"
              fill="#FEFEFE"
            />
          </svg>
        ) : (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11"
              cy="11"
              r="10"
              fill="#7E1DDE"
              stroke="#1E1D1E"
              strokeWidth="2"
            />
            <path
              opacity="0.8"
              d="M11 11C12.3812 11 13.5 9.88125 13.5 8.5C13.5 7.11875 12.3812 6 11 6C9.61875 6 8.5 7.11875 8.5 8.5C8.5 9.88125 9.61875 11 11 11ZM11 12.25C9.33125 12.25 6 13.0875 6 14.75V15C6 15.5523 6.44772 16 7 16H15C15.5523 16 16 15.5523 16 15V14.75C16 13.0875 12.6687 12.25 11 12.25Z"
              fill="#FEFEFE"
            />
          </svg>
        )}
      </Stack>
    </Stack>
  );
};

export default TelegramBotAndAccountThumb;
