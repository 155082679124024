import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext } from 'react';

import { AppContext } from '../../AppContext';

import { BroadcastStatus, BroadcastType } from './interfaces';

interface BroadcastCardProps {
  name: string;
  type?: BroadcastType;
  status?: string;
  recipientsCount: number;
  startDate: Date | null;
  endDate: Date | null;
  onCopy: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const BroadcastCard: FC<BroadcastCardProps> = ({
  name,
  type = 'FLOW',
  status = 'PLANNED',
  recipientsCount,
  startDate,
  endDate,
  onCopy,
  onEdit,
  onDelete,
}) => {
  const { openConfirmationDialog } = useContext(AppContext);

  const currentTime = new Date();
  const startTime = startDate ? new Date(startDate) : currentTime;

  return (
    <Stack
      sx={{
        width: '330px',
        backgroundColor: 'grey.13',
        borderRadius: '12px',
        maxHeight: '302px',
        height: 'max-content',
        p: '12px',
      }}
    >
      <Typography
        sx={{ textAlign: 'center', fontWeight: 600, fontSize: '18px' }}
      >
        {name}
      </Typography>
      <Divider sx={{ m: '12px 0 16px' }} />
      <Stack flexDirection="row" gap="20px">
        <Stack gap="8px">
          <Typography
            sx={{ color: 'grey.5', fontWeight: 500, fontSize: '16px' }}
          >
            {t('common.status')}
          </Typography>
          {type !== 'DRAFT' && (
            <>
              <Typography
                sx={{ color: 'grey.5', fontWeight: 500, fontSize: '16px' }}
              >
                {t('broadcast.broadcastCard.recipients')}
              </Typography>
              {startDate && (
                <Typography
                  sx={{ color: 'grey.5', fontWeight: 500, fontSize: '16px' }}
                >
                  {t('broadcast.broadcastCard.start')}
                </Typography>
              )}
            </>
          )}
          {endDate && (
            <Typography
              sx={{ color: 'grey.5', fontWeight: 500, fontSize: '16px' }}
            >
              {t('broadcast.broadcastCard.end')}
            </Typography>
          )}
        </Stack>
        <Stack gap="8px">
          <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
            {status === BroadcastStatus.draft
              ? t('broadcast.broadcastStatus.draft')
              : status === BroadcastStatus.planned && currentTime > startTime
              ? t('broadcast.broadcastStatus.started')
              : status === BroadcastStatus.creating
              ? t('broadcast.broadcastStatus.creating')
              : status === BroadcastStatus.planned
              ? t('broadcast.broadcastStatus.planned')
              : t('broadcast.broadcastStatus.draft')}
          </Typography>
          {type !== 'DRAFT' && (
            <>
              <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                {recipientsCount}
              </Typography>
              {startDate && (
                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                  {new Date(startDate).toLocaleString()}
                </Typography>
              )}
            </>
          )}
          {endDate && (
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {new Date(endDate).toLocaleString()}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack flexDirection="row" sx={{ mt: '16px' }}>
        {(type === 'DRAFT' || startTime > currentTime) && (
          <Button
            className="red"
            sx={{
              width: '48px',
              height: '48px',
              minWidth: '48px',
              mr: '4px',
            }}
            onClick={() => {
              openConfirmationDialog(
                `${t('broadcast.deleteBroadcast')} ${name}?`,
                t('common.delete'),
                () => {
                  onDelete();
                },
              );
            }}
          >
            <DeleteIcon sx={{ color: 'grey.1', fontSize: '22px' }} />
          </Button>
        )}
        <Button
          className="blue"
          sx={{
            width: startTime < currentTime ? '100%' : ' 48px',
            height: '48px',
            minWidth: '48px',
            mr: startTime < currentTime ? '0' : '12px',
          }}
          onClick={onCopy}
        >
          {startTime < currentTime ? (
            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
              {t('common.copy')}
            </Typography>
          ) : (
            <ContentCopyIcon
              sx={{
                color: 'grey.1',
              }}
            />
          )}
        </Button>
        {(startTime > currentTime || status === BroadcastStatus.draft) && (
          <Button
            className="blue"
            sx={{
              width: '100%',
              height: '48px',
              fontWeight: 500,
              fontSize: ' 16px',
            }}
            onClick={onEdit}
          >
            {t('common.edit')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default BroadcastCard;
