import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { APP_CONFIG } from '../config';
import { UserContext } from '../user/user.context';

const LoginCodeForm = () => {
  const { handleLogin, loginError } = useContext(UserContext);
  const { t } = useTranslation();

  const [authCode, setAuthCode] = useState('');

  useEffect(() => {
    if (authCode.length === 6) {
      handleLogin(authCode);
    }
  }, [authCode]);

  return (
    <Stack
      sx={{
        mt: '36px',
        maxWidth: '280px',
      }}
    >
      <Typography
        sx={{
          color: 'grey.1',
          fontWeight: 600,
          fontSize: '24px',
          textAlign: 'center',
        }}
      >
        {t('login.codeTitle')}
      </Typography>
      <TextField
        value={authCode}
        sx={{
          width: '280px',
          m: '12px 0 0',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: loginError ? 'red.4' : 'grey.10',
              borderRadius: '6px',
            },
            '&.Mui-focused fieldset': {
              borderColor: loginError ? 'red.4' : 'grey.10',
            },
          },
        }}
        placeholder={t('login.placeholderCode')}
        onChange={(e) => {
          setAuthCode(e.target.value.trim());
        }}
      />
      {loginError && (
        <Typography
          sx={{
            color: 'red.4',
            pl: '10px',
            fontWeight: 500,
            fontSize: '14px',
          }}
        >
          {t('login.invalidCode')}
        </Typography>
      )}
      <Typography
        sx={{
          mt: '6px',
          color: 'grey.1',
          opacity: 0.7,
          fontSize: '14px',
          fontWeight: 500,
          textAlign: 'center',
          mb: loginError ? '0' : '24px',
        }}
      >
        {t('login.requirementOfTheCode')}
      </Typography>

      {loginError && (
        <Stack
          sx={{
            m: '24px 0 ',
          }}
        >
          <Link
            to={`https://t.me/${APP_CONFIG.TELEGRAM_AUTH_BOT_USERNAME}?start=login`}
            target="_blank"
            style={{
              display: 'flex',
              textDecoration: 'none',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Divider
              sx={{ width: '29px', backgroundColor: 'blue.2', height: '1px' }}
            />
            <Typography
              sx={{
                color: 'blue.2',
                textAlign: 'center',
                fontWeight: 500,
                fontSize: '16px',
              }}
            >
              {t('login.generateCode')}
            </Typography>
            <Divider
              sx={{ width: '29px', backgroundColor: 'blue.2', height: '1px' }}
            />
          </Link>
        </Stack>
      )}
      <Link
        to={`https://t.me/${APP_CONFIG.TELEGRAM_AUTH_BOT_USERNAME}?start=login`}
        target="_blank"
        style={{
          display: 'inline-block',
          textDecoration: 'none',
        }}
      >
        <Button
          sx={{
            color: 'grey.15',
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '18px',
            backgroundColor: 'green.2',
            width: '280px',
            height: '56px',
            '&:hover': { backgroundColor: 'green.2', color: 'grey.15' },
          }}
        >
          {t('login.login')}
        </Button>
      </Link>
    </Stack>
  );
};

export default LoginCodeForm;
