import { Grid, Stack } from '@mui/material';
import React from 'react';

import { IMedia, MediaType } from '../../interfaces/common';
import VideoPreview from '../media/video.preview';

interface ImageGridProps {
  media: IMedia[];
  onOpenMediaModal: (index: number) => void;
}

const GridImages: React.FC<ImageGridProps> = ({ media, onOpenMediaModal }) => {
  return (
    <Grid
      container
      spacing={2}
      className={`grid__container grid__container-${media.length}`}
      sx={{
        gap: '16px',
        width: '100%',
        margin: '0',
      }}
    >
      {media.map((mediaItem, index) => {
        return (
          <Stack
            key={index}
            className={`grid-template-${media.length}-${index + 1}`}
          >
            {(() => {
              switch (mediaItem.type) {
                case MediaType.photo: {
                  return (
                    <>
                      <img
                        onClick={() => onOpenMediaModal(index)}
                        style={{
                          cursor: 'pointer',
                          width: '100%',
                          height: '100%',
                          maxWidth: '100%',
                          objectFit: 'cover',
                          borderRadius: '6px',
                        }}
                        src={mediaItem.url}
                        alt={`Image ${index}`}
                      />
                    </>
                  );
                }
                case MediaType.video: {
                  return (
                    <>
                      <VideoPreview
                        onOpenModal={() => onOpenMediaModal(index)}
                        videoSrc={mediaItem.url}
                        playerHeight={media.length < 3 ? 300 : '100%'}
                        playerWidth="100%"
                      />
                    </>
                  );
                }
              }
            })()}
          </Stack>
        );
      })}
    </Grid>
  );
};

export default GridImages;
