import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, Fragment, useState } from 'react';

import ModalBox from '../modal/modal-box';

type ControlButtonsNode = {
  onEdit: (e: React.SyntheticEvent) => void;
  onCopy: (e: React.SyntheticEvent) => void;
  onDelete: () => void;
  isEmptyNode?: boolean;
};
const ControlButtonsNode: FC<ControlButtonsNode> = ({
  onEdit,
  onCopy,
  onDelete,
  isEmptyNode = false,
}) => {
  const [isDeleteNodeModal, setIsDeleteNodeModal] = useState(false);

  const openDeleteNodeModal = () => {
    if (isEmptyNode) {
      onDelete();
    } else {
      setIsDeleteNodeModal(true);
    }
  };
  const closeDeleteNodeModal = () => setIsDeleteNodeModal(false);

  return (
    <Fragment>
      <Stack
        direction="row"
        justifyContent="center"
        gap="6px"
        sx={{
          position: 'absolute',
          top: -40,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Button
          className="green"
          sx={{
            width: '36px',
            minWidth: '36px',
            height: '36px',
            borderRadius: '6px',
          }}
          onClick={onEdit}
        >
          <ModeEditOutlineRoundedIcon
            sx={{
              color: 'grey.1',
              width: '15px',
              height: '15px',
            }}
          />
        </Button>
        <Button
          className="green"
          sx={{
            width: '36px',
            minWidth: '36px',
            height: '36px',
            borderRadius: '6px',
          }}
          onClick={onCopy}
        >
          <ContentCopyRoundedIcon
            sx={{
              color: 'grey.1',
              width: '15px',
              height: '16px',
            }}
          />
        </Button>
        <Button
          className="red"
          onClick={openDeleteNodeModal}
          sx={{
            width: '36px',
            minWidth: '36px',
            height: '36px',

            borderRadius: '6px',
          }}
        >
          <DeleteIcon
            sx={{
              color: 'grey.1',
            }}
          />
        </Button>
      </Stack>
      <ModalBox
        open={isDeleteNodeModal}
        onClose={closeDeleteNodeModal}
        sx={{
          padding: '32px 56px',
          borderRadius: '12px',
        }}
        maxWidth={'428px'}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '18px',
          }}
        >
          {t('common.removeFromFlow')}?
        </Typography>
        <Stack flexDirection="row" gap="8px" sx={{ mt: '24px' }}>
          <Button
            className="red"
            sx={{
              height: '56px',
              width: '100%',
            }}
            onClick={onDelete}
          >
            {t('common.delete')}
          </Button>
          <Button
            className="green"
            sx={{
              height: '56px',
              width: '100%',
            }}
            onClick={closeDeleteNodeModal}
          >
            {t('common.cancel')}
          </Button>
        </Stack>
      </ModalBox>
    </Fragment>
  );
};

export default ControlButtonsNode;
