const ENV = process.env.REACT_APP_NODE_ENV || 'development';

type AppConfig = {
  API_URL: string;
  WEB_SOCKET_URL: string;
  TELEGRAM_AUTH_BOT_USERNAME: string;
};

const APP_CONFIGS: Record<string, AppConfig> = {
  development: {
    API_URL: 'https://dev.axioma.bot/api',
    WEB_SOCKET_URL: 'https://dev.axioma.bot',
    TELEGRAM_AUTH_BOT_USERNAME: 'aximonsterbot',
  },
  production: {
    API_URL: 'https://axioma.bot/api',
    WEB_SOCKET_URL: 'https://axioma.bot',
    TELEGRAM_AUTH_BOT_USERNAME: 'axioma_core_bot',
  },
  yarik0: {
    TELEGRAM_AUTH_BOT_USERNAME: 'bot_username',
    API_URL: 'http://localhost:5001/api',
    WEB_SOCKET_URL: 'http://localhost:5001',
  },
  ukrlan0: {
    TELEGRAM_AUTH_BOT_USERNAME: 'axilocalbot',
    API_URL: 'http://localhost:5001/api',
    WEB_SOCKET_URL: 'http://localhost:5003',
  },
};

export const APP_CONFIG = APP_CONFIGS[ENV];
