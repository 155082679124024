import React from 'react';

const VolumeOnIcon = () => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8552 0.860993C15.8552 0.535333 15.6666 0.226813 15.3753 0.0896935C15.0668 -0.0645663 14.7239 -0.0131464 14.4669 0.192533L6.87384 6.24295L6.09382 6.85999H2.6454H2.1432C1.61186 6.85999 1.14908 7.09995 0.840559 7.47703C0.583459 7.76841 0.429199 8.16263 0.429199 8.57399V15.43C0.429199 16.3727 1.2005 17.144 2.1432 17.144H6.09382L14.4669 23.8114C14.6211 23.9314 14.8096 24 14.9982 24C15.1182 24 15.2553 23.9657 15.3753 23.9143C15.6666 23.7772 15.8552 23.4686 15.8552 23.143V20.0698V15.2243V0.860993Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default VolumeOnIcon;
