import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';

interface SelectChipProps {
  projectParams: string[];
  selectedParams: string[];
  onSelectParam: (event: SelectChangeEvent<string[]>) => void;
  onRemoveParam: (value: string) => void;
}

const SelectChip: FC<SelectChipProps> = ({
  projectParams,
  selectedParams,
  onSelectParam,
  onRemoveParam,
}) => {
  const isSelected = (param: string) => selectedParams.includes(param);
  const paramValues = projectParams.filter(isSelected);

  return (
    <Stack sx={{ minWidth: '125px', minHeight: '56px', width: '100%' }}>
      <FormControl
        sx={{
          height: '100%',
          width: '100%',
          '& .MuiSelect-select': { p: ' 12px 8px' },
          '& .MuiInputBase-root': { height: '100%' },
          '& fieldset': {
            borderRadius: '6px',
            border: '1px solid',
            borderColor: '#4C4B4C!important',
            backgroundColor: 'grey.15',
          },
          '& svg': {
            zIndex: '1',
          },
        }}
      >
        <InputLabel>{t('statistics.filterBy')}</InputLabel>
        <Select
          label={t('statistics.filterBy')}
          value={paramValues}
          onChange={onSelectParam}
          renderValue={() => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  '& .MuiMenu-list': {
                    backgroundColor: 'grey.4',
                  },
                  '.MuiMenu-paper &': {
                    border: 'none',
                  },
                }}
              >
                {selectedParams.map((param, index) => (
                  <Chip
                    sx={{
                      backgroundColor: 'green.3',
                      color: 'grey.10',
                      zIndex: '1',
                      fontWeight: 600,
                      fontSize: '12px',
                      '& .MuiSvgIcon-root': {
                        color: 'green.4',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          color: 'grey.15',
                        },
                      },
                    }}
                    key={index}
                    label={param}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    onDelete={() => onRemoveParam(param)}
                  />
                ))}
              </Box>
            );
          }}
        >
          {projectParams
            .filter((param) => !isSelected(param))
            .map((param, index) => (
              <MenuItem
                sx={{
                  justifyContent: 'center',
                  color: 'grey.5',
                  backgroundColor: '#4C4B4C!important',
                  '&:hover': { backgroundColor: 'grey.15', outline: 'none' },
                  '&:active': { backgroundColor: 'grey.15', outline: 'none' },
                  '&:focus-visible': {
                    backgroundColor: 'grey.15',
                    outline: 'none',
                  },
                }}
                key={index}
                value={param}
              >
                {param}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default SelectChip;
