import { Stack } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';

import fetchFlows from '../api/flows/fetch-flows';
import fetchFlowNodes from '../api/flows/fetch-nodes';
import { GlobalContext } from '../GlobalContext';

import { BotContext } from './bot.context';
import FlowNodeTab from './flow-node.tab';
import FlowsList from './flows/flows.list';
import {
  IFlow,
  IFlowNodeExtended,
  IFlowWithActivatorsAndFolder,
} from './flows/interfaces';
import FlowEditor from './flows/nodes-editor';

interface FlowsTabProps {
  collapseDrawer: () => void;
}

const FlowsTab: FC<FlowsTabProps> = ({ collapseDrawer }) => {
  const { bot, flows, access } = useContext(BotContext);
  const { onSelectFlowName, resetFlowName } = useContext(GlobalContext);

  const [selectedFlow, setSelectedFlow] = useState<IFlow | null>(null);
  const [flowNodes, setFlowNodes] = useState<IFlowNodeExtended[]>([]);
  const [flowId, setFlowId] = useState<string | null>(
    sessionStorage.getItem('flowId'),
  );

  useEffect(() => {
    if (!bot || !access?.flows) return;

    fetchFlows(bot._id);
  }, [bot, access]);

  const fetchAndSelectFlow = (flowId: string) => {
    if (!bot || !access?.flows) return;

    fetchFlows(bot._id).then((flows: IFlow[]) => {
      const prevSelectedFlow = flows.find(({ _id }) => flowId === _id);

      if (!prevSelectedFlow) return;

      setFlowId(flowId);
      fetchFlowNodes(flowId).then((nodes: IFlowNodeExtended[]) => {
        setFlowNodes(nodes);
        setSelectedFlow(prevSelectedFlow);
      });
    });
  };

  const selectFlow = (flow: IFlowWithActivatorsAndFolder) => {
    collapseDrawer();
    fetchAndSelectFlow(flow._id);
    onSelectFlowName(flow.name, flow._id);
  };

  const unselectFlow = () => {
    setSelectedFlow(null);
    setFlowId(null);
    resetFlowName();
  };

  useEffect(() => {
    const activeFlowNode = flows?.find(
      (flow) => flow._id === sessionStorage.getItem('flowId'),
    );

    if (activeFlowNode) {
      fetchAndSelectFlow(activeFlowNode._id);
      setFlowId(activeFlowNode._id);
      onSelectFlowName(activeFlowNode.name, activeFlowNode._id);
    }
  }, [flows]);
  return (
    <Stack sx={{ height: '100%' }}>
      {Boolean(!selectedFlow && !flowId) && (
        <FlowsList selectFlow={selectFlow} />
      )}

      {Boolean(flowId && !selectedFlow) && <FlowNodeTab />}
      {Boolean(selectedFlow && flowId) && (
        <FlowEditor
          flow={selectedFlow as IFlow}
          flowNodes={flowNodes}
          onBack={unselectFlow}
          refetchFlow={() => {
            fetchAndSelectFlow(selectedFlow?._id as string);
          }}
        />
      )}
    </Stack>
  );
};

export default FlowsTab;
