import { Divider, Stack } from '@mui/material';
import React, { FC } from 'react';

import FormattedText from '../../common/formatted-text';

interface ServiceMessageComponentProps {
  text: string;
}

const ServiceMessageComponent: FC<ServiceMessageComponentProps> = ({
  text,
}) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%' }}
      gap="20px"
    >
      <Divider
        sx={{ width: '28%', maxWidth: '150px', borderColor: 'grey.10' }}
      />
      <Stack
        sx={{
          zIndex: 1,
          mh: '5px',
          textAlign: 'center',
        }}
      >
        <FormattedText
          text={text}
          style={{
            color: '#A1A1A1',
          }}
        />
      </Stack>
      <Divider
        sx={{ width: '28%', maxWidth: '150px', borderColor: 'grey.10' }}
      />
    </Stack>
  );
};

export default ServiceMessageComponent;
