import CallSplitIcon from '@mui/icons-material/CallSplit';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import MailIcon from '@mui/icons-material/Mail';
import StarIcon from '@mui/icons-material/Star';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import {
  Checkbox,
  Paper,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useContext } from 'react';

import IconRobot from '../../../assets/icons/RobotIcon';
import Input from '../../../common/inputs/InputWithIcon';
import TooltipInfo from '../../../common/tooltip/tooltip';
import { TelegramMessengerAccess } from '../../../telegram-bots/telegram-bot.interface';
import truncate from '../../../utils/truncate';
import { ProjectContext } from '../../project.context';
import { ProjectInviteAccess } from '../../project.interface';

type TableInviteLinkProps = {
  accesses: ProjectInviteAccess[];
  checkAllCheckboxChange: (telegramBotId: string, value: boolean) => void;
  onChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  nameInviteLink: string;
  handleCheckboxChange: (
    telegramBotId: string,
    key: keyof ProjectInviteAccess,
    value: boolean | TelegramMessengerAccess,
  ) => void;
};

const TableInviteLink: FC<TableInviteLinkProps> = ({
  accesses,
  onChangeName,
  nameInviteLink,
  checkAllCheckboxChange,
  handleCheckboxChange,
}) => {
  const { telegramBots } = useContext(ProjectContext);
  return (
    <Stack
      sx={{
        m: '24px auto',
      }}
    >
      <Input
        value={nameInviteLink}
        handleChangeSearch={onChangeName}
        placeholder={t('common.nameLabel')}
        withIcon={false}
        backgroundNarrative="dark"
        fullWidth
      />
      <Stack
        gap="8px"
        sx={{
          mt: '12px',
        }}
      >
        {telegramBots.map((bot) => {
          const currentAccess = accesses.find(
            (acc) => acc.telegramBot === bot.bot._id,
          );
          return (
            <Paper
              key={bot.bot._id}
              sx={{ width: '100%', overflow: 'hidden', flex: '1 1 auto' }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  width: '100%',
                  maxHeight: 440,
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: 'grey.14',
                  p: '0 16px',
                }}
              >
                <Stack
                  gap="6px"
                  alignItems="center"
                  sx={{
                    borderRight: '1px solid',
                    borderColor: 'grey.10',
                    p: '12px 0',
                    width: '100%',
                    minWidth: '120px',
                  }}
                >
                  {bot.bot.avatarUrl ? (
                    <img
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                      }}
                      src={bot.bot.avatarUrl}
                      alt="#"
                    />
                  ) : (
                    <IconRobot
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <Stack direction="row" alignItems="center">
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '18px',
                      }}
                    >
                      {truncate(bot.bot.name, 12)}
                    </Typography>
                    <Checkbox
                      checked={
                        currentAccess?.broadcasts &&
                        currentAccess?.channels &&
                        currentAccess?.flows &&
                        currentAccess?.statistics &&
                        (currentAccess?.messenger ===
                          TelegramMessengerAccess.manager ||
                          currentAccess?.messenger ===
                            TelegramMessengerAccess.admin)
                      }
                      style={{
                        color:
                          currentAccess?.broadcasts &&
                          currentAccess?.channels &&
                          currentAccess?.flows &&
                          currentAccess?.statistics &&
                          (currentAccess?.messenger ===
                            TelegramMessengerAccess.manager ||
                            currentAccess?.messenger ===
                              TelegramMessengerAccess.admin)
                            ? '#DEFF82'
                            : '#4b4a4a',
                      }}
                      onChange={(event) =>
                        checkAllCheckboxChange(
                          bot.bot._id,
                          event.target.checked,
                        )
                      }
                    />
                  </Stack>
                </Stack>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    p: '12px 0',
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        position: 'sticky',
                        top: '0',
                        zIndex: '12',
                      }}
                    >
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <MailIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessBroadcast',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.broadcasts')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <CampaignRoundedIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessChannels',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.channels')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <CallSplitIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessFlow',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.flows')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <TrendingUpRoundedIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessStatistics',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.statistics')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <ChatRoundedIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessMessenger',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.messenger')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <SupportAgentRoundedIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessManager',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.manager')}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: 'transparent',
                          borderBottom: 'none',
                          padding: '16px 6px 1px',
                        }}
                        align="center"
                      >
                        <Stack flexDirection="column" alignItems="center">
                          <TooltipInfo
                            icon={
                              <StarIcon
                                sx={{ color: 'grey.5', fontSize: '18px' }}
                              />
                            }
                            title={t(
                              'projects.screen-new-telegram-bot.participantsTable.tooltips.accessAdmin',
                            )}
                            placement="top"
                          />
                          <Typography
                            sx={{ fontSize: '10px', fontWeight: 600 }}
                          >
                            {t('common.admin')}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        borderBottom: '1px solid rgba(81, 81, 81, 1)',
                      }}
                    >
                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Checkbox
                          checked={currentAccess?.broadcasts || false}
                          style={{
                            color: currentAccess?.broadcasts
                              ? '#DEFF82'
                              : '#4b4a4a',
                          }}
                          onChange={(event) =>
                            handleCheckboxChange(
                              bot.bot._id,
                              'broadcasts',
                              event.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Checkbox
                          checked={currentAccess?.channels || false}
                          style={{
                            color: currentAccess?.channels
                              ? '#DEFF82'
                              : '#4b4a4a',
                          }}
                          onChange={(event) =>
                            handleCheckboxChange(
                              bot.bot._id,
                              'channels',
                              event.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Checkbox
                          checked={currentAccess?.flows || false}
                          style={{
                            color: currentAccess?.flows ? '#DEFF82' : '#4b4a4a',
                          }}
                          onChange={(event) =>
                            handleCheckboxChange(
                              bot.bot._id,
                              'flows',
                              event.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Checkbox
                          checked={currentAccess?.statistics || false}
                          style={{
                            color: currentAccess?.statistics
                              ? '#DEFF82'
                              : '#4b4a4a',
                          }}
                          onChange={(event) =>
                            handleCheckboxChange(
                              bot.bot._id,
                              'statistics',
                              event.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          border: 'none',
                          padding: '9px 9px 6px',
                        }}
                      >
                        <Checkbox
                          checked={
                            currentAccess?.messenger !==
                              TelegramMessengerAccess.none || false
                          }
                          style={{
                            color:
                              currentAccess?.messenger !==
                              TelegramMessengerAccess.none
                                ? '#DEFF82'
                                : '#4b4a4a',
                          }}
                          onChange={(event) =>
                            handleCheckboxChange(
                              bot.bot._id,
                              'messenger',
                              event.target.checked
                                ? TelegramMessengerAccess.manager
                                : TelegramMessengerAccess.none,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Radio
                          checked={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.manager
                          }
                          onChange={() =>
                            handleCheckboxChange(
                              bot.bot._id,
                              'messenger',
                              TelegramMessengerAccess.manager,
                            )
                          }
                          style={{
                            color:
                              currentAccess?.messenger ===
                              TelegramMessengerAccess.manager
                                ? '#DEFF82'
                                : '#4b4a4a',
                          }}
                          disabled={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.none
                          }
                        />
                      </TableCell>

                      <TableCell
                        sx={{ border: 'none', padding: '9px 9px 6px' }}
                        align="center"
                      >
                        <Radio
                          checked={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.admin
                          }
                          style={{
                            color:
                              currentAccess?.messenger ===
                              TelegramMessengerAccess.admin
                                ? '#DEFF82'
                                : '#4b4a4a',
                          }}
                          onChange={() =>
                            handleCheckboxChange(
                              bot.bot._id,
                              'messenger',
                              TelegramMessengerAccess.admin,
                            )
                          }
                          disabled={
                            currentAccess?.messenger ===
                            TelegramMessengerAccess.none
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default TableInviteLink;
