import React, { FC, useContext } from 'react';

import AppLoader from './common/app-loader';
import DialogConfirmation from './common/dialog-confirmation/dialog-confirmation';
import Snackbar from './common/snackbar';
import WithBackgroundPattern from './common/withBgPattern';
import Router from './router';
import { UserContext } from './user/user.context';

const App: FC = () => {
  const { userLoading } = useContext(UserContext);

  if (userLoading) {
    return <AppLoader />;
  }

  return (
    <WithBackgroundPattern>
      <Router />
      <Snackbar />
      <DialogConfirmation />
    </WithBackgroundPattern>
  );
};
export default App;
