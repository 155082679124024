import axios from '../axios';

const uploadFile = async (formData: FormData, projectId = '') => {
  try {
    const response = await axios.post(`/media/upload/${projectId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default uploadFile;
