import HealingIcon from '@mui/icons-material/Healing';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import {
  Button,
  Divider,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useEffect, useState } from 'react';

import ControlButtonsEditor from '../../../../common/flow-nodes/control-buttons-editor';
import { ITelegramChannelWithActivators } from '../../../channels/interfaces';
import {
  ActionFlowNodeTaskData,
  ActionTaskTypes,
  IActionsFlowNodeData,
  IFlowWithActivatorsAndFolder,
} from '../../interfaces';

import ActionsItem from './actions-item';
import ActionsModal from './actions-modal';

interface ActionsEditorProps {
  open: boolean;
  initialTasks: IActionsFlowNodeData['tasks'];
  onClose: () => void;
  onSubmit: (
    e: React.SyntheticEvent,
    tasks: IActionsFlowNodeData['tasks'],
  ) => void;
  flows: IFlowWithActivatorsAndFolder[];
  channels: ITelegramChannelWithActivators[];
}

//TODO: add proper icons to menu
const ActionsEditor: FC<ActionsEditorProps> = ({
  open,
  onClose,
  onSubmit,
  initialTasks,
  flows,
  channels,
}) => {
  const [tasks, setTasks] = useState<IActionsFlowNodeData['tasks']>([]);
  const [creatingTask, setCreatingTask] =
    useState<ActionFlowNodeTaskData | null>(null);

  useEffect(() => {
    setTasks(initialTasks);
  }, [initialTasks]);

  const resetCreatingTask = () => {
    setCreatingTask(null);
  };

  const addToTasks = () => {
    if (!creatingTask) return;

    setTasks((prev) => [...prev, creatingTask]);
    resetCreatingTask();
  };

  const onChangeCreatingTask = (data: ActionFlowNodeTaskData) => {
    setCreatingTask(data);
  };

  const onAddAssignParam = () => {
    const newTask: ActionFlowNodeTaskData = {
      type: ActionTaskTypes.assignParam,
      key: '',
      value: '',
      inputValue: '',
    };

    setCreatingTask(newTask);
  };

  const onAddDeleteParam = () => {
    const newTask: ActionFlowNodeTaskData = {
      type: ActionTaskTypes.deleteParam,
      key: '',
      inputValue: '',
    };

    setCreatingTask(newTask);
  };

  const onEditTask = (index: number, data: ActionFlowNodeTaskData) => {
    setTasks((prev) => {
      const result = [];

      for (let i = 0; i < prev.length; i++) {
        if (i !== index) {
          result.push(prev[i]);
          continue;
        }

        result.push({
          ...prev[i],
          ...data,
        });
      }

      return result;
    });
  };

  const onDeleteTask = (index: number) => {
    setTasks((prev) => {
      const result = [...prev];

      return result.filter((_, i) => index !== i);
    });
  };

  const onSelectChannelCreatingTask = (
    event: SelectChangeEvent,
    actionType: ActionTaskTypes,
  ) => {
    const selected = channels?.find((channel) =>
      channel?._id?.includes(event.target.value),
    );

    if (!selected) return;

    setCreatingTask({
      type:
        actionType === ActionTaskTypes.unbanInChannel
          ? ActionTaskTypes.unbanInChannel
          : actionType === ActionTaskTypes.kickFromChannel
          ? ActionTaskTypes.kickFromChannel
          : ActionTaskTypes.acceptToChannel,
      channelId: selected._id,
      channelName: selected.title,
    });
  };

  const onSelectChannel = (
    event: SelectChangeEvent,
    index: number,
    actionType: ActionTaskTypes,
  ) => {
    const selected = channels?.find((channel) =>
      channel?._id?.includes(event.target.value),
    );

    if (!selected) return;

    onEditTask(index, {
      type:
        actionType === ActionTaskTypes.unbanInChannel
          ? ActionTaskTypes.unbanInChannel
          : actionType === ActionTaskTypes.kickFromChannel
          ? ActionTaskTypes.kickFromChannel
          : ActionTaskTypes.acceptToChannel,
      channelId: selected._id,
      channelName: selected.title,
    });
  };

  const onSelectFlowCreatingTask = (event: SelectChangeEvent) => {
    const selected = flows?.find((flow) =>
      flow?._id?.includes(event.target.value),
    );

    if (!selected) return;

    setCreatingTask({
      type: ActionTaskTypes.startFlow,
      flowId: selected._id,
      flowName: selected.name,
    });
  };

  const onSelectFlow = (event: SelectChangeEvent, index: number) => {
    const selected = flows?.find((flow) =>
      flow?._id?.includes(event.target.value),
    );

    if (!selected) return;

    onEditTask(index, {
      type: ActionTaskTypes.startFlow,
      flowId: selected._id,
      flowName: selected.name,
    });
  };

  const onAddAcceptToChannel = () => {
    const newTask: ActionFlowNodeTaskData = {
      type: ActionTaskTypes.acceptToChannel,
      channelName: '',
      channelId: '',
    };

    setCreatingTask(newTask);
  };

  const onAddStartFlow = () => {
    const newTask: ActionFlowNodeTaskData = {
      type: ActionTaskTypes.startFlow,
      flowId: '',
      flowName: '',
    };

    setCreatingTask(newTask);
  };

  const onAddKickFromChannel = () => {
    const newTask: ActionFlowNodeTaskData = {
      type: ActionTaskTypes.kickFromChannel,
      channelName: '',
      channelId: '',
    };

    setCreatingTask(newTask);
  };

  const onAddUnbanFlow = () => {
    const newTask: ActionFlowNodeTaskData = {
      type: ActionTaskTypes.unbanInChannel,
      channelName: '',
      channelId: '',
    };

    setCreatingTask(newTask);
  };

  const onSubmitButton = (e: React.SyntheticEvent) => {
    onSubmit(e, tasks);
  };

  if (!open) return null;

  const allTasksMatch = () => {
    let allTasksMatch = true;

    for (let index = 0; index < tasks.length; index++) {
      const task = tasks[index];
      const initialTask = initialTasks[index];

      if (task?.type !== initialTask?.type) {
        allTasksMatch = false;
        break;
      }

      switch (task.type) {
        case ActionTaskTypes.assignParam:
          if (
            initialTask.type !== ActionTaskTypes.assignParam ||
            task.key !== initialTask.key ||
            task.value !== initialTask.value
          ) {
            allTasksMatch = false;
          }
          break;
        case ActionTaskTypes.deleteParam:
          if (
            initialTask.type !== ActionTaskTypes.deleteParam ||
            task.key !== initialTask.key
          ) {
            allTasksMatch = false;
          }
          break;
        case ActionTaskTypes.unbanInChannel:
          if (
            initialTask.type !== ActionTaskTypes.unbanInChannel ||
            task.channelId !== initialTask.channelId ||
            task.channelName !== initialTask.channelName
          ) {
            allTasksMatch = false;
          }
          break;
        case ActionTaskTypes.startFlow:
          if (
            initialTask.type !== ActionTaskTypes.startFlow ||
            task.flowId !== initialTask.flowId ||
            task.flowName !== initialTask.flowName
          ) {
            allTasksMatch = false;
          }
          break;
      }
    }
    return allTasksMatch;
  };

  const buttonDisabled =
    tasks.length === 0 ||
    allTasksMatch() ||
    tasks.some((task) => {
      if (task.type === ActionTaskTypes.acceptToChannel) {
        return task.channelName.trim() === '' || task.channelId.trim() === '';
      } else if (task.type === ActionTaskTypes.assignParam) {
        return task.key.trim() === '' || task.value.trim() === '';
      } else if (task.type === ActionTaskTypes.deleteParam) {
        return task.key.trim() === '';
      } else if (task.type === ActionTaskTypes.startFlow) {
        return task.flowName === '' || task.flowId.trim() === '';
      } else if (task.type === ActionTaskTypes.unbanInChannel) {
        return task.channelName.trim() === '' || task.channelId.trim() === '';
      }
      return false;
    });
  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'grey.14',
        width: '340px',
        height: '100%',
        padding: '0 12px 12px',
        zIndex: 1,
        borderLeft: '2px solid',
        borderRight: '2px solid',
        borderColor: 'grey.10',
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{
          mt: '10px',
          overflow: ' auto',
          marginBottom: '20px',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            borderRadius: '4px',
            backgroundColor: 'grey.13',
            padding: '8px',
            height: '38px',
            mb: '16px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: 'grey.1',
              fontWeight: 500,
              fontSize: '16px',
              opacity: '0.8',
            }}
          >
            {t('flowNodes.actionsLabel')}
          </Typography>
        </Stack>
        <Stack direction="column" gap="6px">
          <Button
            startIcon={<PlaylistAddIcon sx={{ fontSize: '24px' }} />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              backgroundColor: 'grey.15',
              borderColor: 'grey.13',
              height: '56px',
              fontWeight: 500,
              fontSize: '16px',
              gap: '10px',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
            }}
            color="inherit"
            onClick={onAddAssignParam}
          >
            {t('flowNodes.assignParam')}
          </Button>
          <Stack
            sx={{
              position: 'relative',
            }}
          >
            <Button
              startIcon={<PlaylistRemoveIcon sx={{ fontSize: '24px' }} />}
              sx={{
                width: '100%',
                border: '1px solid',
                borderRadius: '6px',
                backgroundColor: 'grey.15',
                pl: '30px',
                display: 'flex',
                justifyContent: 'start',
                borderColor: 'grey.13',
                height: '56px',
                fontWeight: 500,
                fontSize: '16px',
                gap: '10px',
              }}
              color="inherit"
              onClick={onAddDeleteParam}
            >
              {t('flowNodes.deleteParam')}
            </Button>
          </Stack>
          <Button
            color="inherit"
            startIcon={<PlayCircleIcon sx={{ fontSize: '24px' }} />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              backgroundColor: 'grey.15',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              borderColor: 'grey.13',
              height: '56px',
              fontWeight: 500,
              fontSize: '16px',
              gap: '10px',
            }}
            onClick={onAddStartFlow}
          >
            {t('common.flow')}
          </Button>
          <Button
            color="inherit"
            startIcon={<PersonAddIcon sx={{ fontSize: '24px' }} />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              backgroundColor: 'grey.15',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              borderColor: 'grey.13',
              height: '56px',
              fontWeight: 500,
              fontSize: '16px',
              gap: '10px',
            }}
            onClick={onAddAcceptToChannel}
          >
            {t('common.channel')}
          </Button>
          <Button
            color="inherit"
            startIcon={<PersonRemoveIcon sx={{ fontSize: '24px' }} />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              backgroundColor: 'grey.15',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              borderColor: 'grey.13',
              height: '56px',
              fontWeight: 500,
              fontSize: '16px',
              gap: '10px',
            }}
            onClick={onAddKickFromChannel}
          >
            {t('flowNodes.kickFromChannel')}
          </Button>
          <Button
            color="inherit"
            startIcon={<HealingIcon sx={{ fontSize: '24px' }} />}
            sx={{
              width: '100%',
              border: '1px solid',
              borderRadius: '6px',
              backgroundColor: 'grey.15',
              pl: '30px',
              display: 'flex',
              justifyContent: 'start',
              borderColor: 'grey.13',
              height: '56px',
              fontWeight: 500,
              fontSize: '16px',
              gap: '10px',
            }}
            onClick={onAddUnbanFlow}
          >
            {t('flowNodes.unbanInChannel')}
          </Button>
        </Stack>
        <Divider sx={{ m: '16px 0' }} />
        <ActionsItem
          tasks={tasks}
          onEditTask={onEditTask}
          onDeleteTask={onDeleteTask}
          onSelectChannel={onSelectChannel}
          onSelectFlow={onSelectFlow}
          channels={channels}
          flows={flows}
        />
      </Stack>
      <ControlButtonsEditor
        onClose={onClose}
        onSubmit={onSubmitButton}
        sendButtonDisabled={buttonDisabled}
      />
      <ActionsModal
        open={Boolean(!!creatingTask)}
        onClose={resetCreatingTask}
        creatingTask={creatingTask}
        addToTasks={addToTasks}
        onChangeCreatingTask={onChangeCreatingTask}
        onSelectChannel={onSelectChannelCreatingTask}
        channels={channels}
        onSelectFlow={onSelectFlowCreatingTask}
        flows={flows}
      />
    </Stack>
  );
};

export default ActionsEditor;
