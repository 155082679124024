import MicRoundedIcon from '@mui/icons-material/MicRounded';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Stack } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import ImagePlusIcon from '../../assets/icons/ImagePlusIcon';
import { TelegramMessageType } from '../../interfaces/common';
import { TemplateMessageType } from '../templates/interfaces';

interface AddMediaButtonProps {
  openMediaModal: () => void;
  openVoiceModal: () => void;
  openVideoNoteModal: () => void;
  messageType: TelegramMessageType | TemplateMessageType;
}

const AddMediaButton: FC<AddMediaButtonProps> = ({
  openMediaModal,
  openVoiceModal,
  openVideoNoteModal,
  messageType,
}) => {
  const buttonDetails: {
    [key: string]: { icon: ReactNode; text: string };
  } = {
    [TelegramMessageType.media]: {
      icon: (
        <Stack
          sx={{
            opacity: '0.6',
          }}
        >
          <ImagePlusIcon />
        </Stack>
      ),
      text: '',
    },
    [TelegramMessageType.voice]: {
      icon: (
        <MicRoundedIcon
          sx={{
            opacity: '0.6',
            width: '35px',
            height: '35px',
          }}
        />
      ),
      text: '',
    },
    [TelegramMessageType.videoNote]: {
      icon: (
        <VideocamIcon
          sx={{
            opacity: '0.6',
            width: '35px',
            height: '35px',
          }}
        />
      ),
      text: '',
    },
  };

  const onClickAddMedia = () => {
    switch (messageType) {
      case TelegramMessageType.media: {
        openMediaModal();
        return;
      }
      case TelegramMessageType.videoNote: {
        openVideoNoteModal();
        return;
      }
      case TelegramMessageType.voice: {
        openVoiceModal();
        return;
      }
    }
  };

  return (
    <Stack
      onClick={onClickAddMedia}
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: messageType === 'videoNote' ? '50%' : '4px',
        backgroundColor: 'grey.13',
        width: '96px',
        minWidth: '96px',
        height: '96px',
        cursor: 'pointer',
      }}
    >
      {buttonDetails[messageType].icon}
    </Stack>
  );
};

export default AddMediaButton;
