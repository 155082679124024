import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, TextField, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import createTelegramBot from '../../../api/bots/create-bot';
import { AppContext } from '../../../AppContext';
import instructions1 from '../../../assets/images/create-bot/instructionsCreateBot1.png';
import instructions2 from '../../../assets/images/create-bot/instructionsCreateBot2.png';
import animation from '../../../assets/lottie/animation.json';
import ModalBox from '../../../common/modal/modal-box';
import BotSettings from '../../../telegram-bots/settings/bot.settings';
import { ITelegramBot } from '../../../telegram-bots/telegram-bot.interface';
import { ProjectContext } from '../../project.context';

const NewTelegramBot: FC = () => {
  const { triggerSnackbar } = useContext(AppContext);
  const { project, refetchBots } = useContext(ProjectContext);
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [token, setToken] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openInstructionModal = () => {
    setIsModalOpen(true);
  };
  const closeInstructionModal = () => {
    setIsModalOpen(false);
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;

    if (newName.length < 25) {
      setName(newName);
    }
  };

  const [createdBot, setCreatedBot] = useState<ITelegramBot | null>(null);

  const onChangeToken = (e: ChangeEvent<HTMLInputElement>) => {
    const newToken = e.target.value;
    setToken(newToken);
  };

  const [loading, setLoading] = useState(false);

  const onCreateTelegramBot = async () => {
    setLoading(true);

    try {
      const createdBot = await createTelegramBot({
        name,
        token,
        project: project._id,
      });

      setCreatedBot(createdBot);
      triggerSnackbar(t('snackbar.botCreated'), 'success');
    } catch (e) {
      triggerSnackbar(t('snackbar.botNotCreated'), 'error');
    }

    setLoading(false);
    refetchBots();
  };

  const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
  const instructions = [
    {
      title: t('projects.screen-new-telegram-bot.instructions.title1'),
      text: `<p>1. ${t(
        `projects.screen-new-telegram-bot.instructions.list1`,
      )} <span style="font-weight: 700;line-height: 140%; color:#0495FF">@BotFather</span> </p>
      <p>2. ${t(`projects.screen-new-telegram-bot.instructions.list2`)}</p>
      <p>3. ${t(`projects.screen-new-telegram-bot.instructions.list3`)}</p> `,
      image: instructions1,
    },
    {
      title: t('projects.screen-new-telegram-bot.instructions.title2'),
      text: `<p>${t(`projects.screen-new-telegram-bot.instructions.text1`)}</p>
        <p style="margin-top: 15px;color:#4C4B4C">Example:</p>
        <p style="margin-bottom: 15px;font-size:14px">6999768049:AAG2I2TXjgQf6mGHLh61tftR344pgEmTNv4</p>
      <p> ${t(`projects.screen-new-telegram-bot.instructions.text2`)}</p>`,
      image: instructions2,
    },
  ];

  const handleNextInstruction = () => {
    const nextIndex = currentInstructionIndex + 1;
    if (nextIndex < instructions.length) {
      setCurrentInstructionIndex(nextIndex);
    }
  };

  const handlePreviousInstruction = () => {
    const previousIndex = currentInstructionIndex - 1;
    if (previousIndex >= 0) {
      setCurrentInstructionIndex(previousIndex);
    }
  };

  return (
    <Stack
      direction="row"
      gap="30px"
      justifyContent="center"
      sx={{
        padding: '40px',
      }}
    >
      {!loading && !createdBot && (
        <Stack
          sx={{
            maxWidth: '590px',
            backgroundColor: 'grey.14',
            padding: '32px 56px',
            borderRadius: '12px',
            border: '1px solid',
            borderColor: 'grey.10',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '24px',
              lineHeight: '140%',
              textAlign: 'center',
            }}
          >
            {t('projects.screen-new-telegram-bot.listTitle')}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '150%',
              textAlign: 'center',
              m: '16px 0',
            }}
          >
            {t('projects.screen-new-telegram-bot.listText')}
          </Typography>
          <Stack gap="12px">
            <TextField
              value={name}
              onChange={onChangeName}
              placeholder={t(
                'projects.screen-new-telegram-bot.listNamePlaceholder',
              )}
            />
            <TextField
              value={token}
              onChange={onChangeToken}
              placeholder={t(
                'projects.screen-new-telegram-bot.listBotTokenPlaceholder',
              )}
            />
          </Stack>
          <Button
            variant="contained"
            sx={{
              height: '56px',
              color: 'grey.10',
              backgroundColor: 'green.2',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '140%',
              textTransform: 'none',
              cursor: 'pointer',
              m: '32px 0 16px',
              '&:hover': { backgroundColor: 'green.2' },
            }}
            onClick={onCreateTelegramBot}
            disabled={!name || !token || !token.includes(':')}
          >
            {t('projects.screen-new-telegram-bot.listButtonNext')}
          </Button>
          <Button
            onClick={openInstructionModal}
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '140%',
              color: 'green.2',
              width: 'maxContent',
              height: 'maxContent',
              margin: '0 auto',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            {t('projects.screen-new-telegram-bot.howToAddBot')}
          </Button>
        </Stack>
      )}
      <ModalBox
        open={isModalOpen}
        onClose={closeInstructionModal}
        sx={{
          height: '575px',
          p: '32px 56px',
          position: 'relative',
        }}
        maxWidth="50%"
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            cursor: 'pointer',
          }}
          onClick={closeInstructionModal}
        >
          <CloseIcon />
        </Stack>
        <Stack
          sx={{
            height: '100%',
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '140%' }}
          >
            {instructions[currentInstructionIndex].title}
          </Typography>
          <Stack
            flexDirection="column"
            gap="16px"
            sx={{
              m: '24px 0 32px',
              height: '100%',
            }}
          >
            <Typography
              sx={{ fontWeight: 500, fontSize: '20px', lineHeight: '150%' }}
              dangerouslySetInnerHTML={{
                __html: instructions[currentInstructionIndex].text,
              }}
            ></Typography>
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                maxHeight: '180px',
                marginTop: 'auto',
              }}
              src={instructions[currentInstructionIndex].image}
              alt="#"
            />
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              mt: 'auto',
            }}
          >
            <Button
              onClick={handlePreviousInstruction}
              disabled={currentInstructionIndex === 0}
              sx={{
                border: '1px solid',
                borderColor: 'green.2',
                borderRadius: '8px',
                padding: '16px 32px',
                width: '88px',
                height: '40px',
                boxShadow: '0 4px 10px 0 rgba(202, 250, 68, 0.15)',
                backgroundColor: ' rgba(202, 250, 68, 0.4)',
                opacity: currentInstructionIndex === 0 ? 0.5 : 1,
                '&:hover': { backgroundColor: ' rgba(202, 250, 68, 0.4)' },
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: 'grey.1',
                }}
              />
            </Button>
            <Button
              sx={{
                border: '1px solid',
                borderColor: 'green.2',
                borderRadius: '8px',
                padding: '16px 32px',
                width: '88px',
                height: '40px',
                boxShadow: '0 4px 10px 0 rgba(202, 250, 68, 0.15)',
                backgroundColor: 'rgba(202, 250, 68, 0.4)',
                opacity:
                  currentInstructionIndex === instructions.length - 1 ? 0.5 : 1,
                '&:hover': { backgroundColor: ' rgba(202, 250, 68, 0.4)' },
              }}
              onClick={handleNextInstruction}
              disabled={currentInstructionIndex === instructions.length - 1}
            >
              <ArrowForwardIcon
                sx={{
                  color: 'grey.1',
                }}
              />
            </Button>
          </Stack>
        </Stack>
      </ModalBox>
      {loading && (
        <Stack
          sx={{
            maxWidth: '400px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '28px',
              color: 'grey.6',
            }}
          >
            {t('projects.screen-new-telegram-bot.creatingBot')}
          </Typography>
          <Lottie animationData={animation} />
        </Stack>
      )}

      {createdBot && <BotSettings bot={createdBot} />}
    </Stack>
  );
};

export default NewTelegramBot;
