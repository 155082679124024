import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

type InputProps = {
  value: string;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string | undefined;
  backgroundNarrative?: string;
  withIcon?: boolean;
  fullWidth?: boolean;
};
const InputWithIcon: FC<InputProps> = ({
  value,
  handleChangeSearch,
  placeholder,
  backgroundNarrative = 'light',
  withIcon = true,
  fullWidth = false,
}) => {
  const { t } = useTranslation();
  return (
    <TextField
      fullWidth={fullWidth}
      sx={{
        p: '0',
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: backgroundNarrative === 'dark' ? '1px solid' : 'none',
            borderColor: 'grey.10',
            borderRadius: '8px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: 'blue.2',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid',
            borderColor: 'blue.2',
          },
        },
      }}
      placeholder={placeholder ? placeholder : t('common.filterPlaceholder')}
      value={value}
      onChange={handleChangeSearch}
      InputProps={{
        sx: {
          position: 'relative',
          fontWeight: 500,
          fontSize: '18px',
          backgroundColor:
            backgroundNarrative === 'dark' ? 'grey.15' : 'grey.10',
          '& input': {
            backgroundColor:
              backgroundNarrative === 'dark' ? 'grey.15' : 'grey.10',
            pt: '15px',
            pb: '15px',
            pr: '15px',
            pl: withIcon ? '40px' : '15px',
          },
        },
        ...(withIcon && {
          startAdornment: (
            <InputAdornment
              sx={{
                position: 'absolute',
                top: '20px',
                left: '15px',
                width: '15px',
                height: '15px',
              }}
              position="start"
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }),
      }}
    />
  );
};

export default InputWithIcon;
