import { Divider, Stack } from '@mui/material';
import React, { FC } from 'react';

import FormattedText from '../../common/formatted-text';

interface DateMessageComponentProps {
  text: string;
}

const DateMessageComponent: FC<DateMessageComponentProps> = ({ text }) => {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap="20px"
      sx={{ width: '100%' }}
    >
      <Divider
        sx={{ width: '28%', maxWidth: '150px', borderColor: 'grey.10' }}
      />
      <Stack
        sx={{
          zIndex: 1,
          mh: '5px',
        }}
      >
        <FormattedText
          text={text}
          style={{
            color: '#A1A1A1',
          }}
        />
      </Stack>
      <Divider
        sx={{ width: '28%', maxWidth: '150px', borderColor: 'grey.10' }}
      />
    </Stack>
  );
};

export default DateMessageComponent;
