import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TelegramButtonsRow from '../../common/telegram/telegram-buttons.row';
import { TelegramButton } from '../../interfaces/common';

interface ButtonsEditorProps {
  onAddRow: () => void;
  buttons: TelegramButton[][];
  onRemoveRow: (rowIndex: number) => void;
  onRemoveButton: (rowIndex: number, buttonIndex: number) => void;
  onAddButton: (buttonIndex: number) => void;
  onButtonChange: (
    rowIndex: number,
    buttonIndex: number,
    key: string,
    value: string,
  ) => void;
  addNewButtonsRowDisabled: boolean;
}

const ButtonsEditor: FC<ButtonsEditorProps> = ({
  buttons,
  onButtonChange,
  onRemoveButton,
  onAddButton,
  onRemoveRow,
  onAddRow,
  addNewButtonsRowDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      gap="10px"
      sx={{
        width: '100%',
        minHeight:
          buttons?.length >= 3
            ? '250px'
            : buttons?.length >= 2
            ? '200px'
            : buttons?.length >= 1
            ? '135px'
            : buttons?.length === 0
            ? '60px'
            : '60px',
        margin: '15px 0 0',
        maxHeight: '250px',
        overflow: 'auto',
      }}
    >
      <Stack
        gap="5px"
        sx={{
          width: '100%',
        }}
      >
        {buttons.map((row, rowIndex) => (
          <TelegramButtonsRow
            key={'buttons-row-' + rowIndex}
            buttons={row}
            onRemoveRow={() => {
              onRemoveRow(rowIndex);
            }}
            onRemoveButton={(buttonIndex) => {
              onRemoveButton(rowIndex, buttonIndex);
            }}
            onAddNewUrlButton={() => {
              onAddButton(rowIndex);
            }}
            onButtonChange={(buttonIndex, key, value) => {
              onButtonChange(rowIndex, buttonIndex, key, value);
            }}
            rowIndex={rowIndex}
          />
        ))}
      </Stack>
      <Button
        sx={{
          width: '100%',
          color: 'green.2',
          fontSize: '16px',
          fontWeight: '500',
          backgroundColor: 'transparent',
          ':hover': { border: 'none', backgroundColor: 'transparent' },
        }}
        disabled={addNewButtonsRowDisabled}
        onClick={onAddRow}
      >
        <AddIcon sx={{ fontSize: '24px', ml: '10px' }} />
        {t('telegramButtons.addRow')}
      </Button>
    </Stack>
  );
};

export default ButtonsEditor;
