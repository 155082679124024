import { CreateTemplate } from '../../telegram-messenger/templates/interfaces';
import axios from '../axios';

const createTemplate = async (
  telegramBotId: string,
  botType: string,
  newTemplate: CreateTemplate,
) => {
  const { data } = await axios.post(`/templates/${telegramBotId}/${botType}`, {
    ...newTemplate,
  });

  return data;
};

export default createTemplate;
