import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Lottie from 'lottie-react';
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';

import createTelegramUserAccount from '../../../api/telegram-accounts/create-telegram-account';
import { AppContext } from '../../../AppContext';
import instructions1 from '../../../assets/images/create-userbot/instructionsCreateUserbot1.png';
import instructions2 from '../../../assets/images/create-userbot/instructionsCreateUserbot2.png';
import instructions3 from '../../../assets/images/create-userbot/instructionsCreateUserbot3.png';
import instructions4 from '../../../assets/images/create-userbot/instructionsCreateUserbot4.png';
import animation from '../../../assets/lottie/animation.json';
import ModalBox from '../../../common/modal/modal-box';
import TooltipInfo from '../../../common/tooltip/tooltip';
import { ProjectContext } from '../../project.context';
import ProjectScreens from '../project-screens.enum';

const NewUserAccount: FC = () => {
  const { triggerSnackbar } = useContext(AppContext);
  const { project, telegramBots, refetchUserAccounts } =
    useContext(ProjectContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [appId, setAppId] = useState<string>('');
  const [appHash, setAppHash] = useState<string>('');
  const [telegramBot, setTelegramBot] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openInstructionModal = () => {
    setIsModalOpen(true);
  };

  const closeInstructionModal = () => {
    setIsModalOpen(false);
  };

  const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
  const instructions = [
    {
      title: `1. ${t('projects.screen-new-telegram-bot.instructions.title1')}`,
      text: `<span> ${t(
        `projects.screen-new-telegram-user-account.instructions.text1-1`,
      )} 
<a href="https://my.telegram.org/auth" target="_blank" style="font-weight: 700;line-height: 140%; color:#0495FF">MyTelegram.org</a> ${t(
        `projects.screen-new-telegram-user-account.instructions.text1-2`,
      )} </span> `,
      image: instructions1,
    },
    {
      title: `2. ${t(
        'projects.screen-new-telegram-user-account.instructions.title2',
      )}`,
      text: `<span>${t(
        `projects.screen-new-telegram-user-account.instructions.text2`,
      )}</span>`,
      image: instructions2,
    },
    {
      title: `3. ${t(
        'projects.screen-new-telegram-user-account.instructions.title3',
      )}`,
      text: `<span>${t(
        `projects.screen-new-telegram-user-account.instructions.text3`,
      )}</span>`,
      image: instructions3,
    },
    {
      title: `4. ${t(
        'projects.screen-new-telegram-user-account.instructions.title4',
      )}`,
      text: `<span>${t(
        `projects.screen-new-telegram-user-account.instructions.text4`,
      )}</span>`,
      image: instructions4,
    },
  ];

  const handleNextInstruction = () => {
    const nextIndex = currentInstructionIndex + 1;
    if (nextIndex < instructions.length) {
      setCurrentInstructionIndex(nextIndex);
    }
  };

  const handlePreviousInstruction = () => {
    const previousIndex = currentInstructionIndex - 1;
    if (previousIndex >= 0) {
      setCurrentInstructionIndex(previousIndex);
    }
  };

  const handleChangeBot = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setTelegramBot(value);
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;

    if (newName.length < 25) {
      setName(newName);
    }
  };

  const onChangeAppId = (e: ChangeEvent<HTMLInputElement>) => {
    const newAppId = e.target.value.trim();
    if (isNaN(Number(newAppId)) && newAppId !== '') return;

    setAppId(newAppId);
  };

  const onChangeAppHash = (e: ChangeEvent<HTMLInputElement>) => {
    const newAppHash = e.target.value;
    setAppHash(newAppHash);
  };

  const onChangePhone = (val: string) => {
    setPhone(val);
  };

  const [loading, setLoading] = useState(false);

  const onCreateTelegramUserAccount = async () => {
    setLoading(true);

    try {
      const acc = await createTelegramUserAccount({
        name,
        telegramAppId: +appId,
        telegramAppHash: appHash,
        telegramBot,
        project: project._id,
        phone: phone.toString(),
      });

      triggerSnackbar(t('snackbar.botCreated'), 'success');
      refetchUserAccounts();

      navigate(
        `/project/${project._id}/${ProjectScreens.userAccount}/${acc._id}/settings`,
      );
    } catch (e) {
      triggerSnackbar(t('snackbar.botNotCreated'), 'error');
    }

    setLoading(false);
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        padding: '40px',
      }}
    >
      {!loading && (
        <Stack
          gap="24px"
          sx={{
            minWidth: '380px',
            width: '600px',
            backgroundColor: 'grey.14',
            padding: '32px 24px',
            borderRadius: '18px',
            border: '1px solid',
            borderColor: 'grey.10',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '24px',
              lineHeight: '140%',
              textAlign: 'center',
            }}
          >
            {t('projects.screen-new-telegram-user-account.listTitle')}
          </Typography>
          <Stack flexDirection="row" gap="12px" alignItems="center">
            <Stack
              sx={{
                width: '50%',
              }}
            >
              <TextField
                value={name}
                onChange={onChangeName}
                placeholder={t('common.nameLabel')}
              />
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '50%',
              }}
            >
              <FormControl fullWidth>
                <InputLabel sx={{ top: '-4px' }}>
                  {t('projects.screen-new-telegram-user-account.telegramBot')}
                </InputLabel>
                <Select
                  sx={{
                    border: 'none',
                    backgroundColor: 'grey.15',
                    '& .MuiSelect-select': {
                      p: '14.5px 16px ',
                    },
                  }}
                  label={t(
                    'projects.screen-new-telegram-user-account.telegramBot',
                  )}
                  value={telegramBot ?? ''}
                  onChange={handleChangeBot}
                >
                  {telegramBots.map(({ bot }) => (
                    <MenuItem key={bot._id} value={bot._id}>
                      {bot.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Stack flexDirection="column" gap="12px" sx={{ m: '8px 0' }}>
            <Stack flexDirection="row" gap="12px">
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '140%',
                }}
              >
                Telegram App Api Id and Hash
              </Typography>
              <TooltipInfo
                icon={
                  <InfoOutlinedIcon
                    sx={{ color: 'grey.5', fontSize: '15px' }}
                  />
                }
                title={
                  <Stack>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '130%',
                      }}
                    >
                      {t(
                        'projects.screen-new-telegram-user-account.telegramAppIdAndHash',
                      )}{' '}
                      <Link
                        to="https://my.telegram.org/"
                        style={{
                          all: 'unset',
                          color: 'white',
                          cursor: 'pointer',
                        }}
                        target="_blank"
                      >
                        https://my.telegram.org
                      </Link>
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap="12px"
            >
              <TextField
                sx={{ width: '100%' }}
                value={appId}
                onChange={onChangeAppId}
                placeholder="App api id"
              />
              <TextField
                sx={{ width: '100%' }}
                value={appHash}
                onChange={onChangeAppHash}
                placeholder="App api hash"
              />
            </Stack>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '140%',
                my: '8px',
              }}
            >
              {t('projects.screen-new-telegram-user-account.phoneLabel')}
            </Typography>
            <PhoneInput
              international
              defaultCountry="UA"
              value={phone}
              onChange={onChangePhone}
            />
          </Stack>
          <Stack>
            <Button
              variant="contained"
              sx={{
                height: '44px',
                backgroundColor: 'green.2',
                color: 'grey.10',
                lineHeight: '140%',
                fontWeight: '600',
                fontSize: ' 18px',
                textTransform: 'none',
                cursor: 'pointer',
                boxShadow: ' 0 4px 10px 0 rgba(202, 250, 68, 0.15)',
                '&:hover': { backgroundColor: 'green.2', color: 'grey.10' },
              }}
              onClick={onCreateTelegramUserAccount}
              disabled={!name || !appId || !appHash || !phone || !telegramBot}
            >
              {t('projects.screen-new-telegram-bot.listButtonNext')}
            </Button>
            <Button
              sx={{
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '140%',
                color: 'green.2',
                width: 'max-content',
                height: 'max-content',
                margin: '16px auto 0',
                '&:hover': { backgroundColor: 'transparent' },
              }}
              onClick={openInstructionModal}
            >
              {t('projects.screen-new-telegram-user-account.howToAddUserbot')}
            </Button>
          </Stack>
        </Stack>
      )}
      <ModalBox
        open={isModalOpen}
        onClose={closeInstructionModal}
        sx={{
          height: '500px',
          p: '32px 56px',
        }}
        maxWidth="50%"
      >
        <Stack
          sx={{
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '140%',
              textAlign: 'center',
            }}
          >
            {instructions[currentInstructionIndex].title}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '150%',
              textAlign: 'center',
              m: '8px 0 24px',
              opacity: '0.6',
            }}
            dangerouslySetInnerHTML={{
              __html: instructions[currentInstructionIndex].text,
            }}
          ></Typography>
          <img
            loading="lazy"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              marginBottom: '24px',
              maxHeight: '250px',
            }}
            src={instructions[currentInstructionIndex].image}
            alt=""
          />
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              mt: 'auto',
            }}
          >
            <Button
              onClick={handlePreviousInstruction}
              disabled={currentInstructionIndex === 0}
              sx={{
                border: '1px solid',
                borderColor: 'green.2',
                borderRadius: '8px',
                padding: '16px 32px',
                width: '88px',
                height: '40px',
                boxShadow: '0 4px 10px 0 rgba(202, 250, 68, 0.15)',
                backgroundColor: ' rgba(202, 250, 68, 0.4)',
                opacity: currentInstructionIndex === 0 ? 0.5 : 1,
                '&:hover': { backgroundColor: ' rgba(202, 250, 68, 0.4)' },
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: 'grey.1',
                }}
              />
            </Button>
            <Button
              sx={{
                border: '1px solid',
                borderColor: 'green.2',
                borderRadius: '8px',
                padding: '16px 32px',
                width: '88px',
                height: '40px',
                boxShadow: '0 4px 10px 0 rgba(202, 250, 68, 0.15)',
                backgroundColor: 'rgba(202, 250, 68, 0.4)',
                opacity:
                  currentInstructionIndex === instructions.length - 1 ? 0.5 : 1,
                '&:hover': { backgroundColor: ' rgba(202, 250, 68, 0.4)' },
              }}
              onClick={handleNextInstruction}
              disabled={currentInstructionIndex === instructions.length - 1}
            >
              <ArrowForwardIcon
                sx={{
                  color: 'grey.1',
                }}
              />
            </Button>
          </Stack>
        </Stack>
      </ModalBox>
      {loading && (
        <Stack
          sx={{
            maxWidth: '400px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '28px',
              color: 'grey.6',
            }}
          >
            {t('projects.creatingBotText')}
          </Typography>
          <Lottie animationData={animation} />
        </Stack>
      )}
    </Stack>
  );
};

export default NewUserAccount;
