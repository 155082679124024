import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFlowFolder } from './interfaces';

interface DeleteFolderModalProps {
  folder: IFlowFolder | null;
  onDeleteFolder: (checked: boolean) => void;
  onCloseModalFolder: () => void;
}

const DeleteFolderModal: FC<DeleteFolderModalProps> = ({
  folder,
  onDeleteFolder,
  onCloseModalFolder,
}) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '150%',
          mb: '13px',
        }}
      >
        {t('flow.folders.deleteFolder')} {folder?.name}?
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label={
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '15px',
            }}
          >
            {t('flow.folders.folderCheckbox')}
          </Typography>
        }
      />
      <Stack flexDirection="row" gap="20px" sx={{ width: '100%', mt: '25px' }}>
        <Button
          fullWidth
          className="red"
          sx={{
            borderRadius: ' 8px',
            height: '56px',
          }}
          onClick={() => onDeleteFolder(checked)}
        >
          {t('common.delete')}
        </Button>
        <Button
          fullWidth
          className="green"
          sx={{
            borderRadius: ' 8px',
            height: '56px',
          }}
          onClick={onCloseModalFolder}
        >
          {t('common.cancel')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeleteFolderModal;
