import { ITelegramUserAccount } from '../../telegram-user-accounts/telegram-user.interface';
import axios from '../axios';

const setTelegramAccountCode = async (
  accountId: ITelegramUserAccount['_id'],
  code: string,
) => {
  const { data } = await axios.post(`/user-accounts/${accountId}/code`, {
    code,
  });

  return data;
};

export default setTelegramAccountCode;
