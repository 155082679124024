import AddIcon from '@mui/icons-material/Add';
import { alpha, Button, Divider, Stack } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import createFlow from '../../api/flows/create-flow';
import createFolder from '../../api/flows/create-folder';
import deleteFolder from '../../api/flows/delete-folder';
import editFlow from '../../api/flows/edit-flow';
import editFolder from '../../api/flows/edit-folder';
import fetchFlowFolders from '../../api/flows/fetch-folders';
import { AppContext } from '../../AppContext';
import { NAVBAR_HEIGHT_PX } from '../../common/constants/constants';
import Input from '../../common/inputs/InputWithIcon';
import ModalBox from '../../common/modal/modal-box';
import useThemeColors from '../../utils/hooks/useThemeColors';
import { BotContext } from '../bot.context';

import CreateEditFlowModal from './create-edit-flow.modal';
import CreateEditFolderModal from './create-edit-folder.modal';
import DeleteFolderModal from './delete-folder.modal';
import FlowTable from './flow.table';
import FoldersList from './folders.list';
import {
  CreateFlow,
  IFlowFolder,
  IFlowWithActivatorsAndFolder,
} from './interfaces';

interface FlowListProps {
  selectFlow: (flow: IFlowWithActivatorsAndFolder) => void;
}

const FlowsList: FC<FlowListProps> = ({ selectFlow }) => {
  const { bot, flows, refetchFlows } = useContext(BotContext);

  const colors = useThemeColors();

  const { openConfirmationDialog } = useContext(AppContext);
  const [flowModalOpen, setFlowModalOpen] = useState(false);
  const [isEditFlowModal, setIsEditFlowModal] = useState<boolean>(false);
  const [selectedFlow, setSelectedFlow] =
    useState<IFlowWithActivatorsAndFolder | null>(null);
  const [search, setSearch] = useState('');

  const [folders, setFolders] = useState<IFlowFolder[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<IFlowFolder | null>(
    null,
  );
  const [flowFolderModalOpen, setFlowFolderModalOpen] = useState(false);
  const [deleteFolderModalOpen, setDeleteFolderModalOpen] = useState(false);
  const [isCreateFlowFolder, setIsCreateFolder] = useState<boolean>(false);
  const [currentFolder, setCurrentFolder] = useState<IFlowFolder | null>(null);

  const getFolders = () => {
    fetchFlowFolders(bot._id).then(setFolders);
  };

  const onCloseModalFolder = () => {
    setFlowFolderModalOpen(false);
    setIsCreateFolder(false);
    setDeleteFolderModalOpen(false);
    setCurrentFolder(null);
  };

  const openEditFolderModal = (folder: IFlowFolder) => {
    setCurrentFolder(folder);
    setFlowFolderModalOpen(true);
    setIsCreateFolder(false);
  };

  const onCreateModalFolder = () => {
    setFlowFolderModalOpen(true);
    setIsCreateFolder(true);
  };

  const openDeleteFolderModal = (folder: IFlowFolder) => {
    setCurrentFolder(folder);
    setDeleteFolderModalOpen(true);
  };

  const onSubmitFolder = (name: string) => {
    if (isCreateFlowFolder) {
      createFolder({
        name,
        telegramBot: bot._id,
      }).then(getFolders);
    } else {
      if (!currentFolder) return;
      editFolder(currentFolder?._id, { name }).then(() => {
        getFolders();
        refetchFlows();
      });
    }
  };

  const onDeleteFolder = (deleteWithFlow: boolean) => {
    if (!currentFolder) return;
    deleteFolder(currentFolder?._id, deleteWithFlow).then(() => {
      getFolders();
      refetchFlows();
    });
    onCloseModalFolder();
  };

  const filteredFlows = useMemo(() => {
    if (search) {
      return flows.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (selectedFolder) {
      return flows.filter(({ folder }) => folder?._id === selectedFolder._id);
    }

    return flows;
  }, [search, selectedFolder, flows]);

  const onCloseFlowModal = () => {
    setFlowModalOpen(false);
    setIsEditFlowModal(false);
    setSelectedFlow(null);
  };

  const onSelectFolder = (folder: IFlowFolder | null) => {
    setSelectedFolder(folder);
  };

  const onEditFlowModal = (flow: IFlowWithActivatorsAndFolder) => {
    setFlowModalOpen(true);
    setIsEditFlowModal(true);
    setSelectedFlow(flow);
  };

  const onCopyFlow = (flow: IFlowWithActivatorsAndFolder) => {
    openConfirmationDialog(
      `${t('common.copyFlow')} "${flow.name}"?`,
      t('common.copy'),
      () => {
        createFlow({
          name: `${flow.name} ( copy )`,
          folder: flow.folder?._id ?? null,
          type: flow.type,
          telegramBot: flow.telegramBot,
          copyFrom: flow._id,
        }).then(refetchFlows);
      },
    );
  };

  const showAllFlows = () => {
    setSelectedFolder(null);
    setSearch('');
  };

  const onSubmit = ({
    name,
    folder,
    type,
    copyFrom,
  }: Omit<CreateFlow, 'telegramBot'>) => {
    if (!isEditFlowModal) {
      createFlow({
        name,
        folder,
        type,
        telegramBot: bot._id,
        copyFrom,
      }).then(refetchFlows);
      setFlowModalOpen(false);
    } else {
      if (!selectedFlow) return;

      editFlow(selectedFlow?._id, {
        name,
        type,
        folder,
      }).then(() => {
        setSelectedFlow(null);
        setFlowModalOpen(false);
        setIsEditFlowModal(false);
        refetchFlows();
        getFolders();
      });
    }
  };

  useEffect(() => {
    getFolders();
  }, [bot]);

  return (
    <Stack
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT_PX}px)`,
        padding: '0 40px 20px',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: 'sticky',
          top: '0',
          py: '20px',
          mx: '-40px',
          px: '40px',
          backgroundColor: alpha(colors.grey['14'], 0.3),
          backdropFilter: 'blur(4px)',
          mb: '20px',
          borderBottom: '1.5px solid',
          borderColor: 'grey.10',
          zIndex: 3,
        }}
      >
        <Stack direction="row">
          <Button
            onClick={() => setFlowModalOpen(true)}
            startIcon={<AddIcon />}
            className="brightGreen"
            sx={{
              width: '264px',
              height: '56px',
            }}
          >
            {t('flow.createFlowBtn')}
          </Button>
        </Stack>
        <Input
          value={search}
          handleChangeSearch={(e) => setSearch(e.target.value)}
        />
      </Stack>
      <Stack
        sx={{
          mt: '20px',
          borderRadius: '12px',
          border: '1.5px solid',
          borderColor: 'grey.10',
          backgroundColor: 'grey.14',
          p: '18px',
          height: '100%',
          overflow: 'auto',
        }}
        gap="5px"
      >
        <FoldersList
          selectedFolder={selectedFolder}
          onSelectFolder={onSelectFolder}
          folders={folders}
          showAllFlows={showAllFlows}
          onDeleteFolder={openDeleteFolderModal}
          onEditFolder={openEditFolderModal}
          onCreateModalFolder={onCreateModalFolder}
        />
        <Divider
          sx={{
            m: '24px 0',
          }}
        />
        <Stack gap="5px">
          <FlowTable
            shownFlows={filteredFlows}
            openEditFlowModal={onEditFlowModal}
            openNodesEditor={selectFlow}
            onCopyFlow={onCopyFlow}
          />
        </Stack>
      </Stack>

      <ModalBox
        open={flowModalOpen}
        onClose={onCloseFlowModal}
        sx={{
          border: '1px solid ',
          borderColor: 'grey.10',
          borderRadius: '12px',
          padding: '32px 56px',
          position: 'relative',
        }}
        maxWidth="458px"
      >
        <CreateEditFlowModal
          folders={folders}
          onCloseModal={onCloseFlowModal}
          selectedFlow={selectedFlow}
          onSubmit={onSubmit}
        />
      </ModalBox>
      <ModalBox
        open={flowFolderModalOpen}
        onClose={onCloseModalFolder}
        sx={{
          padding: '32px 56px',
          position: 'relative',
        }}
        maxWidth="390px"
      >
        <CreateEditFolderModal
          createFolder={isCreateFlowFolder}
          folder={currentFolder}
          onClose={onCloseModalFolder}
          onSubmit={onSubmitFolder}
        />
      </ModalBox>
      <ModalBox
        open={deleteFolderModalOpen}
        onClose={onCloseModalFolder}
        maxWidth="430px"
        sx={{
          p: '32px 56px',
        }}
      >
        <DeleteFolderModal
          folder={currentFolder}
          onDeleteFolder={onDeleteFolder}
          onCloseModalFolder={onCloseModalFolder}
        />
      </ModalBox>
    </Stack>
  );
};

export default FlowsList;
