import { ITelegramChannel } from '../../telegram-bots/channels/interfaces';
import axios from '../axios';

interface searchParamsProps {
  params: string[];
  startDate?: string;
  endDate: string;
  startDate2?: string;
  endDate2?: string;
}

const fetchChannelStatistics = async (
  botId: string,
  channelId: ITelegramChannel['_id'],
  searchParams: searchParamsProps,
) => {
  const { data } = await axios.post(
    `/telegram-bots/${botId}/channels/${channelId}/statistics`,
    searchParams,
  );

  return data;
};

export default fetchChannelStatistics;
