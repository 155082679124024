import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import TooltipInfo from '../../common/tooltip/tooltip';

import {
  CreateFlow,
  FlowTypes,
  IFlowFolder,
  IFlowWithActivatorsAndFolder,
} from './interfaces';

const MAX_NAME_LENGTH = 45;

interface FlowModalProps {
  folders: IFlowFolder[];
  selectedFlow: IFlowWithActivatorsAndFolder | null;
  onCloseModal: () => void;
  onSubmit: (newFlow: Omit<CreateFlow, 'telegramBot'>) => void;
}

const CreateEditFlowModal: FC<FlowModalProps> = ({
  folders,
  onCloseModal,
  onSubmit,
  selectedFlow,
}) => {
  const [name, setName] = useState('');
  const [type, setType] = useState<FlowTypes[]>([
    FlowTypes.broadcast,
    FlowTypes.messenger,
    FlowTypes.join,
    FlowTypes.leave,
    FlowTypes.postback,
  ]);
  const [folder, setFolder] = useState<IFlowFolder['_id'] | null | undefined>(
    null,
  );
  const [isCopyFrom, setIsCopyFrom] = useState<boolean>(false);
  const [copyFrom, setCopyFrom] = useState<string | null>(null);

  const handleChangeFolder = (event: SelectChangeEvent) => {
    setFolder(event.target.value);
  };

  const createButtonDisabled = Boolean(!name);

  const onSubmitClick = () => {
    onSubmit({
      name,
      folder: folder ? folder : null,
      type,
      copyFrom,
    });
    setName('');
    setFolder(null);
    setCopyFrom('');
    setIsCopyFrom(false);
    setType([]);
  };

  const onNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_NAME_LENGTH) {
      setName(value);
    }
  };

  const onCopyFromFlowInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCopyFrom(value);
  };

  const handleCheckboxChange = (names: FlowTypes[], value: boolean) => {
    setType((prev) => {
      if (value) {
        return [...prev, ...names];
      } else {
        return prev.filter((type) => !names.includes(type));
      }
    });
  };

  useEffect(() => {
    if (selectedFlow) {
      setName(selectedFlow.name);
      setType(selectedFlow.type);
      setFolder(selectedFlow.folder?._id ?? null);
    }
  }, [selectedFlow]);
  return (
    <Stack>
      <Stack
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer',
        }}
        onClick={onCloseModal}
      >
        <CloseIcon />
      </Stack>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          fontSize: '24px',
          mb: '16px',
        }}
      >
        {selectedFlow ? t('flow.editFlowTitle') : t('flow.createFlowTitle')}
      </Typography>
      <TextField
        placeholder={t('common.nameLabel')}
        value={name}
        onChange={onNameInput}
      />
      <Box sx={{ minWidth: 120, mt: '8px' }}>
        <FormControl fullWidth>
          <InputLabel>{t('flow.folder')}</InputLabel>
          <Select
            value={
              folder
                ? folders.find((flowFolder) =>
                    flowFolder._id.includes(folder ?? ''),
                  )?._id
                : ''
            }
            sx={{ backgroundColor: 'grey.15' }}
            label="Folder"
            onChange={handleChangeFolder}
          >
            {folders.map(({ _id, name }) => (
              <MenuItem key={_id} value={_id}>
                {name}
              </MenuItem>
            ))}
            {folder && (
              <MenuItem value={undefined}>{t('flow.withoutFolder')}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
      <Stack>
        <Stack flexDirection="row" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => setIsCopyFrom(event.target.checked)}
                checked={isCopyFrom}
                style={{
                  color: isCopyFrom ? '#DEFF82' : '#4b4a4a',
                }}
              />
            }
            sx={{ span: { fontSize: '14px' } }}
            label={t('flow.migrateData')}
          />
        </Stack>
        {isCopyFrom && (
          <TextField
            placeholder={t('flow.enterIdFlow')}
            value={copyFrom}
            onChange={onCopyFromFlowInput}
          />
        )}
      </Stack>
      <Stack
        sx={{
          mt: '16px',
        }}
      >
        <Stack flexDirection="row" gap="8px">
          <Typography
            sx={{
              color: 'grey.5',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '130%',
            }}
          >
            {t('flow.types.title')}
          </Typography>
          <TooltipInfo
            icon={<InfoOutlinedIcon sx={{ color: 'grey.5' }} />}
            title={t('flow.types.tooltip')}
            placement="top"
          />
        </Stack>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{
              width: '50%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.broadcast],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.broadcast)}
                  style={{
                    color: type.includes(FlowTypes.broadcast)
                      ? '#DEFF82'
                      : '#4b4a4a',
                  }}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('common.broadcasts')}
            />
          </Stack>

          <Stack flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.postback],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.postback)}
                  style={{
                    color: type.includes(FlowTypes.postback)
                      ? '#DEFF82'
                      : '#4b4a4a',
                  }}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('flow.types.labels.postback')}
            />
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.messenger],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.messenger)}
                  style={{
                    color: type.includes(FlowTypes.messenger)
                      ? '#DEFF82'
                      : '#4b4a4a',
                  }}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('flow.types.labels.messenger')}
            />
          </Stack>
          <Stack flexDirection="row" alignItems="center" sx={{ mr: '5px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    handleCheckboxChange(
                      [FlowTypes.join, FlowTypes.leave],
                      event.target.checked,
                    )
                  }
                  checked={type.includes(FlowTypes.join)}
                  style={{
                    color: type.includes(FlowTypes.join)
                      ? '#DEFF82'
                      : '#4b4a4a',
                  }}
                />
              }
              sx={{ span: { fontSize: '14px' } }}
              label={t('flow.types.labels.channels')}
            />
          </Stack>
        </Box>
      </Stack>
      <Button
        disabled={createButtonDisabled}
        className="brightGreen"
        sx={{
          borderRadius: '8px',
          height: '56px',
          mt: '24px',
        }}
        onClick={onSubmitClick}
      >
        {selectedFlow ? t('common.save') : t('common.create')}
      </Button>
    </Stack>
  );
};

export default CreateEditFlowModal;
