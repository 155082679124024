import React, { CSSProperties, FC, ReactNode } from 'react';

import bgPattern from '../../assets/bgPattern.png';

interface WithBgPatternProps {
  children: ReactNode;
  style?: CSSProperties;
}

const WithBackgroundPattern: FC<WithBgPatternProps> = ({
  children,
  style = {},
}) => {
  return (
    <div
      style={{
        background: `url("${bgPattern}")`,
        backgroundSize: 'cover',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default WithBackgroundPattern;
