import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FC, useContext, useState } from 'react';

import {
  IMedia,
  TelegramButton,
  TelegramMessageType,
} from '../../interfaces/common';
import { IFlowWithActivatorsAndFolder } from '../../telegram-bots/flows/interfaces';
import { UserContext } from '../../user/user.context';
import truncate from '../../utils/truncate';
import { ITemplate } from '../templates/interfaces';

interface TemplatesBoxProps {
  templates: ITemplate[];
  onTemplateClick: (
    text: string,
    buttons: TelegramButton[][],
    media: IMedia[],
    type: TelegramMessageType,
  ) => void;
  flows: IFlowWithActivatorsAndFolder[];
  onSendFlow: (flow: IFlowWithActivatorsAndFolder) => void;
  disableFlows: boolean;
}

const TemplatesBox: FC<TemplatesBoxProps> = ({
  templates,
  onTemplateClick,
  flows,
  onSendFlow,
  disableFlows,
}) => {
  const { user } = useContext(UserContext);

  const types = {
    generalTemplate: 'generalTemplate',
    privateTemplate: 'privateTemplate',
    flows: 'flows',
  };

  const [chosenType, setChosenType] = useState<string>('generalTemplate');

  return (
    <Stack
      sx={{
        marginBottom: '10px',
      }}
    >
      <Typography
        sx={{ textAlign: 'center', fontSize: '16px', fontWeight: 500 }}
      >
        {t('messenger.templatesLabel')}
      </Typography>
      <Stack>
        <FormControl>
          <RadioGroup
            defaultValue={t('messenger.templates.generalLabel')}
            name="radio-buttons-group"
            sx={{
              flexDirection: 'row',
              m: '0 auto',
              fontWeight: 500,
              fontSize: '14px',
            }}
          >
            <FormControlLabel
              sx={{
                '& .MuiButtonBase-root': {
                  p: '6px',
                },
                '& .MuiTypography-root': {
                  fontSize: '13px',
                },
              }}
              value={t('messenger.templates.generalLabel')}
              control={
                <Radio
                  sx={{
                    color: 'green.3',
                    '&.Mui-checked': {
                      color: 'green.3',
                    },
                  }}
                />
              }
              onClick={() => setChosenType(types.generalTemplate)}
              label={t('messenger.templates.generalLabel')}
            />
            <FormControlLabel
              sx={{
                '& .MuiButtonBase-root': {
                  p: '6px',
                },
                '& .MuiTypography-root': {
                  fontSize: '13px',
                },
              }}
              value={t('messenger.templates.privateLabel')}
              control={
                <Radio
                  sx={{
                    color: 'green.3',
                    '&.Mui-checked': {
                      color: 'green.3',
                    },
                  }}
                />
              }
              onClick={() => setChosenType(types.privateTemplate)}
              label={t('messenger.templates.privateLabel')}
            />
            {!disableFlows && (
              <FormControlLabel
                sx={{
                  '& .MuiButtonBase-root': {
                    p: '6px',
                  },
                  '& .MuiTypography-root': {
                    fontSize: '13px',
                  },
                }}
                value={t('common.flows')}
                control={
                  <Radio
                    sx={{
                      color: 'green.3',
                      '&.Mui-checked': {
                        color: 'green.3',
                      },
                    }}
                  />
                }
                onClick={() => setChosenType(types.flows)}
                label={t('common.flows')}
              />
            )}
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack gap="18px" sx={{ mt: '15px', overflow: 'auto', height: '100%' }}>
        {(() => {
          switch (chosenType) {
            case types.generalTemplate: {
              return (
                <>
                  {templates
                    .filter((template) => !template.isPrivate)
                    .map((template: ITemplate) => (
                      <Button
                        key={template._id}
                        fullWidth
                        sx={{
                          fontWeight: 600,
                          fontSize: '15px',
                          padding: '17px',
                          color: '#FFFFFF',
                          backgroundColor: 'green.1',
                          border: '2px solid',
                          borderRadius: '12px',
                          borderColor: 'green.2',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: 'green.2',
                            color: 'grey.15',
                          },
                        }}
                        onClick={() =>
                          onTemplateClick(
                            template.text ?? '',
                            template.buttons,
                            template?.media ?? [],
                            template?.type.toString() as TelegramMessageType,
                          )
                        }
                      >
                        {truncate(template.name)}
                      </Button>
                    ))}
                </>
              );
            }
            case types.privateTemplate: {
              return (
                <>
                  {templates
                    .filter(
                      (template) =>
                        template.isPrivate &&
                        template.owner.includes(user?._id ?? ''),
                    )
                    .map((template: ITemplate) => (
                      <Button
                        key={template._id}
                        fullWidth
                        sx={{
                          fontWeight: 600,
                          fontSize: '15px',
                          padding: '17px',
                          color: '#FFFFFF',
                          backgroundColor: 'green.1',
                          border: '2px solid',
                          borderRadius: '12px',
                          borderColor: 'green.2',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: 'green.2',
                            color: 'grey.15',
                          },
                        }}
                        onClick={() =>
                          onTemplateClick(
                            template.text ?? '',
                            template.buttons,
                            template?.media ?? [],
                            template?.type.toString() as TelegramMessageType,
                          )
                        }
                      >
                        {truncate(template.name)}
                      </Button>
                    ))}
                </>
              );
            }
            case types.flows: {
              return (
                <>
                  {flows.map((flow) => (
                    <Button
                      key={flow._id}
                      fullWidth
                      sx={{
                        fontWeight: 600,
                        fontSize: '15px',
                        padding: '17px',
                        color: '#FFFFFF',
                        backgroundColor: 'blue.1',
                        border: '2px solid',
                        borderRadius: '12px',
                        borderColor: 'blue.2',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: 'blue.2',
                          color: 'grey.15',
                        },
                      }}
                      onClick={() => onSendFlow(flow)}
                    >
                      {truncate(flow.name)}
                    </Button>
                  ))}
                </>
              );
            }
          }
        })()}
      </Stack>
    </Stack>
  );
};

export default TemplatesBox;
